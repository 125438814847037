import { HttpClient } from "../Helpers/http-client";
import {ISftpAccount} from "../Models/Entities/sftp-account.interface";

export class SftpAccountService {

    static getBlankAccount():ISftpAccount {
        return {
            active: false,
            districtId: null,
            id: 0,
            password: "",
            schoolId: null,
            server: "",
            username: ""
        };
    }

    static async getSftpAccount(districtId: number | null, schoolId: number | null): Promise<ISftpAccount> {
        return HttpClient.get({
            path: "/sftp",
            params: {
                districtId: districtId,
                schoolId: schoolId,
            }
        })
    }
}
