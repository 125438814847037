import { getRouteConfigs } from "./route-configs";
import { AppIcon } from "../Shared/Components/Icons/Icons";

/* eslint-disable */
export interface IRouteConfig {
  key: string;
  path: string;
  name: string;
  component: any;
  icon: AppIcon;
  exact?: boolean;
  enabled?: boolean;
  requireSchool?: boolean;
  requireSchoolOrGroup?: boolean;
  children?: IRouteConfig[];
  parents?: IRouteConfig[];
}
/* eslint-enable */

export class RouteHelper {
  public static getRootConfigs(): IRouteConfig[] {
    const routeConfigs = getRouteConfigs();
    return routeConfigs.map((routeConfig) => {
      delete routeConfig.children;
      return routeConfig;
    });
  }

  public static getFlatConfigsList(): IRouteConfig[] {
    const routeConfigs = getRouteConfigs();
    return RouteHelper.transformNestedConfigsIntoFlat(null, routeConfigs);
  }

  private static transformNestedConfigsIntoFlat(
    parentRouteConfig: IRouteConfig | null,
    childRouteConfigs: IRouteConfig[] | undefined
  ): IRouteConfig[] {
    const flatChildConfigList: IRouteConfig[] = [];

    // Build our list of children + their grandchildren
    childRouteConfigs?.forEach((childConfig) => {
      flatChildConfigList.push({
        ...childConfig,
        parents: [],
      });

      const flatGrandchildConfig = RouteHelper.transformNestedConfigsIntoFlat(
        childConfig,
        childConfig.children
      );
      flatChildConfigList.push(...flatGrandchildConfig);
    });

    // Add our parent route config to each routeConfig's parent list
    return flatChildConfigList.map((childConfig) => {
      if (parentRouteConfig) {
        childConfig.parents?.unshift(parentRouteConfig);
      }
      return childConfig;
    });
  }

  public static doesRouteConfigMatchRootConfig(
    mainConfig: IRouteConfig,
    rootConfig: IRouteConfig
  ): boolean {
    const routeKeys = [
      mainConfig.key,
      ...(mainConfig.parents?.map((parentConfig) => parentConfig.key) || []),
    ];
    return routeKeys.includes(rootConfig.key);
  }
}
