import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDistrictNode } from "../../Models/Interfaces/district-node.interface";
import { ISchoolDistrictManagerReducerState } from "./school-district-manager-reducer-state.interface";
import {
  DistrictTreeService,
  IDistrictTreeNeededData,
} from "../../Services/district-tree.service";
import { ValueHelper } from "../../Helpers/value.helper";

const initialState: ISchoolDistrictManagerReducerState = {
  districts: [],
  schools: [],
  allDistrictNodes: [],
  filteredDistrictNodes: [],
  keyword: null,
  selectedId: null,
  selectedType: null,
};

export const SchoolDistrictManagerReducerSlice = createSlice({
  name: "districts",
  initialState,
  reducers: {
    setDistrictsTree: (
      state: ISchoolDistrictManagerReducerState,
      action: PayloadAction<IDistrictTreeNeededData>
    ): void => {
      const { districts, schools } = action.payload;
      state.districts = districts;
      state.schools = schools;
      state.allDistrictNodes = DistrictTreeService.buildDistrictNodes({
        districts,
        schools,
      });
      state.filteredDistrictNodes = filterDistrictsByKeyword(state);
      state.selectedId = null;
    },

    filterDistrictsTree: (
      state: ISchoolDistrictManagerReducerState,
      action: PayloadAction<string | null>
    ): void => {
      const keyword = action.payload;
      state.keyword = keyword;
      state.filteredDistrictNodes = filterDistrictsByKeyword(state);
    },

    setDistrictIsOpen: (
      state: ISchoolDistrictManagerReducerState,
      action: PayloadAction<{ districtId: number; isOpen: boolean }>
    ): void => {
      const { districtId, isOpen } = action.payload;
      const allDistrictNode = state.allDistrictNodes.find((districtNode) => {
        return districtNode.district.id === districtId;
      });
      const filteredDistrictNode = state.filteredDistrictNodes.find(
        (districtNode) => {
          return districtNode.district.id === districtId;
        }
      );
      if (allDistrictNode) allDistrictNode.isOpen = isOpen;
      if (filteredDistrictNode) filteredDistrictNode.isOpen = isOpen;
    },

    setSelectedDistrict: (
      state: ISchoolDistrictManagerReducerState,
      action: PayloadAction<number | null>
    ): void => {
      state.selectedId = action.payload;
      state.selectedType = state.selectedId ? "district" : null;
    },

    setSelectedSchool: (
      state: ISchoolDistrictManagerReducerState,
      action: PayloadAction<number | null>
    ): void => {
      state.selectedId = action.payload;
      state.selectedType = state.selectedId ? "school" : null;
    },
  },
});

export const SchoolDistrictManagerReducerActions =
  SchoolDistrictManagerReducerSlice.actions;

const filterDistrictsByKeyword = (
  state: ISchoolDistrictManagerReducerState
): IDistrictNode[] => {
  const { keyword, allDistrictNodes, selectedId } = state;
  if (keyword && keyword.length >= 3) {
    return allDistrictNodes
      .map((districtNode) =>
        getFilteredDistrictNode(keyword, districtNode, selectedId)
      )
      .filter((node) => !!node)
      .slice(0, 100) as IDistrictNode[];
  } else {
    return allDistrictNodes.slice(0, 100);
  }
};

const getFilteredDistrictNode = (
  keyword: string | null,
  districtNode: IDistrictNode,
  selectedItemId: number | null
): IDistrictNode | null => {
  const districtSelected =
    districtNode.district.id === selectedItemId ||
    doesTextContainKeyword(districtNode.district.name, keyword) ||
    doesTextContainKeyword(districtNode.district.id.toString(), keyword);
  if (districtSelected) {
    districtNode.isOpen = false;
    return {
      ...districtNode,
      isOpen: false,
    };
  } else {
    const schoolsSelected = districtNode.schools.filter((school) => {
      return (
        school.id === selectedItemId ||
        doesTextContainKeyword(school.name, keyword) ||
        doesTextContainKeyword(school.id.toString(), keyword)
      );
    });
    if (schoolsSelected.length) {
      return {
        ...districtNode,
        schools: schoolsSelected,
        isOpen: true,
      };
    } else {
      return null;
    }
  }
};

function doesTextContainKeyword(
  text: string | null,
  keyword: string | null
): boolean {
  return (
    !!keyword &&
    ValueHelper.getRawText(text).includes(ValueHelper.getRawText(keyword))
  );
}
