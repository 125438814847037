import React from "react";
import {
  CardHeader as MuiCardHeader,
  CardHeaderProps,
} from "@material-ui/core";
import { PaletteTypes } from "../../Themes/palette-types.enum";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { AppIcon } from "../Icons/Icons";

const CardHeaderStyles = appMakeStyles((theme) => ({
  cardHeader: {
    backgroundColor: (props: ICardHeaderProps) =>
      theme.palette.overrideHeaderBackgroundColor ||
      theme.palette[props.palette]?.main,
    color: (props: ICardHeaderProps) =>
      theme.palette.overrideHeaderColor ||
      theme.palette[props.palette]?.contrastText,
    borderRadius: "6px",
    margin: "-20px 15px 0",
    position: "relative",
    zIndex: 3,
    backgroundClip: "border-box",
    whiteSpace: "pre",

    "& .MuiCardHeader-title": {
      color: "white !important",
      fontSize: theme.typography.headerFontSize,
      fontWeight: 300,
    },
    "& .MuiCardHeader-subheader": {
      color: "rgba(255,255,255,.8); !important",
      fontSize: theme.typography.fontSize,
      fontWeight: 300,
      wordWrap: "break-word",
      overflow: "hidden",
      whiteSpace: "normal",
    },
    "& .MuiCardHeader-action": {
      margin: 0,
    },
  },
  cardTitleWithIcon: {
    display: "flex",
    alignItems: "center",

    "& svg": {
      fontSize: theme.typography.headerFontSize * 1.2,
      marginRight: "5px",
    },
  },
}));

export interface ICardHeaderProps extends CardHeaderProps {
  title: string;
  palette: PaletteTypes;
  icon?: AppIcon;
}

export const AppCardHeader = (props: ICardHeaderProps): JSX.Element => {
  const classes = CardHeaderStyles(props);

  const { icon: Icon, title, ...otherProps } = props;

  return (
    <MuiCardHeader
      {...otherProps}
      className={classes.cardHeader}
      title={
        Icon ? (
          <div className={classes.cardTitleWithIcon}>
            <Icon />
            {title}
          </div>
        ) : (
          title
        )
      }
    />
  );
};
