import React from "react";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import { appMakeStyles } from "../../Shared/Themes/app-make-styles";
import { LinearProgress } from "@material-ui/core";

const LoadingBarStyles = appMakeStyles((theme) => ({
  loadingBarContainer: {
    float: "left",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "stretch",
    alignItems: "flex-start",
    zIndex: 10000,
    background: theme.palette.background.loadingTint,
    cursor: "pointer",
  },
  progressBar: {
    width: "100%",
    height: "8px",
  },
}));

export const AppLoadingBar = (): JSX.Element => {
  const classes = LoadingBarStyles({});

  const isLoading = StoreHelper.selector((state) => state.loadingBar.isLoading);

  if (isLoading) {
    return (
      <div className={classes.loadingBarContainer}>
        <LinearProgress
          variant="indeterminate"
          color="primary"
          className={classes.progressBar}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
