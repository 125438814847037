import React, { ComponentProps } from "react";
import accountKey from "@iconify-icons/mdi/account-key";
import accountMultipleRemove from "@iconify-icons/mdi/account-multiple-remove";
import domain from "@iconify-icons/mdi/domain";
import database from "@iconify-icons/mdi/database";
import faceAgent from "@iconify-icons/mdi/face-agent";
import fountainPen from "@iconify-icons/mdi/fountain-pen";
import clockOutline from "@iconify-icons/mdi/clock-outline";
import calendar from "@iconify-icons/mdi/calendar";
import google from "@iconify-icons/mdi/google";
import login from "@iconify-icons/mdi/login";
import arrowCollapseLeft from "@iconify-icons/mdi/arrow-collapse-left";
import arrowCollapseRight from "@iconify-icons/mdi/arrow-collapse-right";
import shapeIcon from "@iconify-icons/mdi/shape";
import uploadIcon from "@iconify-icons/mdi/upload";
import { Icon as IconifyIcon } from "@iconify/react";
import {
  AccessTime,
  Group,
  Person,
  PersonAdd,
  Category,
  School,
  VerifiedUser,
  Edit,
  Delete,
  Add,
  Close,
  KeyboardArrowUp,
  KeyboardArrowDown,
  AccountBox,
  Check,
  Dns,
  Email,
  Menu,
  Flag,
  Home,
  Search,
  Star,
  LocationCity,
  ExpandLess,
  ExpandMore,
  Phone,
  AccountBoxOutlined,
  Info,
  Lock,
  Refresh,
  SupervisedUserCircle,
  AssignmentInd,
  ExitToApp,
  CompareArrows,
  WbSunny,
  Save,
  Brightness3,
  SvgIconComponent,
  EmojiPeople,
  Visibility,
  Public,
  KeyboardArrowRight,
  Notifications,
} from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { HeroIcon } from "./CustomIcons/HeroIcon";

/* eslint-disable react/display-name  */
/* eslint-disable @typescript-eslint/no-explicit-any */

export type AppIcon = (props: ComponentProps<any>) => JSX.Element;

const buildIconifyIcon =
  (iconType: any): AppIcon =>
  (props) =>
    <IconifyIcon icon={iconType} width="20" height="20" {...props} />;

const buildMaterialIcon =
  (MaterialIcon: SvgIconComponent): AppIcon =>
  (props) =>
    <MaterialIcon {...props} />;

const buildCircularProgressIcon = (): AppIcon => (props) =>
  (
    <CircularProgress
      width={20}
      height={20}
      style={{ marginRight: "5px" }}
      {...props}
    />
  );

const buildCustomSvgIcon =
  (SvgIcon: any): AppIcon =>
  (props) =>
    <SvgIcon {...props} />;

export const Icons = {
  AccountBox: buildMaterialIcon(AccountBox),
  AccountBoxOutlined: buildMaterialIcon(AccountBoxOutlined),
  AccountKey: buildIconifyIcon(accountKey),
  AccountMultipleRemove: buildIconifyIcon(accountMultipleRemove),
  ArrowCollapseLeft: buildIconifyIcon(arrowCollapseLeft),
  ArrowCollapseRight: buildIconifyIcon(arrowCollapseRight),
  Add: buildMaterialIcon(Add),
  AssignmentInd: buildMaterialIcon(AssignmentInd),
  Brightness3: buildMaterialIcon(Brightness3),
  Bell: buildMaterialIcon(Notifications),
  Calendar: buildIconifyIcon(calendar),
  Category: buildMaterialIcon(Category),
  Check: buildMaterialIcon(Check),
  ClockOutline: buildIconifyIcon(clockOutline),
  Close: buildMaterialIcon(Close),
  CompareArrows: buildMaterialIcon(CompareArrows),
  Database: buildIconifyIcon(database),
  Delete: buildMaterialIcon(Delete),
  District: buildIconifyIcon(domain),
  Dns: buildMaterialIcon(Dns),
  Edit: buildMaterialIcon(Edit),
  Email: buildMaterialIcon(Email),
  EmojiPeople: buildMaterialIcon(EmojiPeople),
  ExitToApp: buildMaterialIcon(ExitToApp),
  ExpandLess: buildMaterialIcon(ExpandLess),
  ExpandMore: buildMaterialIcon(ExpandMore),
  FaceAgent: buildIconifyIcon(faceAgent),
  Flag: buildMaterialIcon(Flag),
  FountainPen: buildIconifyIcon(fountainPen),
  Globe: buildMaterialIcon(Public),
  Google: buildIconifyIcon(google),
  Group: buildMaterialIcon(Group),
  Hero: buildCustomSvgIcon(HeroIcon),
  Home: buildMaterialIcon(Home),
  Info: buildMaterialIcon(Info),
  KeyboardArrowDown: buildMaterialIcon(KeyboardArrowDown),
  KeyboardArrowRight: buildMaterialIcon(KeyboardArrowRight),
  KeyboardArrowUp: buildMaterialIcon(KeyboardArrowUp),
  Loading: buildCircularProgressIcon(),
  LocationCity: buildMaterialIcon(LocationCity),
  Lock: buildMaterialIcon(Lock),
  Login: buildIconifyIcon(login),
  Menu: buildMaterialIcon(Menu),
  Person: buildMaterialIcon(Person),
  PersonAdd: buildMaterialIcon(PersonAdd),
  Phone: buildMaterialIcon(Phone),
  Refresh: buildMaterialIcon(Refresh),
  Save: buildMaterialIcon(Save),
  School: buildMaterialIcon(School),
  Search: buildMaterialIcon(Search),
  Shape: buildIconifyIcon(shapeIcon),
  Star: buildMaterialIcon(Star),
  SupervisedUserCircle: buildMaterialIcon(SupervisedUserCircle),
  Time: buildMaterialIcon(AccessTime),
  Upload: buildIconifyIcon(uploadIcon),
  VerifiedUser: buildMaterialIcon(VerifiedUser),
  Visibility: buildMaterialIcon(Visibility),
  WbSunny: buildMaterialIcon(WbSunny),
};
