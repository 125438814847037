import React, { useState } from "react";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppMultipleSelectControl } from "../../../../Shared/Components/Controls/MultipleSelectControl";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { Logger } from "../../../../Shared/Helpers/logger";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { IBellScheduleConfig } from "../../../../Shared/Models/Entities/bell-schedule-config.interface";
import { IBellSchedule } from "../../../../Shared/Models/Entities/bell-schedule.interface";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { BellScheduleService } from "../../../../Shared/Services/bell-schedule.service";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppBellSchedulesOptions = (props: {
  bellSchedules: IBellSchedule[];
}): JSX.Element => {
  const { school } = StoreHelper.selector((state) => state.appConfig);

  const {
    data: config,
    setData: setConfig,
    loading,
  } = DataHooks.useFunctionCallState<IBellScheduleConfig>({
    func: () => BellScheduleService.getConfig(school?.id),
  });
  const termsState = DataHooks.useFunctionCallState<string[]>({
    func: () => BellScheduleService.getTerms(school?.id),
  });

  const [changesMade, setChangesMade] = useState(false);

  const handleSave = async () => {
    LoadingBarService.show();
    try {
      if (config) {
        await BellScheduleService.updateConfig(config, school.id);
        setChangesMade(false);
      }
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  return (
    <AppCard hasHeader>
      <AppCardHeader
        title="Bell Schedule Options"
        icon={Icons.Info}
        palette={PaletteTypes.PRIMARY}
      />
      <AppCardContent>
        <AppGrid
          container
          justifyContent="center"
          spacing={3}
          direction="column"
        >
          <AppSelectControl
            label="Today's Bell Schedule"
            name="todayBellSchedule"
            type="number"
            value={config?.todayBellSchedule}
            selected={(value) => {
              const todayBellSchedule = parseInt(value as string);
              if (config) {
                setConfig({ ...config, todayBellSchedule });
                setChangesMade(true);
              }
            }}
            options={props.bellSchedules}
            loading={loading}
            icon={Icons.Calendar}
          />
          <AppSelectControl
            label="Default Bell Schedule"
            value={config?.defaultBellSchedule}
            selected={(value) => {
              const defaultBellSchedule = parseInt(value as string);
              if (config) {
                setConfig({ ...config, defaultBellSchedule });
                setChangesMade(true);
              }
            }}
            options={props.bellSchedules}
            loading={loading}
            icon={Icons.Calendar}
          />
          <AppMultipleSelectControl
            label="Active Terms"
            value={config ? config?.activeTerms.map((val) => val) : []}
            options={
              termsState.data
                ? termsState.data.map((term) => ({
                    name: term,
                    id: term,
                  }))
                : []
            }
            icon={Icons.Shape}
            onUpdate={(value) => {
              if (config) {
                setConfig({ ...config, activeTerms: value });
                setChangesMade(true);
              }
            }}
            multiple
          />
          <AppGrid item container direction="row" justifyContent="flex-end">
            <AppButton
              text="Update"
              icon={Icons.Check}
              palette={PaletteTypes.PRIMARY}
              disabled={!changesMade}
              onClick={() => handleSave()}
            />
          </AppGrid>
        </AppGrid>
      </AppCardContent>
    </AppCard>
  );
};
