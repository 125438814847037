import { HttpClient } from "../Helpers/http-client";
import { IDisciplinaryAction } from "../Models/Entities/disciplinary-action.interface";
import { ITracCodeDisciplinaryAction } from "../Models/Entities/trac-code-disciplinary-action.interface";

export class TracCodeDisciplinaryActionService {
  static async get(id: number): Promise<ITracCodeDisciplinaryAction> {
    return HttpClient.get({
      path: `/trac-code-disciplinary-actions/${id}`,
    });
  }

  static async create(
    tracCodeDisciplinaryAction: ITracCodeDisciplinaryAction,
    tracCodeId: number
  ): Promise<ITracCodeDisciplinaryAction> {
    return HttpClient.post({
      path: `/trac-code-disciplinary-actions?tracCodeId=${tracCodeId}`,
      body: tracCodeDisciplinaryAction,
    });
  }

  static async update(
    tracCodeDisciplinaryAction: ITracCodeDisciplinaryAction
  ): Promise<ITracCodeDisciplinaryAction> {
    return HttpClient.put({
      path: `/trac-code-disciplinary-actions`,
      body: tracCodeDisciplinaryAction,
    });
  }

  static async delete(bellScheduleId: number): Promise<IDisciplinaryAction> {
    return HttpClient.delete({
      path: `/trac-code-disciplinary-actions/${bellScheduleId}`,
    });
  }
}
