import { ISchoolUser } from "../../../Shared/Models/Entities/school-user.interface";
import { SchoolUserService } from "../../../Shared/Services/school-user.service";

export class QuickMovePopupHelper {
  public static async moveUser(
    schoolUserId: number,
    schoolId: number | null,
    districtId: number | null
  ): Promise<ISchoolUser> {
    const targetSchoolUser = await SchoolUserService.getById(schoolUserId);
    await QuickMovePopupHelper.updateUserAccess(
      targetSchoolUser,
      schoolId,
      districtId
    );
    return SchoolUserService.getById(schoolUserId);
  }

  public static async updateUserAccess(
    targetSchoolUser: ISchoolUser,
    schoolId: number | null,
    districtId: number | null
  ): Promise<void> {
    targetSchoolUser.school = schoolId
      ? {
          id: schoolId,
          name: "",
        }
      : undefined;
    targetSchoolUser.district = districtId
      ? {
          id: districtId,
          name: "",
        }
      : undefined;
    targetSchoolUser.role = {
      id: 2, // Set user to All Rights role
      name: "",
    };
    await SchoolUserService.update(targetSchoolUser);
  }
}
