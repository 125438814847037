import React from "react";
import MuiSnackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { SnackbarReducerActions } from "../../Shared/Reducers/Snackbar/snackbar.reducer";
import { StoreHelper } from "../../Shared/Reducers/store-helper";

export const AppSnackbar = (): JSX.Element => {
  const { isActive, severity, message } = StoreHelper.selector(
    (state) => state.snackbar
  );

  const handleAlertClose = () => {
    StoreHelper.dispatch(SnackbarReducerActions.clearSnackbar());
  };

  return (
    <MuiSnackbar
      open={isActive}
      autoHideDuration={9999999000}
      onClose={handleAlertClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={severity}
        onClose={() => {
          handleAlertClose();
        }}
      >
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};
