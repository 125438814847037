import { appMakeStyles } from "../../../../Shared/Themes/app-make-styles";

export const SchoolUserPermissionSelectorStyles = appMakeStyles<{
  disabled: boolean;
}>((theme) => ({
  formControl: {},
  inputLabel: {},
  permissionTree: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: "10px",
    background: (props) =>
      props.disabled ? "transparent" : theme.palette.background.paperAlternate,
    border: "1px solid " + theme.palette.divider,
    borderRadius: "4px",
  },
  treeActions: {
    position: "absolute",
    padding: "7px",
    top: 0,
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
  },
  treeAction: {
    margin: "3px",
  },
  permissionTreeNode: {
    display: "flex",
    flexDirection: "column",
  },
  permissionTreeNodeItem: {
    display: "flex",
  },
  permissionTreeNodeEmptyExpand: {
    display: "flex",
    width: "30px",
  },
  permissionTreeNodeExpand: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    cursor: "pointer",
    zIndex: 100,

    "&:hover": {
      background: theme.palette.background.paperHover,
    },
  },
  permissionTreeNodeSelect: {
    cursor: "pointer",

    "& *": {
      fontSize: "16px !important",
    },
  },
  permissionTreeNodeChildren: {
    paddingLeft: "10px",
    marginLeft: "15px",
    borderLeft: "1px solid " + theme.palette.divider,
  },
}));
