import React, { useState } from "react";
import { AppCard } from "../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../Shared/Components/Cards/CardContent";
import { AppTextFieldControl } from "../../Shared/Components/Controls/TextFieldControl";
import { AppGrid } from "../../Shared/Components/Grid";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import { AuthStyles } from "../AuthStyles";
import HeroLogoDark from "../../Shared/Assets/Images/hero-logo-dark.png";
import { Icons } from "../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../Shared/Themes/palette-types.enum";
import { AppButton } from "../../Shared/Components/Button";
import { AuthenticationService } from "../../Shared/Services/authentication.service";
import { LoadingBarService } from "../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../Shared/Services/snackbar.service";

export const AppRequestPasswordReset = (): JSX.Element => {
  const theme = StoreHelper.selector((state) => state.appConfig.theme);
  const classes = AuthStyles({ theme });
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    LoadingBarService.show();
    try {
      await AuthenticationService.requestPasswordReset(email);
      SnackbarService.success(
        "If an account exists with this email, a password reset link will be sent to you!"
      );
    } catch (error) {
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  React.useEffect(() => {
    document.title = "Hero Admin | Password Reset";
  }, []);
  return (
    <AppGrid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.authContainer}
    >
      <AppGrid item className={classes.authBox}>
        <AppCard>
          <AppCardContent padding={0}>
            <AppGrid item container direction="column" spacing={2} padding={15}>
              <AppGrid item container justifyContent="center">
                <img src={HeroLogoDark} alt="Hero" height="60px" width="auto" />
              </AppGrid>

              <AppGrid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.helpText}
              >
                <div className={classes.helpTitle}>Password Reset</div>
                <div className={classes.helpDescription}>
                  {
                    "Provide the email address you use to login (probably your school email) and we'll send a link to that address. Make sure to check your spam folder after a few minutes."
                  }
                </div>
              </AppGrid>

              <AppTextFieldControl
                label="Email"
                value={email}
                icon={Icons.Email}
                onChange={(event) => {
                  setEmail(event.target.value || "");
                }}
              />

              <AppButton
                gridProps={{ width: "100%" }}
                fullWidth
                text="Request"
                icon={Icons.Login}
                palette={PaletteTypes.PRIMARY}
                onClick={() => handleSubmit()}
              />
            </AppGrid>
          </AppCardContent>
        </AppCard>
      </AppGrid>
    </AppGrid>
  );
};
