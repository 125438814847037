import { ISchoolUserPermission } from "../../../../Shared/Models/Entities/school-user-permission.interface";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";

export interface IPermissionTreeNode {
  id: number;
  name: string;
  description: string;
  children: IPermissionTreeNode[];
  parents: IPermissionTreeNode[];
}

export class SchoolUserPermissionSelectorHelper {
  public static buildNestedTreeFromPermissionArr(
    permissionArr: ISchoolUserPermission[] | null
  ): IPermissionTreeNode[] {
    try {
      const permissionDict: { [permissionId: number]: IPermissionTreeNode } =
        {};

      // Fill out our dictionary at a flat level
      permissionArr?.forEach((permission) => {
        permissionDict[permission.id] = {
          id: permission.id,
          name: permission.name,
          description: permission.description,
          children: [],
          parents: [],
        };
      });

      // Setup all of our child <=> relationships
      permissionArr?.forEach((permission) => {
        if (permission.parentId && permissionDict[permission.parentId]) {
          permissionDict[permission.parentId].children.push(
            permissionDict[permission.id]
          );
          permissionDict[permission.id].parents.push(
            permissionDict[permission.parentId]
          );
        }
      });

      Object.values(permissionDict).forEach((permission) => {
        permission.children = permission.children.sort(
          (p1, p2) => p1.id - p2.id
        );
      });

      // Return our top level nodes
      return (
        permissionArr
          ?.filter((permission) => !permission.parentId)
          .sort((p1, p2) => p1.id - p2.id)
          .map((permission) => permissionDict[permission.id]) || []
      );
    } catch (error) {
      SnackbarService.error(error);
      return [];
    }
  }

  public static handleTreeNodeSelected(
    treeNode: IPermissionTreeNode,
    selectedPermissionIds: number[]
  ): number[] {
    const isSelected = selectedPermissionIds.includes(treeNode.id);
    if (isSelected) {
      const children =
        SchoolUserPermissionSelectorHelper.flattenNodeChildren(treeNode);
      const childIds = children.map((child) => child.id);
      const removeIds = [treeNode.id, ...childIds];
      return selectedPermissionIds.filter(
        (permissionId) => !removeIds.includes(permissionId)
      );
    } else {
      const parents =
        SchoolUserPermissionSelectorHelper.flattenNodeParents(treeNode);
      const parentIds = parents.map((parent) => parent.id);
      const addIds = [treeNode.id, ...parentIds];
      return selectedPermissionIds.concat(...addIds);
    }
  }

  public static flattenNodeChildren(
    treeNode: IPermissionTreeNode
  ): IPermissionTreeNode[] {
    return ([] as IPermissionTreeNode[]).concat(
      ...treeNode.children.map((childTreeNode) => [
        childTreeNode,
        ...SchoolUserPermissionSelectorHelper.flattenNodeChildren(
          childTreeNode
        ),
      ])
    );
  }

  public static flattenNodeParents(
    treeNode: IPermissionTreeNode
  ): IPermissionTreeNode[] {
    return ([] as IPermissionTreeNode[]).concat(
      ...treeNode.parents.map((parentTreeNode) => [
        parentTreeNode,
        ...SchoolUserPermissionSelectorHelper.flattenNodeParents(
          parentTreeNode
        ),
      ])
    );
  }
}
