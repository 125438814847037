import React, {Dispatch, SetStateAction, useState} from "react";
import {AppCustomDialog} from "../../../Shared/Components/Dialogs/CustomDialog";
import {PaletteTypes} from "../../../Shared/Themes/palette-types.enum";
import {Icons} from "../../../Shared/Components/Icons/Icons";
import {ISftpAccount} from "../../../Shared/Models/Entities/sftp-account.interface";
import {IconButton, InputAdornment, OutlinedInput} from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {SftpAccountService} from "../../../Shared/Services/sftp-account.service";
import {AppButton} from "../../../Shared/Components/Button";
import {AppGrid} from "../../../Shared/Components/Grid";

export const AppSftpAccountDetailsDialog = (props: {
    open: boolean;
    sftpAccount: ISftpAccount | null;
    setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
    const [showPassword, setShowPassword] = useState(false);
    const sftpAccount = props.sftpAccount || SftpAccountService.getBlankAccount();
    const fileContent = btoa('username,password,server\n' + sftpAccount.username + ',' + sftpAccount.password + ',' + sftpAccount.server + ':2022');
    const credentialsFile ="data:application/octet-stream;charset=utf-8;base64," + fileContent;

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClose = () => {
        props.setOpen?.(false);
    };

    return (
        <AppCustomDialog
            open={props.open}
            setOpen={props.setOpen}
            fullWidth
            maxWidth="md"
            title="Sftp Account Details"
            icon={Icons.AccountKey}
            palette={PaletteTypes.WARNING}
        >
            <OutlinedInput
                type={'text'}
                value={props.sftpAccount?.username}
                disabled={true}
                label={'Username'}
                style={{width: "250px"}}
             />
            <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                value={props.sftpAccount?.password}
                disabled={true}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >{showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                label={'Password'}
            style={{marginLeft: "20px", marginRight: "20px", width: "400px"}}
            />
            <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
            <a style={{textDecoration: "none", marginTop: "24px",marginRight: "20px"}} href={credentialsFile} download={"credentials.csv"}>
                    <AppButton
                        text="Download Credentials"
                        palette={PaletteTypes.ERROR}
                    />
                </a>
                <AppButton
                    text="Close"
                    icon={Icons.Close}
                    palette={PaletteTypes.WARNING}
                    variant="outlined"
                    onClick={() => handleClose()}
                    style={{marginTop: "20px"}}
                />
            </AppGrid>
        </AppCustomDialog>
    )
}