import React, {useState} from "react";
import {IStudentImport} from "../../../../Shared/Models/Entities/student-import.interface";
import {AppCustomTable} from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import {PaletteTypes} from "../../../../Shared/Themes/palette-types.enum";
import {AppCard} from "../../../../Shared/Components/Cards/Card";
import {AppCardHeader} from "../../../../Shared/Components/Cards/CardHeader";
import {AppCardContent} from "../../../../Shared/Components/Cards/CardContent";
import {AppGrid} from "../../../../Shared/Components/Grid";
import {AppButton} from "../../../../Shared/Components/Button";
import {IGetImportProps, StudentImportService} from "../../../../Shared/Services/student-import.service";
import {AppTablePagination} from "../../../../Shared/Components/Tables/TablePagination/TablePagination";
import {Icons} from "../../../../Shared/Components/Icons/Icons";
import {hasPermission} from "../../../../Shared/Hooks/has-permission";
import {AppPermissionsEnum} from "../../../../Shared/Models/Enums/app-permissions.enum";
import {AppStudentImportCreateDialog} from "../../StudentImportDialogs/StudentImportCreateDialog";
import {SnackbarService} from "../../../../Shared/Services/snackbar.service";
import {AppStudentImportPreviewDialog} from "../../StudentImportDialogs/StudentImportPreviewDialog";
import {IStudentImportProcessResult} from "../../../../Shared/Models/Entities/student-import-process-result.interface";
import {AppConfirmationDialog} from "../../../../Shared/Components/Dialogs/ConfirmationDialog";

export const AppStudentImportTable = (props: {
    studentImports: IStudentImport[] | null;
    studentImportsLoading: boolean;
    count: number | null;
    countLoading: boolean;
    filters: IGetImportProps;
    setFilters: (props: IGetImportProps) => void;
    areFiltersDefault: boolean;
    onImportUpdated: () => void;
}): JSX.Element => {
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [currentImport, setCurrentImport] = useState<IStudentImport | null>(
        null
    );

    return (
        <>
            <AppCard hasHeader>
                <AppCardHeader
                    title="Student Imports"
                    subheader="All student-imports that exist in your school/group. Completed imports are hidden by default"
                    icon={Icons.Upload}
                    palette={PaletteTypes.PRIMARY}
                />
                <AppCardContent padding={0}>
                    <TableContent
                        studentImports={props.studentImports}
                        studentImportsLoading={props.studentImportsLoading}
                        areFiltersDefault={props.areFiltersDefault}
                        currentImport={currentImport}
                        setCurrentImport={setCurrentImport}
                        onImportUpdated={props.onImportUpdated}
                    />
                    <AppTablePagination
                        count={props.count}
                        countLoading={props.countLoading}
                        paginationOptions={props.filters.paginationOptions}
                        paginationOptionsUpdated={(newOptions) => {
                            props.setFilters({
                                ...props.filters,
                                paginationOptions: newOptions,
                            });
                        }}
                    />
                    <AppStudentImportCreateDialog
                        currentImport={currentImport as IStudentImport}
                        open={importDialogOpen}
                        setOpen={setImportDialogOpen}
                        onUpdated={props.onImportUpdated}
                    />
                </AppCardContent>
            </AppCard>
        </>
    );
};

const TableContent = ({
                          studentImports,
                          studentImportsLoading,
                          areFiltersDefault,
                          currentImport,
                          setCurrentImport,
                          onImportUpdated,
                      }: {
    studentImports: IStudentImport[] | null;
    studentImportsLoading: boolean;
    areFiltersDefault: boolean;
    currentImport: IStudentImport | null;
    setCurrentImport: (studentImport: IStudentImport) => void;
    onImportUpdated: () => void;
}) => {
    const canEdit = hasPermission(AppPermissionsEnum.STUDENT_IMPORT_CREATE);
    const canProcess = hasPermission(AppPermissionsEnum.STUDENT_IMPORT_PROCESS);
    const [importPreviewResults, setImportPreviewResults] = useState<IStudentImportProcessResult | null>(null);
    const [importDialogOpen, setImportDialogOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    return (
        <AppCustomTable
            loading={studentImportsLoading && !studentImports}
            showLoadingText={
                areFiltersDefault
                    ? "Choose a term to search by or select a school/group in the sidebar!"
                    : null
            }
            columns={[
                {
                    id: "id",
                    label: "ID",
                },
                {
                    id: "name",
                    label: "Name",
                },
                {
                    id: "schoolName",
                    label: "School/District",
                    width: 350,
                },
                {
                    id: "importType",
                    label: "Import Type",
                },
                {
                    id: "status",
                    label: "Status",
                },
                {
                    id: "actions",
                    label: "Actions",
                },
            ]}
            rows={studentImports?.map((studentImport) => ({
                cells: [
                    {
                        value: studentImport.id,
                    },
                    {
                        value: studentImport.name,
                    },
                    {
                        value: studentImport.schoolName || studentImport.districtName,
                    },
                    {
                        value: studentImport.importType,
                    },
                    {
                        value: studentImport.status,
                    },
                    {
                        value: (
                            <AppGrid container spacing={1}>
                                {canProcess && studentImport.status != "processed" && (
                                <AppButton
                                    text={studentImport.status == "confirmed" ? "Process" : "Preview"}
                                    icon={canEdit ? Icons.Upload : Icons.Visibility}
                                    size="small"
                                    palette={PaletteTypes.SECONDARY}
                                    onClick={async () => {
                                        setCurrentImport(studentImport);
                                        let res;
                                        if ((studentImport.status == "created" || studentImport.status == "scheduled") && canEdit) {
                                            res = await StudentImportService.previewImport(studentImport);
                                            setImportPreviewResults(res);
                                            setImportDialogOpen(true);
                                        } else {
                                            res = await StudentImportService.importStudents(studentImport);
                                            SnackbarService.success(`${res.students.length} students were imported!`);
                                            onImportUpdated();
                                        }
                                    }}
                                />)}
                                {canEdit && (<AppButton
                                    text=""
                                    icon={Icons.Edit}
                                    size="small"
                                    palette={PaletteTypes.SUCCESS}
                                    linkTo={"/student-imports-edit/"+studentImport.id}
                                />)}
                                {canEdit && (<AppButton
                                        text=""
                                        size="small"
                                        style={{textAlign: "center"}}
                                        icon={Icons.Delete}
                                        palette={PaletteTypes.ERROR}
                                        onClick={() => {
                                            setCurrentImport(studentImport);
                                            setDeleteConfirmOpen(true);
                                        }}
                                />)}
                                {importDialogOpen && importPreviewResults != null && (<AppStudentImportPreviewDialog
                                    open={importDialogOpen}
                                    importResult={importPreviewResults}
                                    setOpen={setImportDialogOpen}
                                    onUpdated={onImportUpdated}
                                />)}
                                {deleteConfirmOpen && (<AppConfirmationDialog
                                    open={deleteConfirmOpen}
                                    title="Confirm delete?"
                                    content="Are you sure you want to delete this import? This action cannot be undone."
                                    icon={Icons.Delete}
                                    setOpen={setDeleteConfirmOpen}
                                    agreeAction={async () => {
                                        if (currentImport == null) return;
                                        const result = await StudentImportService.delete(currentImport);
                                        if (result) {
                                            SnackbarService.success("Import " + currentImport.name + " deleted.");
                                            onImportUpdated();
                                        } else {
                                            SnackbarService.error("Failed to delete import.");
                                        }
                                    }}
                                    palette={PaletteTypes.ERROR}
                                />)}
                            </AppGrid>
                        ),
                    },
                ],
            }))}
        />
    );
};

/*
<AppButton
    text={"Deactivate"}
    icon={Icons.Delete}
    palette={PaletteTypes.ERROR}
    onClick={() => {
        setCurrentImport(studentImport);
        setImportDialogOpen(true);
    }}
/>*/
