import React from "react";
import { AppGrid } from "../../../Shared/Components/Grid";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { IBellSchedule } from "../../../Shared/Models/Entities/bell-schedule.interface";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { BellScheduleService } from "../../../Shared/Services/bell-schedule.service";
import { AppBellScheduleList } from "./BellScheduleList/BellSchedulesList";

import { AppBellSchedulesOptions } from "./BellScheduleOptionsPanel/BellScheduleOptions";

export const AppBellSchedulesManager = (): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.BELL_SCHEDULES_EDIT);
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const { loading, data: bellSchedules } = DataHooks.useFunctionCallState<
    IBellSchedule[]
  >({
    func: () => BellScheduleService.getAll(school.id),
    showLoadingIndicator: true,
  });

  return (
    <>
      <AppGrid container spacing={3}>
        <AppGrid item xs={6} md={4} lg={4}>
          <AppBellSchedulesOptions
            bellSchedules={loading ? [] : (bellSchedules as IBellSchedule[])}
          />
        </AppGrid>
        <AppGrid item xs={12} md={8} lg={8}>
          <AppBellScheduleList
            bellSchedules={loading ? [] : (bellSchedules as IBellSchedule[])}
            loading={loading}
            canEdit={canEdit}
          />
        </AppGrid>
      </AppGrid>
    </>
  );
};
