import React from "react";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppButton } from "../../../../Shared/Components/Button";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { SchoolUserRoleService } from "../../../../Shared/Services/school-user-role.service";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";

export const AppGlobalRolesTable = (): JSX.Element => {
  const { data: schoolUserRoles } = DataHooks.useFunctionCallState<
    ISimpleItem[]
  >({
    func: async () => SchoolUserRoleService.get({ returnGlobalRoles: true }),
    showLoadingIndicator: true,
  });
  const canEdit =
    hasPermission(AppPermissionsEnum.GLOBAL_ROLES_EDIT) &&
    hasPermission(AppPermissionsEnum.ROLES_EDIT);

  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="Global Roles"
          subheader="Roles that belongs to all schools in Hero. Not changeable."
          icon={Icons.AccountKey}
          palette={PaletteTypes.PRIMARY}
        />
        <AppCardContent padding={0}>
          <AppCustomTable
            columns={[
              {
                id: "name",
                label: "Name",
              },
              {
                id: "action",
                label: "Actions",
                size: "small",
                width: 126,
              },
            ]}
            rows={schoolUserRoles?.map((schoolUserRole) => ({
              cells: [
                {
                  value: schoolUserRole.name,
                },
                {
                  value: (
                    <AppButton
                      text={canEdit ? "Edit" : "View"}
                      icon={canEdit ? Icons.Edit : Icons.Visibility}
                      palette={PaletteTypes.SECONDARY}
                      linkTo={
                        "/school-user-roles/" + schoolUserRole.id.toString()
                      }
                    />
                  ),
                },
              ],
            }))}
          />
        </AppCardContent>
      </AppCard>
    </>
  );
};
