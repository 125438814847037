import React, { useState } from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import { AuthReducerActions } from "../../Shared/Reducers/Auth/auth-reducer";
import { NavbarStyles } from "./Navbar.styles";
import { IRouteConfig } from "../route.helper";
import { SnackbarReducerActions } from "../../Shared/Reducers/Snackbar/snackbar.reducer";
import { Logger } from "../../Shared/Helpers/logger";
import { AppButton } from "../../Shared/Components/Button";
import { PaletteTypes } from "../../Shared/Themes/palette-types.enum";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import { AppQuickMovePopup } from "./QuickMovePopup/QuickMovePopup";
import { Icons } from "../../Shared/Components/Icons/Icons";
import { AppBreadcrumbs } from "./Breadcrumbs/Breadcrumbs";
import { AppConfigReducerActions } from "../../Shared/Reducers/AppConfig/app-config.reducer";
import { isSchoolUser } from "../../Shared/Hooks/isSchoolUser";

const navbar = ({
  routeConfig,
  sidebarOpen,
  shouldOpenSidebar,
}: RouteComponentProps & {
  routeConfig: IRouteConfig;
  sidebarOpen: boolean;
  shouldOpenSidebar: () => void;
}): JSX.Element => {
  const classes = NavbarStyles({});
  const [quickMovePopupOpen, setQuickMovePopupOpen] = useState(false);
  const { theme } = StoreHelper.selector((state) => state.appConfig);

  React.useEffect(() => {
    if (routeConfig?.name) {
      document.title = `Hero Admin | ${routeConfig.name}`;
    }
  }, [routeConfig?.name]);

  const toggleTheme = () => {
    StoreHelper.dispatch(
      AppConfigReducerActions.setTheme(theme === "light" ? "dark" : "light")
    );
  };

  return (
    <>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          {!sidebarOpen && (
            <AppButton
              className={classes.sidebarButton}
              icon={Icons.Hero}
              onClick={() => shouldOpenSidebar()}
              palette={PaletteTypes.PRIMARY}
              variant="text"
              iconSize={30}
            />
          )}
          <AppBreadcrumbs routeConfig={routeConfig} />
          <AppButton
            className={classes.navbarButton}
            icon={theme === "light" ? Icons.WbSunny : Icons.Brightness3}
            onClick={async () => {
              toggleTheme();
            }}
            palette={PaletteTypes.PRIMARY}
            variant="text"
            iconSize={24}
          />
          {!isSchoolUser() && (
            <AppButton
              className={classes.navbarButton}
              icon={Icons.CompareArrows}
              onClick={async () => {
                setQuickMovePopupOpen(true);
              }}
              palette={PaletteTypes.PRIMARY}
              variant="text"
              iconSize={24}
            />
          )}
          <AppButton
            className={classes.navbarButton}
            icon={Icons.ExitToApp}
            onClick={async () => handleLogout()}
            palette={PaletteTypes.PRIMARY}
            variant="text"
            iconSize={26}
          />
        </Toolbar>
      </AppBar>
      {quickMovePopupOpen && (
        <AppQuickMovePopup
          open={quickMovePopupOpen}
          setOpen={setQuickMovePopupOpen}
        />
      )}
    </>
  );
};

async function handleLogout() {
  try {
    StoreHelper.dispatch(AuthReducerActions.clearTokenUser());
    StoreHelper.dispatch(
      SnackbarReducerActions.openSnackbar({
        message: "You have been successfully logged out!",
        severity: "success",
      })
    );
  } catch (exception) {
    Logger.error(exception);
  }
}

export const AppNavbar = withRouter(navbar);
