import React from "react";
import { AppBaseTable } from "../BaseTable/BaseTable";
import { AppBaseTableCell } from "../BaseTable/BaseTableCell";
import { AppBaseTableRow } from "../BaseTable/BaseTableRow";
import { AppBaseTableHead } from "../BaseTable/BaseTableHead";
import { AppBaseTableBody } from "../BaseTable/BaseTableBody";
import { Size } from "@material-ui/core";
import { AppEmptyTableRow } from "../EmptyTableRow/EmptyTableRow";

export interface ICustomTableProps {
  loading?: boolean;
  showLoadingText?: string | null;
  columns: ICustomTableColumnProps[];
  rows?: ICustomTableRowProps[];
}

export interface ICustomTableColumnProps {
  id: string;
  label: string | React.ReactNode | JSX.Element;
  hideOnCSV?: boolean; // Should we not show this column on our CSV
  hideColumnName?: boolean;
  disableSorting?: boolean;
  size?: Size;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  width?: number;
}

export interface ICustomTableRowProps {
  cells: ICustomTableCellProps[];
  hidden?: boolean;
}

export interface ICustomTableCellProps {
  value: string | React.ReactNode | JSX.Element;
}

export const AppCustomTable = ({
  loading,
  showLoadingText,
  columns,
  rows,
}: ICustomTableProps): JSX.Element => {
  const renderHeaderRow = (column: ICustomTableColumnProps, index: number) => {
    return (
      <AppBaseTableCell
        key={index}
        size={column.size}
        align={column.align}
        width={column.width}
      >
        {column.label}
      </AppBaseTableCell>
    );
  };

  const renderRow = (row: ICustomTableRowProps, index: number) => {
    return (
      <AppBaseTableRow key={index}>
        {row.cells.map((cell, index) => {
          const column = columns[index];
          return (
            <AppBaseTableCell
              key={index}
              size={column.size}
              align={column.align}
              width={column.width}
            >
              {cell.value}
            </AppBaseTableCell>
          );
        })}
      </AppBaseTableRow>
    );
  };

  return (
    <AppBaseTable>
      <AppBaseTableHead>
        <AppBaseTableRow>{columns.map(renderHeaderRow)}</AppBaseTableRow>
      </AppBaseTableHead>
      <AppBaseTableBody>
        {loading || !rows || !rows.length ? (
          <AppEmptyTableRow
            cellCount={columns.length}
            text={
              showLoadingText || (loading ? "Loading..." : "No Results Found")
            }
          />
        ) : (
          rows.map(renderRow)
        )}
      </AppBaseTableBody>
    </AppBaseTable>
  );
};
