import React, {Dispatch, SetStateAction, useState} from "react";
import { IEvent } from "../../../../Shared/Models/Entities/event.interface";
import { IStudentSimple } from "../../../../Shared/Models/Entities/student-simple.interface";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { IPaginationOptions } from "../../../../Shared/Components/Tables/TablePagination/pagination-options.interface";
import { TablePaginationHelper } from "../../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppEventAddStudentsPopupTable } from "./EventAddStudentsPopupTable/EventAddStudentsPopupTable";
import { StudentService } from "../../../../Shared/Services/student.service";
import { AppEventAddStudentsPopupFilters } from "./EventAddStudentsPopupFilters/EventAddStudentsPopupFilters";
import { useDebounce } from "../../../../Shared/Hooks/use-debounce";

export interface IEventAddStudentsPopupFilters {
  paginationOptions: IPaginationOptions;
  firstName?: string;
  lastName?: string;
  externalId?: string;
  grade?: string;
  districtId?: number;
  schoolId?: number;
}

export const AppEventAddStudentsPopup = (props: {
  event: IEvent;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  studentsSelected: (students: IStudentSimple[]) => void;
}): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [filters, setFilters] = useState<IEventAddStudentsPopupFilters>({ paginationOptions:  TablePaginationHelper.getDefaultOptions(5)});
  const fixedFilters = {
    ...filters,
    districtId: district?.id,
    schoolId: school?.id,
  };

  const studentsListState = DataHooks.useFunctionCallState<IStudentSimple[]>({
    func: async () => StudentService.get(fixedFilters),
    deps: [
      fixedFilters.firstName,
      fixedFilters.lastName,
      fixedFilters.externalId,
      fixedFilters.grade,
      fixedFilters.schoolId,
      fixedFilters.districtId,
      fixedFilters.paginationOptions?.size,
      fixedFilters.paginationOptions?.page,
    ],
    showLoadingIndicator: true,
  });

  const studentsCountState = DataHooks.useFunctionCallState<number>({
    func: () => StudentService.getCount(fixedFilters),
    deps: [
      fixedFilters.firstName,
      fixedFilters.lastName,
      fixedFilters.externalId,
      fixedFilters.grade,
      fixedFilters.schoolId,
      fixedFilters.districtId,
    ],
  });

  const addStudent = (student: IStudentSimple): void => {
    props.studentsSelected((props.event.students || []).concat(student));
  };

  const removeStudent = (student: IStudentSimple): void => {
    props.studentsSelected(
      (props.event.students || []).filter((s) => s.id !== student.id)
    );
  };

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="lg"
      title="Add Students"
      icon={Icons.PersonAdd}
      palette={PaletteTypes.PRIMARY}
      padding={0}
    >
      <AppGrid container direction="column" marginTop={20}>
        <AppEventAddStudentsPopupFilters
          filters={filters}
          updateFilters={(filtersToUpdate) => {
            setFilters({
              ...filters,
              ...filtersToUpdate,

              // Reset pagination since we are viewing a new list
              paginationOptions: TablePaginationHelper.getDefaultOptions(5),
            });
          }}
        />
        <AppEventAddStudentsPopupTable
          selectedStudents={props.event.students || []}
          students={studentsListState.data || []}
          count={studentsCountState.data}
          countLoading={studentsCountState.loading}
          filters={filters}
          setFilters={setFilters}
          addStudent={addStudent}
          removeStudent={removeStudent}
        />
      </AppGrid>
    </AppCustomDialog>
  );
};
