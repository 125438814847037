import React, { Dispatch, SetStateAction } from "react";
import { IEventSupervisor } from "../../../Shared/Models/Entities/event-supervisor.interface";
import { AppCustomDialog } from "../../../Shared/Components/Dialogs/CustomDialog";
import { AppEventSupervisorEditorPanel } from "../EventSupervisorEditorPanel/EventSupervisorEditorPanel";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../Shared/Components/Icons/Icons";

export const AppEventSupervisorCreateDialog = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const eventSupervisor = {
    name: "",
    key: "",
    canManageEvent: true,
  } as IEventSupervisor;

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="sm"
      title={"Create Supervisor"}
      subHeader="Fill out this form to create a supervisor in Hero."
      icon={Icons.AccountKey}
      palette={PaletteTypes.SECONDARY}
    >
      <AppEventSupervisorEditorPanel
        eventSupervisor={eventSupervisor}
        isCreating={true}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
