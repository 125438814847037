import { ISimpleItem } from "../Models/Entities/simple-item.interface";

export class LocationMetadataHelper {
  private static countries: ISimpleItem[] = [
    {
      id: 1,
      name: "United States",
    },
    {
      id: 2,
      name: "Canada",
    },
  ];

  private static states: Array<{
    countryId: number;
    abbreviation: string;
    name: string;
  }> = [
    {
      countryId: 1,
      abbreviation: "AL",
      name: "Alabama",
    },
    {
      countryId: 1,
      abbreviation: "AK",
      name: "Alaska",
    },
    {
      countryId: 1,
      abbreviation: "AZ",
      name: "Arizona",
    },
    {
      countryId: 1,
      abbreviation: "AR",
      name: "Arkansas",
    },
    {
      countryId: 1,
      abbreviation: "CA",
      name: "California",
    },
    {
      countryId: 1,
      abbreviation: "CO",
      name: "Colorado",
    },
    {
      countryId: 1,
      abbreviation: "CT",
      name: "Connecticut",
    },
    {
      countryId: 1,
      abbreviation: "DE",
      name: "Delaware",
    },
    {
      countryId: 1,
      abbreviation: "DC",
      name: "Washington DC",
    },
    {
      countryId: 1,
      abbreviation: "FL",
      name: "Florida",
    },
    {
      countryId: 1,
      abbreviation: "GA",
      name: "Georgia",
    },
    {
      countryId: 1,
      abbreviation: "HI",
      name: "Hawaii",
    },
    {
      countryId: 1,
      abbreviation: "ID",
      name: "Idaho",
    },
    {
      countryId: 1,
      abbreviation: "IL",
      name: "Illinois",
    },
    {
      countryId: 1,
      abbreviation: "IN",
      name: "Indiana",
    },
    {
      countryId: 1,
      abbreviation: "IA",
      name: "Iowa",
    },
    {
      countryId: 1,
      abbreviation: "KS",
      name: "Kansas",
    },
    {
      countryId: 1,
      abbreviation: "KY",
      name: "Kentucky",
    },
    {
      countryId: 1,
      abbreviation: "LA",
      name: "Louisiana",
    },
    {
      countryId: 1,
      abbreviation: "ME",
      name: "Maine",
    },
    {
      countryId: 1,
      abbreviation: "MD",
      name: "Maryland",
    },
    {
      countryId: 1,
      abbreviation: "MA",
      name: "Massachusetts",
    },
    {
      countryId: 1,
      abbreviation: "MI",
      name: "Michigan",
    },
    {
      countryId: 1,
      abbreviation: "MN",
      name: "Minnesota",
    },
    {
      countryId: 1,
      abbreviation: "MS",
      name: "Mississippi",
    },
    {
      countryId: 1,
      abbreviation: "MO",
      name: "Missouri",
    },
    {
      countryId: 1,
      abbreviation: "MT",
      name: "Montana",
    },
    {
      countryId: 1,
      abbreviation: "NE",
      name: "Nebraska",
    },
    {
      countryId: 1,
      abbreviation: "NV",
      name: "Nevada",
    },
    {
      countryId: 1,
      abbreviation: "NH",
      name: "New Hampshire",
    },
    {
      countryId: 1,
      abbreviation: "NJ",
      name: "New Jersey",
    },
    {
      countryId: 1,
      abbreviation: "NM",
      name: "New Mexico",
    },
    {
      countryId: 1,
      abbreviation: "NY",
      name: "New York",
    },
    {
      countryId: 1,
      abbreviation: "NC",
      name: "North Carolina",
    },
    {
      countryId: 1,
      abbreviation: "ND",
      name: "North Dakota",
    },
    {
      countryId: 1,
      abbreviation: "OH",
      name: "Ohio",
    },
    {
      countryId: 1,
      abbreviation: "OK",
      name: "Oklahoma",
    },
    {
      countryId: 1,
      abbreviation: "OR",
      name: "Oregon",
    },
    {
      countryId: 1,
      abbreviation: "PA",
      name: "Pennsylvania",
    },
    {
      countryId: 1,
      abbreviation: "PR",
      name: "Puerto Rico",
    },
    {
      countryId: 1,
      abbreviation: "RI",
      name: "Rhode Island",
    },
    {
      countryId: 1,
      abbreviation: "SC",
      name: "South Carolina",
    },
    {
      countryId: 1,
      abbreviation: "SD",
      name: "South Dakota",
    },
    {
      countryId: 1,
      abbreviation: "TN",
      name: "Tennessee",
    },
    {
      countryId: 1,
      abbreviation: "TX",
      name: "Texas",
    },
    {
      countryId: 1,
      abbreviation: "UT",
      name: "Utah",
    },
    {
      countryId: 1,
      abbreviation: "VT",
      name: "Vermont",
    },
    {
      countryId: 1,
      abbreviation: "VA",
      name: "Virginia",
    },
    {
      countryId: 1,
      abbreviation: "WA",
      name: "Washington",
    },
    {
      countryId: 1,
      abbreviation: "WV",
      name: "West Virginia",
    },
    {
      countryId: 1,
      abbreviation: "WI",
      name: "Wisconsin",
    },
    {
      countryId: 1,
      abbreviation: "WY",
      name: "Wyoming",
    },
    {
      countryId: 2,
      abbreviation: "AB",
      name: "Alberta",
    },
    {
      countryId: 2,
      abbreviation: "BC",
      name: "British Columbia",
    },
    {
      countryId: 2,
      abbreviation: "MB",
      name: "Manitoba",
    },
    {
      countryId: 2,
      abbreviation: "NB",
      name: "New Brunswick",
    },
    {
      countryId: 2,
      abbreviation: "NL",
      name: "Newfoundland",
    },
    {
      countryId: 2,
      abbreviation: "NS",
      name: "Nova Scotia",
    },
    {
      countryId: 2,
      abbreviation: "NT",
      name: "Northwest Territories",
    },
    {
      countryId: 2,
      abbreviation: "NU",
      name: "Nunavut",
    },
    {
      countryId: 2,
      abbreviation: "ON",
      name: "Ontario",
    },
    {
      countryId: 2,
      abbreviation: "PE",
      name: "Prince Edward Island",
    },
    {
      countryId: 2,
      abbreviation: "QC",
      name: "Quebec",
    },
    {
      countryId: 2,
      abbreviation: "SK",
      name: "Saskatchewan",
    },
    {
      countryId: 2,
      abbreviation: "YT",
      name: "Yukon",
    },
  ];

  public static getCountries(): ISimpleOption[] {
    return LocationMetadataHelper.countries.map((country) => ({
      id: country.name,
      name: country.name,
    }));
  }

  public static getStatesByCountry(
    countryName?: string | null
  ): ISimpleOption[] {
    const country = LocationMetadataHelper.countries.find(
      (c) => c.name === countryName
    );
    return LocationMetadataHelper.states
      .filter((state) => state.countryId === (country?.id || 1))
      .map((state) => ({
        id: state.abbreviation,
        name: state.name,
      }));
  }
}

export interface ISimpleOption {
  id: string;
  name: string;
}
