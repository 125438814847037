import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppInterventionEditorPanel } from "../InterventionEditorPanel/InterventionEditorPanel";
import { IIntervention } from "../../../../Shared/Models/Entities/interventions.interface";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppInterventionEditDialog = (props: {
  intervention: IIntervention;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
  isCloning: boolean;
}): JSX.Element => {
  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title={
        props.isCloning
          ? "Clone Intervention"
          : "Edit Intervention: " + props.intervention.name
      }
      subHeader={`Fill out this form to ${
        props.isCloning ? "clone" : "edit"
      } an intervention in your school.`}
      icon={Icons.Person}
      palette={props.isCloning ? PaletteTypes.PRIMARY : PaletteTypes.SECONDARY}
    >
      <AppInterventionEditorPanel
        intervention={props.intervention}
        isCreating={false}
        isCloning={props.isCloning}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
