import { HttpClient } from "../Helpers/http-client";
import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { PaginationHelper } from "../Helpers/pagination.helper";
import { IEvent } from "../Models/Entities/event.interface";

export class EventService {
  static async get({
    categoryId,
    status,
    schoolId,
    districtId,
    paginationOptions,
  }: {
    categoryId?: number;
    status?: "true" | "false";
    schoolId?: number;
    districtId?: number;
    paginationOptions: IPaginationOptions;
  }): Promise<IEvent[]> {
    return HttpClient.get({
      path: "/events",
      params: {
        categoryId,
        ...(status === "true" && {
          active: true,
        }),
        ...(status === "false" && {
          active: false,
        }),
        ...(schoolId && {
          schoolId,
        }),
        ...(districtId && {
          districtId,
        }),
        ...PaginationHelper.getPaginationParams(paginationOptions),
      },
    });
  }

  static async getCount({
    categoryId,
    status,
    schoolId,
    districtId,
  }: {
    categoryId?: number;
    status?: "true" | "false";
    schoolId?: number;
    districtId?: number;
  }): Promise<number> {
    return HttpClient.get({
      path: "/events/count",
      params: {
        categoryId,
        ...(status === "true" && {
          active: true,
        }),
        ...(status === "false" && {
          active: false,
        }),
        ...(schoolId && {
          schoolId,
        }),
        ...(districtId && {
          districtId,
        }),
      },
    });
  }

  static async getById(eventId: number): Promise<IEvent> {
    return HttpClient.get({
      path: "/events/" + eventId,
    });
  }

  static async create(
    event: IEvent,
    schoolId?: number,
    districtId?: number
  ): Promise<IEvent> {
    return HttpClient.post({
      path: "/events",
      body: event,
      params: {
        ...(schoolId && { schoolId }),
        ...(districtId && { districtId }),
      },
    });
  }

  static async update(event: IEvent): Promise<IEvent> {
    return HttpClient.put({
      path: "/events/" + event.id,
      body: event,
    });
  }

  static async delete(eventId: number): Promise<IEvent> {
    return HttpClient.delete({
      path: "/events/" + eventId,
    });
  }

  static async canDelete(eventId: number): Promise<boolean> {
    return HttpClient.get({
      path: "/events/" + eventId + "/can-delete",
    });
  }
}
