import React, { useState } from "react";
import { AppCard } from "../../../Shared/Components/Cards/Card";
import { AppButton } from "../../../Shared/Components/Button";
import { AppCardHeader } from "../../../Shared/Components/Cards/CardHeader";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { AppCardContent } from "../../../Shared/Components/Cards/CardContent";
import { AppCustomTable } from "../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppGrid } from "../../../Shared/Components/Grid";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { AppInterventionCreateDialog } from "./InterventionCreateDialog/InterventionCreateDialog";
import { AppInterventionEditDialog } from "./InterventionEditDialog/InterventionEditDialog";
import { InterventionService } from "../../../Shared/Services/intervention.service";
import { IIntervention } from "../../../Shared/Models/Entities/interventions.interface";
import { AppTablePagination } from "../../../Shared/Components/Tables/TablePagination/TablePagination";
import { TablePaginationHelper } from "../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { AppInterventionFilters } from "./InterventionListFilters/InterventionFilters";
import { IPaginationOptions } from "../../../Shared/Components/Tables/TablePagination/pagination-options.interface";

export interface IInterventionFilters {
  searchKey?: string;
  paginationOptions: IPaginationOptions;
  pagination: boolean;
}

export const AppTracCodeManageInterventions = (): JSX.Element => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  const canEdit = hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT);
  const [selectedIntervention, setSelectedIntervention] = useState(
    null as IIntervention | null
  );
  const [filters, setFilters] = useState<IInterventionFilters>({
    paginationOptions: TablePaginationHelper.getDefaultOptions(),
    pagination: true,
  });
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const {
    data: interventions,
    refreshData: refreshIntervention,
    loading: dataLoading,
  } = DataHooks.useFunctionCallState({
    func: () =>
      InterventionService.getAll({
        schoolId: school?.id,
        searchKey: filters.searchKey,
        paginationOptions: filters.paginationOptions,
        pagination: true,
      }),
    deps: [filters, school?.id],
    showLoadingIndicator: true,
  });
  const {
    data: interventionCount,
    setData: setInterventionCount,
    refreshData: refreshInterventionCount,
    loading: loading,
  } = DataHooks.useFunctionCallState({
    func: () =>
      InterventionService.getCount({
        schoolId: school?.id,
        searchKey: filters.searchKey,
        paginationOptions: filters.paginationOptions,
        pagination: true,
      }),
    deps: [filters],
    showLoadingIndicator: true,
  });
  const onDataUpdated = async () => {
    const refreshInterventionCall = refreshIntervention();
    const refreshInterventionCountCall = refreshInterventionCount();
    await refreshInterventionCall;
    await refreshInterventionCountCall;
  };
  return (
    <>
      <AppGrid container direction="column" spacing={2}>
        <AppGrid
          item
          container
          direction="row"
          wrap="wrap-reverse"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={5}
        >
          <AppInterventionFilters
            filters={filters}
            setFilters={(filters) => {
              // Invalidate count so we will re-fetch
              setInterventionCount(null);
              setFilters({
                ...filters,
                // Reset pagination since we are viewing a new list
                paginationOptions: TablePaginationHelper.getDefaultOptions(),
              });
            }}
          />
          <AppGrid item container spacing={2} marginLeft="auto">
            {hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT) && (
              <>
                <AppButton
                  text="Manage Trac Codes"
                  icon={Icons.Edit}
                  size="large"
                  palette={PaletteTypes.PRIMARY}
                  linkTo={`/trac-codes`}
                />
                <AppButton
                  text="Create Intervention"
                  icon={Icons.Add}
                  size="large"
                  palette={PaletteTypes.SUCCESS}
                  onClick={() => {
                    setCreateDialogOpen(true);
                  }}
                />
              </>
            )}
          </AppGrid>
        </AppGrid>
        <AppGrid item>
          <AppCard hasHeader>
            <AppCardHeader
              title="Interventions"
              palette={PaletteTypes.PRIMARY}
              icon={Icons.Info}
            />
            <AppCardContent padding={0}>
              <AppCustomTable
                loading={dataLoading}
                columns={[
                  {
                    id: "name",
                    label: "Name",
                  },
                  {
                    id: "description",
                    label: "Description",
                  },
                  ...(canEdit
                    ? [
                        {
                          id: "clone",
                          label: "",
                        },
                        {
                          id: "edit",
                          label: "",
                        },
                      ]
                    : []),
                ]}
                rows={interventions?.map((intervention) => ({
                  cells: [
                    {
                      value: intervention.name,
                    },
                    {
                      value: intervention.description,
                    },

                    {
                      value: (
                        <AppGrid
                          container
                          justifyContent="flex-end"
                          spacing={1}
                        >
                          <AppButton
                            icon={canEdit ? Icons.Edit : Icons.Visibility}
                            palette={PaletteTypes.PRIMARY}
                            text={canEdit ? "Clone" : "View"}
                            onClick={() => {
                              setSelectedIntervention(intervention);
                              setEditDialogOpen(true);
                              setIsCloning(true);
                            }}
                          />

                          <AppButton
                            icon={Icons.Edit}
                            palette={PaletteTypes.SECONDARY}
                            text={canEdit ? "Edit" : "View"}
                            onClick={() => {
                              // props.selectPeriod(index);
                              setSelectedIntervention(intervention);
                              setEditDialogOpen(true);
                              setIsCloning(false);
                            }}
                          />
                        </AppGrid>
                      ),
                    },
                  ],
                }))}
              />
              <AppTablePagination
                count={interventionCount}
                countLoading={loading}
                paginationOptions={filters.paginationOptions}
                paginationOptionsUpdated={(newOptions) => {
                  setFilters({
                    ...filters,
                    paginationOptions: newOptions,
                  });
                }}
              />
            </AppCardContent>
          </AppCard>
        </AppGrid>
      </AppGrid>
      <AppInterventionCreateDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
        onDataUpdated={onDataUpdated}
      />
      {selectedIntervention && (
        <AppInterventionEditDialog
          intervention={selectedIntervention as IIntervention}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          onDataUpdated={onDataUpdated}
          isCloning={isCloning}
        />
      )}
    </>
  );
};
