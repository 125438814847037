import React, { Dispatch, SetStateAction, useState } from "react";
import { Utils } from "../../../Shared/Helpers/utils";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppButton } from "../../../Shared/Components/Button";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { Logger } from "../../../Shared/Helpers/logger";
import { AppTextFieldControl } from "../../../Shared/Components/Controls/TextFieldControl";
import { EventCategoryService } from "../../../Shared/Services/event-category.service";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import {IEventCategory} from "../event-category.interface";

export const AppEventCategoryEditorPanel = (props: {
  eventCategory: IEventCategory;
  isCreating: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [eventCategory, setEventCategory] = useState<IEventCategory>(
    Utils.cloneObject(props.eventCategory)
  );
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);

  const { data: canDelete } = DataHooks.useFunctionCallState<boolean>({
    func: () => {
      if (!props.isCreating && eventCategory?.id) {
        return EventCategoryService.canDelete(eventCategory.id);
      } else {
        return false;
      }
    },
    deps: [],
  });

  const updateFields = (updatedFields: Partial<IEventCategory>) => {
    setEventCategory({ ...eventCategory, ...updatedFields });
  };

  const handleClose = () => {
    props.setOpen?.(false);
  };

  async function handleSubmit(): Promise<void> {
    const isValid = eventCategory.name;
    setHaveTriedSubmitting(true);
    if (isValid) {
      LoadingBarService.show();
      try {
        if (props.isCreating) {
          await EventCategoryService.create(
            eventCategory,
            school?.id,
            district?.id
          );
        } else {
          await EventCategoryService.update(
            eventCategory,
            school?.id,
            district?.id
          );
        }
        await props.onDataUpdated();
        SnackbarService.success(
          (props.isCreating ? "Created " : "Updated ") + eventCategory.name
        );
        handleClose();
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    }
  }

  async function handleDelete(): Promise<void> {
    LoadingBarService.show();
    try {
      await EventCategoryService.delete(eventCategory.id);
      await props.onDataUpdated();
      SnackbarService.success("Deleted " + eventCategory.name);
      handleClose();
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  }

  return (
    <AppGrid container spacing={3}>
      <AppTextFieldControl
        gridProps={{ xs: 12 }}
        label="Name *"
        value={eventCategory.name}
        onChange={(event) => {
          updateFields({
            name: event.target.value,
          });
        }}
        error={
          !eventCategory.name && (!props.isCreating || haveTriedSubmitting)
        }
        icon={Icons.AccountBox}
        opaqueBackground
      />
      <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
        {canDelete && (
          <AppButton
            text="Delete"
            icon={Icons.Delete}
            palette={PaletteTypes.ERROR}
            onClick={() => handleDelete()}
          />
        )}
        <AppButton
          text="Cancel"
          icon={Icons.Close}
          palette={PaletteTypes.WARNING}
          variant="outlined"
          onClick={() => handleClose()}
        />
        <AppButton
          text="Submit"
          icon={props.isCreating ? Icons.Add : Icons.Check}
          palette={PaletteTypes.SUCCESS}
          onClick={() => handleSubmit()}
        />
      </AppGrid>
    </AppGrid>
  );
};
