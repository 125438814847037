import React, { useEffect, useState } from "react";
import { AuthenticationService } from "../../Shared/Services/authentication.service";
import { useHistory } from "react-router";
import { AppButton } from "../../Shared/Components/Button";
import { PaletteTypes } from "../../Shared/Themes/palette-types.enum";
import { AppGrid } from "../../Shared/Components/Grid";
import { Icons } from "../../Shared/Components/Icons/Icons";
import { AppCard } from "../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../Shared/Components/Cards/CardContent";
import { AppTextFieldControl } from "../../Shared/Components/Controls/TextFieldControl";
import { AppDivider } from "../../Shared/Components/Divider";
import HeroLogoDark from "../../Shared/Assets/Images/hero-logo-dark.png";

import { SnackbarService } from "../../Shared/Services/snackbar.service";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import { LoadingBarService } from "../../Shared/Services/loading-bar.service";
import { AuthStyles } from "../AuthStyles";
import { UrlHelper } from "../../Shared/Helpers/url.helper";
import { LocalStorageHelper } from "../../Shared/Helpers/local-storage.helper";
import { LocalStorageKeysEnum } from "../../Shared/Models/Enums/local-storage-keys.enum";
const numbers = "0123456789".split("");

export class PasswordHelper {
  public static validate(password: string, confirmPassword: string): void {
    if (password !== confirmPassword) throw Error("Passwords do not match");
    if (this.tooShort(password))
      throw Error("Passwords must have at least 8 letters");
    if (!this.hasNumber(password))
      throw Error("Passwords must have at least 1 number");
    if (!this.hasUpper(password))
      throw Error("Passwords must have at least 1 uppercase letter");
    if (!this.hasLower(password))
      throw Error("Passwords must have at least 1 lowercase letter");
  }

  public static tooShort(pwd: string): boolean {
    return pwd.length < 8;
  }

  public static hasNumber(pwd: string): boolean {
    return pwd.split("").some((char) => numbers.includes(char));
  }

  public static hasUpper(pwd: string): boolean {
    return pwd
      .split("")
      .some(
        (letter) =>
          letter !== letter.toLowerCase() && letter === letter.toUpperCase()
      );
  }

  public static hasLower(pwd: string): boolean {
    return pwd
      .split("")
      .some(
        (letter) =>
          letter === letter.toLowerCase() && letter !== letter.toUpperCase()
      );
  }
}

export const AppPasswordReset = (): JSX.Element => {
  const theme = StoreHelper.selector((state) => state.appConfig.theme);
  const classes = AuthStyles({ theme });
  const authToken = UrlHelper.getParamString("token");
  const history = useHistory();

  // Set authorization token
  useEffect(() => {
    if (!authToken) history.push("/request-password-reset");
    LocalStorageHelper.setItem(LocalStorageKeysEnum.ACCESS_TOKEN, authToken);
  }, [authToken]);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async () => {
    LoadingBarService.show();
    try {
      PasswordHelper.validate(password, confirmPassword);
      SnackbarService.success("Password reset, please login");
      await AuthenticationService.resetPassword(password);
      history.push("/");
    } catch (error) {
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  React.useEffect(() => {
    document.title = "Hero Admin | Login";
  }, []);

  return (
    <AppGrid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.authContainer}
    >
      <AppGrid item className={classes.loginBox}>
        <AppCard>
          <AppCardContent padding={0}>
            <AppGrid item container direction="column" spacing={2} padding={15}>
              <AppGrid item container justifyContent="center">
                <img src={HeroLogoDark} alt="Hero" height="60px" width="auto" />
              </AppGrid>

              <AppGrid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.helpText}
              >
                <div className={classes.helpTitle}>Password Reset</div>
                <div className={classes.helpDescription}>
                  Please enter your new password below.
                </div>
              </AppGrid>

              <AppTextFieldControl
                label="Password"
                value={password}
                icon={Icons.Person}
                onChange={(event) => {
                  setPassword(event.target.value || "");
                }}
                type="password"
              />

              <AppTextFieldControl
                label="Confirm Password"
                value={confirmPassword}
                icon={Icons.Lock}
                onChange={(event) => {
                  setConfirmPassword(event.target.value || "");
                }}
                type="password"
              />

              <AppButton
                gridProps={{ width: "100%" }}
                fullWidth
                text="Reset Password"
                icon={Icons.Check}
                palette={PaletteTypes.PRIMARY}
                onClick={() => handleSubmit()}
              />
            </AppGrid>

            <AppDivider />

            <AppGrid item container padding={15}>
              <AppButton
                gridProps={{ width: "100%" }}
                fullWidth
                text="Login"
                icon={Icons.VerifiedUser}
                palette={PaletteTypes.SECONDARY}
                linkTo="/"
              />
            </AppGrid>
          </AppCardContent>
        </AppCard>
      </AppGrid>
    </AppGrid>
  );
};
