import React from "react";
import { PaletteTypes } from "../Themes/palette-types.enum";
import { appMakeStyles } from "../Themes/app-make-styles";
import { AppGrid, IGridProps } from "./Grid";
import { Button, ButtonProps } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AppIcon } from "./Icons/Icons";

export interface IButtonProps extends ButtonProps {
  palette: PaletteTypes;
  icon?: AppIcon;
  iconSize?: number;
  height?: string | number;
  text?: string;
  size?: "small" | "medium" | "large";
  gridProps?: IGridProps;
  linkTo?: string;
}

const ButtonStyles = appMakeStyles<{
  size?: "small" | "medium" | "large";
  iconSize?: number;
  height?: string | number;
  variant: "text" | "outlined" | "contained";
  palette: PaletteTypes;
}>((theme) => ({
  button: {
    textTransform: "none",
    minWidth: "auto",

    padding: (props) => {
      switch (props.size) {
        case "large":
          return "8px 20px";
        case "small":
          return "3px 5px";
        default:
          return "6px 16px";
      }
    },

    height: (props) => {
      if (props.height) {
        return props.height;
      }
      switch (props.size) {
        case "large":
          return "44px";
        case "small":
          return "36px";
        default:
          return "40px";
      }
    },

    "&.Mui-disabled": {
      opacity: 0.6,
      border: "none",
    },

    "& svg": {
      width: (props) => (props.iconSize || 20) + "px",
      height: (props) => (props.iconSize || 20) + "px",
      marginRight: "5px",
    },

    "&.Mui-disabled, &:not(.Mui-disabled)": {
      backgroundColor: (props) => {
        switch (props.variant) {
          case "contained":
            return theme.palette[props.palette]?.main;
          case "outlined":
            return undefined;
          case "text":
            return undefined;
        }
      },
      borderColor: (props) => {
        switch (props.variant) {
          case "contained":
            return undefined;
          case "outlined":
            return theme.palette[props.palette]?.outline;
          case "text":
            return undefined;
        }
      },
      color: (props) => {
        switch (props.variant) {
          case "contained":
            return theme.palette[props.palette]?.contrastText;
          case "outlined":
            return theme.palette[props.palette]?.outline;
          case "text":
            return theme.palette[props.palette]?.main;
        }
      },
    },

    "&:hover,&:active,&:focus": {
      backgroundColor: (props) => {
        switch (props.variant) {
          case "contained":
            return theme.palette[props.palette]?.dark;
          case "outlined":
            return theme.palette[props.palette]?.main;
          case "text":
            return undefined;
        }
      },
      borderColor: (props) => {
        switch (props.variant) {
          case "contained":
            return undefined;
          case "outlined":
            return "transparent";
          case "text":
            return theme.palette[props.palette]?.contrastText;
        }
      },
      color: (props) => {
        switch (props.variant) {
          case "contained":
            return theme.palette[props.palette]?.contrastText;
          case "outlined":
            return theme.palette[props.palette]?.contrastText;
          case "text":
            return theme.palette[props.palette]?.dark;
        }
      },
    },
  },
}));

export const AppButton = (props: IButtonProps): JSX.Element => {
  const {
    size,
    iconSize,
    height,
    variant = "contained",
    palette,
    icon: ButtonIcon,
    text,
    gridProps,
    className,
    linkTo,
    fullWidth,
    ...otherProps
  } = props;

  const classes = ButtonStyles({
    size,
    iconSize,
    height,
    variant,
    palette,
  });

  return (
    <AppGrid item width={fullWidth ? "100%" : undefined} {...gridProps}>
      <Button
        variant={variant}
        fullWidth={fullWidth}
        {...otherProps}
        {...(linkTo && { component: Link, to: linkTo })}
        className={classes.button + " " + className}
      >
        {ButtonIcon && <ButtonIcon />}
        {text}
      </Button>
    </AppGrid>
  );
};
