import React, { useEffect, useState } from "react";
import {
  AppTextFieldControl,
  ITextFieldControlProps,
} from "./TextFieldControl";

export interface INumberControlProps extends ITextFieldControlProps {
  min?: number;
  num?: number | null;
  numUpdated: (num: number) => void;
}

export const AppNumberControl = (props: INumberControlProps): JSX.Element => {
  const { min, num, numUpdated, ...otherProps } = props;

  // Always update this local value (we want our input to always update, but maybe not send data up)
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(num?.toString() ?? "");
  }, [num]);

  return (
    <AppTextFieldControl
      onKeyDown={(e) => ["e", "E", "+"].includes(e.key) && e.preventDefault()}
      type="number"
      value={value}
      onChange={(event) => {
        const newValue = event.target.value;
        setValue(newValue);

        const newNum = Number(newValue);
        if (!isNaN(newNum)) {
          const aboveMin = min === undefined ? true : newNum >= min;
          if (aboveMin) {
            // If number update is valid, we should send it up
            numUpdated(newNum);
          } else {
            // If not valid, we should reset our text
            setValue("");
          }
        }
      }}
      {...otherProps}
    />
  );
};
