import { appMakeStyles } from "../../Shared/Themes/app-make-styles";

export const SideBarStyles = appMakeStyles((theme) => ({
  drawer: { display: "flex", flexBasis: "content", width: "auto" },
  sidebarWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "210px",
    height: "100%",

    backgroundColor: theme.palette.navigation.background,
    color: "white",
  },
  header: {
    flex: "0 0 auto",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    padding: "10px",
    backgroundColor: theme.palette.navigation.accent,
    borderBottom: theme.palette.navigation.border,
    transition: "background-color 300ms linear",
    cursor: "pointer",
    userSelect: "none",

    "&:hover": {
      backgroundColor: theme.palette.navigation.selected,
    },
  },
  logoLink: {
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    backgroundColor: "transparent",
    padding: "10px",
  },
  logoImage: {
    height: "42px",
    width: "auto",
  },
  closeButton: {
    position: "absolute",
    top: "0",
    right: "0",
    display: "flex",

    padding: "6px",
    color: theme.palette.navigation.text,

    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  list: {
    flex: "1 0 auto",
    color: theme.palette.navigation.text,
    display: "flex",
    flexDirection: "column",
    padding: "5px",
  },
  item: {
    display: "flex",
    alignItems: "center",
    "justify-content": "flex-start",

    width: "auto",
    margin: "5px",
    padding: "12px",

    borderRadius: "6px",
    transition: "background 300ms linear",
    background: theme.palette.navigation.item,
    textDecoration: "none !important",
    color: theme.palette.navigation.disabledText,
    border: "1px solid transparent",

    "&:hover": {
      border: theme.palette.navigation.border,
      color: theme.palette.navigation.text,
    },
  },
  itemSelected: {
    opacity: "1 !important",
    background: theme.palette.navigation.selected,
    color: theme.palette.navigation.text,
    fontWeight: 700,
    border: theme.palette.navigation.border,

    "& *": {
      opacity: 1,
    },
  },
  itemIcon: {
    height: "30px",
    width: "24px",
    marginRight: "15px",
  },
  itemText: {
    textDecoration: "none !important",
  },
  schoolDistrictSelectorContainer: {
    flex: "0 0 auto",

    backgroundColor: theme.palette.navigation.accent,
    borderTop: theme.palette.navigation.border,
    cursor: "pointer",
    fontWeight: 700,
    color: theme.palette.navigation.disabledText,

    "&:hover": {
      backgroundColor: theme.palette.navigation.selected,
      color: theme.palette.navigation.text,
    },
  },
  schoolDistrictSelector: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
  },
  schoolDistrictIcon: {
    marginRight: "15px",
  },
  schoolDistrictName: {},
}));
