import React from "react";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppButton } from "../../../../Shared/Components/Button";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { IStudentSimple } from "../../../../Shared/Models/Entities/student-simple.interface";

export const AppEventRegisteredStudentsList = ({
  students,
  studentsSelected,
}: {
  students: IStudentSimple[] | null;
  studentsSelected: (students: IStudentSimple[]) => void;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  const removeStudent = (student: IStudentSimple): void => {
    studentsSelected((students || []).filter((s) => s.id !== student.id));
  };

  return (
    <AppCustomTable
      showLoadingText={"No Students Registered"}
      columns={[
        {
          id: "id",
          label: "ID",
        },
        {
          id: "first-name",
          label: "First Name",
        },
        {
          id: "last-name",
          label: "Last Name",
        },
        {
          id: "grade",
          label: "Grade",
        },
        ...(canEdit
          ? [
              {
                id: "action",
                label: "",
                width: 90,
              },
            ]
          : []),
      ]}
      rows={students?.map((student) => ({
        cells: [
          {
            value: student.externalId,
          },
          {
            value: student.firstName,
          },
          {
            value: student.lastName,
          },
          {
            value: student.grade,
          },
          ...(canEdit
            ? [
                {
                  value: (
                    <AppButton
                      icon={Icons.Delete}
                      palette={PaletteTypes.ERROR}
                      onClick={() => removeStudent(student)}
                    />
                  ),
                },
              ]
            : []),
        ],
      }))}
    />
  );
};
