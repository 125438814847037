import React from "react";
import { AppGrid, IGridProps } from "../Grid";
import { FormControlLabel, Switch, SwitchProps } from "@material-ui/core";
import { appMakeStyles } from "../../Themes/app-make-styles";

const SwitchClasses = appMakeStyles((theme) => ({
  formControlLabel: {
    color: theme.palette.text.primary + " !important",
    marginLeft: 0,

    "& .Mui-disabled, &.Mui-disabled": {
      color: theme.palette.text.secondary + " !important",

      "& .MuiSwitch-root": {
        opacity: 0.6,
      },
    },
  },
  switch: {
    height: "30px",
    width: "50px",
    padding: "9px 8px",
    overflow: "visible",

    "& .MuiSwitch-track": {
      width: "100%",
      backgroundColor: theme.palette.text.primary + " !important",
    },

    "& .MuiSwitch-switchBase": {
      padding: "5px",
    },

    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      opacity: 0.5 + " !important",
    },
    "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked + .MuiSwitch-track": {
      opacity: 0.35 + " !important",
    },
    "& .MuiSwitch-switchBase:not(.Mui-checked) + .MuiSwitch-track": {
      opacity: 0.35 + " !important",
    },
    "& .MuiSwitch-switchBase.Mui-disabled:not(.Mui-checked) + .MuiSwitch-track":
      {
        opacity: 0.35 + " !important",
      },

    "& .MuiSwitch-colorPrimary.Mui-checked": {
      color: theme.palette.primary.outline,
      transform: "translateX(20px) !important",
    },

    "& .Mui-disabled.Mui-checked": {
      color: theme.palette.primary.outline + " !important",
    },
    "& .Mui-disabled:not(.Mui-checked)": {
      color: theme.palette.background.editableInput + " !important",
    },
  },
  helpText: {
    color: theme.palette.grey.A700,
    margin: 0,
    fontSize: "0.8571428571428571rem",
    marginTop: " 3px",
    textAlign: "left",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
}));

export type SwitchControlProps = SwitchProps & {
  label: string;
  loading?: boolean;
  gridProps?: IGridProps;
  helperText?: string;
};

export const AppSwitchControl = (props: SwitchControlProps): JSX.Element => {
  const classes = SwitchClasses({});
  const { label, gridProps, value, ...otherProps } = props;

  return (
    <AppGrid item {...gridProps} direction="column">
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Switch
            value={value || false}
            {...otherProps}
            color="primary"
            className={classes.switch}
          />
        }
        label={label}
      />
      {props.helperText && (
        <div className={classes.helpText}>{props.helperText}</div>
      )}
    </AppGrid>
  );
};
