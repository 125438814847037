import React, { useState } from "react";
import { SideBarStyles } from "./SideBar.styles";
import classNames from "classnames";
import { IRouteConfig, RouteHelper } from "../route.helper";
import { Link } from "react-router-dom";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import HeroLogoLight from "../../Shared/Assets/Images/hero-logo-light.png";
import { AppIcon, Icons } from "../../Shared/Components/Icons/Icons";
import { AppAccessTypesEnum } from "../../Shared/Models/Enums/app-access-types.enum";
import { AppSchoolDistrictSelector } from "./SchoolDistrictSelectorDialog/SchoolDistrictSelectorDialog";

export const AppSidebar = ({
  selectedRouteConfig,
  sidebarOpen,
  shouldClose,
}: {
  selectedRouteConfig: IRouteConfig;
  sidebarOpen: boolean;
  shouldClose: () => void;
}): JSX.Element => {
  const classes = SideBarStyles({});
  const [schoolDistrictSelectorOpen, setSchoolDistrictSelectorOpen] =
    useState(false);

  if (sidebarOpen) {
    return (
      <>
        <div className={classes.drawer}>
          <div className={classes.sidebarWrapper}>
            <SidebarHeader shouldClose={shouldClose} />
            <SidebarLinks selectedRouteConfig={selectedRouteConfig} />
            <SchoolDistrictSelectorButton
              setOpen={() => setSchoolDistrictSelectorOpen(true)}
            />
          </div>
        </div>
        {schoolDistrictSelectorOpen && (
          <AppSchoolDistrictSelector
            open={schoolDistrictSelectorOpen}
            setOpen={setSchoolDistrictSelectorOpen}
          />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

const SidebarHeader = ({
  shouldClose,
}: {
  shouldClose: () => void;
}): JSX.Element => {
  const classes = SideBarStyles({});

  return (
    <div className={classes.header} onClick={() => shouldClose()}>
      <div className={classes.logoLink}>
        <img src={HeroLogoLight} alt="Hero" className={classes.logoImage} />
      </div>
    </div>
  );
};

const SidebarLinks = ({
  selectedRouteConfig,
}: {
  selectedRouteConfig: IRouteConfig;
}): JSX.Element => {
  const classes = SideBarStyles({});

  const enabledRootRoutes = RouteHelper.getRootConfigs().filter(
    (route) => route.enabled !== false
  );

  return (
    <div className={classes.list}>
      {enabledRootRoutes.map((rootRouteConfig, index) => {
        const RouteIcon = rootRouteConfig.icon;
        const isSelected = RouteHelper.doesRouteConfigMatchRootConfig(
          selectedRouteConfig,
          rootRouteConfig
        );
        return (
          <Link
            to={rootRouteConfig.path}
            key={index}
            className={classNames({
              [classes.item]: true,
              [classes.itemSelected]: isSelected,
            })}
          >
            <RouteIcon className={classes.itemIcon} />
            <div className={classes.itemText}>{rootRouteConfig.name}</div>
          </Link>
        );
      })}
    </div>
  );
};

const SchoolDistrictSelectorButton = ({
  setOpen,
}: {
  setOpen: () => void;
}): JSX.Element => {
  const classes = SideBarStyles({});
  const { icon: Icon, text } = getSchoolDistrictSelectorButtonState();

  return (
    <div
      className={classes.schoolDistrictSelectorContainer}
      onClick={() => {
        setOpen();
      }}
    >
      <div className={classes.schoolDistrictSelector}>
        <Icon className={classes.schoolDistrictIcon} />
        <div className={classes.schoolDistrictName}>{text}</div>
      </div>
    </div>
  );
};

const getSchoolDistrictSelectorButtonState = (): {
  icon: AppIcon;
  text: string;
} => {
  const { accessType, district, school } = StoreHelper.selector(
    (state) => state.appConfig
  );

  switch (accessType) {
    case AppAccessTypesEnum.GLOBAL:
      return {
        icon: Icons.Globe,
        text: "All School Groups",
      };
    case AppAccessTypesEnum.DISTRICT:
      return {
        icon: Icons.District,
        text: district?.name ?? "No Group Found",
      };
    case AppAccessTypesEnum.SCHOOL:
      return {
        icon: Icons.School,
        text: school?.name || "No School Found",
      };
    default:
      return {
        icon: Icons.Globe,
        text: "Viewing All",
      };
  }
};
