import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppBaseTable } from "../../../../Shared/Components/Tables/BaseTable/BaseTable";
import { Logger } from "../../../../Shared/Helpers/logger";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { ITracCode } from "../../../../Shared/Models/Entities/trac-code.interface";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { TracCodeService } from "../../../../Shared/Services/trac-code.service";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppTracCodeDraggableItem } from "./TracCodeDraggableItem/TracCodeDraggableItem";

export const AppTracCodesReOrderDialog = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refreshData: () => void;
}): JSX.Element => {
  const { school, district } = StoreHelper.selector((state) => state.appConfig);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [changesMade, setChangesMade] = useState(false);
  const { data: tracCodes, setData } = DataHooks.useFunctionCallState<
    ITracCode[]
  >({
    func: () =>
      TracCodeService.getAll({
        schoolId: school?.id,
        districtId: district?.id,
        // dummy paginationOptions
        paginationOptions: {
          sizeOptions: [],
          size: 0,
          page: 0,
        },
        inactive: true,
        paginate: false,
      }),
    deps: [school?.id, district?.id],
    showLoadingIndicator: true,
  });

  const handleSave = async () => {
    LoadingBarService.show();

    try {
      const updatedOrderTracCodes = await TracCodeService.reOrder(
        tracCodes as ITracCode[],
        school.id
      );

      setData(updatedOrderTracCodes);
      setChangesMade(false);
      props.setOpen(false);
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      if (!tracCodes) return null;
      const updatedTracCodes = [...tracCodes];
      const dragItem = tracCodes[dragIndex];
      updatedTracCodes.splice(dragIndex, 1);
      updatedTracCodes.splice(hoverIndex, 0, dragItem);

      setData(updatedTracCodes);
      if (dragIndex !== hoverIndex) setChangesMade(true);
    },
    [tracCodes]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <AppCustomDialog
        open={props.open}
        setOpen={props.setOpen}
        maxWidth="md"
        fullWidth
        aria-labelledby="confirmation-dialog"
        palette={PaletteTypes.SECONDARY}
        title={"ReOrder Trac Codes"}
        icon={Icons.Edit}
        onClose={props.refreshData}
        subHeader="Drag and drop to reorder your Trac Codes"
        action={
          <AppButton
            text="Save"
            icon={Icons.Save}
            palette={PaletteTypes.SUCCESS}
            onClick={handleSave}
            disabled={!changesMade}
          />
        }
      >
        <AppBaseTable>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>School/District</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tracCodes?.map((tracCode, index) => (
              <AppTracCodeDraggableItem
                key={`trac-code-draggable-row-${index}`}
                tracCode={tracCode}
                index={index}
                moveListItem={moveListItem}
                isHovered={index === hoverIndex}
                setHovered={setHoverIndex}
                firstOrLast={index === 0 || index === tracCodes.length - 1}
              />
            ))}
          </TableBody>
        </AppBaseTable>
      </AppCustomDialog>
    </DndProvider>
  );
};
