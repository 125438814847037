import { appMakeStyles } from "../../../Shared/Themes/app-make-styles";

export const BreadcrumbsStyles = appMakeStyles((theme) => ({
  breadcrumbs: {
    "margin-right": "auto",
    fontSize: theme.typography.headerFontSize,
    color: theme.palette.text.secondary,

    "& a:-webkit-any-link": {
      color: "inherit",
    },
  },
  breadcrumbLink: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "underline",
    },
  },
  breadcrumbBase: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
  },
  routeIcon: {
    marginRight: "5px",
    height: theme.typography.headerFontSize,
    width: theme.typography.headerFontSize,
  },
}));
