import React, { useState } from "react";
import { IAdminUser } from "../../../Shared/Models/Entities/admin-user.interface";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { AdminUserService } from "../../../Shared/Services/admin-user.service";
import { IDOMEvent } from "../../../Shared/Models/Interfaces/dom-event.interface";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppAdminUserTable } from "./AdminUserTable/AdminUserTable";
import { AppAdminUserCreateDialog } from "./AdminUserCreateDialog/AdminUserCreateDialog";
import { AppInternalAdminUserCreateDialog } from "./InternalAdminUserCreateDialog/InternalAdminUserCreateDialog";
import { TablePaginationHelper } from "../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { AppAdminUserListFilters } from "./AdminUserListFilters/AdminUserListFilters";
import { ISchoolSimple } from "../../../Shared/Models/Entities/school.interface";
import { ISimpleItem } from "../../../Shared/Models/Entities/simple-item.interface";
import { IPaginationOptions } from "../../../Shared/Components/Tables/TablePagination/pagination-options.interface";

interface IAdminUserFilters {
  searchKey?: string;
  school?: ISchoolSimple | null;
  district?: ISimpleItem | null;
  paginationOptions: IPaginationOptions;
}

export const AppAdminUserManager = (): JSX.Element => {
  const [createAdminUserDialogOpen, setCreateAdminUserDialogOpen] =
    useState(false);
  const [
    createInternalAdminUserDialogOpen,
    setCreateInternalAdminUserDialogOpen,
  ] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const [filters, setFilters] = useState<IAdminUserFilters>({
    paginationOptions: TablePaginationHelper.getDefaultOptions(),
  });

  const {
    loading,
    data: adminUsers,
    refreshData,
  } = DataHooks.useFunctionCallState<IAdminUser[]>({
    func: () =>
      AdminUserService.get({
        ...(filters.searchKey !== null && { searchKey: filters.searchKey }),
        ...(filters.district !== null && { districtId: filters.district?.id }),
        ...(filters.school !== null && { schoolId: filters.school?.id }),
        paginationOptions: filters.paginationOptions,
      }),
    showLoadingIndicator: true,
    deps: [filters],
  });

  const {
    loading: countLoading,
    data: count,
    refreshData: refreshCount,
  } = DataHooks.useFunctionCallState<number>({
    func: () =>
      AdminUserService.getCount({
        ...(filters.searchKey !== null && { searchKey: filters.searchKey }),
        ...(filters.district !== null && { districtId: filters.district?.id }),
        ...(filters.school !== null && { schoolId: filters.school?.id }),
        paginationOptions: filters.paginationOptions,
      }),
    deps: [filters],
  });

  const searchHandler = (event: IDOMEvent) => {
    setSearchKey(event.target.value);
    setFilters({
      ...filters,
      searchKey: searchKey,
      paginationOptions: TablePaginationHelper.getDefaultOptions(),
    });
  };

  const handelRefresh = () => {
    refreshData();
    refreshCount();
  };

  return (
    <>
      <AppGrid
        container
        direction="column"
        justifyContent="flex-start"
        spacing={2}
      >
        <AppAdminUserListFilters
          school={filters.school}
          setSchool={(school) => {
            setFilters({
              ...filters,
              school,
              district: null,
              paginationOptions: TablePaginationHelper.getDefaultOptions(),
            });
          }}
          district={filters.district}
          setDistrict={(district) => {
            setFilters({
              ...filters,
              district,
              school: null,
              paginationOptions: TablePaginationHelper.getDefaultOptions(),
            });
          }}
          searchHandler={searchHandler}
          searchKey={searchKey}
          setCreateAdminUserDialogOpen={setCreateAdminUserDialogOpen}
          setCreateInternalAdminUserDialogOpen={
            setCreateInternalAdminUserDialogOpen
          }
        />
        <AppGrid item>
          <AppAdminUserTable
            count={count}
            countLoading={countLoading}
            loading={loading}
            adminUsers={adminUsers ? adminUsers : []}
            onDataUpdated={async () => handelRefresh()}
            paginationOptions={filters.paginationOptions}
            setPaginationOptions={(options: IPaginationOptions) => {
              setFilters({
                ...filters,
                paginationOptions: options,
              });
            }}
          />
        </AppGrid>
      </AppGrid>
      <AppAdminUserCreateDialog
        open={createAdminUserDialogOpen}
        setOpen={setCreateAdminUserDialogOpen}
        onDataUpdated={async () => handelRefresh()}
      />
      <AppInternalAdminUserCreateDialog
        open={createInternalAdminUserDialogOpen}
        setOpen={setCreateInternalAdminUserDialogOpen}
        onDataUpdated={async () => handelRefresh()}
      />
    </>
  );
};
