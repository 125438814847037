import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ISnackbarReducerState,
  SnackbarSeverities,
} from "./snackbar-reducer-state.interface";

const initialState: ISnackbarReducerState = {
  isActive: false,
  message: "",
  key: 0,
  severity: "success",
};

export interface ISnackbarProps {
  message: string;
  severity: SnackbarSeverities;
}

export const SnackbarReducerSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (
      state: ISnackbarReducerState,
      action: PayloadAction<ISnackbarProps>
    ): void => {
      const { message, severity } = action.payload;
      const key = Math.floor(Math.random() * 100000);

      state.message = message;
      state.severity = severity;
      state.isActive = true;
      state.key = key;

      // TODO: Add back timeout removal, circular deps f'd it
    },
    clearSnackbar: (state: ISnackbarReducerState) => {
      state.isActive = false;
    },
  },
});
export const SnackbarReducerActions = SnackbarReducerSlice.actions;
