import React from "react";
import { IEvent } from "../../../../Shared/Models/Entities/event.interface";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppDateControl } from "../../../../Shared/Components/Controls/DateControl";
import { IEventValidationResults } from "../EventViewer.helper";
import { Tab, Tabs } from "@material-ui/core";
import {
  EventRecurrenceDays,
  EventRecurrenceRangeTypes,
  EventRecurrenceTypes,
  IEventRecurrence,
} from "../../../../Shared/Models/Entities/event-recurrence.interface";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppMultipleSelectControl } from "../../../../Shared/Components/Controls/MultipleSelectControl";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { TimeHelper } from "../../../../Shared/Helpers/time-helper";
import { AppNumberControl } from "../../../../Shared/Components/Controls/NumberControl";
import { AppTimeControl } from "../../../../Shared/Components/Controls/TimeControl";

export const AppEventDates = ({
  event,
  updateEvent,
  errorResults,
}: {
  event: IEvent | null;
  updateEvent: (event: IEvent) => void;
  errorResults: IEventValidationResults;
}): JSX.Element => {
  const handleUpdate = (props: Partial<IEvent>) => {
    if (event) {
      updateEvent({
        ...event,
        ...props,
      });
    }
  };

  const handleRecurrenceUpdate = (props: Partial<IEventRecurrence>) => {
    if (event) {
      updateEvent({
        ...event,
        recurrence: {
          ...event.recurrence,
          ...props,
        },
      });
    }
  };

  return (
    <AppGrid container spacing={4}>
      <AppEventDatesHours
        event={event}
        handleUpdate={handleUpdate}
        errorResults={errorResults}
      />
      <AppEventDatesRecurrenceTypes
        event={event}
        handleRecurrenceUpdate={handleRecurrenceUpdate}
        errorResults={errorResults}
      />
      <AppEventDatesRecurrenceRange
        event={event}
        handleRecurrenceUpdate={handleRecurrenceUpdate}
        errorResults={errorResults}
      />
    </AppGrid>
  );
};

const AppEventDatesHours = ({
  event,
  handleUpdate,
  errorResults,
}: {
  event: IEvent | null;
  handleUpdate: (event: Partial<IEvent>) => void;
  errorResults: IEventValidationResults;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  return (
    <AppGrid item container spacing={1}>
      <AppFormCategory
        gridProps={{ xs: 12 }}
        title="What time of day should this event occur?"
      />

      <AppTimeControl
        gridProps={{ xs: 6 }}
        label="Start Hour"
        value={
          event?.start || TimeHelper.formatDateToDBString(TimeHelper.getNoon())
        }
        onChange={(date) => {
          if (date) {
            handleUpdate({
              start: TimeHelper.formatDateToDBString(date),
            });
          }
        }}
        error={!!errorResults.start || !!errorResults.timeOrder}
        disabled={!canEdit}
      />

      <AppTimeControl
        gridProps={{ xs: 6 }}
        label="End Hour"
        value={
          event?.end || TimeHelper.formatDateToDBString(TimeHelper.getNoon())
        }
        onChange={(date) => {
          if (date) {
            handleUpdate({
              end: TimeHelper.formatDateToDBString(date),
            });
          }
        }}
        error={!!errorResults.end || !!errorResults.timeOrder}
        disabled={!canEdit}
      />
    </AppGrid>
  );
};

const AppEventDatesRecurrenceTypes = ({
  event,
  handleRecurrenceUpdate,
  errorResults,
}: {
  event: IEvent | null;
  handleRecurrenceUpdate: (recurrence: Partial<IEventRecurrence>) => void;
  errorResults: IEventValidationResults;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  const weekdayOptions = [
    EventRecurrenceDays.Sunday,
    EventRecurrenceDays.Monday,
    EventRecurrenceDays.Tuesday,
    EventRecurrenceDays.Wednesday,
    EventRecurrenceDays.Thursday,
    EventRecurrenceDays.Friday,
    EventRecurrenceDays.Saturday,
  ].map((day) => ({
    id: day,
    name: day,
  }));

  const daysOfMonthOptions = [...Array(31)].map((_, index) => {
    const day = index + 1;
    const getName = (): string => {
      switch (day) {
        case 1:
          return "1st";
        case 2:
          return "2nd";
        case 3:
          return "3rd";
        case 21:
          return "21st";
        case 22:
          return "22nd";
        case 23:
          return "23rd";
        case 31:
          return "31st";
        default:
          return (index + 1).toString() + "th";
      }
    };
    return {
      id: day,
      name: getName(),
    };
  });

  return (
    <AppGrid item container spacing={1}>
      <AppFormCategory
        gridProps={{ xs: 12 }}
        title="How often should this event occur?"
      />

      <AppGrid item xs={12} marginTop={-10} marginBottom={10}>
        <Tabs
          value={event?.recurrence?.type || 0}
          onChange={(event, newValue) => {
            handleRecurrenceUpdate({
              type: newValue,
            });
          }}
          textColor="secondary"
          indicatorColor="secondary"
          color="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab
            label="Once"
            value={EventRecurrenceTypes.ONCE}
            disabled={!canEdit}
          />
          <Tab
            label="Every X Days"
            value={EventRecurrenceTypes.DAILY}
            disabled={!canEdit}
          />
          <Tab
            label="Days Of The Week"
            value={EventRecurrenceTypes.WEEKLY}
            disabled={!canEdit}
          />
          <Tab
            label="Every X Months"
            value={EventRecurrenceTypes.MONTHLY}
            disabled={!canEdit}
          />
        </Tabs>
      </AppGrid>

      {event?.recurrence?.type === EventRecurrenceTypes.ONCE && (
        <>
          <AppDateControl
            gridProps={{ xs: 6 }}
            label="Date"
            value={event?.recurrence?.date || new Date()}
            onChange={(date) => {
              if (date) {
                handleRecurrenceUpdate({
                  date: TimeHelper.formatDateToDBString(date),
                });
              }
            }}
            error={!!errorResults.date}
            disabled={!canEdit}
          />
        </>
      )}

      {event?.recurrence?.type === EventRecurrenceTypes.DAILY && (
        <AppNumberControl
          gridProps={{ xs: 6 }}
          label="Every X Days"
          num={event?.recurrence?.everyXDays}
          min={1}
          numUpdated={(num) => {
            handleRecurrenceUpdate({
              everyXDays: num,
            });
          }}
          error={!!errorResults.everyXDays}
          disabled={!canEdit}
        />
      )}

      {event?.recurrence?.type === EventRecurrenceTypes.WEEKLY && (
        <AppMultipleSelectControl
          gridProps={{ xs: 6 }}
          label="Days"
          placeholder="None"
          icon={Icons.Shape}
          value={event?.recurrence?.weekdays || []}
          onUpdate={(value) => {
            handleRecurrenceUpdate({
              weekdays: value as EventRecurrenceDays[],
            });
          }}
          options={weekdayOptions}
          error={!!errorResults.weekdays}
          disabled={!canEdit}
        />
      )}

      {event?.recurrence?.type === EventRecurrenceTypes.MONTHLY && (
        <>
          <AppSelectControl
            gridProps={{ xs: 6 }}
            label="Day Of Month"
            value={event?.recurrence?.dayOfMonth}
            selected={(value) => {
              if (value) {
                handleRecurrenceUpdate({
                  dayOfMonth: Number(value),
                });
              }
            }}
            options={daysOfMonthOptions}
            error={!!errorResults.dayOfMonth}
            disabled={!canEdit}
          />
          <AppNumberControl
            gridProps={{ xs: 6 }}
            label="Every X Months"
            num={event?.recurrence?.everyXMonths}
            min={1}
            numUpdated={(num) => {
              handleRecurrenceUpdate({
                everyXMonths: num,
              });
            }}
            error={!!errorResults.everyXMonths}
            disabled={!canEdit}
          />
        </>
      )}
    </AppGrid>
  );
};

const AppEventDatesRecurrenceRange = ({
  event,
  handleRecurrenceUpdate,
  errorResults,
}: {
  event: IEvent | null;
  handleRecurrenceUpdate: (recurrence: Partial<IEventRecurrence>) => void;
  errorResults: IEventValidationResults;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  if (event?.recurrence?.type === EventRecurrenceTypes.ONCE) {
    return <></>;
  } else {
    return (
      <AppGrid item container spacing={1}>
        <AppFormCategory
          gridProps={{ xs: 12 }}
          title="How long should this event keep occurring?"
        />

        <AppGrid item xs={12} marginTop={-10} marginBottom={10}>
          <Tabs
            value={event?.recurrence?.rangeType || 0}
            onChange={(event, newValue) => {
              handleRecurrenceUpdate({
                rangeType: newValue,
              });
            }}
            textColor="secondary"
            indicatorColor="secondary"
            color="primary"
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab
              label="Until Deactivated"
              value={EventRecurrenceRangeTypes.NO_END_DATE}
              disabled={!canEdit}
            />
            <Tab
              label="Until a specific day"
              value={EventRecurrenceRangeTypes.END_BY_DATE}
              disabled={!canEdit}
            />
            <Tab
              label="Until a specific amount"
              value={EventRecurrenceRangeTypes.END_AFTER_OCCURRENCES}
              disabled={!canEdit}
            />
          </Tabs>
        </AppGrid>

        <AppDateControl
          gridProps={{ xs: 6 }}
          label="Start Date"
          value={event?.recurrence?.rangeStart || null}
          onChange={(date) => {
            if (date) {
              handleRecurrenceUpdate({
                rangeStart: TimeHelper.formatDateToDBString(date),
              });
            }
          }}
          error={!!errorResults.rangeStart || !!errorResults.dateOrder}
          disabled={!canEdit}
        />

        {event?.recurrence?.rangeType ===
          EventRecurrenceRangeTypes.END_BY_DATE && (
          <AppDateControl
            gridProps={{ xs: 6 }}
            label="End Date"
            value={event?.recurrence?.rangeEnd || null}
            onChange={(date) => {
              if (date) {
                handleRecurrenceUpdate({
                  rangeEnd: TimeHelper.formatDateToDBString(date),
                });
              }
            }}
            error={!!errorResults.rangeEnd || !!errorResults.dateOrder}
            disabled={!canEdit}
          />
        )}

        {event?.recurrence?.rangeType ===
          EventRecurrenceRangeTypes.END_AFTER_OCCURRENCES && (
          <AppNumberControl
            gridProps={{ xs: 6 }}
            label="# of Occurrences"
            num={event?.recurrence?.occurrences}
            min={1}
            numUpdated={(num) => {
              handleRecurrenceUpdate({
                occurrences: num,
              });
            }}
            error={!!errorResults.occurrences}
            disabled={!canEdit}
          />
        )}
      </AppGrid>
    );
  }
};
