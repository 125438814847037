import React, { Dispatch, SetStateAction, useState } from "react";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { UrlHelper } from "../../../Shared/Helpers/url.helper";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppCard } from "../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../Shared/Components/Cards/CardHeader";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppCardContent } from "../../../Shared/Components/Cards/CardContent";
import {
  ISchoolUserRoleValidationResults,
  SchoolUserRoleViewerHelper,
} from "./SchoolUserRoleViewer.helper";
import { AppButton } from "../../../Shared/Components/Button";
import { AppActionBar } from "../../../Shared/Components/ActionBar";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { Logger } from "../../../Shared/Helpers/logger";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { SchoolUserRoleService } from "../../../Shared/Services/school-user-role.service";
import { ISchoolUserRole } from "../../../Shared/Models/Entities/school-user-role.interface";
import { AppSchoolUserRoleDeleteDialog } from "./SchoolUserRoleDeleteDialog/SchoolUserRoleDeleteDialog";
import { AppTextFieldControl } from "../../../Shared/Components/Controls/TextFieldControl";
import { AppSchoolUserPermissionSelector } from "./SchoolUserPermissionSelector/SchoolUserPermissionSelector";
import { FormControl, FormGroup, FormLabel } from "@material-ui/core";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { AppSelectControl } from "../../../Shared/Components/Controls/SelectControl";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";

const SchoolUserRoleViewer = (props: RouteComponentProps): JSX.Element => {
  const [isCreating, setIsCreating] = useState(false);
  const schoolUserRoleId = UrlHelper.getParamNumber("id");
  const [errorResults, setErrorResults] = useState(
    {} as ISchoolUserRoleValidationResults
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const { district, school } = StoreHelper.selector((state) => state.appConfig);

  // School user role
  const getSchoolUserRoleToEdit = async (): Promise<ISchoolUserRole> => {
    if (schoolUserRoleId) {
      setIsCreating(false);
      return SchoolUserRoleService.getById(schoolUserRoleId);
    } else {
      setIsCreating(true);
      return await SchoolUserRoleViewerHelper.getDefault();
    }
  };
  const schoolUserRoleData = DataHooks.useFunctionCallState<ISchoolUserRole>({
    func: getSchoolUserRoleToEdit,
    deps: [schoolUserRoleId],
    showLoadingIndicator: true,
  });
  const schoolUserRole = schoolUserRoleData.data;

  // Permissions
  const canEditGlobalRoles = hasPermission(
    AppPermissionsEnum.GLOBAL_ROLES_EDIT
  );
  const canEditLocalRoles = hasPermission(AppPermissionsEnum.ROLES_EDIT);
  const canEdit = schoolUserRole
    ? schoolUserRole.isGlobal
      ? canEditLocalRoles && canEditGlobalRoles
      : canEditLocalRoles
    : false;

  const updateSchoolUserRole = (updatedSchoolUserRole: ISchoolUserRole) => {
    schoolUserRoleData.setData(updatedSchoolUserRole);
    setHasChanges(true);
    if (haveTriedSubmitting) {
      const results = SchoolUserRoleViewerHelper.validateSchoolUserRole(
        updatedSchoolUserRole
      );
      setErrorResults(results);
    }
  };

  const handleSubmit = async () => {
    if (schoolUserRole) {
      const errorResults =
        SchoolUserRoleViewerHelper.validateSchoolUserRole(schoolUserRole);
      const isValid = Object.values(errorResults).length === 0;
      setHaveTriedSubmitting(true);
      setErrorResults(errorResults);
      if (isValid) {
        LoadingBarService.show();
        try {
          setHasChanges(false);
          if (isCreating) {
            await SchoolUserRoleService.create(
              schoolUserRole,
              school?.id,
              district?.id
            );
          } else {
            await SchoolUserRoleService.update(schoolUserRole);
          }
          SnackbarService.success(
            (isCreating ? "Created " : "Updated ") + schoolUserRole.name
          );
          goBackToList();
        } catch (error) {
          Logger.error(error);
          SnackbarService.error(error);
        }
        LoadingBarService.hide();
      }
    }
  };

  const goBackToList = () => {
    props.history.push("/school-user-roles");
  };

  return (
    <>
      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Editor
        canEdit={canEdit}
        loading={schoolUserRoleData.loading}
        schoolUserRole={schoolUserRole}
        errorResults={errorResults}
        updateSchoolUserRole={updateSchoolUserRole}
      />
      {canEdit && (
        <Actions
          isCreating={isCreating}
          schoolUserRole={schoolUserRole}
          handleSubmit={handleSubmit}
          hasChanges={hasChanges}
          setArchiveDialogOpen={setArchiveDialogOpen}
        />
      )}
      <AppSchoolUserRoleDeleteDialog
        schoolUserRole={schoolUserRole}
        open={archiveDialogOpen}
        setOpen={setArchiveDialogOpen}
        onUserUpdated={goBackToList}
      />
    </>
  );
};

const Editor = ({
  canEdit,
  loading,
  schoolUserRole,
  errorResults,
  updateSchoolUserRole,
}: {
  canEdit: boolean;
  loading: boolean;
  schoolUserRole: ISchoolUserRole | null;
  errorResults: ISchoolUserRoleValidationResults;
  updateSchoolUserRole: (role: ISchoolUserRole) => void;
}) => {
  return (
    <AppGrid container justifyContent="center" spacing={3}>
      <AppGrid item xs={12} lg={7} xl={6}>
        <AppCard hasHeader>
          <AppCardHeader
            title="School User Role"
            icon={Icons.AssignmentInd}
            palette={PaletteTypes.PRIMARY}
          />
          <AppCardContent>
            <AppGrid container spacing={3}>
              <AppGrid item xs={12}>
                <AppTextFieldControl
                  value={schoolUserRole?.name}
                  label={"Name *"}
                  onChange={(event) => {
                    if (schoolUserRole) {
                      updateSchoolUserRole({
                        ...schoolUserRole,
                        name: event.target.value as string,
                      });
                    }
                  }}
                  icon={Icons.AccountBox}
                  error={!!errorResults.name}
                  disabled={!canEdit}
                />
              </AppGrid>
              <AppGrid item xs={12}>
                <AppSelectControl
                  value={schoolUserRole?.posNegTracCodePermission}
                  options={[
                    {
                      id: "0",
                      name: "All",
                    },
                    {
                      id: "1",
                      name: "Positive Only",
                    },
                    {
                      id: "-1",
                      name: "Negative Only",
                    },
                  ]}
                  label={"Pos Neg Trac Code Permission *"}
                  selected={(value) => {
                    if (schoolUserRole && value) {
                      updateSchoolUserRole({
                        ...schoolUserRole,
                        posNegTracCodePermission: Number(value),
                      });
                    }
                  }}
                  icon={Icons.Add}
                  error={!!errorResults.posNegTracCodePermission}
                  disabled={!canEdit}
                />
              </AppGrid>
              <AppGrid item xs={12}>
                <FormGroup>
                  <FormLabel style={{ marginBottom: "4px" }}>
                    Permissions *
                  </FormLabel>
                  <FormControl>
                    <AppSchoolUserPermissionSelector
                      selectedPermissionIds={
                        schoolUserRole?.permissionIds || []
                      }
                      selectedPermissionIdsUpdated={(permissionIds) => {
                        if (schoolUserRole) {
                          updateSchoolUserRole({
                            ...schoolUserRole,
                            permissionIds,
                          });
                        }
                      }}
                      loading={loading}
                      disabled={!canEdit}
                    />
                  </FormControl>
                </FormGroup>
              </AppGrid>
            </AppGrid>
          </AppCardContent>
        </AppCard>
      </AppGrid>
    </AppGrid>
  );
};

const Actions = ({
  isCreating,
  schoolUserRole,
  handleSubmit,
  hasChanges,
  setArchiveDialogOpen,
}: {
  isCreating: boolean;
  schoolUserRole: ISchoolUserRole | null;
  handleSubmit: () => void;
  hasChanges: boolean;
  setArchiveDialogOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { data: canDelete } = DataHooks.useFunctionCallState<boolean>({
    func: async () =>
      schoolUserRole?.id
        ? SchoolUserRoleService.canDelete(schoolUserRole?.id)
        : null,
    deps: [schoolUserRole?.id],
    showLoadingIndicator: true,
  });

  return (
    <AppActionBar
      leftActions={
        <>
          {!isCreating && schoolUserRole && canDelete === true && (
            <>
              <AppButton
                text={"Delete"}
                icon={Icons.Delete}
                size="large"
                palette={PaletteTypes.ERROR}
                onClick={() => {
                  setArchiveDialogOpen(true);
                }}
              />
            </>
          )}
        </>
      }
      rightActions={
        <>
          {!isCreating && (
            <AppButton
              text={"Cancel"}
              icon={Icons.Close}
              size="large"
              variant="outlined"
              palette={PaletteTypes.WARNING}
              linkTo="/school-users"
            />
          )}
          <AppButton
            text={isCreating ? "Create" : "Update"}
            icon={Icons.Save}
            size="large"
            palette={PaletteTypes.SUCCESS}
            onClick={handleSubmit}
            disabled={!hasChanges}
          />
        </>
      }
    />
  );
};

export const AppSchoolUserRoleViewer = withRouter(SchoolUserRoleViewer);
