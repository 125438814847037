import React, { useState } from "react";
import { useHistory } from "react-router";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { IPopulatedTracCode } from "../../../../Shared/Models/Entities/populated-trac-code.interface";
import { ITracCodeDisciplinaryAction } from "../../../../Shared/Models/Entities/trac-code-disciplinary-action.interface";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { TracCodeDisciplinaryActionManagerDialog } from "./TracCodeDisciplinaryActionManagerDialog/TracCodeDisciplinaryActionManagerDialog";

export const AppTracCodeDisciplinaryActions = (props: {
  canEdit: boolean;
  disciplinaryActions: ITracCodeDisciplinaryAction[];
  loading: boolean;
  tracCode: IPopulatedTracCode;
  refreshTracCode: () => void;
}): JSX.Element => {
  const history = useHistory();
  const [
    selectedTracCodeDisciplinaryActionId,
    setSelectedTracCodeDisciplinaryActionId,
  ] = useState(-1);
  const [showEditorDialog, setShowEditorDialog] = useState(false);
  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="Disciplinary Actions"
          palette={PaletteTypes.PRIMARY}
          icon={Icons.Check}
          action={
            props.canEdit && (
              <AppGrid container spacing={2}>
                <AppButton
                  text="Manage Actions"
                  palette={PaletteTypes.SECONDARY}
                  onClick={() => {
                    history.push("/disciplinary-actions");
                  }}
                />
                <AppButton
                  text="Add"
                  icon={Icons.Add}
                  palette={PaletteTypes.SUCCESS}
                  onClick={() => {
                    setSelectedTracCodeDisciplinaryActionId(-1);
                    setShowEditorDialog(true);
                  }}
                />
              </AppGrid>
            )
          }
        />
        <AppCardContent padding={0}>
          <AppCustomTable
            loading={props.loading}
            columns={[
              {
                id: "name",
                label: "Name",
              },
              {
                id: "points",
                label: "Points",
              },
              {
                id: "triggerAt",
                label: "Trigger At",
              },
              {
                id: "printCopies",
                label: "Print Copies",
              },
              {
                id: "repeat",
                label: "Action Repeat",
              },
              ...(props.canEdit
                ? [
                    {
                      id: "edit",
                      label: "",
                    },
                  ]
                : []),
            ]}
            rows={props.disciplinaryActions?.map((action) => ({
              cells: [
                {
                  value: action.name,
                },
                {
                  value: action.points,
                },
                {
                  value:
                    action.triggerAt === 9999 ? "Default" : action.triggerAt,
                },
                {
                  value: action.printCopies,
                },
                {
                  value: action.repeat,
                },
                ...(props.canEdit
                  ? [
                      {
                        value: (
                          <AppGrid container spacing={1}>
                            <AppButton
                              icon={Icons.Edit}
                              palette={PaletteTypes.SECONDARY}
                              onClick={() => {
                                setSelectedTracCodeDisciplinaryActionId(
                                  action.id
                                );
                                setShowEditorDialog(true);
                              }}
                            />
                          </AppGrid>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </AppCardContent>
      </AppCard>
      {showEditorDialog && (
        <TracCodeDisciplinaryActionManagerDialog
          tracCodeDisciplinaryActionId={selectedTracCodeDisciplinaryActionId}
          open={showEditorDialog}
          setOpen={setShowEditorDialog}
          tracCode={props.tracCode}
          refreshTracCode={props.refreshTracCode}
        />
      )}
    </>
  );
};
