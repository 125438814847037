import { IBellSchedulePeriod } from "../../../../Shared/Models/Entities/bell-schedule-period.interface";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
export interface IPeriodValidationResults {
    name?: PeriodValidationErrors;
}
export enum PeriodValidationErrors {
    REQUIRED = "Required",
}  
export class PeriodEditorHelper {
    
    
    static validatePeriod(period: IBellSchedulePeriod): IPeriodValidationResults {
        const results = {} as IPeriodValidationResults;
        if(!period.name || period.name.trim().length === 0){
            results.name = PeriodValidationErrors.REQUIRED;
            SnackbarService.warn("Name is required.");
        }
        return results;
    };
}