import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppAdminUserEditorPanel } from "../AdminUserEditorPanel/AdminUserEditorPanel";
import { IAdminUser } from "../../../../Shared/Models/Entities/admin-user.interface";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppAdminUserEditDialog = (props: {
  adminUser: IAdminUser;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title={"Edit User: " + props.adminUser.firstName}
      subHeader="Fill out this form to create a new user in Hero."
      icon={Icons.Person}
      palette={PaletteTypes.SECONDARY}
    >
      <AppAdminUserEditorPanel
        adminUser={props.adminUser}
        isCreating={false}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
