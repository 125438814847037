import React, { Dispatch, SetStateAction } from "react";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { EventService } from "../../../../Shared/Services/event.service";
import { AppConfirmationDialog } from "../../../../Shared/Components/Dialogs/ConfirmationDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { IEvent } from "../../../../Shared/Models/Entities/event.interface";
import { RouteComponentProps, withRouter } from "react-router";

const EventDeleteDialog = (
  props: RouteComponentProps & {
    event: IEvent;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  }
): JSX.Element => {
  const handleDeleteConfirmed = async () => {
    if (props.event) {
      LoadingBarService.show();
      try {
        await EventService.delete(props.event.id);
        SnackbarService.success("Delete event: " + props.event.name);
        props.history.push("/events");
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    } else {
      Logger.error("No event passed to delete dialog!");
    }
  };

  return (
    <AppConfirmationDialog
      open={props.open}
      setOpen={props.setOpen}
      title={"Archive Event?"}
      content={"Are you sure you want to delete this event?"}
      icon={Icons.Delete}
      agreeAction={handleDeleteConfirmed}
      palette={PaletteTypes.ERROR}
    />
  );
};

export const AppEventDeleteDialog = withRouter(EventDeleteDialog);
