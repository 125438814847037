import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@material-ui/core";
import React from "react";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { AppGrid, IGridProps } from "../Grid";
import { AppIcon } from "../Icons/Icons";

const SelectControlStyles = appMakeStyles<ISelectControlProps>((theme) => ({
  formControl: {
    "& .Mui-disabled": {
      color: theme.palette.text.secondary,

      "& .MuiSelect-icon": {
        display: "none",
      },
    },
    "& .Mui-error": {
      color: theme.palette.text.disabled,
      borderColor: theme.palette.error.outline + " !important",
    },
  },
  select: {
    background: (props) =>
      props.disabled ? "transparent" : theme.palette.background.editableInput,
    color: theme.palette.text.primary,
    borderColor: theme.palette.divider,

    "&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)": {
      color: theme.palette.text.primary,
      borderColor: theme.palette.primary.outline + " !important",
    },

    "& fieldset": {
      color: "inherit !important",
      borderColor: "inherit !important",
      background: "transparent",
      zIndex: 0,
    },
    "& select": {
      background: "transparent !important",
      color: "inherit !important",
    },
    "& svg": {
      marginRight: "8px",
      flexBasis: "24px",
      flexShrink: 0,
      color: "inherit !important",
    },

    "& .MuiInputBase-adornedStart": {
      paddingLeft: "10px",
    },
  },
  selectEmpty: {
    "& select": {
      color: theme.palette.text.disabled + " !important",
    },
  },
}));

export interface ISelectControlProps extends Partial<SelectProps> {
  icon?: AppIcon;
  options?: Array<{
    id: number | string;
    name: string;
  }>;
  loading?: boolean;
  gridProps?: IGridProps;
  opaqueBackground?: boolean;
  helperText?: string;
  selected: null | ((value: string | null) => void);
}

const EMPTY_VALUE = "";

export const AppSelectControl = (props: ISelectControlProps): JSX.Element => {
  const classes = SelectControlStyles(props);
  const {
    label,
    value,
    options,
    variant,
    gridProps,
    loading,
    icon: SelectIcon,
    error,
    helperText,
    placeholder,
    selected,
    ...otherProps
  } = props;

  return (
    <AppGrid item {...gridProps}>
      <FormControl
        fullWidth
        variant={variant || "outlined"}
        className={classes.formControl}
        error={error}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          {...otherProps}
          native
          startAdornment={SelectIcon ? <SelectIcon /> : undefined}
          label={label}
          value={loading ? EMPTY_VALUE : !value ? EMPTY_VALUE : value}
          variant={variant || "outlined"}
          className={
            classes.select +
            " " +
            (value || value === 0 ? "" : classes.selectEmpty)
          }
          error={error}
          onChange={(event) => {
            const value = event.target.value as string;
            if (value === EMPTY_VALUE) {
              selected?.(null);
            } else {
              selected?.(value);
            }
          }}
        >
          <option value={EMPTY_VALUE}>{placeholder || ""}</option>
          {options?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Select>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </AppGrid>
  );
};
