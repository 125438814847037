import React, { useState } from "react";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { StudentImportService} from "../../../Shared/Services/student-import.service";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppButton } from "../../../Shared/Components/Button";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppStudentImportTable } from "./StudentImportListTable/StudentImportTable";
import { TablePaginationHelper } from "../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { AppStudentImportListFilters } from "./StudentImportListFilters/StudentImportListFilters";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { IPaginationOptions } from "../../../Shared/Components/Tables/TablePagination/pagination-options.interface";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { IStudentImport } from "../../../Shared/Models/Entities/student-import.interface";
import {AppStudentImportCreateDialog} from "../StudentImportDialogs/StudentImportCreateDialog";
import {SftpAccountService} from "../../../Shared/Services/sftp-account.service";
import {ISftpAccount} from "../../../Shared/Models/Entities/sftp-account.interface";
import {AppSftpAccountDetailsDialog} from "../StudentImportDialogs/SftpAccountDetailsDialog";

export interface IStudentImportListFilters {
  showGroupOnly?: boolean;
  showExpired?: boolean;
  showProcessed?: boolean;
  searchKey?: string;
  paginationOptions: IPaginationOptions;
}

export const AppStudentImportList = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [filters, setFilters] = useState<IStudentImportListFilters>({
    paginationOptions: TablePaginationHelper.getDefaultOptions(),
  });
  const areFiltersDefault = !district &&
      !school && !filters.showExpired &&
      !filters.showGroupOnly &&
      !filters.showProcessed &&
      (filters.searchKey == null || filters.searchKey == "");
  const fixedFilters = {
    ...filters,
    districtId: district?.id,
    schoolId: school?.id,
  };

  const studentImportListState = DataHooks.useFunctionCallState<
      IStudentImport[]
      >({
    func: async () =>
        // Don't get data if our filters are empty
        areFiltersDefault ? null : StudentImportService.getAll(fixedFilters),
    deps: [filters, school?.id, district?.id],
    showLoadingIndicator: true,
  });

  const studentImportsCountState = DataHooks.useFunctionCallState<number>({
    func: async (currData) =>
        // Return current count if possible
        currData
            ? currData
            : // Don't get data if our filters are empty
            areFiltersDefault
                ? null
                : await StudentImportService.getCount(fixedFilters),
    deps: [filters],
  });

  return (
      <>
        <AppGrid container direction="column" spacing={2}>
          <AppGrid
              item
              container
              direction="row"
              wrap="wrap-reverse"
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={2}
          >
            <AppStudentImportListFilters
                filters={filters}
                setFilters={(filters) => {
                  // Invalidate count so we will re-fetch
                  studentImportsCountState.setData(null);
                  setFilters({
                    ...filters,
                    // Reset pagination since we are viewing a new list
                    paginationOptions: TablePaginationHelper.getDefaultOptions(),
                  });
                }}
            />
            <AppGrid item container spacing={2} marginLeft="auto">
              <Actions
                  districtId={district?.id}
                  schoolId={school?.id}
                  studentImports={studentImportListState.data || []}
                  shouldRefresh={studentImportListState.refreshData}
              />
            </AppGrid>
          </AppGrid>
          <AppGrid item>
            <AppStudentImportTable
                studentImports={studentImportListState.data}
                studentImportsLoading={studentImportListState.loading}
                count={studentImportsCountState.data}
                countLoading={studentImportsCountState.loading}
                filters={filters}
                setFilters={setFilters}
                areFiltersDefault={areFiltersDefault}
                onImportUpdated={studentImportListState.refreshData}
            />
          </AppGrid>
        </AppGrid>
      </>
  );
};

const Actions = ({
                   shouldRefresh,
                   districtId,
                   schoolId,
                 }: {
  studentImports: IStudentImport[];
  shouldRefresh: () => void;
  districtId: number | null;
  schoolId: number | null;
}) => {
  const [createImportDialogOpen, setCreateImportDialogOpen] = useState(false);
  const [sftpAccountDialogOpen, setSftpAccountDialogOpen] = useState(false);
  const [sftpAccount, setSftpAccount] = useState<ISftpAccount | null>(null);


  const handleSftpAccount = async () => {
      const account = (await SftpAccountService.getSftpAccount(districtId, schoolId)) || SftpAccountService.getBlankAccount();
      setSftpAccount(account);
      setSftpAccountDialogOpen(true);
  }

    return (
      <>
        {hasPermission(AppPermissionsEnum.STUDENT_IMPORT_CREATE) && (
            <>
                <AppButton
                    text="Sftp Account"
                    icon={Icons.AccountKey}
                    size="large"
                    palette={PaletteTypes.WARNING}
                    onClick={handleSftpAccount}
                />
                <AppButton
                    text="Create Import"
                    icon={Icons.Add}
                    size="large"
                    palette={PaletteTypes.SUCCESS}
                    linkTo="/student-imports-create"
                />
            </>
        )}
          <AppStudentImportCreateDialog
              open={createImportDialogOpen}
              setOpen={setCreateImportDialogOpen}
              onUpdated={shouldRefresh}
          />
          <AppSftpAccountDetailsDialog
              open={sftpAccountDialogOpen}
              setOpen={setSftpAccountDialogOpen}
              sftpAccount={sftpAccount}
          />
      </>
  );
};
