import { createSlice } from "@reduxjs/toolkit";
import { ILoadingBarReducerState } from "./loading-bar-reducer-state.interface";

const initialState: ILoadingBarReducerState = {
  isLoading: false,
};

export const LoadingBarReducerSlice = createSlice({
  name: "loading-bar",
  initialState,
  reducers: {
    show: (state: ILoadingBarReducerState): void => {
      state.isLoading = true;
    },
    hide: (state: ILoadingBarReducerState): void => {
      state.isLoading = false;
    },
  },
});
export const LoadingBarReducerActions = LoadingBarReducerSlice.actions;
