export enum AppPermissionsEnum {
  // General privileges
  ACCESS_MANAGEMENT_PORTAL = "AccessManagementPortal",
  SUPER_ADMIN = "SuperAdmin",

  // Admin Users
  ADMIN_USERS_EDIT = "AdminUsersEdit",
  ADMIN_USERS_GET = "AdminUsersGet",

  // Districts
  DISTRICTS_CREATE = "DistrictsCreate",
  DISTRICTS_DELETE = "DistrictsDelete",
  DISTRICTS_EDIT = "DistrictsEdit",

  // Events
  EVENTS_GET = "EventsGet",
  EVENTS_EDIT = "EventsEdit",

  // School User Import
  IMPORT_SCHOOL_USERS = "ImportSchoolUsers",

  // Schools
  SCHOOLS_CREATE = "SchoolsCreate",
  SCHOOLS_DELETE = "SchoolsDelete",
  SCHOOLS_EDIT = "SchoolsEdit",
  SCHOOLS_ENROLLMENT_VIEW = "SchoolsEnrollmentView",
  SCHOOLS_SUBSCRIPTION_EDIT = "SchoolsSubscriptionEdit",

  // School Users
  SCHOOL_USERS_EDIT = "SchoolUsersEdit",
  SCHOOL_USERS_GET = "SchoolUsersGet",

  // Student Import
  STUDENT_IMPORT_CREATE = "StudentImportCreate",
  STUDENT_IMPORT_PROCESS = "StudentImportProcess",
  STUDENT_IMPORT_GET = "StudentImportGet",
  SFTP_ACCOUNT_ACCESS = "SftpAccountAccess",

  // Roles
  ROLES_GET = "RolesGet",
  ROLES_EDIT = "RolesEdit",
  GLOBAL_ROLES_EDIT = "GlobalRolesEdit",

  // Bell Schedules
  BELL_SCHEDULES_EDIT = "BellSchedulesEdit",
  BELL_SCHEDULES_GET = "BellSchedulesGet",

  // Trac Codes
  TRAC_CODES_VIEW = "TracCodesView",
  TRAC_CODES_EDIT = "TracCodesEdit",
}
