import { HttpClient } from "../Helpers/http-client";
import { IDateSet } from "../Models/Entities/date-set.interface";

export class DateSetService {
  static async getAll(schoolId: number): Promise<IDateSet[]> {
    return HttpClient.get({
      path: `/date-set/?schoolId=${schoolId}`,
    });
  }

  static async getById(id: number): Promise<IDateSet> {
    return HttpClient.get({
      path: `/date-set/${id}`,
    });
  }

  static async create(dateSet: IDateSet, schoolID: number): Promise<IDateSet> {
    return HttpClient.post({
      path: `/date-set`,
      body: dateSet,
      params: {
        schoolID,
      },
    });
  }

  static async update(dateSet: IDateSet): Promise<IDateSet> {
    return HttpClient.put({
      path: "/date-set/" + dateSet.id,
      body: dateSet,
    });
  }
}
