import React, { Dispatch, SetStateAction, useState } from "react";
import { AppCustomDialog } from "../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppAutocompleteControl } from "../../../Shared/Components/Controls/AutocompleteControl";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { ISchoolSimple } from "../../../Shared/Models/Entities/school.interface";
import { SchoolService } from "../../../Shared/Services/school.service";
import { ISimpleItem } from "../../../Shared/Models/Entities/simple-item.interface";
import { DistrictService } from "../../../Shared/Services/district.service";
import { AppTextFieldControl } from "../../../Shared/Components/Controls/TextFieldControl";
import { AppButton } from "../../../Shared/Components/Button";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { Logger } from "../../../Shared/Helpers/logger";
import { QuickMovePopupHelper } from "./QuickMovePopup.Helper";
import { ValueHelper } from "../../../Shared/Helpers/value.helper";
import { AppRadioControl } from "../../../Shared/Components/Controls/RadioControl";
import { LocalStorageKeysEnum } from "../../../Shared/Models/Enums/local-storage-keys.enum";
import { Icons } from "../../../Shared/Components/Icons/Icons";

export const AppQuickMovePopup = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  // Form Options
  const [schoolUserId, setSchoolUserId] = DataHooks.useCachedState<
    number | null
  >({
    defaultObject: null,
    localStorageKey: LocalStorageKeysEnum.QUICK_MOVE_POPUP_SCHOOL_USER_ID,
  });
  const [accessType, setAccessType] = useState("district");
  const [districtId, setDistrictId] = useState<number | null>(null);
  const [schoolId, setSchoolId] = useState<number | null>(null);

  // Dropdown Data
  const schoolOptionsState = DataHooks.useFunctionCallState<ISchoolSimple[]>({
    func: SchoolService.getAllSimple,
  });
  const districtOptionsState = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: DistrictService.getAllSimple,
  });

  const handleSubmit = async () => {
    if (schoolUserId) {
      try {
        const movedUser = await QuickMovePopupHelper.moveUser(
          schoolUserId,
          schoolId,
          districtId
        );
        SnackbarService.success(
          `Moved ${movedUser.firstName} ${movedUser.lastName} to ${
            movedUser.district?.name || movedUser.school?.name || "the abyss"
          }`
        );
        handleClose();
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
    } else {
      SnackbarService.warn("Please select a user!");
    }
  };

  const handleClose = () => {
    props.setOpen?.(false);
  };

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title="Quick Move Popup"
      subHeader="Use this form to quickly move school users across Hero."
      icon={Icons.CompareArrows}
      palette={PaletteTypes.PRIMARY}
    >
      <AppGrid container spacing={3}>
        <AppRadioControl
          gridProps={{ xs: 12 }}
          label="Access Type *"
          value={accessType}
          valueUpdated={(value) => {
            setAccessType(value as string);
            setSchoolId(null);
            setDistrictId(null);
          }}
          options={[
            {
              label: "User should have access to an entire school group",
              value: "district",
            },
            {
              label: "User should have access to one school",
              value: "school",
            },
          ]}
        />

        <AppTextFieldControl
          gridProps={{ xs: 6 }}
          label="School User Id"
          name="schoolUserId"
          value={schoolUserId}
          type="number"
          onChange={(event) => {
            const schoolUserId = ValueHelper.getNumberFromString(
              event.target.value
            );
            setSchoolUserId(schoolUserId);
          }}
          icon={Icons.AccountBox}
        />

        <AppAutocompleteControl
          gridProps={{ xs: 6, hidden: accessType !== "district" }}
          label="School Group"
          placeholder="Select a school group"
          icon={Icons.District}
          options={
            districtOptionsState.data?.map((schoolGroup) => ({
              id: schoolGroup.id,
              name: `${schoolGroup.name} - ${schoolGroup.id}`,
            })) || []
          }
          selectedId={districtId}
          selectedIdUpdated={(selectedId) => {
            setDistrictId(selectedId);
            setSchoolId(null);
          }}
          loading={districtOptionsState.loading}
        />

        <AppAutocompleteControl
          gridProps={{ xs: 6, hidden: accessType !== "school" }}
          label="School"
          placeholder="Select a school"
          icon={Icons.School}
          options={
            schoolOptionsState.data?.map((school) => ({
              id: school.id,
              name: `${school.name} - ${school.id}`,
            })) || []
          }
          selectedId={schoolId}
          selectedIdUpdated={(selectedId) => {
            setSchoolId(selectedId);
            setDistrictId(null);
          }}
          loading={schoolOptionsState.loading}
        />

        <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
          <AppButton
            text="Cancel"
            icon={Icons.Close}
            palette={PaletteTypes.WARNING}
            variant="outlined"
            onClick={() => handleClose()}
          />
          <AppButton
            text="Move"
            icon={Icons.CompareArrows}
            palette={PaletteTypes.PRIMARY}
            onClick={() => handleSubmit()}
          />
        </AppGrid>
      </AppGrid>
    </AppCustomDialog>
  );
};
