import React, { Dispatch, LegacyRef, SetStateAction, useRef } from "react";
import { TableCell } from "@material-ui/core";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import { ITracCode } from "../../../../../Shared/Models/Entities/trac-code.interface";
import { appMakeStyles } from "../../../../../Shared/Themes/app-make-styles";

const TableRowStyles = appMakeStyles((theme) => ({
  tableCell: {
    background: theme.palette.background.paperAlternate + " !important",
    color: theme.palette.text.secondary,
    height: "50px",
    borderBottom: "1px solid " + theme.palette.divider,
  },
}));

export const AppTracCodeDraggableItem = ({
  tracCode,
  index,
  moveListItem,
  isHovered,
  setHovered,
  firstOrLast,
}: {
  tracCode: ITracCode;
  index: number;
  moveListItem: (dragIndex: number, hoverIndex: number) => void;
  isHovered: boolean;
  setHovered: Dispatch<SetStateAction<number>>;
  firstOrLast: boolean;
}): JSX.Element => {
  const [{}, dragRef] = useDrag({
    type: "item",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: "item",
    hover: (item: { index: number }, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect() as DOMRect;
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY =
        (monitor.getClientOffset() as XYCoord).y - hoverBoundingRect.y;

      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      moveListItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
      setHovered(hoverIndex);
    },
    drop: () => {
      setHovered(-1);
    },
  });

  const ref = useRef<HTMLElement>(null);

  const classes = TableRowStyles({});

  const dragDropRef = dragRef(dropRef(ref));
  const opacity = !firstOrLast && isHovered ? 0 : 1;
  return (
    <div
      ref={dragDropRef as LegacyRef<HTMLDivElement>}
      style={{ display: "table-row", opacity }}
    >
      <TableCell className={classes.tableCell}>{tracCode.name}</TableCell>
      <TableCell className={classes.tableCell}>
        {tracCode.school
          ? tracCode.school.name
          : tracCode.district
          ? tracCode.district.name
          : "none"}
      </TableCell>
    </div>
  );
};
