import {IStudentImport} from "../Models/Entities/student-import.interface";
import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { HttpClient } from "../Helpers/http-client";
import { PaginationHelper } from "../Helpers/pagination.helper";
import {IStudentImportProcessResult} from "../Models/Entities/student-import-process-result.interface";

export interface IGetImportProps {
    schoolId?: number;
    districtId?: number;
    showGroupOnly?: boolean;
    showExpired?: boolean;
    showProcessed?: boolean;
    searchKey?: string;
    paginationOptions: IPaginationOptions;
}

export class StudentImportService {

    static async getAll(props: IGetImportProps): Promise<IStudentImport[]> {
        return HttpClient.get({
            path: "/student-import/by-school",
            params: {
                ...PaginationHelper.getPaginationParams(props.paginationOptions),
                ...(props?.showGroupOnly && {
                    showGroupOnly: true,
                }),
                ...(props?.showExpired && {
                    expired: true,
                }),
                ...(props?.showProcessed && {
                    showProcessed: true,
                }),
                ...(props?.searchKey != null && props.searchKey.length > 3 && {
                    searchKey: props.searchKey,
                }),
                ...(props?.schoolId && {
                    schoolId: props.schoolId,
                }),
                ...(props?.districtId && {
                    districtId: props.districtId,
                }),
            },
        });
    }

    static async getCount(props: IGetImportProps): Promise<number> {
        return HttpClient.get({
            path: "/student-import/count",
            params: {
                ...(props?.showGroupOnly && {
                    showGroupOnly: true,
                }),
                ...(props?.showExpired && {
                    expired: true,
                }),
                ...(props?.showProcessed && {
                    showProcessed: true,
                }),
                ...(props?.searchKey != null && props.searchKey.length > 3 && {
                    searchKey: props.searchKey,
                }),
                ...(props?.schoolId && {
                    schoolId: props.schoolId,
                }),
                ...(props?.districtId && {
                    districtId: props.districtId,
                }),
            },
        });
    }

    static async getById(schoolUserId: number): Promise<IStudentImport> {
        return HttpClient.get({
            path: "/student-import/" + schoolUserId,
        });
    }

    static async create(studentImport: IStudentImport): Promise<IStudentImport> {
        return HttpClient.post({
            path: "/student-import",
            body: studentImport,
        });
    }

    static async update(studentImport: IStudentImport): Promise<IStudentImport> {
        return HttpClient.post({
            path: "/student-import",
            body: studentImport,
        });
    }

    static async delete(studentImport: IStudentImport): Promise<boolean> {
        return HttpClient.delete({
            path: "/student-import/" + studentImport.id,
        });
    }

    static async previewImport(studentImport: IStudentImport): Promise<IStudentImportProcessResult> {
        return HttpClient.get({
            path: "/student-import/preview-import/" + studentImport.id,
        });
    }

    static async importStudents(studentImport: IStudentImport): Promise<IStudentImportProcessResult> {
        return HttpClient.get({
            path: "/student-import/process-import/" + studentImport.id,
        });
    }

    static async getSftpFile(studentImport: IStudentImport): Promise<string> {
        return HttpClient.get({
            path: "/student-import/get-file",
            params: {
                districtId: studentImport.districtId,
                schoolId: studentImport.schoolId,
                fileName: studentImport.uri,
                encrypted: studentImport.pgpEncrypted,
            }
        })
    }
}
