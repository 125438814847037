import {IStudentImport} from "../../../Shared/Models/Entities/student-import.interface";
import {IStudentImportValidationResults} from "../StudentImportInterfaces/StudentImportValidationResults.interface";
import {IScheduleSelectOptions} from "../StudentImportInterfaces/ScheduleSelectOptions.interface";
import {StudentImportValidationErrors} from "../StudentImportEnums/StudentImportValidationErrors.enum";
import {StudentImportIntervals} from "../StudentImportEnums/StudentImportIntervals";
import {StudentImportTypes} from "../StudentImportEnums/StudentImportTypes.enum";

export class StudentImportEditorPanelHelper {
    static newStudentImport(): IStudentImport {
        return {
            creationDate: null,
            districtId: null,
            districtName: null,
            expirationDate: null,
            fileContent: null,
            hasHeaders: true,
            id: null,
            importType: "",
            name: null,
            mapping: [],
            schedule: null,
            schoolId: null,
            schoolName: null,
            status: "created",
            totalRecords: 0,
            uri: null,
            columnDelimiter: ",",
            rowDelimiter: "\n",
            quoteCharacter: "\"",
            pgpEncrypted: false
        };
    }
    static validateStudentImport(studentImport: IStudentImport): IStudentImportValidationResults {
        const results: IStudentImportValidationResults = {isValid: true, errors: []};

        const requiredMappings = ["ExternalId","FirstName","LastName","Grade","SchoolId"];

        if (studentImport.name == null || studentImport.name.length < 2) {
            results.errors.push(StudentImportValidationErrors.IMPORT_NAME);
            results.isValid = false;
        }

        if ((studentImport.schoolId == null && studentImport.districtId == null)
        || (studentImport.schoolId != null && studentImport.districtId != null)) {
            results.errors.push(StudentImportValidationErrors.REQUIRED_SCHOOL_OR_DISTRICT);
            results.isValid = false;
        }

        if (studentImport.importType == null || studentImport.importType == "") {
            results.errors.push(StudentImportValidationErrors.IMPORT_TYPE);
            results.isValid = false;
        }

        if (studentImport.mapping == null
            || studentImport.mapping.length < 4
            || !requiredMappings.every(col=> studentImport.mapping?.includes(col))) {
            results.errors.push(StudentImportValidationErrors.MAPPING);
            results.isValid = false;
        }

        if (studentImport.importType != null &&
            studentImport.importType == StudentImportTypes.SFTP &&
            (studentImport.schedule == null || studentImport.schedule.length < 1)) {
            results.errors.push(StudentImportValidationErrors.SCHEDULED_SFTP);
            results.isValid = false;
        }

        return results;
    }

    static intervalToEnum(ordinal: string): StudentImportIntervals {
        const index = Object.keys(StudentImportIntervals).indexOf(ordinal);
        return Object.values(StudentImportIntervals)[index];
    }

    static monthlyOptions(): IScheduleSelectOptions[] {
        const options = [] as IScheduleSelectOptions[];
        for (let ix = 0; ix < 31; ix++) {
            options[ix] = { name: (ix + 1).toString(), id: ix + 1 };
        }
        return options;
    }
}