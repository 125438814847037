import React, { Dispatch, SetStateAction } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { AppButton } from "../Button";
import { AppCardHeader } from "../Cards/CardHeader";
import { AppCard } from "../Cards/Card";
import { AppCardContent } from "../Cards/CardContent";
import { PaletteTypes } from "../../Themes/palette-types.enum";
import { AppGrid } from "../Grid";
import { AppIcon, Icons } from "../Icons/Icons";

export const AppConfirmationDialog = (props: {
  open: boolean;
  title: string;
  content: string;
  icon: AppIcon;
  setOpen: Dispatch<SetStateAction<boolean>>;
  agreeAction: () => void;
  palette: PaletteTypes;
}): JSX.Element => {
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleAgree = () => {
    props.setOpen(false);
    props.agreeAction();
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="confirmation-dialog"
    >
      <DialogContent>
        <AppCard hasHeader>
          <AppCardHeader
            title={props.title}
            subheader={props.content}
            icon={props.icon}
            palette={props.palette}
          />
          <AppCardContent>
            <AppGrid container justifyContent="flex-end" spacing={1}>
              <AppButton
                text="No"
                icon={Icons.Close}
                palette={PaletteTypes.WARNING}
                onClick={handleClose}
                variant="outlined"
              />
              <AppButton
                text="Yes"
                icon={Icons.Check}
                palette={PaletteTypes.SUCCESS}
                onClick={handleAgree}
              />
            </AppGrid>
          </AppCardContent>
        </AppCard>
      </DialogContent>
    </Dialog>
  );
};
