import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { AppEventCategoryEditorPanel } from "../EventCategoryEditorPanel/EventCategoryEditorPanel";
import { IEventCategory } from "../event-category.interface";

export const AppEventCategoryEditDialog = (props: {
  eventCategory: IEventCategory;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="sm"
      title={"Edit Category"}
      subHeader="Fill out this form to update a category in Hero."
      icon={Icons.Shape}
      palette={PaletteTypes.SECONDARY}
    >
      <AppEventCategoryEditorPanel
        eventCategory={props.eventCategory}
        isCreating={false}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
