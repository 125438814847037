import React from "react";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppDistrictRolesTable } from "./DistrictRolesTable/DistrictRolesTable";
import { AppGlobalRolesTable } from "./GlobalRolesTable/GlobalRolesTable";
import { AppButton } from "../../../Shared/Components/Button";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";

export const AppSchoolUserRoleList = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);

  return (
    <>
      <AppGrid container direction="row" spacing={2}>
        <AppGrid container direction="column" spacing={2} item xs={6}>
          <AppGrid item container justifyContent="flex-end" spacing={2}>
            {hasPermission(AppPermissionsEnum.ROLES_EDIT) &&
              (district?.id || school?.id) && (
                <AppButton
                  text="Create Role"
                  icon={Icons.Add}
                  size="large"
                  palette={PaletteTypes.SUCCESS}
                  linkTo="/school-user-roles/create"
                />
              )}
          </AppGrid>
          <AppGrid item>
            <AppDistrictRolesTable />
          </AppGrid>
        </AppGrid>
        <AppGrid item xs={6}>
          <AppGlobalRolesTable />
        </AppGrid>
      </AppGrid>
    </>
  );
};
