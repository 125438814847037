import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { GridTypeMap } from "@material-ui/core/Grid/Grid";
import { AppGrid } from "../Grid";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { Icons } from "../Icons/Icons";
import { TimeHelper } from "../../Helpers/time-helper";

const TimeControlStyles = appMakeStyles<ITimeControlProps>((theme) => ({
  datePicker: {
    background: (props) =>
      props.disabled ? "transparent" : theme.palette.background.editableInput,
    color: theme.palette.text.primary + " !important",
    borderColor: theme.palette.divider,

    "& label": {
      color: theme.palette.text.disabled + " !important",
    },
    "& fieldset": {
      color: "inherit !important",
      borderColor: "inherit !important",
    },
    "& svg": {
      flexBasis: "24px",
      flexShrink: 0,
      color: "inherit !important",
    },
    button: {
      color: "inherit !important",
    },

    "& .MuiInputBase-root": {
      paddingRight: 0,
      borderColor: theme.palette.divider,
      cursor: "pointer",

      "&.Mui-disabled": {
        color: theme.palette.text.secondary,
        borderColor: theme.palette.divider,

        "& .MuiIconButton-root": {
          display: "none",
        },
      },

      "&.Mui-error": {
        color: theme.palette.text.primary,
        borderColor: theme.palette.error.outline,
      },

      "&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)": {
        borderColor: theme.palette.primary.outline,
        color: theme.palette.text.primary,
      },
    },
  },
}));

export interface ITimeControlProps {
  label: string;
  value: string;
  onChange: (date: Date | null) => void;
  error: boolean;
  gridProps?: Partial<GridTypeMap["props"]>;
  disabled?: boolean;
}

export const AppTimeControl = (props: ITimeControlProps): JSX.Element => {
  const classes = TimeControlStyles(props);
  const { label, value, onChange, error, gridProps, disabled } = props;
  const date = new Date(value);

  return (
    <AppGrid item {...gridProps}>
      <FormControl fullWidth error={error} variant="outlined">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardTimePicker
            className={classes.datePicker}
            error={error}
            label={label}
            value={date}
            format="hh:mm a"
            mask="__:__ _M"
            keyboardIcon={<Icons.Time />}
            onBlur={(event) => {
              const value = event.target.value;
              if (value) {
                const parseDate =
                  TimeHelper.parseDateFromInvalidDateString(value);
                if (parseDate) {
                  onChange(TimeHelper.changeTimeToBaseDate(date, parseDate));
                }
              }
            }}
            onChange={(newDate) => {
              const isValidDate = TimeHelper.isValidDate(newDate);
              if (
                newDate &&
                isValidDate &&
                newDate?.toString() !== date?.toString()
              ) {
                onChange(TimeHelper.changeTimeToBaseDate(date, newDate));
              }
            }}
            fullWidth
            inputVariant="outlined"
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </AppGrid>
  );
};
