import React from "react";
import { IHeroColumn } from "../../../../Models/Interfaces/csv.interface";
import { AppCard } from "../../../Cards/Card";
import { AppCardContent } from "../../../Cards/CardContent";
import { AppGrid } from "../../../Grid";
import { DragSourceMonitor, useDrag } from "react-dnd";

export const AppDraggableHeroField = (props: {
  heroColumn: IHeroColumn;
  index: number;
}): JSX.Element => {
  const [, drag] = useDrag(
    () => ({
      type: props.index.toString(),
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props.index]
  );

  return (
    <AppGrid item container>
      <div ref={drag}>
        <AppCard>
          <AppCardContent>
            {props.heroColumn.name}{" "}
            {props.heroColumn.mandatory && <span>*</span>}
          </AppCardContent>
        </AppCard>
      </div>
    </AppGrid>
  );
};
