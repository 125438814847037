import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppTextFieldControl } from "./TextFieldControl";
import { AppGrid, IGridProps } from "../Grid";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { AppIcon } from "../Icons/Icons";

const AutocompleteControlStyles = appMakeStyles((theme) => ({
  autocomplete: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.text.disabled,
      marginRight: 0,
    },
    "& .Mui-disabled .MuiSvgIcon-root": {
      display: "none",
    },
    "& .Mui-error .MuiSvgIcon-root": {
      color: theme.palette.text.disabled,
    },
  },
}));

export interface IAutocompleteControlProps {
  label?: string;
  placeholder?: string;
  selectedId: number | null | undefined;
  selectedIdUpdated: (value: number | null) => void;
  options: Array<{
    id: number;
    name: string;
  }>;
  error?: boolean;
  icon?: AppIcon;
  gridProps?: IGridProps;
  opaqueBackground?: boolean;
  loading?: boolean;
  helperText?: string;
  fullWidth?: boolean;
  disableClearable?: boolean;
  disabled?: boolean;
}

// eslint-disable-next-line
export const AppAutocompleteControl = ({
  label,
  placeholder,
  selectedId,
  selectedIdUpdated,
  options,
  error,
  icon,
  gridProps,
  opaqueBackground,
  loading,
  helperText,
  fullWidth,
  disableClearable,
  disabled,
}: IAutocompleteControlProps): JSX.Element => {
  const classes = AutocompleteControlStyles({});

  const selectedOption =
    options.find((option) => selectedId === option.id) || null;

  return (
    <AppGrid item {...gridProps}>
      <Autocomplete
        className={classes.autocomplete}
        value={selectedOption}
        options={options}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option?.name || "Unknown"}
        disableClearable={disableClearable}
        onChange={(event, selected) => {
          selectedIdUpdated(selected?.id || null);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <AppTextFieldControl
            {...params}
            error={error}
            label={label}
            placeholder={placeholder}
            icon={icon}
            opaqueBackground={opaqueBackground}
            loading={loading}
            helperText={helperText}
            fullWidth={fullWidth}
            disabled={disabled}
          />
        )}
      />
    </AppGrid>
  );
};
