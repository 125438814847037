import { IEventSupervisor } from "../../../Shared/Models/Entities/event-supervisor.interface";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Utils } from "../../../Shared/Helpers/utils";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppButton } from "../../../Shared/Components/Button";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { EventSupervisorService } from "../../../Shared/Services/event-supervisor.service";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { Logger } from "../../../Shared/Helpers/logger";
import { AppTextFieldControl } from "../../../Shared/Components/Controls/TextFieldControl";
import { AppSwitchControl } from "../../../Shared/Components/Controls/SwitchControl";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";

export const AppEventSupervisorEditorPanel = (props: {
  eventSupervisor: IEventSupervisor;
  isCreating: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [eventSupervisor, setEventSupervisor] = useState(
    Utils.cloneObject(props.eventSupervisor)
  );
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);

  const { data: canDelete } = DataHooks.useFunctionCallState<boolean>({
    func: () => {
      if (!props.isCreating && eventSupervisor?.id) {
        return EventSupervisorService.canDelete(eventSupervisor.id);
      } else {
        return false;
      }
    },
    deps: [],
  });

  const updateFields = (updatedFields: Partial<IEventSupervisor>) => {
    setEventSupervisor({ ...eventSupervisor, ...updatedFields });
  };

  const handleClose = () => {
    props.setOpen?.(false);
  };

  async function handleSubmit(): Promise<void> {
    const isValid = eventSupervisor.name && eventSupervisor.key;
    setHaveTriedSubmitting(true);
    if (isValid) {
      LoadingBarService.show();
      try {
        if (props.isCreating) {
          await EventSupervisorService.create(
            eventSupervisor,
            school?.id,
            district?.id
          );
        } else {
          await EventSupervisorService.update(
            eventSupervisor,
            school?.id,
            district?.id
          );
        }
        void (await props.onDataUpdated());
        SnackbarService.success(
          (props.isCreating ? "Created " : "Updated ") + eventSupervisor.name
        );
        handleClose();
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    }
  }

  async function handleDelete(): Promise<void> {
    LoadingBarService.show();
    try {
      await EventSupervisorService.delete(eventSupervisor.id);
      await props.onDataUpdated();
      SnackbarService.success("Deleted " + eventSupervisor.name);
      handleClose();
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  }

  return (
    <AppGrid container spacing={3}>
      <AppTextFieldControl
        gridProps={{ xs: 12 }}
        label="Name *"
        value={eventSupervisor.name}
        onChange={(event) => {
          updateFields({
            name: event.target.value,
          });
        }}
        error={
          !eventSupervisor.name && (!props.isCreating || haveTriedSubmitting)
        }
        icon={Icons.AccountBox}
        opaqueBackground
      />
      <AppTextFieldControl
        gridProps={{ xs: 12 }}
        label="Key *"
        helperText="Your Supervisor ID must be unique. We recommend using your school's phone number."
        value={eventSupervisor.key}
        onChange={(event) => {
          updateFields({
            key: event.target.value,
          });
        }}
        error={
          !eventSupervisor.key && (!props.isCreating || haveTriedSubmitting)
        }
        icon={Icons.AccountBox}
        opaqueBackground
      />
      <AppSwitchControl
        gridProps={{ xs: 12 }}
        label="Can Manage Events"
        checked={!!eventSupervisor.canManageEvent}
        onChange={() => {
          updateFields({
            canManageEvent: !eventSupervisor.canManageEvent,
          });
        }}
      />
      <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
        {canDelete && (
          <AppButton
            text="Delete"
            icon={Icons.Delete}
            palette={PaletteTypes.ERROR}
            onClick={() => handleDelete()}
          />
        )}
        <AppButton
          text="Cancel"
          icon={Icons.Close}
          palette={PaletteTypes.WARNING}
          variant="outlined"
          onClick={() => handleClose()}
        />
        <AppButton
          text="Submit"
          icon={props.isCreating ? Icons.Add : Icons.Check}
          palette={PaletteTypes.SUCCESS}
          onClick={() => handleSubmit()}
        />
      </AppGrid>
    </AppGrid>
  );
};
