import { appMakeStyles } from "../Shared/Themes/app-make-styles";
import loginBackground from "../Shared/Assets/Images/login-background.jpg";

export const AuthStyles = appMakeStyles<{ theme: string }>((theme) => ({
  authContainer: {
    width: "100vw",
    height: "100vh",
    background: `url(${loginBackground})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  authBox: {
    width: "500px",
    maxWidth: "90%",
  },
  helpText: {
    fontSize: theme.typography.largeFontSize,
  },
  helpTitle: {
    color: theme.palette.text.secondary,
  },
  helpDescription: {
    color: theme.palette.text.disabled,
  },
}));
