import React from "react";
import { IStudentSimple } from "../../../../../Shared/Models/Entities/student-simple.interface";
import { IEventAddStudentsPopupFilters } from "../EventAddStudentsPopup";
import { AppCustomTable } from "../../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppGrid } from "../../../../../Shared/Components/Grid";
import { AppButton } from "../../../../../Shared/Components/Button";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../../Shared/Themes/palette-types.enum";
import { AppTablePagination } from "../../../../../Shared/Components/Tables/TablePagination/TablePagination";

export const AppEventAddStudentsPopupTable = ({
  selectedStudents,
  students,
  count,
  countLoading,
  filters,
  setFilters,
  addStudent,
  removeStudent,
}: {
  selectedStudents: IStudentSimple[];
  students: IStudentSimple[];
  count: number | null;
  countLoading: boolean;
  filters: IEventAddStudentsPopupFilters;
  setFilters: (props: IEventAddStudentsPopupFilters) => void;
  addStudent: (student: IStudentSimple) => void;
  removeStudent: (student: IStudentSimple) => void;
}): JSX.Element => {
  const selectedStudentIds = selectedStudents.map((student) => student.id);

  return (
    <AppGrid item>
      <AppCustomTable
        columns={[
          {
            id: "id",
            label: "ID",
            width: 80,
          },
          {
            id: "name",
            label: "Name",
          },
          {
            id: "grade",
            label: "Grade",
          },
          {
            id: "action",
            label: "",
            width: 150,
          },
        ]}
        rows={students?.map((student) => ({
          cells: [
            {
              value: student.externalId,
            },
            {
              value: student.firstName + " " + student.lastName,
            },
            {
              value: student.grade,
            },
            {
              value: (
                <AppGrid container spacing={1}>
                  {selectedStudentIds.includes(student.id) ? (
                    <AppButton
                      text={"Remove"}
                      icon={Icons.Delete}
                      palette={PaletteTypes.ERROR}
                      onClick={() => removeStudent(student)}
                      fullWidth
                    />
                  ) : (
                    <AppButton
                      text={"Add"}
                      icon={Icons.Add}
                      palette={PaletteTypes.SECONDARY}
                      onClick={() => addStudent(student)}
                      fullWidth
                    />
                  )}
                </AppGrid>
              ),
            },
          ],
        }))}
      />
      <AppTablePagination
        count={count}
        countLoading={countLoading}
        paginationOptions={filters.paginationOptions}
        paginationOptionsUpdated={(newOptions) => {
          setFilters({
            ...filters,
            paginationOptions: newOptions,
          });
        }}
      />
    </AppGrid>
  );
};
