import { HttpClient } from "../Helpers/http-client";
import { ISimpleItem } from "../Models/Entities/simple-item.interface";
import { ISchoolUserRole } from "../Models/Entities/school-user-role.interface";
import { ISchoolUser } from "../Models/Entities/school-user.interface";

export interface IGetSchoolUserRoleProps {
  schoolId?: number;
  districtId?: number;
  returnGlobalRoles?: boolean;
}

export class SchoolUserRoleService {
  static async get(props: IGetSchoolUserRoleProps): Promise<ISimpleItem[]> {
    const { schoolId, districtId, returnGlobalRoles } = props;

    return HttpClient.get({
      path: "/school-user-roles",
      params: {
        ...(schoolId && { schoolId }),
        ...(districtId && { districtId }),
        ...(returnGlobalRoles && { returnGlobalRoles }),
      },
    });
  }

  static async getById(id: number): Promise<ISchoolUserRole> {
    return HttpClient.get({
      path: "/school-user-roles/" + id,
    });
  }

  static async create(
    role: ISchoolUserRole,
    schoolId: number,
    districtId: number
  ): Promise<ISchoolUserRole> {
    return HttpClient.post({
      path: "/school-user-roles",
      body: role,
      params: {
        ...(schoolId && { schoolId }),
        ...(districtId && { districtId }),
      },
    });
  }

  static async update(role: ISchoolUserRole): Promise<ISchoolUserRole> {
    return HttpClient.put({
      path: "/school-user-roles/" + role.id.toString(),
      body: role,
    });
  }

  static async delete(roleId: number): Promise<ISchoolUser> {
    return HttpClient.delete({
      path: "/school-user-roles/" + roleId,
    });
  }

  static async canDelete(id: number): Promise<boolean> {
    return HttpClient.get({
      path: "/school-user-roles/" + id + "/can-delete",
    });
  }
}
