import React from "react";
import { IDistrictNode } from "../../../../../Shared/Models/Interfaces/district-node.interface";
import { DistrictTreeNodeStyles } from "./DistrictTreeNode.styles";
import classNames from "classnames";
import { StoreHelper } from "../../../../../Shared/Reducers/store-helper";
import { SchoolDistrictManagerReducerActions } from "../../../../../Shared/Reducers/SchoolDistrictManager/school-district-manager.reducer";
import { ISchoolSimple } from "../../../../../Shared/Models/Entities/school.interface";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";

export const AppDistrictTreeNode = ({
  districtNode,
}: {
  districtNode: IDistrictNode;
}): JSX.Element => {
  const classes = DistrictTreeNodeStyles({});
  const selectedId = StoreHelper.selector(
    (state) => state.districts.selectedId
  );

  const isSelected = districtNode.district.id === selectedId;
  const hasSchools = districtNode.schools?.length > 0;

  const SchoolList = () => {
    if (districtNode.isOpen && hasSchools) {
      return (
        <div className={classes.schoolList}>
          {districtNode.schools.map((school, index) => {
            const isSelected = school.id === selectedId;
            const key = school.name + school.id.toString() + index;
            return (
              <DistrictTreeSchool
                school={school}
                isSelected={isSelected}
                key={key}
              />
            );
          })}
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div
      className={classes.container}
      key={districtNode.district.id.toString() + isSelected}
    >
      <DistrictTreeDistrict
        isSelected={isSelected}
        hasSchools={hasSchools}
        isOpen={districtNode.isOpen}
        districtId={districtNode.district.id}
        districtName={districtNode.district.name}
      />
      <SchoolList />
    </div>
  );
};

const DistrictTreeDistrict = ({
  isSelected,
  hasSchools,
  isOpen,
  districtName,
  districtId,
}: {
  isSelected: boolean;
  hasSchools: boolean;
  isOpen: boolean;
  districtName: string;
  districtId: number;
}): JSX.Element => {
  const classes = DistrictTreeNodeStyles({});

  return (
    <div
      className={classNames({
        [classes.districtNode]: true,
        [classes.nodeItem]: true,
        [classes.nodeItemSelected]: isSelected,
        [classes.disabledItem]: !hasSchools,
        [classes.districtNodeOpen]: isOpen && hasSchools,
      })}
      onClick={() => {
        StoreHelper.dispatch(
          SchoolDistrictManagerReducerActions.setSelectedDistrict(districtId)
        );
        StoreHelper.dispatch(
          SchoolDistrictManagerReducerActions.setDistrictIsOpen({
            districtId: districtId,
            isOpen: true,
          })
        );
      }}
    >
      {hasSchools ? (
        <div
          className={classes.districtIcon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            StoreHelper.dispatch(
              SchoolDistrictManagerReducerActions.setDistrictIsOpen({
                districtId: districtId,
                isOpen: !isOpen,
              })
            );
          }}
        >
          {isOpen ? <Icons.ExpandLess /> : <Icons.ExpandMore />}
        </div>
      ) : (
        <div className={classes.emptyDistrictIcon} />
      )}
      <div className={classes.districtText}>
        <div className={classes.nodeInfo}>
          <div className={classes.nodeId}>{districtId}</div>
          <div className={classes.nodeName}>{districtName}</div>
        </div>
      </div>
    </div>
  );
};

const DistrictTreeSchool = ({
  school,
  isSelected,
}: {
  school: ISchoolSimple;
  isSelected: boolean;
}): JSX.Element => {
  const classes = DistrictTreeNodeStyles({});
  return (
    <div
      className={classNames({
        [classes.schoolNode]: true,
        [classes.nodeItem]: true,
        [classes.nodeItemSelected]: isSelected,
      })}
      onClick={() =>
        StoreHelper.dispatch(
          SchoolDistrictManagerReducerActions.setSelectedSchool(school.id)
        )
      }
    >
      <div className={classes.nodeInfo}>
        <div className={classes.nodeId}>{school.id}</div>
        <div className={classes.nodeName}>{school.name}</div>
      </div>
    </div>
  );
};
