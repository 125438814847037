import { HttpClient } from "../Helpers/http-client";
import { IBellScheduleConfig } from "../Models/Entities/bell-schedule-config.interface";
import { IBellSchedulePeriod } from "../Models/Entities/bell-schedule-period.interface";
import { IBellSchedule } from "../Models/Entities/bell-schedule.interface";

export class BellScheduleService {
  static async getAll(schoolID: number): Promise<IBellSchedule[]> {
    return HttpClient.get({ path: "/bell-schedules", params: { schoolID } });
  }

  static async getById(id: number): Promise<IBellSchedule> {
    return HttpClient.get({ path: `/bell-schedules/${id}` });
  }

  static async create(
    {
      name,
      description,
      periods,
    }: {
      name: string;
      description: string;
      periods: IBellSchedulePeriod[];
    },
    schoolID: number
  ): Promise<IBellSchedule> {
    const bellSchedule: IBellSchedule = {
      name,
      description,
      periods,
      id: -1,
    };
    return HttpClient.post({
      path: "/bell-schedules",
      body: bellSchedule,
      params: {
        schoolID,
      },
    });
  }

  static async update(bellSchedule: IBellSchedule): Promise<IBellSchedule> {
    return HttpClient.put({
      path: "/bell-schedules/" + bellSchedule.id,
      body: bellSchedule,
    });
  }

  static async delete(bellSchedule: IBellSchedule): Promise<void> {
    return HttpClient.delete({
      path: "/bell-schedules/" + bellSchedule.id,
      body: bellSchedule,
    });
  }

  static async getConfig(schoolID: string): Promise<IBellScheduleConfig> {
    return HttpClient.get({ path: `/bell-schedules/${schoolID}/config` });
  }

  static async updateConfig(
    bellScheduleConfig: IBellScheduleConfig,
    schoolID: number
  ): Promise<IBellScheduleConfig> {
    return HttpClient.put({
      path: "/bell-schedules/config",
      body: { ...bellScheduleConfig, schoolID },
    });
  }

  static async getTerms(schoolID: number): Promise<string[]> {
    return HttpClient.get({
      path: `/bell-schedules/${schoolID}/active-terms`,
    });
  }
}
