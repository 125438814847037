import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";

export class PaginationHelper {
  public static getPaginationParams(paginationProps: IPaginationOptions): {
    offset: number;
    pageSize: number;
  } {
    const page = paginationProps?.page || 0;
    const pageSize = paginationProps?.size || 10;
    return {
      offset: page * pageSize,
      pageSize,
    };
  }
}
