import { HttpClient } from "../Helpers/http-client";
import { ISchool, ISchoolSimple } from "../Models/Entities/school.interface";

export interface IChurnInfo {
  id: string;
  execution: {
    status: number;
    details: string;
    dateTime: {
      enqueueAt: string;
      startAt: string;
      endAt: string;
    };
    retryCount: number;
  };
}

export class SchoolService {
  static async getAllSimple(): Promise<ISchoolSimple[]> {
    return HttpClient.get({
      path: "/schools",
    });
  }

  static async getById(id: number): Promise<ISchool> {
    return HttpClient.get({
      path: "/schools/" + id,
    });
  }

  static async enqueueChurn(schoolId: number): Promise<IChurnInfo> {
    return HttpClient.post({
      path: `/schools/${schoolId}/enqueueChurn`,
    });
  }

  static async getChurnStatus(schoolId: number): Promise<IChurnInfo> {
    return HttpClient.get({
      path: `/schools/${schoolId}/getChurnProcessStatus`,
    });
  }

  static async upsertSchool(school: ISchool): Promise<ISchool> {
    return HttpClient.post({
      path: "/schools/upsert",
      body: school,
    });
  }
}
