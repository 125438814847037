import React, { Dispatch, SetStateAction, useState } from "react";
import { IAdminUser } from "../../../../Shared/Models/Entities/admin-user.interface";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import {
  IUserValidationResults,
  AdminUserEditorPanelHelper,
} from "./AdminUserEditorPanel.helper";
import { Utils } from "../../../../Shared/Helpers/utils";
import { Logger } from "../../../../Shared/Helpers/logger";
import { AdminUserService } from "../../../../Shared/Services/admin-user.service";
import { AppButton } from "../../../../Shared/Components/Button";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { AppMultipleSelectControl } from "../../../../Shared/Components/Controls/MultipleSelectControl";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { adminUserPermissionsOptions } from "../../../../Shared/Models/Configs/adminUserPermissionsOptions";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AdminUserSchoolDistrictSelector } from "./AdminUserSchoolDistrictSelector/AdminUserSchoolDistrictSelector";

export const AppAdminUserEditorPanel = (props: {
  adminUser: IAdminUser;
  isCreating: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const [adminUser, setAdminUser] = useState(
    Utils.cloneObject(props.adminUser)
  );
  const [errorResults, setErrorResults] = useState(
    {} as IUserValidationResults
  );
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);

  const handleInputChange = () => {
    setAdminUser({ ...adminUser });
    if (haveTriedSubmitting) {
      const results = AdminUserEditorPanelHelper.validateUser(adminUser);
      setErrorResults(results);
    }
  };

  const handleClose = () => {
    props.setOpen?.(false);
  };

  async function handleSubmit(): Promise<void> {
    const errorResults = AdminUserEditorPanelHelper.validateUser(adminUser);
    const isValid = Object.values(errorResults).length === 0;
    setHaveTriedSubmitting(true);
    setErrorResults(errorResults);
    if (isValid) {
      LoadingBarService.show();
      try {
        if (props.isCreating) {
          await AdminUserService.create(adminUser);
        } else {
          await AdminUserService.update(adminUser);
        }
        void props.onDataUpdated();
        SnackbarService.success(
          (props.isCreating ? "Created " : "Updated ") +
            adminUser.firstName +
            " " +
            adminUser.lastName
        );
        handleClose();
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    }
  }

  return (
    <AppGrid container spacing={3}>
      <AppFormCategory gridProps={{ xs: 12 }} title="Basic Info" />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="First Name *"
        name="firstName"
        value={adminUser.firstName}
        onChange={(event) => {
          adminUser.firstName = event.target.value;
          handleInputChange();
        }}
        error={!!errorResults.firstName}
        icon={Icons.AccountBox}
        opaqueBackground
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="Last Name *"
        name="lastName"
        value={adminUser.lastName}
        onChange={(event) => {
          adminUser.lastName = event.target.value;
          handleInputChange();
        }}
        error={!!errorResults.lastName}
        icon={Icons.AccountBox}
        opaqueBackground
      />
      <AppMultipleSelectControl
        gridProps={{ xs: 4 }}
        label="Permissions"
        name="permissions"
        value={adminUser.permissions as string[]}
        options={adminUserPermissionsOptions}
        onUpdate={(value) => {
          adminUser.permissions = value as AppPermissionsEnum[];
          handleInputChange();
        }}
        icon={Icons.Dns}
        multiple
      />
      <AppTextFieldControl
        gridProps={{ xs: 8 }}
        label="Email Address *"
        name="userEmail"
        value={adminUser.email}
        onChange={(event) => {
          adminUser.email = event.target.value as string;
          handleInputChange();
        }}
        error={!!errorResults.email}
        icon={Icons.Email}
        opaqueBackground
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="Internal ID"
        name="userInternalID"
        value={adminUser.id || "Auto-Generated"}
        icon={Icons.Dns}
        disabled
        opaqueBackground
      />

      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="Internal ID"
        name="userInternalID"
        value={adminUser.id || "Auto-Generated"}
        icon={Icons.Dns}
        disabled
        opaqueBackground
      />
      {!adminUser.internal && (
        <AdminUserSchoolDistrictSelector
          schoolId={adminUser.schoolId as number}
          schoolGroupId={adminUser.schoolGroupId as number}
          setSchoolId={(schoolId: number | null) => {
            adminUser.schoolId = schoolId;
            handleInputChange();
          }}
          setSchoolGroupId={(schoolGroupId: number | null) => {
            adminUser.schoolGroupId = schoolGroupId;
            handleInputChange();
          }}
          error={!!errorResults.schoolOrSchoolGroup}
        />
      )}

      <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
        <AppButton
          text="Cancel"
          icon={Icons.Close}
          palette={PaletteTypes.WARNING}
          variant="outlined"
          onClick={() => handleClose()}
        />
        <AppButton
          text="Submit"
          icon={props.isCreating ? Icons.Add : Icons.Check}
          palette={PaletteTypes.SUCCESS}
          onClick={() => handleSubmit()}
        />
      </AppGrid>
    </AppGrid>
  );
};
