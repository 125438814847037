import React from "react";
import { OutlinedTextFieldProps, TextField } from "@material-ui/core";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { AppGrid, IGridProps } from "../Grid";
import { AppIcon, Icons } from "../Icons/Icons";

const TextFieldControlStyles = appMakeStyles<ITextFieldControlProps>(
  (theme) => ({
    textField: {
      "& .MuiInputBase-root": {
        background: (props) =>
          props.disabled
            ? "transparent"
            : theme.palette.background.editableInput,
        color: theme.palette.text.primary,
        borderColor: theme.palette.divider,

        "&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)": {
          color: theme.palette.text.primary,

          "& fieldset": {
            border:
              "1px solid " + theme.palette.primary.outline + " !important",
          },
        },
      },

      "& svg": {
        marginRight: "8px",
        flexBasis: "24px",
        flexShrink: 0,
        color: "inherit",
      },

      input: {
        color: "inherit",
      },

      "& fieldset": {
        borderColor: "inherit !important",
      },

      "& .Mui-disabled": {
        color: theme.palette.text.secondary,

        "& .MuiSelect-icon": {
          display: "none",
        },
      },

      "& .Mui-error": {
        borderColor: theme.palette.error.outline,

        input: {
          color: theme.palette.text.disabled,
        },
      },

      "& .MuiInputBase-adornedStart": {
        paddingLeft: "10px",
      },
    },
  })
);

export interface ITextFieldControlProps
  extends Partial<OutlinedTextFieldProps> {
  icon?: AppIcon;
  opaqueBackground?: boolean;
  gridProps?: IGridProps;
  loading?: boolean;
}

export const AppTextFieldControl = (
  props: ITextFieldControlProps
): JSX.Element => {
  const {
    type,
    variant,
    value,
    InputProps = {},
    gridProps,
    loading,
    icon: ButtonIcon,
    error = null,
    placeholder,
    disabled,
    ...otherProps
  } = props;

  const styles = TextFieldControlStyles(props);

  return (
    <AppGrid item {...gridProps}>
      <TextField
        {...otherProps}
        autoComplete="off"
        className={styles.textField}
        fullWidth
        value={value ?? ""}
        type={type || "text"}
        variant={variant || "outlined"}
        placeholder={disabled ? "" : placeholder}
        disabled={disabled}
        InputProps={{
          ...InputProps,
          ...(ButtonIcon && {
            startAdornment: <ButtonIcon />,
          }),
          ...(loading && {
            startAdornment: <Icons.Loading />,
          }),
        }}
        {...(error && {
          error: true,
        })}
        InputLabelProps={{ shrink: true }}
      />
    </AppGrid>
  );
};
