import { DependencyList, useEffect } from "react";

export class EffectHooks {
  public static asyncUseEffect(
    func: () => Promise<void>,
    deps: DependencyList = []
  ): void {
    useEffect(() => {
      void func();
    }, deps);
  }
}
