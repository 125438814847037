import React from "react";
import { IEventAddStudentsPopupFilters } from "../EventAddStudentsPopup";
import { AppGrid } from "../../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../../Shared/Components/Controls/TextFieldControl";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";

export const AppEventAddStudentsPopupFilters = ({
    filters, updateFilters,
}: {
  filters: IEventAddStudentsPopupFilters;
  updateFilters: (filters: Partial<IEventAddStudentsPopupFilters>) => void;
}): JSX.Element => {
  return (
    <AppGrid item padding={15}>
      <AppGrid container spacing={3} alignItems="center">
        <AppTextFieldControl
          gridProps={{ xs: 3 }}
          label="Id"
          onChange={(event) => {
            updateFilters({
              externalId: event.target.value,
            });
          }}
          value={filters.externalId}
          icon={Icons.AccountBox}
        />

        <AppTextFieldControl
          gridProps={{ xs: 3 }}
          label="First Name"
          onChange={(event) => {
            updateFilters({
              firstName: event.target.value,
            });
          }}
          value={filters.firstName}
          icon={Icons.AccountBox}
        />

        <AppTextFieldControl
          gridProps={{ xs: 3 }}
          label="Last Name"
          onChange={(event) => {
            updateFilters({
              lastName: event.target.value,
            });
          }}
          value={filters.lastName}
          icon={Icons.AccountBox}
        />

        <AppTextFieldControl
          gridProps={{ xs: 3 }}
          label="Grade"
          onChange={(event) => {
            updateFilters({
              grade: event.target.value,
            });
          }}
          value={filters.grade}
          icon={Icons.AccountBox}
        />
      </AppGrid>
    </AppGrid>
  );
};
