import React, { useState } from "react";
import { ISchoolUserSimple } from "../../../../Shared/Models/Entities/school-user.interface";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppButton } from "../../../../Shared/Components/Button";
import { IGetUserProps } from "../../../../Shared/Services/school-user.service";
import { AppTablePagination } from "../../../../Shared/Components/Tables/TablePagination/TablePagination";
import { AppSchoolUserArchiveDialog } from "../../SchoolUserArchiveDialog/SchoolUserArchiveDialog";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { MediaQueryHooks } from "../../../../Shared/Hooks/media-query-hooks";

export const AppSchoolUserTable = (props: {
  schoolUsers: ISchoolUserSimple[] | null;
  schoolUsersLoading: boolean;
  count: number | null;
  countLoading: boolean;
  filters: IGetUserProps;
  setFilters: (props: IGetUserProps) => void;
  areFiltersDefault: boolean;
  onUserUpdated: () => void;
}): JSX.Element => {
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [archivingUser, setArchivingUser] = useState<ISchoolUserSimple | null>(
    null
  );

  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="School Users"
          subheader="All school-based users that exist in your school/group. Can be teachers, principals, etc."
          icon={Icons.Group}
          palette={PaletteTypes.PRIMARY}
        />
        <AppCardContent padding={0}>
          <TableContent
            schoolUsers={props.schoolUsers}
            schoolUsersLoading={props.schoolUsersLoading}
            areFiltersDefault={props.areFiltersDefault}
            setArchivingUser={setArchivingUser}
            setArchiveDialogOpen={setArchiveDialogOpen}
          />
          <AppTablePagination
            count={props.count}
            countLoading={props.countLoading}
            paginationOptions={props.filters.paginationOptions}
            paginationOptionsUpdated={(newOptions) => {
              props.setFilters({
                ...props.filters,
                paginationOptions: newOptions,
              });
            }}
          />
        </AppCardContent>
      </AppCard>
      <AppSchoolUserArchiveDialog
        schoolUser={archivingUser}
        open={archiveDialogOpen}
        setOpen={setArchiveDialogOpen}
        onUserUpdated={props.onUserUpdated}
      />
    </>
  );
};

const TableContent = ({
  schoolUsers,
  schoolUsersLoading,
  areFiltersDefault,
  setArchivingUser,
  setArchiveDialogOpen,
}: {
  schoolUsers: ISchoolUserSimple[] | null;
  schoolUsersLoading: boolean;
  areFiltersDefault: boolean;
  setArchivingUser: (user: ISchoolUserSimple) => void;
  setArchiveDialogOpen: (open: boolean) => void;
}) => {
  const isDesktop = MediaQueryHooks.isDesktop();
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);

  return (
    <AppCustomTable
      loading={schoolUsersLoading && !schoolUsers}
      showLoadingText={
        areFiltersDefault
          ? "Choose a term to search by or select a school/group in the sidebar!"
          : null
      }
      columns={[
        {
          id: "id",
          label: "ID",
        },
        {
          id: "email",
          label: "Email",
          width: 300,
        },
        {
          id: "last",
          label: "Last",
        },
        {
          id: "first",
          label: "First",
        },
        {
          id: "school",
          label: "School",
        },
        {
          id: "district",
          label: "School Group",
        },
        {
          id: "role",
          label: "Role",
        },
        {
          id: "instructor-code",
          label: "Instructor Code",
        },
        {
          id: "action",
          label: "Actions",
        },
      ]}
      rows={schoolUsers?.map((user) => ({
        cells: [
          {
            value: user.id,
          },
          {
            value: user.email,
          },
          {
            value: user.lastName,
          },
          {
            value: user.firstName,
          },
          {
            value: user.school ? (
              <>
                {user.school.name} <br /> ({user.school.id})
              </>
            ) : (
              "-"
            ),
          },
          {
            value: user.district ? (
              <>
                <span>{user.district.name}</span>
                &nbsp;
                <span>({user.district.id})</span>
              </>
            ) : (
              "-"
            ),
          },
          {
            value: user.role?.name || "-",
          },
          {
            value: user.instructorCode,
          },
          {
            value: (
              <AppGrid container spacing={1}>
                <AppButton
                  text={isDesktop ? (canEdit ? "Edit" : "View") : undefined}
                  icon={canEdit ? Icons.Edit : Icons.Visibility}
                  palette={PaletteTypes.SECONDARY}
                  linkTo={"/school-users/" + user.id.toString()}
                />
                {canEdit && (
                  <AppButton
                    text={
                      isDesktop
                        ? user.active
                          ? "Archive"
                          : "Restore"
                        : undefined
                    }
                    icon={user.active ? Icons.Delete : Icons.Refresh}
                    palette={
                      user.active ? PaletteTypes.ERROR : PaletteTypes.SUCCESS
                    }
                    onClick={() => {
                      setArchivingUser(user);
                      setArchiveDialogOpen(true);
                    }}
                  />
                )}
              </AppGrid>
            ),
          },
        ],
      }))}
    />
  );
};
