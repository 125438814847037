import React from "react";
import "./App.css";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "fontsource-roboto";
import { AppSnackbar } from "./Snackbar/Snackbar";
import { AppProject } from "../Project/Project";
import { AppAuth } from "../Auth/Auth";
import { BrowserRouter } from "react-router-dom";
import { Themes } from "../Shared/Themes/themes";
import { StoreHelper } from "../Shared/Reducers/store-helper";
import { AppBackgroundWrapper } from "./BackgroundWrapper/BackgroundWrapper";
import { AppLoadingBar } from "./LoadingBar/LoadingBar";

export const App = (): JSX.Element => {
  const isAuthenticated = StoreHelper.selector(
    (state) => state.auth.isUserAuthenticated
  );
  const theme = StoreHelper.selector((state) => state.appConfig.theme);

  return (
    <ThemeProvider theme={theme === "light" ? Themes.light : Themes.dark}>
      <CssBaseline />
      <AppBackgroundWrapper>
        <BrowserRouter>
          {isAuthenticated ? <AppProject /> : <AppAuth />}
        </BrowserRouter>
      </AppBackgroundWrapper>
      <AppSnackbar />
      <AppLoadingBar />
    </ThemeProvider>
  );
};
