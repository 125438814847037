import { LoadingBarReducerActions } from "../Reducers/LoadingBar/loading-bar.reducer";
import { StoreHelper } from "../Reducers/store-helper";

export class LoadingBarService {
  public static show(): void {
    StoreHelper.dispatch(LoadingBarReducerActions.show());
  }
  public static hide(): void {
    StoreHelper.dispatch(LoadingBarReducerActions.hide());
  }
}
