export class Utils {
  static arrayToDictionary<Input, Output>(
    list: Input[],
    recordSelector: (
      value: Input,
      index: number,
      array: Input[]
    ) => { [id: string]: Output }
  ): { [prop: string]: Output } {
    return Object.assign({}, ...list.map(recordSelector));
  }

  static cloneObject<T>(original: T): T {
    return JSON.parse(JSON.stringify(original));
  }
}
