import React from "react";
import { appMakeStyles } from "../../Shared/Themes/app-make-styles";

const BackgroundWrapperStyles = appMakeStyles((theme) => ({
  backgroundWrapper: {
    background: theme.palette.background.default,
  },
}));

export const AppBackgroundWrapper = ({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element => {
  const classes = BackgroundWrapperStyles({});

  return <div className={classes.backgroundWrapper}>{children}</div>;
};
