export interface ITracCodeType {
  id: number;
  name: string;
  value: number;
}

export class TracCodeTypeHelper {
  public static tracCodeTypes = [
    {
      id: 1,
      name: "Positive",
      value: 1,
    },
    {
      id: 2,
      name: "Negative",
      value: -1,
    },
    {
      id: 3,
      name: "Neutral",
      value: 0,
    },
    {
      id: 4,
      name: "Tardy",
      value: -1,
    },
  ];

  public static tracCodeTypeNames: { [id: number]: string } = {
    1: "Positive",
    2: "Negative",
    3: "Neutral",
    4: "Tardy",
  };

  public static getOptions(): { label: string; value: string }[] {
    return TracCodeTypeHelper.tracCodeTypes.map((type) => ({
      label: type.name,
      value: type.id.toString(),
    }));
  }

  public static getName(id: number): string {
    return TracCodeTypeHelper.tracCodeTypeNames[id];
  }
}
