import { SchoolService } from "./school.service";
import { DistrictService } from "./district.service";
import { Utils } from "../Helpers/utils";
import { IDistrictNode } from "../Models/Interfaces/district-node.interface";
import { SchoolDistrictManagerReducerActions } from "../Reducers/SchoolDistrictManager/school-district-manager.reducer";
import { Logger } from "../Helpers/logger";
import { StoreHelper } from "../Reducers/store-helper";
import { ISimpleItem } from "../Models/Entities/simple-item.interface";
import { ISchoolSimple } from "../Models/Entities/school.interface";

export interface IDistrictTreeNeededData {
  districts: ISimpleItem[];
  schools: ISchoolSimple[];
}

export class DistrictTreeService {
  static async reloadData(): Promise<void> {
    try {
      const data = await DistrictTreeService.getData();
      StoreHelper.dispatch(
        SchoolDistrictManagerReducerActions.setDistrictsTree(data)
      );
    } catch (error) {
      Logger.error(error);
    }
  }

  static async getData(): Promise<IDistrictTreeNeededData> {
    const districtsCall = DistrictService.getAllSimple();
    const schoolsCall = SchoolService.getAllSimple();

    const districts = await districtsCall;
    const schools = await schoolsCall;

    return {
      districts,
      schools,
    };
  }

  static buildDistrictNodes({
    districts,
    schools,
  }: IDistrictTreeNeededData): IDistrictNode[] {
    const districtsViewModel = Utils.arrayToDictionary<
      ISimpleItem,
      IDistrictNode
    >(districts, (district) => {
      const item = DistrictTreeService.createDistrictNode(district);
      return {
        [district.id]: item,
      };
    });

    schools.forEach((school) => {
      const districtId = school.districtId?.toString();
      if (districtId && districtId in districtsViewModel) {
        districtsViewModel[districtId].schools.push(school);
      }
    });

    return Object.values(districtsViewModel);
  }

  static createDistrictNode(district: ISimpleItem): IDistrictNode {
    return {
      district,
      schools: [],
      isOpen: false,
    };
  }
}
