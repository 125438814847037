import React from "react";
import { TableBody as MuiTableBody, TableBodyProps } from "@material-ui/core";
import { appMakeStyles } from "../../../Themes/app-make-styles";

const BaseTableBodyStyles = appMakeStyles((theme) => ({
  tableBody: {
    "& .MuiTableRow-root": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiTableRow-root:nth-child(2n - 1)": {
      backgroundColor: theme.palette.background.paperAlternate,
    },
  },
}));

// eslint-disable-next-line
export type BaseTableBodyProps = TableBodyProps & {};

export const AppBaseTableBody = (props: BaseTableBodyProps): JSX.Element => {
  const classes = BaseTableBodyStyles({});

  return <MuiTableBody {...props} className={classes.tableBody} />;
};
