import React, { Dispatch, SetStateAction } from "react";
import { AppDistrictEditor } from "../DistrictEditor/DistrictEditor";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { IDistrict } from "../../../../Shared/Models/Entities/district.interface";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppDistrictCreateDialog = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const district: IDistrict = {
    id: 0,
    name: "",
    parentId: null,
    salesForceId: null,
  };

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="sm"
      title="Create School Group"
      icon={Icons.District}
      palette={PaletteTypes.SUCCESS}
    >
      <AppDistrictEditor
        district={district}
        hasSchools={false}
        isCreating={true}
        setOpen={props.setOpen}
      />
    </AppCustomDialog>
  );
};
