import React, { Dispatch, SetStateAction } from "react";
import { AdminUserEditorPanelHelper } from "../AdminUserEditorPanel/AdminUserEditorPanel.helper";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppAdminUserEditorPanel } from "../AdminUserEditorPanel/AdminUserEditorPanel";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppAdminUserCreateDialog = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const user = AdminUserEditorPanelHelper.getBlankUser();

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title="Create A New User"
      icon={Icons.PersonAdd}
      palette={PaletteTypes.SUCCESS}
    >
      <AppAdminUserEditorPanel
        adminUser={user}
        isCreating={true}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
