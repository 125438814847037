import React, { Dispatch, SetStateAction, useState } from "react";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { TimeHelper } from "../../../../Shared/Helpers/time-helper";
import { IDateSet } from "../../../../Shared/Models/Entities/date-set.interface";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import DateFnsUtils from "@date-io/date-fns";
import { appMakeStyles } from "../../../../Shared/Themes/app-make-styles";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { DateSetService } from "../../../../Shared/Services/date-set-service";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppButton } from "../../../../Shared/Components/Button";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";

const SelectedDayStyle = appMakeStyles((theme) => ({
  selectedDayWrapper: {
    backgroundColor: theme.palette.success.light,
    borderRadius: "100%",
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  unselectedDayWrapper: {
    borderRadius: "100%",
    width: "36px",
    height: "36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  day: {
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.5) !important",
  },
  outsideCurrentMonth: {
    width: "36px",
    height: "36px",
  },
  selectedCard: {
    position: "relative",
    "&:hover": {
      "& $closeButton": {
        display: "flex",
        cursor: "pointer",
        background: "white",
        borderRadius: "50%",
        border: "1px solid black",
      },
    },
  },
  closeButton: {
    display: "none",
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

class DateSetHelper {
  static getDefault(): IDateSet {
    return { id: -1, name: "", type: 0, customerId: -1, dateSetItems: [] };
  }
}

export const AppDateSetManager = (props: {
  dateSetId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  canEdit: boolean;
  handleSubmit: (dateSet: IDateSet) => Promise<unknown>;
  title: string;
  refreshData: () => void;
}): JSX.Element => {
  const classes = SelectedDayStyle({});
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const isCreating = props.dateSetId === -1;

  const getDateSetToEdit = async (): Promise<IDateSet> => {
    if (!isCreating) {
      return DateSetService.getById(props.dateSetId);
    } else {
      return DateSetHelper.getDefault();
    }
  };

  const {
    data: dateSet,
    setData: setDateSet,
    loading,
  } = DataHooks.useFunctionCallState({
    func: getDateSetToEdit,
    showLoadingIndicator: true,
  });

  const dates: Date[] = dateSet
    ? dateSet.dateSetItems.map((item) => new Date(item.date))
    : [];

  const [today] = useState(new Date());

  const removeDate = (dateToRemove: Date) => {
    if (dateSet) {
      const dateSetItems = [...dateSet?.dateSetItems];
      const toRemoveIndex = dates.findIndex((date) =>
        TimeHelper.isSameDay(date, dateToRemove)
      );
      dateSetItems.splice(toRemoveIndex, 1);
      setDateSet({
        ...dateSet,
        dateSetItems: dateSetItems,
      });
    }
  };

  const handleAdd = async () => {
    LoadingBarService.show();
    try {
      setTriedToSubmit(true);
      if (dateSet?.name === "") {
        throw Error("Please provide a name for Excluded Day");
      }
      if (dateSet) {
        await props.handleSubmit(dateSet);
        props.refreshData();
      }

      props.setOpen(false);
    } catch (error) {
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  return (
    <>
      {!loading && (
        <AppCustomDialog
          open={props.open}
          setOpen={props.setOpen}
          fullWidth
          maxWidth="md"
          title={props.title}
          icon={Icons.Edit}
          palette={PaletteTypes.SUCCESS}
          action={
            props.canEdit && (
              <AppButton
                text={isCreating ? "Add" : "Edit"}
                icon={isCreating ? Icons.Add : Icons.Edit}
                palette={PaletteTypes.SUCCESS}
                onClick={handleAdd}
              />
            )
          }
        >
          <AppGrid item container direction="column">
            <AppTextFieldControl
              gridProps={{ xs: 6 }}
              label="Name"
              name="name"
              value={dateSet?.name}
              onChange={(event) => {
                const name = event.target.value;
                if (dateSet) {
                  setDateSet({ ...dateSet, name });
                }
              }}
              icon={Icons.Info}
              error={triedToSubmit && dateSet?.name === ""}
              opaqueBackground
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Calendar
                date={today}
                onChange={(newDate) => {
                  const isValidDate = TimeHelper.isValidDate(newDate);

                  if (
                    isValidDate &&
                    newDate &&
                    dates.some((date) => TimeHelper.isSameDay(date, newDate))
                  ) {
                    removeDate(newDate);
                  } else if (dateSet && isValidDate && newDate) {
                    setDateSet({
                      ...dateSet,
                      dateSetItems: [
                        ...dateSet?.dateSetItems,
                        {
                          id: -1,
                          date: TimeHelper.formatDateToDBString(newDate),
                        },
                      ],
                    });
                  }
                }}
                renderDay={(day, _, dayInCurrentMonth) => {
                  if (!dayInCurrentMonth) {
                    return <div className={classes.outsideCurrentMonth}></div>;
                  }
                  if (
                    dates.some((date) =>
                      TimeHelper.isSameDay(date, day as Date)
                    )
                  ) {
                    return (
                      <div className={classes.selectedDayWrapper}>
                        <span>{day?.getDate()}</span>
                      </div>
                    );
                  }
                  return (
                    <div className={classes.unselectedDayWrapper}>
                      <span>{day?.getDate()}</span>
                    </div>
                  );
                }}
              />
            </MuiPickersUtilsProvider>

            <AppGrid container spacing={3}>
              {dates
                .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                .map((item) => (
                  <AppGrid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                    key={`date-set-item-${item.toUTCString()}`}
                    className={classes.selectedCard}
                  >
                    <AppCard>
                      <AppCardContent background={PaletteTypes.SECONDARY}>
                        {TimeHelper.formatShortDate(item)}
                      </AppCardContent>
                    </AppCard>
                    <Icons.Close
                      className={classes.closeButton}
                      onClick={() => removeDate(item)}
                    />
                  </AppGrid>
                ))}
            </AppGrid>
          </AppGrid>
        </AppCustomDialog>
      )}
    </>
  );
};
