import React from "react";
import { IPaginationOptions } from "./pagination-options.interface";
import { TablePaginationHelper } from "./table-pagination.helper";
import { AppGrid } from "../../Grid";
import { AppBaseTablePagination } from "../BaseTable/BaseTablePagination";

export interface ITablePaginationProps {
  count?: number | null;
  countLoading?: boolean;
  paginationOptions?: IPaginationOptions;
  paginationOptionsUpdated: (paginationOptions: IPaginationOptions) => void;
}

export const AppTablePagination = (
  props: ITablePaginationProps
): JSX.Element => {
  const paginationOptions =
    props.paginationOptions || TablePaginationHelper.getDefaultOptions();

  return (
    <AppGrid container justifyContent="flex-end">
      <AppBaseTablePagination
        rowsPerPageOptions={paginationOptions.sizeOptions}
        rowsPerPage={paginationOptions.size}
        page={paginationOptions.page}
        placeholder={"Loading count..."}
        count={typeof props.count === "number" ? props.count : -1}
        labelDisplayedRows={({ from, to, count }) => {
          const countText =
            props.countLoading || props.count === null ? "..." : count;
          return `${from}-${to} of ${countText}`;
        }}
        onPageChange={(event, page) => {
          paginationOptions.page = page;
          props.paginationOptionsUpdated(paginationOptions);
        }}
        onRowsPerPageChange={(event) => {
          const size = parseInt(event.target.value, 10);
          paginationOptions.size = size;
          paginationOptions.page = 0;
          props.paginationOptionsUpdated(paginationOptions);
        }}
      />
    </AppGrid>
  );
};
