import React, { useEffect, useState } from "react";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { ISchoolUser } from "../../../../Shared/Models/Entities/school-user.interface";
import { ISchoolUserValidationResults } from "../SchoolUserViewer.helper";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { LocationMetadataHelper } from "../../../../Shared/Helpers/location-metadata.helper";
import { ISimpleOption } from "../../../../Shared/Models/Entities/simple-option.interface";
import { SchoolUserCategoryService } from "../../../../Shared/Services/school-user-category.service";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { TimeHelper } from "../../../../Shared/Helpers/time-helper";

export const AppSchoolUserInfoViewer = ({
  schoolUser,
  updateSchoolUser,
  errorResults,
}: {
  schoolUser: ISchoolUser | null;
  updateSchoolUser: (schoolUser: ISchoolUser) => void;
  errorResults: ISchoolUserValidationResults;
}): JSX.Element => {
  const [editingUser, setEditingUser] = useState<ISchoolUser | null>(null);

  useEffect(() => {
    setEditingUser(schoolUser);
  }, [schoolUser?.id]);

  const handleUpdate = (props: Partial<ISchoolUser>) => {
    if (schoolUser && editingUser) {
      setEditingUser({
        ...editingUser,
        ...props,
      });
      updateSchoolUser({
        ...schoolUser,
        ...props,
      });
    }
  };

  return (
    <AppGrid container spacing={3}>
      <BasicInfo
        editingUser={editingUser}
        handleUpdate={handleUpdate}
        errorResults={errorResults}
      />
      <AppFormCategory title="Location" gridProps={{ xs: 12 }} />
      <Location editingUser={editingUser} handleUpdate={handleUpdate} />
      <AppFormCategory title="Metadata" gridProps={{ xs: 12 }} />
      <Metadata editingUser={editingUser} handleUpdate={handleUpdate} />
    </AppGrid>
  );
};

const BasicInfo = ({
  editingUser,
  handleUpdate,
  errorResults,
}: {
  editingUser: ISchoolUser | null;
  handleUpdate: (props: Partial<ISchoolUser>) => void;
  errorResults: ISchoolUserValidationResults;
}) => {
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);
  const categoriesData = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: SchoolUserCategoryService.getAllSimple,
  });

  return (
    <>
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="First Name *"
        name="firstName"
        value={editingUser?.firstName}
        onChange={(event) => {
          handleUpdate({
            firstName: event.target.value,
          });
        }}
        icon={Icons.AccountBox}
        disabled={!canEdit}
      />
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="Last Name *"
        name="lastName"
        value={editingUser?.lastName}
        onChange={(event) => {
          handleUpdate({
            lastName: event.target.value,
          });
        }}
        icon={Icons.AccountBox}
        disabled={!canEdit}
      />
      <AppTextFieldControl
        gridProps={{ xs: 8 }}
        label="Email Address *"
        name="userEmail"
        value={editingUser?.email}
        onChange={(event) => {
          handleUpdate({
            email: event.target.value as string,
          });
        }}
        error={!!errorResults.email}
        helperText={errorResults.email}
        icon={Icons.Email}
        disabled={!canEdit}
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="Telephone"
        name="telephone"
        value={editingUser?.telephone}
        onChange={(event) => {
          handleUpdate({
            telephone: event.target.value as string,
          });
        }}
        icon={Icons.Email}
        disabled={!canEdit}
      />
      <AppSelectControl
        gridProps={{ xs: 6 }}
        label="Category"
        name="category"
        type="number"
        value={editingUser?.category?.id}
        selected={(value) => {
          handleUpdate({
            category: {
              id: Number(value),
              name: "",
            },
          });
        }}
        options={categoriesData.data || []}
        icon={Icons.Category}
        disabled={!canEdit}
      />
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="Last Login Date"
        name="lastLoginDate"
        value={
          TimeHelper.formatDate(editingUser?.lastLoginTime || null) || "N/A"
        }
        icon={Icons.Calendar}
        disabled
      />
    </>
  );
};

const Location = ({
  editingUser,
  handleUpdate,
}: {
  editingUser: ISchoolUser | null;
  handleUpdate: (props: Partial<ISchoolUser>) => void;
}) => {
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);
  const countries = LocationMetadataHelper.getCountries();
  const statesData = DataHooks.useFunctionCallState<ISimpleOption[]>({
    func: () => LocationMetadataHelper.getStatesByCountry(editingUser?.country),
    deps: [editingUser?.country],
  });

  return (
    <>
      <AppTextFieldControl
        gridProps={{ xs: 8 }}
        label="Address"
        name="address"
        value={editingUser?.address}
        onChange={(event) => {
          handleUpdate({
            address: event.target.value as string,
          });
        }}
        icon={Icons.Home}
        disabled={!canEdit}
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="City"
        name="city"
        value={editingUser?.city}
        onChange={(event) => {
          handleUpdate({
            city: event.target.value as string,
          });
        }}
        icon={Icons.LocationCity}
        disabled={!canEdit}
      />
      <AppSelectControl
        gridProps={{ xs: 4 }}
        label="State"
        name="state"
        value={editingUser?.state}
        selected={(value) => {
          handleUpdate({
            state: value || undefined,
          });
        }}
        options={statesData.data || []}
        icon={Icons.Flag}
        disabled={!canEdit}
      />
      <AppSelectControl
        gridProps={{ xs: 4 }}
        label="Country"
        name="country"
        value={editingUser?.country}
        type="number"
        selected={(value) => {
          handleUpdate({
            country: value || undefined,
          });
        }}
        options={countries}
        icon={Icons.Flag}
        disabled={!canEdit}
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="ZIP Code"
        name="zipCode"
        value={editingUser?.zipCode}
        onChange={(event) => {
          handleUpdate({
            zipCode: event.target.value as string,
          });
        }}
        icon={Icons.Home}
        disabled={!canEdit}
      />
    </>
  );
};

const Metadata = ({
  editingUser,
  handleUpdate,
}: {
  editingUser: ISchoolUser | null;
  handleUpdate: (props: Partial<ISchoolUser>) => void;
}) => {
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);

  return (
    <>
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="Instructor Code"
        name="instructorCode"
        helperText="Does your school use any type of unique identifier for teachers? If so, enter it above. This is not required."
        value={editingUser?.instructorCode}
        onChange={(event) => {
          handleUpdate({
            instructorCode: event.target.value as string,
          });
        }}
        icon={Icons.Dns}
        disabled={!canEdit}
      />
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="External Code"
        name="externalCode"
        helperText="If your school uses Single Sign-On, enter the External User Code above."
        value={editingUser?.externalCode}
        onChange={(event) => {
          handleUpdate({
            externalCode: event.target.value as string,
          });
        }}
        icon={Icons.Dns}
        disabled={!canEdit}
      />
    </>
  );
};
