export class SchoolChurnTableHelper {
  static formatDate(strDate: string): string {
    return new Date(strDate)?.toUTCString();
  }

  static getStatusName(statusId: number): string {
    switch (statusId) {
      case 1:
        return "Pending";
      case 2:
        return "InProcess";
      case 3:
        return "Succeeded";
      case 4:
        return "Failed";
      default:
        return "InvalidState";
    }
  }

  static getStatusColor(statusId: number): string {
    switch (statusId) {
      case 1:
        return "#808080";
      case 2:
        return "#ffa700";
      case 3:
        return "#008000";
      case 4:
        return "#ff0000";
      default:
        return "ff0000";
    }
  }
}
