import {ISimpleOption} from "../Entities/simple-option.interface";
import {AppPermissionsEnum} from "../Enums/app-permissions.enum";

export const adminUserPermissionsOptions: ISimpleOption[] = [
    // General privileges
    {
        id: AppPermissionsEnum.ACCESS_MANAGEMENT_PORTAL,
        name: "Access Management Portal",
    },
    {id: AppPermissionsEnum.SUPER_ADMIN, name: "Super Admin"},

    // Admin Users
    {id: AppPermissionsEnum.ADMIN_USERS_GET, name: "View Admin Users"},
    {id: AppPermissionsEnum.ADMIN_USERS_EDIT, name: "Edit Admin Users"},

    // Districts
    {id: AppPermissionsEnum.DISTRICTS_CREATE, name: "Create Districts"},
    {id: AppPermissionsEnum.DISTRICTS_EDIT, name: "Edit Districts"},
    {id: AppPermissionsEnum.DISTRICTS_DELETE, name: "Delete Districts"},

    // Events
    {id: AppPermissionsEnum.EVENTS_GET, name: "View Events"},
    {id: AppPermissionsEnum.EVENTS_EDIT, name: "Edit  Events"},

    // School User Import

    {id: AppPermissionsEnum.IMPORT_SCHOOL_USERS, name: "Import School Users"},

    // Schools
    {id: AppPermissionsEnum.SCHOOLS_CREATE, name: "Create Schools"},
    {id: AppPermissionsEnum.SCHOOLS_EDIT, name: "Edit Schools"},
    {id: AppPermissionsEnum.SCHOOLS_DELETE, name: "Delete Schools"},
    {
        id: AppPermissionsEnum.SCHOOLS_ENROLLMENT_VIEW,
        name: "View School Enrollment",
    },
    {
        id: AppPermissionsEnum.SCHOOLS_SUBSCRIPTION_EDIT,
        name: "Edit School Subscription",
    },

    // School Users
    {id: AppPermissionsEnum.SCHOOL_USERS_GET, name: "View School Users"},
    {id: AppPermissionsEnum.SCHOOL_USERS_EDIT, name: "Edit School Users"},

    // Roles
    {id: AppPermissionsEnum.ROLES_GET, name: "View Roles"},
    {id: AppPermissionsEnum.ROLES_EDIT, name: "Edit Roles"},
    {id: AppPermissionsEnum.GLOBAL_ROLES_EDIT, name: "Edit Global Roles"},

    // Bell Schedules
    {id: AppPermissionsEnum.BELL_SCHEDULES_EDIT, name: "Edit Bell Schedules"},
    {id: AppPermissionsEnum.BELL_SCHEDULES_GET, name: "View Bell Schedules"},

    // Trac Codes
    {id: AppPermissionsEnum.TRAC_CODES_VIEW, name: "View Trac Codes"},
    {id: AppPermissionsEnum.TRAC_CODES_EDIT, name: "Edit Trac Codes"},

    // Student Imports
    {id: AppPermissionsEnum.STUDENT_IMPORT_CREATE, name: "Create Student Imports"},
    {id: AppPermissionsEnum.STUDENT_IMPORT_GET, name: "View Student Imports"},
    {id: AppPermissionsEnum.STUDENT_IMPORT_PROCESS, name: "Process Student Imports"},
];
