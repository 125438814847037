import React, { useState } from "react";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { IGetUserProps } from "../../../../Shared/Services/school-user.service";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppSchoolUserListFilters = ({
  filters,
  setFilters,
}: {
  filters: IGetUserProps;
  setFilters: (props: IGetUserProps) => void;
}): JSX.Element => {
  const [currFilterKey, setCurrFilterKey] = useState(filters.searchKey);

  return (
    <AppGrid item container spacing={3} alignItems="center">
      <AppTextFieldControl
        gridProps={{ width: 435 }}
        placeholder="Search by name, email, or instructorCode"
        icon={Icons.Search}
        value={currFilterKey}
        type="search"
        onBlur={() => {
          if (currFilterKey !== filters.searchKey) {
            setFilters({
              ...filters,
              searchKey: currFilterKey,
            });
          }
        }}
        onKeyPress={(event) => {
          if (event.code === "Enter") {
            setFilters({
              ...filters,
              searchKey: currFilterKey,
            });
          }
        }}
        onChange={(event) => {
          setCurrFilterKey(event.target.value);
        }}
        opaqueBackground
      />
      <AppSwitchControl
        gridProps={{ width: 215 }}
        label="Show Inactive"
        checked={filters.inactive}
        onChange={(event) => {
          const value = event.target.checked;
          setFilters({
            ...filters,
            inactive: value,
          });
        }}
      />
    </AppGrid>
  );
};
