import React, {Dispatch, SetStateAction} from "react";
import {AppCustomDialog} from "../../../Shared/Components/Dialogs/CustomDialog";
import {PaletteTypes} from "../../../Shared/Themes/palette-types.enum";
import {Icons} from "../../../Shared/Components/Icons/Icons";
import {IStudentImport} from "../../../Shared/Models/Entities/student-import.interface";
import {AppStudentImportEditorPanel} from "./StudentImportEditorPanel";

export const AppStudentImportCreateDialog = (props: {
    open: boolean;
    currentImport?: IStudentImport;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onUpdated: () => void;
}): JSX.Element => {

    return (
        <AppCustomDialog
            open={props.open}
            setOpen={props.setOpen}
            fullWidth
            maxWidth="md"
            title="Create a new student import"
            icon={Icons.Upload}
            palette={PaletteTypes.SUCCESS}
        >
            <AppStudentImportEditorPanel/>
        </AppCustomDialog>
    )
}