import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { HttpClient } from "../Helpers/http-client";
import { PaginationHelper } from "../Helpers/pagination.helper";
import { IPopulatedTracCode } from "../Models/Entities/populated-trac-code.interface";
import { ITracCode } from "../Models/Entities/trac-code.interface";

export interface IGetTracCodeProps {
  searchKey?: string;
  schoolId?: number;
  districtId?: number;
  inactive: boolean;
  paginationOptions: IPaginationOptions;
  paginate?: boolean;
}

export class TracCodeService {
  static async getAll(props: IGetTracCodeProps): Promise<ITracCode[]> {
    return HttpClient.get({
      path: "/trac-codes",
      params: {
        ...PaginationHelper.getPaginationParams(props.paginationOptions),
        ...(props?.districtId && {
          districtId: props?.districtId,
        }),
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),
        inactive: props.inactive,
        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
        ...(props?.paginate && {
          paginate: props.paginate,
        }),
      },
    });
  }

  static async getCount(props: IGetTracCodeProps): Promise<number> {
    return HttpClient.get({
      path: "/trac-codes/count",
      params: {
        ...(props?.districtId && {
          districtId: props?.districtId,
        }),
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),
        ...(props?.inactive && {
          inactive: true,
        }),
        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
        ...(props?.paginate && {
          paginate: props.paginate,
        }),
      },
    });
  }

  static async reOrder(
    tracCodes: ITracCode[],
    schoolId: number
  ): Promise<ITracCode[]> {
    return HttpClient.put({
      path: `/trac-codes/order/?schoolId=${schoolId}`,
      body: tracCodes,
    });
  }

  static async get(id: number, schoolId?: number): Promise<IPopulatedTracCode> {
    return HttpClient.get({
      path: `/trac-codes/${id}${
        schoolId !== undefined ? `?schoolId=${schoolId}` : ""
      }`,
    });
  }

  static async update(
    tracCode: IPopulatedTracCode
  ): Promise<IPopulatedTracCode> {
    return HttpClient.put({
      path: `/trac-codes`,
      body: tracCode,
    });
  }
  static async create(
    tracCode: IPopulatedTracCode,
    schoolId: number
  ): Promise<IPopulatedTracCode> {
    return HttpClient.post({
      path: `/trac-codes?schoolId=${schoolId}`,
      body: tracCode,
    });
  }
}
