import { appMakeStyles } from "../../Shared/Themes/app-make-styles";

export const NavbarStyles = appMakeStyles((theme) => ({
  appBar: {
    flexGrow: 1,
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  toolBar: {
    backgroundColor: "transparent",
    display: "flex",
    alignItems: "center",
    padding: "0 16px 0 24px",
  },
  sidebarButton: {
    marginRight: "15px",
    padding: "8px !important",
    height: "auto",
    minWidth: 0,

    "& svg": {
      marginRight: "0",
    },
  },
  navbarButton: {
    minWidth: 0,
    padding: "6px !important",
    color:
      theme.palette.overrideHeaderColor ||
      theme.palette.primary.main + " !important",
    marginLeft: "10px",

    "& *": {
      color:
        theme.palette.overrideHeaderColor ||
        theme.palette.primary.main + " !important",
      marginRight: "0 !important",
    },
  },
}));
