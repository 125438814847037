import { ITheme } from "./theme.interface";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { makeStyles, StyleRules } from "@material-ui/core";

export const appMakeStyles = <
  // eslint-disable-next-line
  Props extends object = {},
  ClassKey extends string = string
>(
  themeOptions: (theme: ITheme) => StyleRules<ClassKey, Props>
): ((props: Props) => ClassNameMap<ClassKey>) => {
  // eslint-disable-next-line
  return makeStyles<ITheme, Props, ClassKey>(themeOptions as any);
};
