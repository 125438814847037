import { ValueHelper } from "./value.helper";
import { useParams } from "react-router";

export class UrlHelper {
  public static getParamNumber(key: string): number {
    return ValueHelper.getNumberFromString(
      // eslint-disable-next-line
      useParams<any>()[key],
      null
    );
  }

  public static getParamString(key: string): string {
    // eslint-disable-next-line
    return useParams<any>()[key];
  }
}
