import React, { useState } from "react";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { UrlHelper } from "../../../Shared/Helpers/url.helper";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppCard } from "../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../Shared/Components/Cards/CardHeader";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppCardContent } from "../../../Shared/Components/Cards/CardContent";
import { AppButton } from "../../../Shared/Components/Button";
import { AppActionBar } from "../../../Shared/Components/ActionBar";
import { Prompt, withRouter } from "react-router";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { Logger } from "../../../Shared/Helpers/logger";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { IEvent } from "../../../Shared/Models/Entities/event.interface";
import { EventService } from "../../../Shared/Services/event.service";
import { AppEventInfo } from "./EventInfo/EventInfo";
import { AppEventDates } from "./EventDates/EventDates";
import { AppEventRegisteredStudentsList } from "./EventRegisteredStudentsList/EventRegisteredStudentsList";
import {
  EventViewerHelper,
  IEventValidationResults,
} from "./EventViewer.helper";
import { AppEventAddStudentsPopup } from "./EventAddStudentsPopup/EventAddStudentsPopup";
import { IStudentSimple } from "../../../Shared/Models/Entities/student-simple.interface";
import { AppEventImportStudentsPopup } from "./EventImportStudentsPopup/EventImportStudentsPopup";
import { AppEventDeleteDialog } from "./EventDeleteDialog/EventDeleteDialog";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";

const EventViewer = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [isCreating, setIsCreating] = useState(false);
  const eventId = UrlHelper.getParamNumber("id");
  const [errorResults, setErrorResults] = useState(
    {} as IEventValidationResults
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addStudentsDialogOpen, setAddStudentsDialogOpen] = useState(false);
  const [importStudentsDialogOpen, setImportStudentsDialogOpen] =
    useState(false);
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  const getEventToEdit = async (): Promise<IEvent> => {
    if (eventId) {
      setIsCreating(false);
      return EventService.getById(eventId);
    } else {
      setIsCreating(true);
      return EventViewerHelper.getDefault();
    }
  };

  const eventData = DataHooks.useFunctionCallState<IEvent>({
    func: getEventToEdit,
    deps: [eventId],
    showLoadingIndicator: true,
  });
  const event = eventData.data;

  const { data: canDelete } = DataHooks.useFunctionCallState<boolean>({
    func: () => (eventId ? EventService.canDelete(eventId) : false),
    deps: [eventId],
  });

  const updateEvent = (updatedEvent: IEvent) => {
    eventData.setData(updatedEvent);
    setHasChanges(true);
    if (haveTriedSubmitting) {
      const results = EventViewerHelper.validateEvent(updatedEvent);
      setErrorResults(results);
    }
  };

  const handleSubmit = async () => {
    if (event) {
      const errorResults = EventViewerHelper.validateEvent(event);
      const isValid = Object.values(errorResults).length === 0;
      setHaveTriedSubmitting(true);
      setErrorResults(errorResults);
      if (isValid) {
        LoadingBarService.show();
        try {
          setHasChanges(false);
          if (isCreating) {
            await EventService.create(event, school?.id, district?.id);
          } else {
            await EventService.update(event);
          }
          SnackbarService.success(
            (isCreating ? "Created " : "Updated ") + event.name
          );
          setHasChanges(false);
        } catch (error) {
          Logger.error(error);
          SnackbarService.error(error);
        }
        LoadingBarService.hide();
      }
    }
  };

  const handleStudentsSelected = (students: IStudentSimple[]): void => {
    if (event) {
      updateEvent({
        ...event,
        students,
      });
    }
  };

  return (
    <>
      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <AppGrid container justifyContent="center" spacing={3}>
        <AppGrid item xs={12} lg={7} xl={6} container spacing={3}>
          <AppGrid item xs={12}>
            <AppCard hasHeader>
              <AppCardHeader
                title="Event Info"
                icon={Icons.Info}
                palette={PaletteTypes.PRIMARY}
              />
              <AppCardContent>
                <AppEventInfo
                  event={event}
                  updateEvent={updateEvent}
                  errorResults={errorResults}
                />
              </AppCardContent>
            </AppCard>
          </AppGrid>
          <AppGrid item xs={12}>
            <AppCard hasHeader>
              <AppCardHeader
                title="Times & Dates"
                subheader="When should this event occur?"
                icon={Icons.Calendar}
                palette={PaletteTypes.PRIMARY}
              />
              <AppCardContent>
                <AppEventDates
                  event={event}
                  updateEvent={updateEvent}
                  errorResults={errorResults}
                />
              </AppCardContent>
            </AppCard>
          </AppGrid>
        </AppGrid>
        <AppGrid
          item
          xs={12}
          lg={5}
          xl={4}
          container
          justifyContent="flex-end"
          spacing={1}
        >
          {canEdit && (
            <AppButton
              text="Add Students"
              icon={Icons.Add}
              size="large"
              palette={PaletteTypes.SUCCESS}
              onClick={() => setAddStudentsDialogOpen(true)}
            />
          )}
          {canEdit && (
            <AppButton
              text="Import Students"
              icon={Icons.Upload}
              size="large"
              palette={PaletteTypes.SUCCESS}
              onClick={() => setImportStudentsDialogOpen(true)}
            />
          )}
          <AppGrid item xs={12}>
            <AppCard hasHeader>
              <AppCardHeader
                title={"Registered Students"}
                subheader={
                  "Which students should be pre-registered on event start?"
                }
                icon={Icons.Group}
                palette={PaletteTypes.PRIMARY}
              />
              <AppCardContent padding={0}>
                <AppEventRegisteredStudentsList
                  students={event?.students || null}
                  studentsSelected={handleStudentsSelected}
                />
              </AppCardContent>
            </AppCard>
          </AppGrid>
        </AppGrid>
      </AppGrid>
      {canEdit && (
        <AppActionBar
          leftActions={
            canDelete ? (
              <AppButton
                text={"Delete"}
                icon={Icons.Delete}
                size="large"
                palette={PaletteTypes.ERROR}
                onClick={() => {
                  setDeleteDialogOpen(true);
                }}
              />
            ) : (
              <></>
            )
          }
          rightActions={
            <>
              <AppButton
                text={"Cancel"}
                icon={Icons.Close}
                size="large"
                variant="outlined"
                palette={PaletteTypes.WARNING}
                linkTo="/events"
              />
              <AppButton
                text={isCreating ? "Create" : "Update"}
                icon={Icons.Save}
                size="large"
                palette={PaletteTypes.SUCCESS}
                onClick={handleSubmit}
                disabled={!hasChanges}
              />
            </>
          }
        />
      )}
      {event && addStudentsDialogOpen && (
        <AppEventAddStudentsPopup
          event={event}
          open={addStudentsDialogOpen}
          setOpen={setAddStudentsDialogOpen}
          studentsSelected={handleStudentsSelected}
        />
      )}
      {event && importStudentsDialogOpen && (
        <AppEventImportStudentsPopup
          event={event}
          open={importStudentsDialogOpen}
          setOpen={setImportStudentsDialogOpen}
          studentsSelected={handleStudentsSelected}
        />
      )}
      {event && deleteDialogOpen && (
        <AppEventDeleteDialog
          event={event}
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
        />
      )}
    </>
  );
};

export const AppEventViewer = withRouter(EventViewer);
