import React from "react";
import { useHistory } from "react-router";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { IBellSchedule } from "../../../../Shared/Models/Entities/bell-schedule.interface";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppBellScheduleList = (props: {
  bellSchedules: IBellSchedule[];
  loading: boolean;
  canEdit: boolean;
}): JSX.Element => {
  const history = useHistory();

  return (
    <AppCard hasHeader>
      <AppCardHeader
        title="Bell Schedules"
        palette={PaletteTypes.PRIMARY}
        icon={Icons.Bell}
        action={
          props.canEdit && (
            <AppButton
              text="Add Bell Schedule"
              icon={Icons.Add}
              palette={PaletteTypes.SUCCESS}
              onClick={() => history.push(`/bell-schedule`)}
            />
          )
        }
      />
      <AppCardContent padding={0}>
        <AppCustomTable
          loading={props.loading}
          columns={[
            {
              id: "name",
              label: "Name",
            },
            {
              id: "description",
              label: "Description",
            },
            {
              id: "edit",
              label: "",
            },
          ]}
          rows={props.bellSchedules.map((bellSchedule) => ({
            cells: [
              {
                value: bellSchedule.name,
              },
              {
                value: bellSchedule.description,
              },
              {
                value: (
                  <AppGrid container spacing={1} width={50}>
                    <AppButton
                      icon={props.canEdit ? Icons.Edit : Icons.Visibility}
                      palette={PaletteTypes.SECONDARY}
                      text={props.canEdit ? "Edit" : "View"}
                      onClick={() =>
                        history.push(`/bell-schedule/${bellSchedule.id}`)
                      }
                    />
                  </AppGrid>
                ),
              },
            ],
          }))}
        />
      </AppCardContent>
    </AppCard>
  );
};
