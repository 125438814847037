import React, { useState } from "react";
import { ISchoolUserSimple } from "../../../Shared/Models/Entities/school-user.interface";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { SchoolUserService } from "../../../Shared/Services/school-user.service";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppButton } from "../../../Shared/Components/Button";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppSchoolUserTable } from "./SchoolUserListTable/SchoolUserTable";
import { TablePaginationHelper } from "../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { AppSchoolUserListFilters } from "./SchoolUserListFilters/SchoolUserListFilters";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { IPaginationOptions } from "../../../Shared/Components/Tables/TablePagination/pagination-options.interface";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppSchoolUserDeactivateUsersDialog } from "./SchoolUserDeactivateUsersDialog/SchoolUserDeactivateUsersDialog";
import { AppSchoolUserUpdatePasswordsDialog } from "./SchoolUserUpdatePasswordsDialog/SchoolUserUpdatePasswordsDialog";

export interface ISchoolUserListFilters {
  searchKey?: string;
  inactive?: boolean;
  paginationOptions: IPaginationOptions;
}

export const AppSchoolUserList = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [filters, setFilters] = useState<ISchoolUserListFilters>({
    paginationOptions: TablePaginationHelper.getDefaultOptions(),
  });
  const areFiltersDefault = !district && !school && !filters.searchKey;
  const fixedFilters = {
    ...filters,
    districtId: district?.id,
    schoolId: school?.id,
  };

  const schoolUsersListState = DataHooks.useFunctionCallState<
    ISchoolUserSimple[]
  >({
    func: async () =>
      // Don't get data if our filters are empty
      areFiltersDefault ? null : SchoolUserService.getSimple(fixedFilters),
    deps: [filters, school?.id, district?.id],
    showLoadingIndicator: true,
  });

  const schoolUsersCountState = DataHooks.useFunctionCallState<number>({
    func: async (currData) =>
      // Return current count if possible
      currData
        ? currData
        : // Don't get data if our filters are empty
        areFiltersDefault
        ? null
        : await SchoolUserService.getCount(fixedFilters),
    deps: [filters],
  });

  return (
    <>
      <AppGrid container direction="column" spacing={2}>
        <AppGrid
          item
          container
          direction="row"
          wrap="wrap-reverse"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
        >
          <AppSchoolUserListFilters
            filters={filters}
            setFilters={(filters) => {
              // Invalidate count so we will re-fetch
              schoolUsersCountState.setData(null);
              setFilters({
                ...filters,
                // Reset pagination since we are viewing a new list
                paginationOptions: TablePaginationHelper.getDefaultOptions(),
              });
            }}
          />
          <AppGrid item container spacing={2} marginLeft="auto">
            <Actions
              schoolUsers={schoolUsersListState.data || []}
              showingInactive={!!filters.inactive}
              shouldRefresh={schoolUsersListState.refreshData}
            />
          </AppGrid>
        </AppGrid>
        <AppGrid item>
          <AppSchoolUserTable
            schoolUsers={schoolUsersListState.data}
            schoolUsersLoading={schoolUsersListState.loading}
            count={schoolUsersCountState.data}
            countLoading={schoolUsersCountState.loading}
            filters={filters}
            setFilters={setFilters}
            areFiltersDefault={areFiltersDefault}
            onUserUpdated={schoolUsersListState.refreshData}
          />
        </AppGrid>
      </AppGrid>
    </>
  );
};

const Actions = ({
  schoolUsers,
  showingInactive,
  shouldRefresh,
}: {
  schoolUsers: ISchoolUserSimple[];
  showingInactive: boolean;
  shouldRefresh: () => void;
}) => {
  const [deactivateUsersPopupOpen, setDeactivateUsersPopupOpen] =
    useState(false);
  const [updatePasswordsPopupOpen, setUpdatePasswordsPopupOpen] =
    useState(false);

  return (
    <>
      {hasPermission(AppPermissionsEnum.ROLES_GET) && (
        <AppButton
          text="Role Manager"
          icon={Icons.AssignmentInd}
          size="large"
          palette={PaletteTypes.PRIMARY}
          linkTo="/school-user-roles"
        />
      )}
      {hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT) && (
        <AppButton
          text="Deactivate Users"
          icon={Icons.AccountMultipleRemove}
          size="large"
          palette={PaletteTypes.ERROR}
          onClick={() => {
            setDeactivateUsersPopupOpen(true);
          }}
          disabled={!schoolUsers.length || showingInactive}
        />
      )}
      {hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT) && (
        <AppButton
          text="Update Passwords"
          icon={Icons.AccountKey}
          size="large"
          palette={PaletteTypes.SECONDARY}
          onClick={() => {
            setUpdatePasswordsPopupOpen(true);
          }}
          disabled={!schoolUsers.length}
        />
      )}
      {hasPermission(AppPermissionsEnum.IMPORT_SCHOOL_USERS) && (
        <AppButton
          text="Import School Users"
          icon={Icons.PersonAdd}
          size="large"
          palette={PaletteTypes.SUCCESS}
          linkTo="/school-users-import"
        />
      )}
      {hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT) && (
        <AppButton
          text="Create School User"
          icon={Icons.PersonAdd}
          size="large"
          palette={PaletteTypes.SUCCESS}
          linkTo="/school-users/create"
        />
      )}
      <AppSchoolUserDeactivateUsersDialog
        schoolUsers={schoolUsers}
        open={deactivateUsersPopupOpen}
        setOpen={setDeactivateUsersPopupOpen}
        onDelete={shouldRefresh}
      />
      <AppSchoolUserUpdatePasswordsDialog
        schoolUsers={schoolUsers}
        open={updatePasswordsPopupOpen}
        setOpen={setUpdatePasswordsPopupOpen}
      />
    </>
  );
};
