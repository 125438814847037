import React, { useState } from "react";
import { Prompt, useHistory } from "react-router";
import { AppActionBar } from "../../../Shared/Components/ActionBar";
import { AppButton } from "../../../Shared/Components/Button";
import { AppCard } from "../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../Shared/Components/Cards/CardContent";
import { AppGrid } from "../../../Shared/Components/Grid";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { AppLoadingContent } from "../../../Shared/Components/LoadingContent";
import { Logger } from "../../../Shared/Helpers/logger";
import { UrlHelper } from "../../../Shared/Helpers/url.helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { IBellSchedule } from "../../../Shared/Models/Entities/bell-schedule.interface";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { BellScheduleService } from "../../../Shared/Services/bell-schedule.service";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppBellScheduleDeleteDialog } from "./BellScheduleDeleteDialog/BellScheduleDeleteDialog";
import { AppBellScheduleInfo } from "./BellScheduleInfo/BellScheduleInfo";
import { AppPeriodEditorDialog } from "./PeriodEditorDialog/PeriodEditorDialog";
import { AppPeriodList } from "./PeriodList/PeriodList";

class BellScheduleViewerHelper {
  static getDefault(): IBellSchedule {
    return {
      id: -1,
      name: "",
      description: "",
      periods: [],
    };
  }
}

export const AppBellScheduleViewer = (): JSX.Element => {
  const history = useHistory();
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const bellScheduleId = UrlHelper.getParamNumber("id");
  const canEdit = hasPermission(AppPermissionsEnum.BELL_SCHEDULES_EDIT);
  const [selectedPeriodIndex, setSelectedPeriodIndex] = useState<number>(-1);
  const [createPeriod, setCreatePeriod] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const getBellScheduleToEdit = async (): Promise<IBellSchedule> => {
    if (bellScheduleId) {
      setIsCreating(false);
      return BellScheduleService.getById(bellScheduleId);
    } else {
      setIsCreating(true);
      return BellScheduleViewerHelper.getDefault();
    }
  };
  const {
    data: bellSchedule,
    setData: setBellSchedule,
    loading,
    refreshData: refreshBellSchedule,
  } = DataHooks.useFunctionCallState({
    func: getBellScheduleToEdit,
  });

  const handleSave = async () => {
    LoadingBarService.show();
    try {
      if (bellSchedule) {
        if (bellSchedule.name === "" || bellSchedule.description === "")
          throw Error("Please fill out required fields");
        if (bellSchedule.periods.length === 0)
          throw Error("Please add at least 1 period");
        if (isCreating) {
          const { id } = await BellScheduleService.create(
            bellSchedule,
            school.id
          );
          setHasChanges(false);
          history.push(`/bell-schedule/${id}`);
        } else {
          await BellScheduleService.update(bellSchedule);
        }
        LoadingBarService.hide();
        setHasChanges(false);
        setSaveAttempted(false);
      }
    } catch (error) {
      setSaveAttempted(true);
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  return (
    <>
      <AppGrid container spacing={3} direction="row">
        <Prompt
          when={hasChanges}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <AppGrid item xs={6} md={4} lg={4}>
          {loading ? (
            <AppCard>
              <AppCardContent padding={0} maxHeight="80vh" overflow="auto">
                <AppLoadingContent size={50} padding={50} />
              </AppCardContent>
            </AppCard>
          ) : (
            <AppBellScheduleInfo
              canEdit={canEdit}
              bellSchedule={bellSchedule as IBellSchedule}
              setBellSchedule={setBellSchedule}
              hasChanges={hasChanges}
              setHasChanges={setHasChanges}
              saveAttempted={saveAttempted}
            />
          )}
        </AppGrid>
        <AppGrid item xs={12} md={8} lg={8}>
          {loading ? (
            <AppCard>
              <AppCardContent padding={0} maxHeight="80vh" overflow="auto">
                <AppLoadingContent size={50} padding={50} />
              </AppCardContent>
            </AppCard>
          ) : (
            <AppPeriodList
              canEdit={canEdit}
              bellSchedule={bellSchedule as IBellSchedule}
              refreshBellSchedule={refreshBellSchedule}
              loading={loading}
              selectPeriod={setSelectedPeriodIndex}
              addPeriod={setCreatePeriod}
            />
          )}
        </AppGrid>
      </AppGrid>
      {selectedPeriodIndex !== -1 && (
        <AppPeriodEditorDialog
          open={selectedPeriodIndex !== -1}
          period={bellSchedule?.periods[selectedPeriodIndex]}
          onDataUpdated={async () => await refreshBellSchedule()}
          // eslint-disable-next-line
          // @ts-ignore
          setOpen={() => setSelectedPeriodIndex(-1)}
          handleSubmit={(updatedPeriod) => {
            const { periods } = bellSchedule as IBellSchedule;
            periods[selectedPeriodIndex] = {
              ...periods[selectedPeriodIndex],
              ...updatedPeriod,
            };
            if (bellSchedule) {
              setBellSchedule({
                ...bellSchedule,
                periods,
              });
            }
            setHasChanges(true);
            setSelectedPeriodIndex(-1);
          }}
          handleDelete={() => {
            const { periods } = bellSchedule as IBellSchedule;
            periods.splice(selectedPeriodIndex, 1);
            if (bellSchedule) {
              setBellSchedule({
                ...bellSchedule,
                periods,
              });
            }
            setHasChanges(true);
            setSelectedPeriodIndex(-1);
          }}
        />
      )}
      {createPeriod && (
        <AppPeriodEditorDialog
          open={createPeriod}
          onDataUpdated={async () => await refreshBellSchedule()}
          setOpen={setCreatePeriod}
          handleSubmit={(newPeriod) => {
            const { periods } = bellSchedule as IBellSchedule;

            // TODO find better way of adding partial
            periods.push(newPeriod);
            if (bellSchedule) {
              setBellSchedule({
                ...bellSchedule,
                periods,
              });
            }
            setHasChanges(true);
            setCreatePeriod(false);
          }}
        />
      )}
      {showDeleteDialog && bellSchedule && canEdit && (
        <AppBellScheduleDeleteDialog
          bellSchedule={bellSchedule}
          open={showDeleteDialog}
          setOpen={setShowDeleteDialog}
        />
      )}
      {canEdit && (
        <AppActionBar
          leftActions={
            <>
              {!isCreating && (
                <AppButton
                  text="Delete"
                  icon={Icons.Delete}
                  palette={PaletteTypes.ERROR}
                  onClick={() => setShowDeleteDialog(true)}
                />
              )}
            </>
          }
          rightActions={
            <AppButton
              text={isCreating ? "Create" : "Update"}
              icon={isCreating ? Icons.Add : Icons.Save}
              palette={PaletteTypes.SUCCESS}
              disabled={!hasChanges}
              onClick={handleSave}
            />
          }
        />
      )}
    </>
  );
};
