import {IStudentImport} from "../../../Shared/Models/Entities/student-import.interface";
import React, {useEffect, useState} from "react";
import {AppGrid} from "../../../Shared/Components/Grid";
import {StudentImportEditorPanelHelper} from "../StudentImportHelpers/StudentImportEditorPanel.helper";
import {AppFormCategory} from "../../../Shared/Components/FormCategory";
import {
    AdminUserSchoolDistrictSelector
} from "../../AdminUsers/AdminUserManager/AdminUserEditorPanel/AdminUserSchoolDistrictSelector/AdminUserSchoolDistrictSelector";
import {StoreHelper} from "../../../Shared/Reducers/store-helper";
import {AppSelectControl} from "../../../Shared/Components/Controls/SelectControl";
import {Icons} from "../../../Shared/Components/Icons/Icons";
import {PaletteTypes} from "../../../Shared/Themes/palette-types.enum";
import {AppFileInput} from "../../../Shared/Components/FileInput";
import {AppMultipleSelectControl} from "../../../Shared/Components/Controls/MultipleSelectControl";
import {AppButton} from "../../../Shared/Components/Button";
import {AppCSVValidator} from "../../../Shared/Components/Validators/CSVValidator/CSVValidator";
import {ICSVValidationCategory, IHeroColumn} from "../../../Shared/Models/Interfaces/csv.interface";
import {parse, ParseConfig} from "papaparse";
import {SnackbarService} from "../../../Shared/Services/snackbar.service";
import {AppSwitchControl} from "../../../Shared/Components/Controls/SwitchControl";
import {LoadingBarService} from "../../../Shared/Services/loading-bar.service";
import {StudentImportService} from "../../../Shared/Services/student-import.service";
import {AppTextFieldControl} from "../../../Shared/Components/Controls/TextFieldControl";
import {IStudentImportValidationResults} from "../StudentImportInterfaces/StudentImportValidationResults.interface";
import {IScheduleOptions} from "../StudentImportInterfaces/ScheduleOptions.interface";
import {StudentImportTypes} from "../StudentImportEnums/StudentImportTypes.enum";
import {StudentImportValidationErrors} from "../StudentImportEnums/StudentImportValidationErrors.enum";
import {StudentImportIntervals} from "../StudentImportEnums/StudentImportIntervals";
import {useHistory, useParams} from "react-router";

export const AppStudentImportEditorPanel = (): JSX.Element => {

    const {district, school} = StoreHelper.selector((state) => state.appConfig);
    const districtId = district?.id as number | null;
    const schoolId = school?.id as number | null;
    const id = Number(useParams<{id: string | undefined}>().id);
    const isCreating = isNaN(id);
    const [studentImport, setStudentImport] = useState<IStudentImport>(StudentImportEditorPanelHelper.newStudentImport());

    if (!isCreating) {
        useEffect(() => {
            (async () => {
                const si = await StudentImportService.getById(id);
                setStudentImport(si);
            })();
        }, [])
    }

    const [errorResults, setErrorResults] = useState<IStudentImportValidationResults>({isValid: true, errors: []});

    const [scheduleInterval, setScheduleInterval] = useState<IScheduleOptions>(
        {intervalUnit: StudentImportIntervals.NONE, intervalValues: []});

    const [mapping, setMapping] = useState<null | string[]>(studentImport?.mapping);
    const [csvSchema, setCSVSchema] = useState<null | ICSVValidationCategory[]>(null);

    const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);
    const history = useHistory();
    const handleInputChange = () => {
        studentImport.schedule = scheduleInterval.intervalUnit + "," + scheduleInterval.intervalValues.join(",");
        setStudentImport({...studentImport});
        if (haveTriedSubmitting) {
            const results = StudentImportEditorPanelHelper.validateStudentImport(studentImport);
            setErrorResults(results);
        }
    };

    const handleClose = () => {
        history.push("/student-imports");
    };

    const handleFileSelect = (file: Blob) => {
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = (event) => {
            const result = event.target?.result as string;
            if (result == null) return;
            setupSchema(result);
            studentImport.fileContent = btoa(result);
            handleInputChange();
        }

        reader.onerror = () => {
            errorResults.errors.push(StudentImportValidationErrors.FILE_INVALID);
            setErrorResults(errorResults);
        }
    };

    const handleSftpImport = async () => {
        studentImport.fileContent = btoa(await StudentImportService.getSftpFile(studentImport));
        handleInputChange();
    };

    const handleSubmit = async () => {
        setHaveTriedSubmitting(true);
        LoadingBarService.show();
        try {
            if (isCreating) {
                // The mapping control doesn't have an onChange right now. Setting it here.
                studentImport.mapping = mapping;
                studentImport.schedule = scheduleInterval.intervalUnit + "," + scheduleInterval.intervalValues.join(",");
                setStudentImport(studentImport);
            }
            const errorResults = StudentImportEditorPanelHelper.validateStudentImport(studentImport);
            setErrorResults(errorResults);
            if (errorResults.isValid && isCreating) {
                const res = await StudentImportService.create(studentImport);
                SnackbarService.success(`${res.importType} ${res.name} student import created`);
                handleClose();
            } else if (errorResults.isValid) {
                const res = await StudentImportService.update(studentImport)
                SnackbarService.success(`${res.importType} ${res.name} student import updated`);
                handleClose();
            } else {

                SnackbarService.error(errorResults.errors.map((error) => error.toString()).join(" "));
            }
        } catch (error) {
            SnackbarService.error(error);
        }
        LoadingBarService.hide();
    };

    const shouldShowFileInput = () => {
        const result = isCreating && studentImport.hasHeaders != null &&
            studentImport.fileContent == null && studentImport.importType != "" &&
            studentImport.importType != "SFTP";
        return result;
    }

    const shouldShowCsvMapper = () => {
        const result = isCreating && csvSchema && (mapping == null || mapping.length == 0)
        return result;
    }

    const STUDENT_COLUMNS: IHeroColumn[] = [
        {name: "SchoolId", mandatory: true, databaseField: "SchoolId"},
        {name: "Student ID", mandatory: true, databaseField: "ExternalId"},
        {name: "First Name", mandatory: true, databaseField: "FirstName"},
        {name: "Last Name", mandatory: true, databaseField: "LastName"},
        {name: "Grade", mandatory: true, databaseField: "Grade"},
        {name: "Middle Initial", mandatory: false, databaseField: "MiddleInitial"},
        {name: "BirthDate", mandatory: false, databaseField: "BirthDate"},
        {name: "Address", mandatory: false, databaseField: "Address"},
        {name: "City", mandatory: false, databaseField: "City"},
        {name: "State", mandatory: false, databaseField: "State"},
        {name: "Country", mandatory: false, databaseField: "Country"},
        {name: "Zip", mandatory: false, databaseField: "Zip"},
        {name: "Gender", mandatory: false, databaseField: "Gender"},
        {name: "Ethnic Group", mandatory: false, databaseField: "EthnicGroup"},
        {name: "Student Email", mandatory: false, databaseField: "StudentEmail"},
        {name: "Student Language", mandatory: false, databaseField: "StudentLanguage"},
        {name: "Teacher", mandatory: false, databaseField: "Teacher"},
        {name: "Home Phone", mandatory: false, databaseField: "HomePhone"},
        {name: "Work Phone", mandatory: false, databaseField: "WorkPhone"},
        {name: "Contact Phone", mandatory: false, databaseField: "ContactPhone"},
        {name: "Guardian", mandatory: false, databaseField: "Guardian"},
        {name: "Guardian Email", mandatory: false, databaseField: "GuardianEmail"},
        {name: "Emergency Contact", mandatory: false, databaseField: "EmergencyContact"},
        {name: "Notes", mandatory: false, databaseField: "Notes"},
        {name: "Homeroom", mandatory: false, databaseField: "Homeroom"},
        {name: "Active", mandatory: false, databaseField: "Active"},
        {name: "Send Letters Via Email", mandatory: false, databaseField: "SendLettersViaEmail"},
    ];

    const setupSchema = (fileContent: string) => {
        const result = fileContent;
        let index = 0;
        let count = 0;
        let sampleLines = "";
        const maxScan = fileContent.length;

        // this code will have errors if there is any newline in a quoted cell
        while (count < 2) {
            if (result.substr(index, 2) == "\r\n") {
                count += 1;
                index += 2;
            } else if (result.substr(index, 1) == "\n") {
                count += 1;
                index += 1;
            } else {
                index += 1;
            }
            if (count == 2) {
                sampleLines = result.substring(0, index);
            }
            if (index > maxScan) {
                SnackbarService.error(
                    "File must contain at least two student records!"
                );
                return;
            }
        }

        const config: ParseConfig = {
            delimiter: studentImport.columnDelimiter,
            newline: studentImport.rowDelimiter,
            quoteChar: studentImport.quoteCharacter,
        };
        const firstRows = parse(sampleLines, config).data as string[][];
        const mandatoryFields = STUDENT_COLUMNS.filter((column) => column.mandatory);
        if (firstRows[0].length < mandatoryFields.length) {
            SnackbarService.error(
                "Not enough fields to map to mandatory Hero fields, please upload a different file. The file is invalid, column separator is incorrect, or mapping is wrong."
            );
            return;
        }
        let headers: string[];
        if (studentImport.hasHeaders) {
            headers = firstRows[0];
        } else {
            headers = [];
            for (let ix = 0; ix < firstRows[0].length; ix++) {
                headers[ix] = ix.toString();
            }
        }
        // this if is here to support editing an existing map but needs work
        // as the student columns are still shown in the available hero column list
        if (mapping == null || mapping.length == 0) {
            setCSVSchema(headers.map((header, ix) => ({
                columnHeader: header,
                sampleData: studentImport.hasHeaders ? firstRows[1][ix] : firstRows[0][ix],
                heroField: {id: "", name: ""},
            })));
        } else {
            setCSVSchema(headers.map((header, ix) => {
                const mappedName = STUDENT_COLUMNS.find((col) => col.databaseField == mapping[ix])?.name as string;
                if (mappedName == undefined || mappedName == null) mappedName == "";
                return {
                    columnHeader: header,
                    sampleData: studentImport.hasHeaders ? firstRows[1][ix] : firstRows[0][ix],
                    heroField: {id: mapping[ix], name: mappedName},
                };
            }));
        }
    }

    if (studentImport.schoolId == null && studentImport.districtId == null) {
        studentImport.districtId = districtId;
        studentImport.schoolId = schoolId;
    }
    if (studentImport.fileContent != null && csvSchema == null) setupSchema(atob(studentImport.fileContent as string));

    return (
        <AppGrid container spacing={3}>
            <AppTextFieldControl
                label="Import Name"
                name="importName"
                value={studentImport.name}
                onChange={(event) => {
                    studentImport.name = event.target.value;
                    handleInputChange();
                }}
            />
            {isCreating && (<AdminUserSchoolDistrictSelector
                schoolId={schoolId}
                schoolGroupId={districtId}
                setSchoolId={(schoolId: number | null) => {
                    studentImport.schoolId = schoolId;
                    handleInputChange();
                }}
                setSchoolGroupId={(districtId: number | null) => {
                    studentImport.districtId = districtId;
                    handleInputChange();
                }}
                error={errorResults.errors.includes(StudentImportValidationErrors.REQUIRED_SCHOOL_OR_DISTRICT)}
                schoolGroupLabel="Upload for entire school group"
                schoolLabel="Upload for one school"

            />)}
            <AppFormCategory gridProps={{xs: 12}} title="Import details"/>
            {isCreating && (<AppSelectControl
                label="Column Separator"
                name="columnDelimiter"
                type="string"
                value={studentImport.columnDelimiter}
                selected={(value) => {
                    if (value) {
                        studentImport.columnDelimiter = value;
                        handleInputChange();
                    }
                }}
                options={[
                    {name: "comma", id: ','},
                    {name: "tab", id: '\t'},
                ]}
                style={{width: "140px"}}
            />)}
            {isCreating && (<AppSelectControl
                label="Quote Character"
                name="quoteCharacter"
                type="string"
                value={studentImport.quoteCharacter}
                selected={(value) => {
                    if (value) studentImport.quoteCharacter = value;
                    handleInputChange();
                }}
                options={[
                    {name: "double quote", id: '"'},
                    {name: "single quote", id: "'"},
                ]}
                style={{width: "160px"}}
            />)}
            <AppSelectControl
                label="Import Type"
                name="importType"
                type="string"
                value={studentImport.importType}
                selected={(value) => {
                    studentImport.importType = value;
                    scheduleInterval.intervalUnit = StudentImportIntervals.NONE
                    scheduleInterval.intervalValues = [] as string[];
                    setScheduleInterval(scheduleInterval);
                    handleInputChange();
                }}
                options={[
                    {name: "One time", id: StudentImportTypes.ONE_TIME},
                    {name: "SFTP scheduled", id: StudentImportTypes.SFTP},
                ]}
                style={{width: "220px"}}
                icon={Icons.Upload}
            />
            {studentImport.importType != null &&
                studentImport.importType == StudentImportTypes.SFTP.valueOf() &&
                (<>
                    <AppSelectControl
                        label="Interval"
                        name="scheduleInterval"
                        type="string"
                        value={scheduleInterval.intervalUnit?.valueOf()}
                        selected={(value) => {
                            if (value != null) scheduleInterval.intervalUnit = StudentImportEditorPanelHelper.intervalToEnum(value.toUpperCase());
                            switch(scheduleInterval.intervalUnit) {
                                case StudentImportIntervals.WEEKLY:
                                    scheduleInterval.intervalValues = ["M","Tu","W","Th","F"];
                                    break;
                                default:
                                    scheduleInterval.intervalValues = [];
                            }
                            setScheduleInterval(scheduleInterval);
                            handleInputChange();
                        }}
                        options={[
                            {name: StudentImportIntervals.DAILY.valueOf(), id: StudentImportIntervals.DAILY},
                            {name: StudentImportIntervals.WEEKLY.valueOf(), id: StudentImportIntervals.WEEKLY},
                            {name: StudentImportIntervals.MONTHLY.valueOf(), id: StudentImportIntervals.MONTHLY},
                        ]}
                        icon={Icons.Calendar}
                        style={{width: "160px"}}
                    />
                </>)}
            {scheduleInterval.intervalUnit != null &&
                scheduleInterval.intervalUnit == StudentImportIntervals.WEEKLY &&
                (<AppMultipleSelectControl
                    value={scheduleInterval.intervalValues}
                    onUpdate={(values) => {
                        scheduleInterval.intervalValues = values;
                        setScheduleInterval(scheduleInterval);
                        handleInputChange();
                    }}
                    label="Days"
                    name="scheduleDays"
                    options={[
                        {name: "Monday", id: "M"},
                        {name: "Tuesday", id: "Tu"},
                        {name: "Wednesday", id: "W"},
                        {name: "Thursday", id: "Th"},
                        {name: "Friday", id: "F"},
                        {name: "Saturday", id: "Sa"},
                        {name: "Sunday", id: "Su"}
                    ]}
                    icon={Icons.Calendar}
                    style={{width: "200px"}}
                    multiple
                />)}
            {scheduleInterval.intervalUnit != null &&
                scheduleInterval.intervalUnit == StudentImportIntervals.MONTHLY &&
                (<AppSelectControl
                    value={scheduleInterval.intervalValues[0]}
                    selected={(value) => {
                        if (value != null) {
                            scheduleInterval.intervalValues = [value];
                            setScheduleInterval(scheduleInterval);
                            handleInputChange();
                        }
                    }}
                    label="Day of month"
                    name="scheduleDayOfMonth"
                    options={StudentImportEditorPanelHelper.monthlyOptions()}
                    icon={Icons.Calendar}
                    style={{width: "120px"}}
                />)}
            <AppGrid container spacing={3} style={{marginBottom: "20px"}}>
                {studentImport.fileContent == null && (<AppSwitchControl
                    label="File has header row"
                    checked={!!studentImport.hasHeaders}
                    onChange={() => {
                        studentImport.hasHeaders = !studentImport.hasHeaders;
                        handleInputChange();
                    }}
                />)}
                {shouldShowFileInput() && (<AppFileInput
                    text="Upload file"
                    icon={Icons.Upload}
                    size="large"
                    variant="text"
                    palette={PaletteTypes.SUCCESS}
                    rawFile={true}
                    fileSelected={handleFileSelect}
                />)}
                {studentImport.importType == "SFTP" && (
                    <>
                    <AppSwitchControl
                        label="PGP Encrypted"
                        checked={!!studentImport.pgpEncrypted}
                        onChange={() => {
                            studentImport.pgpEncrypted = !studentImport.pgpEncrypted;
                            handleInputChange();
                        }}
                    />
                    <AppTextFieldControl
                        label="File Name"
                        name="fileName"
                        value={studentImport.uri}
                        onChange={(event) => {
                            studentImport.uri = event.target.value;
                            handleInputChange();
                        }}
                    />
                    {studentImport.uri != null && studentImport.fileContent == null && (<AppButton
                        text="Import"
                        icon={Icons.Close}
                        palette={PaletteTypes.SUCCESS}
                        variant="outlined"
                        onClick={() => handleSftpImport()}
                        style={{marginTop:"10px"}}
                    />)}
                    </>
                )}
            </AppGrid>
            <AppGrid container>
                {shouldShowCsvMapper() && (
                    <AppCSVValidator
                        csvValidationCategories={csvSchema as ICSVValidationCategory[]} // this can't be null here so casting
                        heroColumns={STUDENT_COLUMNS}
                        confirmValidation={setMapping}
                    />)}
            </AppGrid>
            <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
                <AppButton
                    text="Cancel"
                    icon={Icons.Close}
                    palette={PaletteTypes.WARNING}
                    variant="outlined"
                    onClick={() => handleClose()}
                />
                <AppButton
                    text="Save"
                    icon={Icons.Save}
                    palette={PaletteTypes.SUCCESS}
                    onClick={() => handleSubmit()}
                />
            </AppGrid>
        </AppGrid>
    )
}