import { HttpClient } from "../Helpers/http-client";
import { ISimpleItem } from "../Models/Entities/simple-item.interface";

export class SupportTierService {
  static async getSupportTiers(): Promise<ISimpleItem[]> {
    return HttpClient.get({
      path: "/support-tiers",
    });
  }
}
