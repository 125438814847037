import { StoreHelper } from "../Reducers/store-helper";

export const isSchoolUser = (): boolean => {
  return StoreHelper.selector((state) => state.auth.user.isSchoolUser);
};

export const isGlobalUser = (): boolean => {
  return StoreHelper.selector(
    (state) => !state.appConfig.school?.id && !state.appConfig.district?.id
  );
};
