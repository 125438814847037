import React, { Dispatch, SetStateAction } from "react";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { IBellSchedule } from "../../../../Shared/Models/Entities/bell-schedule.interface";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppBellScheduleInfo = (props: {
  bellSchedule: IBellSchedule;
  canEdit: boolean;
  setBellSchedule: (bellSchedule: IBellSchedule) => void;
  hasChanges: boolean;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
  saveAttempted: boolean;
}): JSX.Element => {
  return (
    <AppCard hasHeader>
      <AppCardHeader
        title="Bell Schedule Info"
        icon={Icons.Info}
        palette={PaletteTypes.PRIMARY}
      />
      <AppCardContent>
        <AppGrid
          container
          justifyContent="center"
          spacing={3}
          direction="column"
        >
          <AppTextFieldControl
            value={props.bellSchedule.name}
            label="Name"
            onChange={(event) => {
              const name = event.target.value;
              if (props.bellSchedule) {
                props.setBellSchedule({ ...props.bellSchedule, name });
                props.setHasChanges(true);
              }
            }}
            disabled={!props.canEdit}
            error={props.saveAttempted && props.bellSchedule.name === ""}
          />
          <AppTextFieldControl
            value={props.bellSchedule.description}
            label="Description"
            onChange={(event) => {
              const description = event.target.value;
              if (props.bellSchedule) {
                props.setBellSchedule({ ...props.bellSchedule, description });
                props.setHasChanges(true);
              }
            }}
            error={props.saveAttempted && props.bellSchedule.description === ""}
            disabled={!props.canEdit}
          />
        </AppGrid>
      </AppCardContent>
    </AppCard>
  );
};
