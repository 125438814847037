export interface IEventRecurrence {
  // Recurrence Type
  type: EventRecurrenceTypes;

  // Range (All Types)
  rangeType: EventRecurrenceRangeTypes;
  rangeStart: string;
  rangeEnd?: string;
  occurrences?: number;

  // Properties for specific types
  date?: string; // Once
  everyXDays?: number; // Daily
  weekdays?: EventRecurrenceDays[]; // Weekly
  dayOfMonth?: number; // Monthly & Yearly
  everyXMonths?: number; // Monthly
}

export enum EventRecurrenceTypes {
  ONCE = 0,
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
}

export enum EventRecurrenceRangeTypes {
  NO_END_DATE = 0,
  END_AFTER_OCCURRENCES = 1,
  END_BY_DATE = 2,
}

export enum EventRecurrenceDays {
  Sunday = "Sunday",
  Monday = "Monday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
  Thursday = "Thursday",
  Friday = "Friday",
  Saturday = "Saturday",
}
