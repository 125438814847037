import React, { useState } from "react";
import { ISchoolUser } from "../../../Shared/Models/Entities/school-user.interface";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { SchoolUserService } from "../../../Shared/Services/school-user.service";
import { UrlHelper } from "../../../Shared/Helpers/url.helper";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppCard } from "../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../Shared/Components/Cards/CardHeader";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppCardContent } from "../../../Shared/Components/Cards/CardContent";
import {
  ISchoolUserValidationResults,
  SchoolUserViewerHelper,
} from "./SchoolUserViewer.helper";
import { AppButton } from "../../../Shared/Components/Button";
import { AppActionBar } from "../../../Shared/Components/ActionBar";
import { AppSchoolUserInfoViewer } from "./SchoolUserInfoViewer/SchoolUserInfoViewer";
import { AppSchoolUserAccessEditor } from "./SchoolUserAccessEditor/SchoolUserAccessEditor";
import { AppSchoolUserPasswordEditor } from "./SchoolUserPasswordEditor/SchoolUserPasswordEditor";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { Logger } from "../../../Shared/Helpers/logger";
import { AppSchoolUserArchiveDialog } from "../SchoolUserArchiveDialog/SchoolUserArchiveDialog";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";

const SchoolUserViewer = (props: RouteComponentProps): JSX.Element => {
  const [isCreating, setIsCreating] = useState(false);
  const schoolUserId = UrlHelper.getParamNumber("id");
  const [errorResults, setErrorResults] = useState(
    {} as ISchoolUserValidationResults
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);

  const getSchoolUserToEdit = async (): Promise<ISchoolUser> => {
    if (schoolUserId) {
      setIsCreating(false);
      return SchoolUserService.getById(schoolUserId);
    } else {
      setIsCreating(true);
      return await SchoolUserViewerHelper.getDefault();
    }
  };

  const schoolUserData = DataHooks.useFunctionCallState<ISchoolUser>({
    func: getSchoolUserToEdit,
    deps: [schoolUserId],
    showLoadingIndicator: true,
  });
  const schoolUser = schoolUserData.data;

  const updateSchoolUser = (updatedSchoolUser: ISchoolUser) => {
    schoolUserData.setData(updatedSchoolUser);
    setHasChanges(true);
    if (haveTriedSubmitting) {
      const results =
        SchoolUserViewerHelper.validateSchoolUser(updatedSchoolUser);
      setErrorResults(results);
    }
  };

  const handleSubmit = async () => {
    if (schoolUser) {
      const errorResults =
        SchoolUserViewerHelper.validateSchoolUser(schoolUser);
      const isValid = Object.values(errorResults).length === 0;
      setHaveTriedSubmitting(true);
      setErrorResults(errorResults);
      if (isValid) {
        LoadingBarService.show();
        try {
          setHasChanges(false);
          if (isCreating) {
            await SchoolUserService.create(schoolUser);
          } else {
            await SchoolUserService.update(schoolUser);
          }
          SnackbarService.success(
            (isCreating ? "Created " : "Updated ") +
              schoolUser.firstName +
              " " +
              schoolUser.lastName
          );
          props.history.push("/school-users");
        } catch (error) {
          Logger.error(error);
          SnackbarService.error(error);
        }
        LoadingBarService.hide();
      }
    }
  };

  return (
    <>
      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <AppGrid container justifyContent="center" spacing={3}>
        <AppGrid
          item
          xs={12}
          lg={7}
          xl={6}
          container
          direction="column"
          spacing={1}
        >
          <AppGrid item>
            <AppCard hasHeader>
              <AppCardHeader
                title="User Info"
                icon={Icons.Info}
                palette={PaletteTypes.PRIMARY}
              />
              <AppCardContent>
                <AppSchoolUserInfoViewer
                  schoolUser={schoolUser}
                  updateSchoolUser={updateSchoolUser}
                  errorResults={errorResults}
                />
              </AppCardContent>
            </AppCard>
          </AppGrid>
        </AppGrid>
        <AppGrid
          item
          xs={12}
          lg={5}
          xl={4}
          container
          direction="column"
          spacing={3}
        >
          <AppGrid item>
            <AppCard hasHeader>
              <AppCardHeader
                title="User Access"
                icon={Icons.District}
                palette={PaletteTypes.PRIMARY}
              />
              <AppCardContent>
                <AppSchoolUserAccessEditor
                  schoolUser={schoolUser}
                  updateSchoolUser={updateSchoolUser}
                  errorResults={errorResults}
                />
              </AppCardContent>
            </AppCard>
          </AppGrid>
          {canEdit && (
            <AppGrid item>
              <AppCard hasHeader>
                <AppCardHeader
                  title={isCreating ? "Set Password" : "Password Reset"}
                  icon={Icons.Lock}
                  palette={PaletteTypes.ERROR}
                />
                <AppCardContent>
                  <AppSchoolUserPasswordEditor
                    schoolUser={schoolUser}
                    updateSchoolUser={updateSchoolUser}
                    errorResults={errorResults}
                  />
                </AppCardContent>
              </AppCard>
            </AppGrid>
          )}
        </AppGrid>
      </AppGrid>
      {canEdit && (
        <AppActionBar
          leftActions={
            <>
              {!isCreating && schoolUser && (
                <>
                  <AppButton
                    text={schoolUser.active ? "Archive" : "Restore"}
                    icon={schoolUser.active ? Icons.Delete : Icons.Refresh}
                    size="large"
                    palette={
                      schoolUser.active
                        ? PaletteTypes.ERROR
                        : PaletteTypes.SUCCESS
                    }
                    onClick={() => {
                      setArchiveDialogOpen(true);
                    }}
                  />
                </>
              )}
            </>
          }
          rightActions={
            <>
              {!isCreating && (
                <AppButton
                  text={"Cancel"}
                  icon={Icons.Close}
                  size="large"
                  variant="outlined"
                  palette={PaletteTypes.WARNING}
                  linkTo="/school-users"
                />
              )}
              <AppButton
                text={isCreating ? "Create" : "Update"}
                icon={Icons.Save}
                size="large"
                palette={PaletteTypes.SUCCESS}
                onClick={handleSubmit}
                disabled={!hasChanges}
              />
            </>
          }
        />
      )}
      <AppSchoolUserArchiveDialog
        schoolUser={schoolUser}
        open={archiveDialogOpen}
        setOpen={setArchiveDialogOpen}
        onUserUpdated={schoolUserData.refreshData}
      />
    </>
  );
};

export const AppSchoolUserViewer = withRouter(SchoolUserViewer);
