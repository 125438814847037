import React from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { ICSVValidationCategory } from "../../../../Models/Interfaces/csv.interface";
import { appMakeStyles } from "../../../../Themes/app-make-styles";
import { PaletteTypes } from "../../../../Themes/palette-types.enum";
import { AppCard } from "../../../Cards/Card";
import { AppCardContent } from "../../../Cards/CardContent";
import { AppCardHeader } from "../../../Cards/CardHeader";
import { AppGrid } from "../../../Grid";
import { Icons } from "../../../Icons/Icons";

const CSVValidationCAtegoryListItemStyles = appMakeStyles(() => ({
  iconStyles: {
    border: "1px solid red",
    borderRadius: "50%",
    color: "red",
    height: "15px",
    marginLeft: "5px",
    width: "auto",
    cursor: "pointer",
  },
  iconContainer: {
    textAlign: "center",
  },
}));

export const AppCSVValidationCategoryListItem = (props: {
  validationCategory: ICSVValidationCategory;
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDrop: (item: any) => void;
  heroColumnOptions: string[];
  removeHeroColumn: () => void;
}): JSX.Element => {
  const classes = CSVValidationCAtegoryListItemStyles(props);
  const [, drop] = useDrop(
    () => ({
      accept: props.heroColumnOptions.map((_, index) => index.toString()),
      drop(_item: { type: string }, monitor) {
        if (props.validationCategory.heroField.id === "")
          props.onDrop(monitor.getItemType());
        return undefined;
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggingColor: monitor.getItemType() as string,
      }),
    }),
    [props.onDrop]
  );

  return (
    <AppGrid item>
      <div ref={drop}>
        <AppCard hasHeader>
          <AppCardHeader
            title={`${props.index + 1} - ${
              props.validationCategory.columnHeader
            }`}
            palette={PaletteTypes.PRIMARY}
          />
          <AppCardContent>
            <AppGrid container direction="column">
              <AppGrid item>
                Sample Data: {props.validationCategory.sampleData}
              </AppGrid>
              <AppGrid item container direction="row">
                Hero Field: {props.validationCategory.heroField.name}{" "}
                <AppGrid item className={classes.iconContainer}>
                  {props.validationCategory.heroField.id !== "" && (
                    <Icons.Close
                      onClick={props.removeHeroColumn}
                      className={classes.iconStyles}
                    />
                  )}
                </AppGrid>
              </AppGrid>
            </AppGrid>
          </AppCardContent>
        </AppCard>
      </div>
    </AppGrid>
  );
};
