import React from "react";
import { Card as MuiCard, CardProps } from "@material-ui/core";
import { appMakeStyles } from "../../Themes/app-make-styles";

const CardStyles = appMakeStyles<ICardProps>(() => ({
  card: {
    display: "flex",
    flexDirection: "column",
    marginTop: (props) => (props.hasHeader ? "20px" : "0"),
    overflow: "visible",
    borderRadius: "6px",
  },
}));

export interface ICardProps extends CardProps {
  hasHeader?: boolean;
}

export const AppCard = (props: ICardProps): JSX.Element => {
  const { hasHeader, ...otherProps } = props;
  const classes = CardStyles({ hasHeader });
  return <MuiCard {...otherProps} className={classes.card} />;
};
