import { StoreHelper } from "../Reducers/store-helper";
import { SnackbarReducerActions } from "../Reducers/Snackbar/snackbar.reducer";
import { ValueHelper } from "../Helpers/value.helper";

/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
export class SnackbarService {
  public static success(message: string): void {
    StoreHelper.dispatch(
      SnackbarReducerActions.openSnackbar({
        message,
        severity: "success",
      })
    );
  }
  public static warn(message: string): void {
    StoreHelper.dispatch(
      SnackbarReducerActions.openSnackbar({
        message,
        severity: "warning",
      })
    );
  }

  public static error(error: any): void {
    StoreHelper.dispatch(
      SnackbarReducerActions.openSnackbar({
        message: SnackbarService.getErrorMessage(error),
        severity: "error",
      })
    );
  }

  private static getErrorMessage(error: any): string {
    if (ValueHelper.isString(error)) {
      return error;
    } else if (error?.response?.data?.toString()) {
      return error.response?.data.toString();
    } else if (error?.message) {
      return error.message;
    } else {
      return "Unknown Error Occurred!";
    }
  }
}
