export class ValueHelper {
  /**
   * Convert a piece of text into the raw text that can be compared with other strings
   * - When comparing names/emails/accountingId/internalId, there are some things we don't care about (ex: lowercase/uppercase)
   */
  public static getRawText(text: string | null): string {
    return (
      text?.toLowerCase()?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&") || ""
    );
  }

  public static matchText(textOne: string, textTwo: string): boolean {
    return ValueHelper.getRawText(textOne) === ValueHelper.getRawText(textTwo);
  }

  /**
   * Is this value a number? (Not null, NaN, undefined, true, false, '1', [], {}, or anything but number)
   */
  // eslint-disable-next-line
  public static isNumber(value: any) {
    return !isNaN(value) && typeof value === "number" && value !== Infinity;
  }

  // eslint-disable-next-line
  public static isString(s: any): boolean {
    return typeof s === "string" || s instanceof String ? true : false;
  }
  /**
   * Convert a string into a number. If not a valid number, returns null
   */
  // eslint-disable-next-line
  public static getNumberFromString<T = any>(
    value: string,
    defaultIfNotNumber = null
    // eslint-disable-next-line
  ): number | any {
    const num = Number(value);
    if (ValueHelper.isNumber(num) && value !== "") {
      return num;
    } else {
      return defaultIfNotNumber;
    }
  }
}
