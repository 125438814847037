import { CircularProgress, GridSpacing } from "@material-ui/core";
import React from "react";
import { AppGrid } from "./Grid";

export interface ILoadingContentInterface {
  size?: number;
  spacing?: GridSpacing;
  padding?: number;
  margin?: number;
}

export const AppLoadingContent = (
  props: ILoadingContentInterface
): JSX.Element => {
  return (
    <AppGrid
      container
      padding={props.padding}
      margin={props.margin}
      justifyContent="center"
    >
      <CircularProgress size={props.size || 100} />
    </AppGrid>
  );
};
