import React, { Dispatch, SetStateAction } from "react";
import { AppConfirmationDialog } from "../../../../Shared/Components/Dialogs/ConfirmationDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { IAdminUser } from "../../../../Shared/Models/Entities/admin-user.interface";
import { AdminUserService } from "../../../../Shared/Services/admin-user.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppAdminUserDeleteDialog = (props: {
  adminUser: IAdminUser;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const handleDeleteConfirmed = async () => {
    LoadingBarService.show();
    try {
      await AdminUserService.delete(props.adminUser);
      void props.onDataUpdated();
      SnackbarService.success(
        "Deleted " + props.adminUser.firstName + " " + props.adminUser.lastName
      );
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  return (
    <AppConfirmationDialog
      open={props.open}
      setOpen={props.setOpen}
      title="Delete User?"
      content="Are you sure you want to delete this user?"
      icon={Icons.Delete}
      agreeAction={handleDeleteConfirmed}
      palette={PaletteTypes.ERROR}
    />
  );
};
