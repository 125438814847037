import React from "react";
import { appMakeStyles } from "../Themes/app-make-styles";

const ActionBarStyles = appMakeStyles((theme) => ({
  actionBarContainer: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,

    zIndex: 100,
    background: theme.palette.background.default,
    borderTop: theme.palette.divider + " 1px solid",
  },
  actionBar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "4px",
  },
  leftActions: {
    display: "flex",
    padding: "3px",

    "& button": {
      margin: "3px",
    },
  },
  rightActions: {
    display: "flex",
    padding: "3px",

    "& button, & a": {
      margin: "3px",
    },
  },
}));

export const AppActionBar = (props: {
  leftActions?: JSX.Element;
  rightActions?: JSX.Element;
}): JSX.Element => {
  const classes = ActionBarStyles({});

  return (
    <div className={classes.actionBarContainer}>
      <div className={classes.actionBar}>
        <div className={classes.leftActions}>{props.leftActions}</div>
        <div className={classes.rightActions}>{props.rightActions}</div>
      </div>
    </div>
  );
};

export const AppActionBarDivider = (): JSX.Element => (
  <div
    style={{
      height: "44px",
      width: "1px",
      margin: "auto 10px",
      background: "rgba(0, 0, 0, .12)",
    }}
  />
);
