import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { GridTypeMap } from "@material-ui/core/Grid/Grid";
import { AppGrid } from "../Grid";
import { appMakeStyles } from "../../Themes/app-make-styles";

const DateControlStyles = appMakeStyles<IDateControlProps>((theme) => ({
  datePicker: {
    background: (props) =>
      props.disabled ? "transparent" : theme.palette.background.editableInput,
    color: theme.palette.text.primary + " !important",
    borderColor: theme.palette.divider,

    "& label": {
      color: theme.palette.text.disabled + " !important",
    },
    "& fieldset": {
      color: "inherit !important",
      borderColor: "inherit !important",
    },
    "& svg": {
      flexBasis: "24px",
      flexShrink: 0,
      color: "inherit !important",
    },
    button: {
      color: "inherit !important",
    },

    "& .MuiInputBase-root": {
      paddingRight: 0,
      borderColor: theme.palette.divider,
      cursor: "pointer",

      "&.Mui-disabled": {
        color: theme.palette.text.secondary,
        borderColor: theme.palette.divider,

        "& .MuiIconButton-root": {
          display: "none",
        },
      },

      "&.Mui-error": {
        color: theme.palette.text.primary,
        borderColor: theme.palette.error.outline,
      },

      "&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)": {
        borderColor: theme.palette.primary.outline,
        color: theme.palette.text.primary,
      },
    },
  },
}));

export interface IDateControlProps {
  label: string;
  value: Date | string | null;
  onChange: (date: Date | null) => void;
  error: boolean;
  gridProps?: Partial<GridTypeMap["props"]>;
  disabled?: boolean;
}

export const AppDateControl = (props: IDateControlProps): JSX.Element => {
  const classes = DateControlStyles(props);
  const { label, value, onChange, error, gridProps, disabled } = props;

  const date =
    value instanceof Date ? value : value ? new Date(value) || null : null;

  return (
    <AppGrid item {...gridProps}>
      <FormControl fullWidth error={error} variant="outlined">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            className={classes.datePicker}
            error={error}
            label={label}
            format="MM/dd/yyyy"
            value={date}
            onChange={(newDate) => {
              if (newDate?.toString() !== date?.toString()) {
                onChange(newDate);
              }
            }}
            fullWidth
            inputVariant="outlined"
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    </AppGrid>
  );
};
