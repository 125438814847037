import React, { useState } from "react";
import { IAdminUser } from "../../../../Shared/Models/Entities/admin-user.interface";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppAdminUserEditDialog } from "../AdminUserEditDialog/AdminUserEditDialog";
import { AppAdminUserDeleteDialog } from "../AdminUserDeleteDialog/AdminUserDeleteDialog";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { MediaQueryHooks } from "../../../../Shared/Hooks/media-query-hooks";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppTablePagination } from "../../../../Shared/Components/Tables/TablePagination/TablePagination";
import { IPaginationOptions } from "../../../../Shared/Components/Tables/TablePagination/pagination-options.interface";

export const AppAdminUserTable = (props: {
  count: number | null;
  countLoading: boolean;
  paginationOptions: IPaginationOptions;
  setPaginationOptions: (options: IPaginationOptions) => void;
  loading: boolean;
  adminUsers: IAdminUser[];
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null as IAdminUser | null);

  const allPermissionKeys = Object.values(AppPermissionsEnum);
  const getPermissionCount = (user: IAdminUser): number =>
    user.permissions.filter((permissionKey) =>
      allPermissionKeys.includes(permissionKey)
    )?.length;

  const isDesktop = MediaQueryHooks.isDesktop();
  const canEdit = hasPermission(AppPermissionsEnum.ADMIN_USERS_EDIT);

  const Table = () => (
    <AppCustomTable
      loading={props.loading}
      columns={[
        {
          id: "super-admin",
          label: "",
          width: 45,
        },
        {
          id: "first",
          label: "First",
        },
        {
          id: "last",
          label: "Last",
        },
        {
          id: "email",
          label: "Email",
        },
        {
          id: "permission",
          label: "Permission",
        },
        {
          id: "action",
          label: "Actions",
          width: isDesktop ? 250 : 170,
        },
      ]}
      rows={props.adminUsers.map((user) => ({
        cells: [
          {
            value: user.permissions.includes(AppPermissionsEnum.SUPER_ADMIN) ? (
              <Icons.Star style={{ color: "#FEC200" }} />
            ) : (
              <></>
            ),
          },
          {
            value: user.firstName,
          },
          {
            value: user.lastName,
          },
          {
            value: user.email,
          },
          {
            value: getPermissionCount(user),
          },
          {
            value: canEdit ? (
              <AppGrid container spacing={1}>
                <AppButton
                  text={
                    isDesktop
                      ? hasPermission(AppPermissionsEnum.ADMIN_USERS_EDIT)
                        ? "Edit"
                        : "View"
                      : undefined
                  }
                  icon={Icons.Edit}
                  palette={PaletteTypes.SECONDARY}
                  onClick={() => {
                    setSelectedUser(user);
                    setEditDialogOpen(true);
                  }}
                />
                {hasPermission(AppPermissionsEnum.ADMIN_USERS_EDIT) && (
                  <AppButton
                    text={isDesktop ? "Archive" : undefined}
                    icon={Icons.Delete}
                    palette={PaletteTypes.ERROR}
                    onClick={() => {
                      setSelectedUser(user);
                      setDeleteDialogOpen(true);
                    }}
                  />
                )}
              </AppGrid>
            ) : (
              ""
            ),
          },
        ],
      }))}
    />
  );

  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="Admin Users"
          subheader="Schoolmint employees who should be able to perform actions or view data for all of Hero"
          icon={Icons.VerifiedUser}
          palette={PaletteTypes.PRIMARY}
        />
        <Table />
        <AppTablePagination
          count={props.count}
          countLoading={props.countLoading}
          paginationOptions={props.paginationOptions}
          paginationOptionsUpdated={(newOptions) => {
            props.setPaginationOptions(newOptions);
          }}
        />
      </AppCard>
      {selectedUser && (
        <>
          <AppAdminUserEditDialog
            adminUser={selectedUser as IAdminUser}
            open={editDialogOpen}
            setOpen={setEditDialogOpen}
            onDataUpdated={props.onDataUpdated}
          />
          <AppAdminUserDeleteDialog
            adminUser={selectedUser as IAdminUser}
            open={deleteDialogOpen}
            setOpen={setDeleteDialogOpen}
            onDataUpdated={props.onDataUpdated}
          />
        </>
      )}
    </>
  );
};
