import React from "react";
import { appMakeStyles } from "../Themes/app-make-styles";
import { AppGrid } from "./Grid";
import { GridTypeMap } from "@material-ui/core/Grid/Grid";

const CategoryControlStyles = appMakeStyles((theme) => ({
  category: {
    margin: "0 0 0",
    color: theme.palette.text.disabled,
    fontSize: theme.typography.largeFontSize,
  },
}));

export const AppFormCategory = (props: {
  title: string;
  gridProps?: Partial<GridTypeMap["props"]>;
}): JSX.Element => {
  const styles = CategoryControlStyles({});

  const { title, gridProps } = props;

  return (
    <AppGrid item {...gridProps}>
      <h3 className={styles.category}>{title}</h3>
    </AppGrid>
  );
};
