import React, { Dispatch, SetStateAction } from "react";
import { PaletteTypes } from "../../Themes/palette-types.enum";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { AppCard } from "../Cards/Card";
import { AppCardHeader } from "../Cards/CardHeader";
import { AppCardContent } from "../Cards/CardContent";
import { AppIcon } from "../Icons/Icons";

export interface ICustomDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  subHeader?: string;
  icon: AppIcon;
  palette: PaletteTypes;
  children: React.ReactNode;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  padding?: number;
  action?: React.ReactNode;
  onClose?: () => void;
}

export const AppCustomDialog = (props: ICustomDialogProps): JSX.Element => {
  const handleClose = () => {
    props.onClose && props.onClose();
    props.setOpen(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="custom-dialog"
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      hideBackdrop={true}
    >
      <DialogContent>
        <AppCard hasHeader>
          <AppCardHeader
            title={props.title}
            subheader={props.subHeader}
            icon={props.icon}
            palette={props.palette}
            action={props.action}
          />
          <AppCardContent padding={props.padding}>
            {props.children}
          </AppCardContent>
        </AppCard>
      </DialogContent>
    </Dialog>
  );
};
