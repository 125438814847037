import React, { useEffect, useState } from "react";
import { AuthenticationService } from "../../Shared/Services/authentication.service";
import { RouteComponentProps } from "react-router";
import { AppButton } from "../../Shared/Components/Button";
import { PaletteTypes } from "../../Shared/Themes/palette-types.enum";
import { AppGrid } from "../../Shared/Components/Grid";
import { Icons } from "../../Shared/Components/Icons/Icons";
import { AppCard } from "../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../Shared/Components/Cards/CardContent";
import { AppTextFieldControl } from "../../Shared/Components/Controls/TextFieldControl";
import { AppDivider } from "../../Shared/Components/Divider";
import HeroLogoDark from "../../Shared/Assets/Images/hero-logo-dark.png";
import { GoogleAuthTokenHandler } from "./google-auth-token-handler";
import { BasicLoginHandler } from "./basic-login-handler";
import { SnackbarService } from "../../Shared/Services/snackbar.service";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import { LoadingBarService } from "../../Shared/Services/loading-bar.service";
import { AuthStyles } from "../AuthStyles";

export const AppLogin = (props: RouteComponentProps): JSX.Element => {
  const theme = StoreHelper.selector((state) => state.appConfig.theme);
  const classes = AuthStyles({ theme });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const goToLoginPage = async () => {
    LoadingBarService.show();
    try {
      const { loginUrl } = await AuthenticationService.getLoginUrl();
      window.location.href = loginUrl;
    } catch (error) {
      LoadingBarService.hide();
      SnackbarService.error(error);
    }
  };

  useEffect(() => {
    void GoogleAuthTokenHandler.checkGoogleAuthTokenInQueryParams(props);
  }, [location, history]);

  const handleSubmit = () => {
    if (!email || !password)
      SnackbarService.error(
        "Please enter a email AND a password to log in as a school user!"
      );

    LoadingBarService.show();
    try {
      void BasicLoginHandler.handleBasicLogin(email, password);
    } catch (error) {
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  React.useEffect(() => {
    document.title = "Hero Admin | Login";
  }, []);

  return (
    <AppGrid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.authContainer}
    >
      <AppGrid item className={classes.loginBox}>
        <AppCard>
          <AppCardContent padding={0}>
            <AppGrid item container direction="column" spacing={2} padding={15}>
              <AppGrid item container justifyContent="center">
                <img src={HeroLogoDark} alt="Hero" height="60px" width="auto" />
              </AppGrid>

              <AppGrid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.helpText}
              >
                <div className={classes.helpTitle}>
                  Welcome to the Admin Portal!
                </div>
                <div className={classes.helpDescription}>
                  A work in progress app currently for internal use only
                </div>
              </AppGrid>

              <AppTextFieldControl
                label="Email"
                value={email}
                icon={Icons.Person}
                onChange={(event) => {
                  setEmail(event.target.value || "");
                }}
              />

              <AppTextFieldControl
                label="Password"
                value={password}
                icon={Icons.Lock}
                onChange={(event) => {
                  setPassword(event.target.value || "");
                }}
                type="password"
              />

              <AppButton
                gridProps={{ width: "100%" }}
                fullWidth
                text="Login"
                icon={Icons.Login}
                palette={PaletteTypes.PRIMARY}
                onClick={() => handleSubmit()}
              />

              <AppButton
                gridProps={{ width: "100%" }}
                fullWidth
                text="Forgot Password"
                palette={PaletteTypes.PRIMARY}
                variant="outlined"
                linkTo="/request-password-reset"
              />
            </AppGrid>

            <AppDivider />

            <AppGrid item container padding={15}>
              <AppButton
                gridProps={{ width: "100%" }}
                fullWidth
                text="Schoolmint Admin Login"
                icon={Icons.VerifiedUser}
                palette={PaletteTypes.SECONDARY}
                onClick={async () => goToLoginPage()}
              />
            </AppGrid>
          </AppCardContent>
        </AppCard>
      </AppGrid>
    </AppGrid>
  );
};
