import {
  TypedUseSelectorHook,
  useSelector as reduxUseSelector,
} from "react-redux";
import { appStore } from "./app-store";

type AppState = ReturnType<typeof appStore.getState>;

export class StoreHelper {
  static get dispatch(): typeof appStore.dispatch {
    return appStore.dispatch;
  }
  static selector: TypedUseSelectorHook<AppState> = reduxUseSelector;
}
