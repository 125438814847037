import React, { Dispatch, SetStateAction, useState } from "react";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { Utils } from "../../../../Shared/Helpers/utils";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppButton } from "../../../../Shared/Components/Button";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { InterventionEditorPanelHelper } from "./InterventionEditorPanel.helper";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { IIntervention } from "../../../../Shared/Models/Entities/interventions.interface";
import { IInterventionValidationResults } from "./InterventionEditorPanel.helper";
import { InterventionService } from "../../../../Shared/Services/intervention.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { useHistory } from "react-router";

export const AppInterventionEditorPanel = (props: {
  intervention: IIntervention;
  isCreating: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  isCloning: boolean;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const history = useHistory();
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const [intervention, setIntervention] = useState(
    Utils.cloneObject(
      props.isCloning ? { ...props.intervention, name: "" } : props.intervention
    )
  );
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);
  const [errorResults, setErrorResults] = useState(
    {} as IInterventionValidationResults
  );
  const handleInputChange = () => {
    setIntervention({ ...intervention });
    if (haveTriedSubmitting) {
      const results =
        InterventionEditorPanelHelper.validateIntervention(intervention);
      setErrorResults(results);
    }
  };
  const handleClose = () => {
    props.setOpen?.(false);
  };

  async function handleSubmit(): Promise<void> {
    const errorResults =
      InterventionEditorPanelHelper.validateIntervention(intervention);
    const isValid = Object.values(errorResults).length === 0;
    setHaveTriedSubmitting(true);
    setErrorResults(errorResults);
    if (isValid) {
      LoadingBarService.show();
      try {
        if (props.isCreating || props.isCloning) {
          await InterventionService.create(school.id, intervention);
          history.push(`/interventions/${school.id}`);
        } else {
          await InterventionService.update(intervention);
          history.push(`/interventions/${school.id}`);
        }
        props.onDataUpdated();
        SnackbarService.success(
          (props.isCreating ? "Created " : "Updated ") + intervention.name
        );
        handleClose();
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    } else {
      SnackbarService.error(errorResults.name as string);
    }
  }

  async function handleDelete(): Promise<void> {
    LoadingBarService.show();
    try {
      await InterventionService.delete(intervention.id);
      history.push(`/interventions/${school.id}`);
      props.onDataUpdated();
      SnackbarService.success(`Deleted ${intervention.name}`);
      handleClose();
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  }
  return (
    <>
      <AppGrid item container spacing={3}>
        <AppFormCategory gridProps={{ xs: 12 }} title="Intervention Info" />
        <AppTextFieldControl
          gridProps={{ xs: 4 }}
          label="Name"
          name="name"
          value={props.isCloning ? null : intervention.name}
          onChange={(event) => {
            intervention.name = event.target.value;
            handleInputChange();
          }}
          error={!!errorResults.name}
          icon={Icons.AccountBox}
          opaqueBackground
        />

        <AppTextFieldControl
          gridProps={{ xs: 9 }}
          label="Description"
          name="description"
          value={intervention.description}
          multiline
          minRows={4}
          maxRows={20}
          onChange={(event) => {
            intervention.description = event.target.value;
            handleInputChange();
          }}
          icon={Icons.Edit}
          opaqueBackground
        />
        <AppGrid xs={12} item container direction="row">
          <AppGrid xs={6} item container justifyContent="flex-start">
            {!props.isCreating && !props.isCloning && (
              <AppButton
                text="Delete"
                icon={Icons.Delete}
                palette={PaletteTypes.ERROR}
                variant="outlined"
                onClick={handleDelete}
              />
            )}
          </AppGrid>

          <AppGrid xs={6} item container spacing={1} justifyContent="flex-end">
            <AppButton
              text="Cancel"
              icon={Icons.Close}
              palette={PaletteTypes.WARNING}
              variant="outlined"
              onClick={handleClose}
            />
            <AppButton
              text="Save"
              icon={props.isCreating ? Icons.Add : Icons.Check}
              palette={PaletteTypes.SUCCESS}
              onClick={() => {
                handleSubmit();
              }}
            />
          </AppGrid>
        </AppGrid>
      </AppGrid>
    </>
  );
};
