import { IPalette } from "./theme.interface";
import Background from "../Assets/Images/subtle_white_mini_waves.png";

export const LightPalette = {
  // Text & Background
  text: {
    primary: "rgba(0, 0, 0, 0.9)",
    secondary: "rgba(0, 0, 0, 0.7)",
    disabled: "rgba(0, 0, 0, 0.5)",
    hint: "rgba(0, 0, 0, 0.5)",
  },
  background: {
    default: `url(${Background})`,
    paper: "rgb(251, 251, 252)",
    paperHover: "rgba(240, 240, 240)",
    paperAlternate: "rgb(255, 255, 255)",
    loadingTint: "rgba(0, 0, 0, .12)",
    editableInput: "rgb(255, 255, 255)",
  },
  divider: "rgba(0, 0, 0, 0.12)",
  errorDivider: "rgb(140, 22, 22)",

  // Default Colors
  // This is the header color for tables/forms
  primary: {
    main: "rgb(37,67,82)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(37,67,82)",
  },
  // This is the theme for navigation and generally opening things that open elsewhere
  secondary: {
    main: "#2686C3",
    contrastText: "rgb(255, 255, 255)",
    outline: "#2686C3",
  },

  // State Colors
  // Creating or Saving Items
  success: {
    main: "#38a169",
    contrastText: "rgb(255, 255, 255)",
    outline: "#38a169",
  },
  // Updating, Editing, Moving, Modifying items
  info: {
    main: "#2686C3",
    contrastText: "rgb(255, 255, 255)",
    outline: "#2686C3",
  },
  // Currently unusued. If you are confirming something, you should use the color of the action you are performing
  warning: {
    main: "rgb(255, 103, 20)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(255, 103, 20)",
  },
  // Deleting + Archiving Items
  error: {
    main: "rgb(200, 46, 46)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(200, 46, 46)",
  },

  // Misc Colors
  navigation: {
    background: "rgb(197, 57, 15)",
    item: "rgba(255, 255, 255, .0)",
    selected: "rgba(0, 0, 0, .08)",
    border: "1px solid rgba(0, 0, 0, .15)",
    text: "rgba(255, 255, 255, 1) !important",
    disabledText: "rgba(255, 255, 255, .9) !important",
    accent: "rgba(255, 255, 255, .0)",
  },
} as IPalette;
