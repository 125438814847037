import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../../../Shared/Components/Dialogs/CustomDialog";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";
import { IPopulatedTracCode } from "../../../../../Shared/Models/Entities/populated-trac-code.interface";
import { ITracCodeIntervention } from "../../../../../Shared/Models/Entities/trac-code-intervention.interface";
import { PaletteTypes } from "../../../../../Shared/Themes/palette-types.enum";
import { AppTracCodeInterventionEditorPanel } from "../TracCodeInterventionEditorPanel/TracCodeInterventionEditorPanel";

export const AppTracCodeInterventionEditDialog = (props: {
  tracCode: IPopulatedTracCode;
  tcIntervention: ITracCodeIntervention;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => void;
}): JSX.Element => {
  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title={`Edit Intervention to ${props.tracCode.name}`}
      icon={Icons.Edit}
      palette={PaletteTypes.SECONDARY}
    >
      <AppTracCodeInterventionEditorPanel
        tracCode={props.tracCode}
        tcIntervention={props.tcIntervention}
        isCreating={false}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
