import React, { useState } from "react";
import { useHistory } from "react-router";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { IPopulatedTracCode } from "../../../../Shared/Models/Entities/populated-trac-code.interface";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { AppTracCodeInterventionCreateDialog } from "./TracCodeInterventionCreateDialog/TracCodeInterventionCreateDialog";
import { AppTracCodeInterventionEditDialog } from "./TracCodeInterventionEditDialog/TracCodeInterventionEditDialog";
import { ITracCodeIntervention } from "../../../../Shared/Models/Entities/trac-code-intervention.interface";

export const AppTracCodeInterventions = (props: {
  tracCode: IPopulatedTracCode;
  refreshTracCode: () => void;
  setTracCode: (tracCode: IPopulatedTracCode) => void;
  canEdit: boolean;
}): JSX.Element => {
  const history = useHistory();
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedTracCodeIntervention, setSelectedTracCodeIntervention] =
    useState(null as ITracCodeIntervention | null);
  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="Interventions"
          palette={PaletteTypes.PRIMARY}
          icon={Icons.Info}
          action={
            props.canEdit && (
              <AppGrid item container spacing={2}>
                <AppButton
                  text="Manage Interventions"
                  palette={PaletteTypes.SECONDARY}
                  onClick={() => history.push(`/interventions/${school.id}`)}
                />
                <AppButton
                  text="Add"
                  icon={Icons.Add}
                  palette={PaletteTypes.SUCCESS}
                  onClick={() => {
                    setCreateDialogOpen(true);
                  }}
                />
              </AppGrid>
            )
          }
        />
        <AppCardContent padding={0}>
          <AppCustomTable
            columns={[
              {
                id: "name",
                label: "Name",
              },
              {
                id: "description",
                label: "Description",
              },
              {
                id: "trigger",
                label: "Trigger At",
              },
              ...(props.canEdit
                ? [
                    {
                      id: "edit",
                      label: "",
                    },
                  ]
                : []),
            ]}
            rows={props.tracCode.tracCodeInterventions?.map((intervention) => ({
              cells: [
                {
                  value: intervention.name,
                },
                {
                  value: intervention.description,
                },
                {
                  value: intervention.tracCountTrigger,
                },
                ...(props.canEdit
                  ? [
                      {
                        value: (
                          <AppGrid container spacing={1}>
                            <AppButton
                              icon={Icons.Edit}
                              palette={PaletteTypes.SECONDARY}
                              onClick={() => {
                                setSelectedTracCodeIntervention(intervention);
                                setEditDialogOpen(true);
                              }}
                            />
                          </AppGrid>
                        ),
                      },
                    ]
                  : []),
              ],
            }))}
          />
        </AppCardContent>
      </AppCard>
      {createDialogOpen && (
        <AppTracCodeInterventionCreateDialog
          tracCode={props.tracCode}
          open={createDialogOpen}
          setOpen={setCreateDialogOpen}
          onDataUpdated={props.refreshTracCode}
        />
      )}
      {selectedTracCodeIntervention && (
        <AppTracCodeInterventionEditDialog
          tracCode={props.tracCode}
          tcIntervention={selectedTracCodeIntervention as ITracCodeIntervention}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          onDataUpdated={props.refreshTracCode}
        />
      )}
    </>
  );
};
