import React, { useEffect, useState } from "react";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { SchoolUserPermissionService } from "../../../../Shared/Services/school-user-permission.service";
import { ISchoolUserPermission } from "../../../../Shared/Models/Entities/school-user-permission.interface";
import {
  IPermissionTreeNode,
  SchoolUserPermissionSelectorHelper,
} from "./school-user-permission-selector.helper";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppLoadingContent } from "../../../../Shared/Components/LoadingContent";
import { SchoolUserPermissionSelectorStyles } from "./SchoolUserPermissionSelector.styles";
import { AppButton } from "../../../../Shared/Components/Button";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppSchoolUserPermissionSelector = ({
  selectedPermissionIds,
  selectedPermissionIdsUpdated,
  loading,
  disabled,
}: {
  selectedPermissionIds: number[];
  selectedPermissionIdsUpdated: (permissionIds: number[]) => void;
  loading: boolean;
  disabled: boolean;
}): JSX.Element => {
  // Get selectedPermissionIds
  const classes = SchoolUserPermissionSelectorStyles({ disabled });

  const privilegesArrState = DataHooks.useFunctionCallState<
    ISchoolUserPermission[]
  >({
    func: SchoolUserPermissionService.getAll,
  });

  const rootTreeNodes =
    SchoolUserPermissionSelectorHelper.buildNestedTreeFromPermissionArr(
      privilegesArrState.data
    );

  const [expandedIds, setExpandedIds] = useState<number[]>([]);
  useEffect(() => {
    setExpandedIds(
      privilegesArrState.data?.map((permission) => permission.id) || []
    );
  }, [privilegesArrState.data?.length]);

  const handleExpand = (treeNode: IPermissionTreeNode): void => {
    setExpandedIds(expandedIds.concat(treeNode.id));
  };
  const handleCollapse = (treeNode: IPermissionTreeNode): void => {
    setExpandedIds(expandedIds.filter((id) => treeNode.id !== id));
  };

  const handleSelect = (treeNode: IPermissionTreeNode): void => {
    const updatedPermissionIds =
      SchoolUserPermissionSelectorHelper.handleTreeNodeSelected(
        treeNode,
        selectedPermissionIds
      );
    selectedPermissionIdsUpdated(updatedPermissionIds);
  };

  const handleSelectAll = (): void => {
    const allPrivilegeIds = (privilegesArrState.data || []).map((p) => p.id);
    selectedPermissionIdsUpdated(allPrivilegeIds);
  };

  const handleClear = (): void => {
    selectedPermissionIdsUpdated([]);
  };

  if (loading || privilegesArrState.loading) {
    return <AppLoadingContent />;
  }

  return (
    <div className={classes.permissionTree}>
      <div className={classes.treeActions}>
        {!disabled && (
          <AppButton
            className={classes.treeAction}
            text="Select All"
            palette={PaletteTypes.PRIMARY}
            size="small"
            variant="outlined"
            onClick={handleSelectAll}
          />
        )}
        {!disabled && (
          <AppButton
            className={classes.treeAction}
            text="Clear"
            palette={PaletteTypes.PRIMARY}
            size="small"
            variant="outlined"
            onClick={handleClear}
          />
        )}
      </div>
      {rootTreeNodes.map((rootTreeNode, index) => (
        <PermissionTreeNode
          key={rootTreeNode.id + "|" + index}
          treeNode={rootTreeNode}
          selectedPermissionIds={selectedPermissionIds}
          expandedIds={expandedIds}
          disabled={disabled}
          handleExpand={handleExpand}
          handleCollapse={handleCollapse}
          handleSelect={handleSelect}
        />
      ))}
    </div>
  );
};

const PermissionTreeNode = ({
  treeNode,
  selectedPermissionIds,
  expandedIds,
  handleExpand,
  handleCollapse,
  handleSelect,
  disabled,
}: {
  treeNode: IPermissionTreeNode;
  selectedPermissionIds: number[];
  expandedIds: number[];
  handleExpand: (treeNode: IPermissionTreeNode) => void;
  handleCollapse: (treeNode: IPermissionTreeNode) => void;
  handleSelect: (treeNode: IPermissionTreeNode) => void;
  disabled: boolean;
}): JSX.Element => {
  const classes = SchoolUserPermissionSelectorStyles({ disabled });

  return (
    <div className={classes.permissionTreeNode}>
      <div className={classes.permissionTreeNodeItem}>
        {treeNode.children.length > 0 ? (
          expandedIds.includes(treeNode.id) ? (
            <div
              className={classes.permissionTreeNodeExpand}
              onClick={() => handleCollapse(treeNode)}
            >
              <Icons.KeyboardArrowDown />
            </div>
          ) : (
            <div
              className={classes.permissionTreeNodeExpand}
              onClick={() => handleExpand(treeNode)}
            >
              <Icons.KeyboardArrowRight />
            </div>
          )
        ) : (
          <div className={classes.permissionTreeNodeEmptyExpand} />
        )}
        <div className={classes.permissionTreeNodeSelect}>
          <AppSwitchControl
            label={treeNode.name}
            checked={selectedPermissionIds.includes(treeNode.id) || false}
            onChange={() => handleSelect(treeNode)}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={classes.permissionTreeNodeChildren}>
        {expandedIds.includes(treeNode.id) &&
          treeNode.children.map((childTreeNode, index) => (
            <PermissionTreeNode
              key={childTreeNode.id + "|" + index}
              treeNode={childTreeNode}
              selectedPermissionIds={selectedPermissionIds}
              expandedIds={expandedIds}
              disabled={disabled}
              handleExpand={handleExpand}
              handleCollapse={handleCollapse}
              handleSelect={handleSelect}
            />
          ))}
      </div>
    </div>
  );
};
