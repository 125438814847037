import React, { useState } from "react";
import { AppGrid } from "../../../Shared/Components/Grid";
import { IPaginationOptions } from "../../../Shared/Components/Tables/TablePagination/pagination-options.interface";
import { TablePaginationHelper } from "../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { ITracCode } from "../../../Shared/Models/Entities/trac-code.interface";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { TracCodeService } from "../../../Shared/Services/trac-code.service";
import { AppTracCodesListFilters } from "./TracCodesListFilters/TracCodesListFilters";
import { AppTracCodesReOrderDialog } from "./TracCodesReOrder/TracCodesReOrderDialog";
import { AppTracCodeTable } from "./TracCodesTable/TracCodesTable";

export interface ITracCodesListFilters {
  searchKey?: string;
  inactive: boolean;
  paginationOptions: IPaginationOptions;
}

export const AppTracCodesManager = (): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT);
  const { school, district } = StoreHelper.selector((state) => state.appConfig);
  const [filters, setFilters] = useState<ITracCodesListFilters>({
    inactive: true,
    paginationOptions: TablePaginationHelper.getDefaultOptions(),
  });
  const [reOrderOpen, setReOrderOpen] = useState(false);
  const areFiltersDefault = !district && !school && !filters.searchKey;

  const {
    loading,
    data: tracCodes,
    refreshData,
  } = DataHooks.useFunctionCallState<ITracCode[]>({
    func: () =>
      TracCodeService.getAll({
        schoolId: school?.id,
        districtId: district?.id,
        searchKey: filters.searchKey,
        paginationOptions: filters.paginationOptions,
        inactive: filters.inactive,
        paginate: true,
      }),
    deps: [filters, school?.id, district?.id],
    showLoadingIndicator: true,
  });

  const tracCodesCountState = DataHooks.useFunctionCallState<number>({
    func: () =>
      TracCodeService.getCount({
        schoolId: school?.id,
        districtId: district?.id,
        searchKey: filters.searchKey,
        paginationOptions: filters.paginationOptions,
        inactive: filters.inactive,
        paginate: true,
      }),
    deps: [filters],
  });
  return (
    <>
      <AppGrid container direction="column" spacing={3}>
        <AppGrid
          item
          container
          direction="row"
          wrap="wrap-reverse"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
        >
          <AppTracCodesListFilters
            filters={filters}
            setFilters={(filters) => {
              // Invalidate count so we will re-fetch
              tracCodesCountState.setData(null);
              setFilters({
                ...filters,
                // Reset pagination since we are viewing a new list
                paginationOptions: TablePaginationHelper.getDefaultOptions(),
              });
            }}
          />
        </AppGrid>
        <AppGrid item>
          <AppTracCodeTable
            tracCodes={tracCodes}
            tracCodesLoading={loading}
            count={tracCodesCountState.data}
            countLoading={tracCodesCountState.loading}
            filters={filters}
            setFilters={setFilters}
            areFiltersDefault={areFiltersDefault}
            onUserUpdated={refreshData}
            canEdit={canEdit}
            openReOrder={setReOrderOpen}
          />
        </AppGrid>
      </AppGrid>
      {reOrderOpen && (
        <AppTracCodesReOrderDialog
          open={reOrderOpen}
          setOpen={setReOrderOpen}
          refreshData={refreshData}
        />
      )}
    </>
  );
};
