import React from "react";
import { IRouteConfig } from "../../route.helper";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { BreadcrumbsStyles } from "./Breadcrumbs.styles";

export const AppBreadcrumbs = ({
  routeConfig,
}: {
  routeConfig: IRouteConfig;
}): JSX.Element => {
  const classes = BreadcrumbsStyles({});

  return (
    <Breadcrumbs className={classes.breadcrumbs}>
      {routeConfig.parents?.map((parentRouteConfig) => (
        <Link
          to={parentRouteConfig.path}
          className={classes.breadcrumbLink}
          key={routeConfig.key}
        >
          <parentRouteConfig.icon className={classes.routeIcon} />
          {parentRouteConfig.name}
        </Link>
      ))}
      <div className={classes.breadcrumbBase}>
        <routeConfig.icon className={classes.routeIcon} />
        {routeConfig.name}
      </div>
    </Breadcrumbs>
  );
};
