import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
} from "@material-ui/core";
import React from "react";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { GridTypeMap } from "@material-ui/core/Grid/Grid";
import { AppGrid } from "../Grid";
import { AppIcon } from "../Icons/Icons";

const MultipleSelectControlStyles = appMakeStyles<IMultipleSelectControlProps>(
  (theme) => ({
    formControl: {
      "& .Mui-disabled": {
        color: theme.palette.text.secondary,

        "& .MuiSelect-icon": {
          display: "none",
        },
      },
      "& .Mui-error": {
        borderColor: theme.palette.error.outline + " !important",

        select: {
          color: theme.palette.text.disabled,
        },
      },
    },
    select: {
      background: (props) =>
        props.disabled ? "transparent" : theme.palette.background.editableInput,
      color: theme.palette.text.primary,
      borderColor: theme.palette.divider,

      "&:hover:not(.Mui-disabled), &:focus:not(.Mui-disabled)": {
        color: theme.palette.text.primary,
        borderColor: theme.palette.primary.outline + " !important",
      },

      "& fieldset": {
        color: "inherit !important",
        borderColor: "inherit !important",
        background: "transparent",
        zIndex: 0,
      },
      "& select": {
        background: "transparent !important",
        color: "inherit !important",
      },
      "& svg": {
        marginRight: "8px",
        flexBasis: "24px",
        flexShrink: 0,
        color: "inherit !important",
      },

      "& .MuiInputBase-adornedStart": {
        paddingLeft: "10px",
      },
    },
  })
);

export interface IMultipleSelectControlProps extends Partial<SelectProps> {
  icon?: AppIcon;
  value: string[];
  options?: Array<{
    id: string;
    name: string;
  }>;
  helperText?: string;
  gridProps?: Partial<GridTypeMap["props"]>;
  onUpdate: (value: string[]) => void;
}

export const AppMultipleSelectControl = (
  props: IMultipleSelectControlProps
): JSX.Element => {
  const classes = MultipleSelectControlStyles(props);
  const {
    label,
    value,
    options,
    variant,
    helperText,
    error,
    gridProps,
    onUpdate,
    ...otherProps
  } = props;

  return (
    <AppGrid item {...gridProps}>
      <FormControl
        fullWidth
        variant={variant || "outlined"}
        className={classes.formControl}
        error={error}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          {...otherProps}
          label={label}
          variant={variant || "outlined"}
          MenuProps={{ variant: "menu" }}
          error={error}
          className={classes.select}
          value={value === null || value === undefined ? "" : value}
          renderValue={(value) => {
            const array = value as string[];
            if (array?.length) {
              const optionIds = options?.map((option) => option.id);
              const validItems = array.filter((item) =>
                optionIds?.includes(item)
              );
              if (validItems.length > 1) {
                return validItems.length + " Selected";
              } else {
                return options?.find((item) => item.id === validItems[0])?.name;
              }
            } else {
              return "-";
            }
          }}
          multiple
          onChange={(event) => {
            const selectedIds = event.target.value as string[];
            if (selectedIds) {
              if (selectedIds.includes("/SELECT#ALL/")) {
                if (props.value?.length === props.options?.length) {
                  onUpdate([]);
                } else {
                  const allIds = props.options?.map((opt) => opt.id) || [];
                  onUpdate(allIds);
                }
              } else {
                onUpdate(selectedIds);
              }
            }
          }}
        >
          <MenuItem key={"selectAll"} value={"/SELECT#ALL/"}>
            <Checkbox
              checked={props.value?.length === props.options?.length}
              color={"primary"}
            />
            <ListItemText primary={"Select All"} />
          </MenuItem>
          {options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox
                checked={props.value?.includes(option.id)}
                color={"primary"}
              />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </FormControl>
    </AppGrid>
  );
};
