import React, { useEffect, useState } from "react";
import { AppGrid } from "../../Grid";
import {
  ICSVValidationCategory,
  IHeroColumn,
} from "../../../Models/Interfaces/csv.interface";
import { AppDraggableHeroField } from "./DraggableHeroField/DraggableHeroField";
import { AppCSVValidationCategoryListItem } from "./CSVValidationCategoryListItem/CSVValidationCategoryListItem";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { AppCard } from "../../Cards/Card";
import { AppCardHeader } from "../../Cards/CardHeader";
import { PaletteTypes } from "../../../Themes/palette-types.enum";
import { AppCardContent } from "../../Cards/CardContent";
import { Icons } from "../../Icons/Icons";
import { AppButton } from "../../Button";

export const AppCSVValidator = (props: {
  csvValidationCategories: ICSVValidationCategory[];
  heroColumns: IHeroColumn[];
  confirmValidation: (mapping: string[]) => void;
}): JSX.Element => {
  const [csvValidationCategories, setCSVValidationCategories] = useState(
    props.csvValidationCategories
  );
  const [heroColumns, setHeroColumns] = useState([...props.heroColumns]);

  useEffect(() => {
    const csvCategoriesToBeSorted = [...csvValidationCategories];
    const autoSortedHeroColumns = [...heroColumns];
    const autoSortedCsvCategories = csvCategoriesToBeSorted.map((category) => {
      const matchIndex = autoSortedHeroColumns.findIndex((heroColumn) =>
        category.columnHeader
          .toLowerCase()
          .includes(heroColumn.databaseField.toLowerCase())
      );

      if (matchIndex === -1) {
        return category;
      }

      const heroField = autoSortedHeroColumns[matchIndex];

      category.heroField = {
        name: heroField.name,
        id: heroField.databaseField,
      };

      autoSortedHeroColumns.splice(matchIndex, 1);

      return category;
    });

    setCSVValidationCategories(autoSortedCsvCategories);

    setHeroColumns(autoSortedHeroColumns);
  }, []);

  const handleSubmit = () => {
    const mapping = csvValidationCategories.map(
      (category) => category.heroField.id
    );
    props.confirmValidation(mapping);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <AppGrid container direction="row" justifyContent="center" spacing={6}>
        <AppGrid item container xs={3}>
          <AppCard hasHeader>
            <AppCardHeader
              title={"Available Hero Fields"}
              palette={PaletteTypes.PRIMARY}
            />
            <AppCardContent>
              <AppGrid
                item
                container
                xs={3}
                direction="column"
                spacing={3}
                maxWidth={"100%"}
              >
                {heroColumns.map((heroColumn, i) => (
                  <AppDraggableHeroField
                    key={`draggable-hero-column-${i}`}
                    heroColumn={heroColumn}
                    index={i}
                  />
                ))}
                {!heroColumns.some((heroColumn) => heroColumn.mandatory) && (
                  <AppButton
                    gridProps={{ width: "100%" }}
                    fullWidth
                    text="Confirm"
                    icon={Icons.Save}
                    palette={PaletteTypes.SUCCESS}
                    onClick={() => handleSubmit()}
                  />
                )}
              </AppGrid>
            </AppCardContent>
          </AppCard>
        </AppGrid>

        <AppGrid item container direction="column" spacing={3}>
          {csvValidationCategories.map((validationCategory, i) => (
            <AppCSVValidationCategoryListItem
              key={`draggable-hero-column-${i}`}
              validationCategory={validationCategory}
              index={i}
              heroColumnOptions={props.heroColumns.map(
                (heroColumns) => heroColumns.name
              )}
              onDrop={(heroColumnIndexString: string) => {
                const heroColumnIndex = Number(heroColumnIndexString);
                const cssValidationCategory = [...csvValidationCategories][i];
                const heroField = heroColumns[heroColumnIndex];
                cssValidationCategory.heroField = {
                  name: heroField.name,
                  id: heroField.databaseField,
                };
                csvValidationCategories[i] = cssValidationCategory;

                heroColumns.splice(heroColumnIndex, 1);
                // doing this unusual sort to bubble the unpopulated fields up to the top
                setCSVValidationCategories(csvValidationCategories);
                setHeroColumns([...heroColumns]);
              }}
              removeHeroColumn={() => {
                const cssValidationCategory = [...csvValidationCategories][i];
                const heroColumnName = cssValidationCategory.heroField.name;
                const heroColumnIndex = props.heroColumns.findIndex(
                  (heroColumn) => heroColumn.name === heroColumnName
                );
                const heroColumn = props.heroColumns[heroColumnIndex];
                cssValidationCategory.heroField = { id: "", name: "" };
                csvValidationCategories[i] = cssValidationCategory;

                setCSVValidationCategories(csvValidationCategories);
                setHeroColumns([heroColumn, ...heroColumns]);
              }}
            />
          ))}
        </AppGrid>
      </AppGrid>
    </DndProvider>
  );
};
