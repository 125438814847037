import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalStorageKeysEnum } from "../../Models/Enums/local-storage-keys.enum";
import { LocalStorageHelper } from "../../Helpers/local-storage.helper";
import { IAppConfigReducerState } from "./app-config-reducer-state.interface";
import { ISimpleItem } from "../../Models/Entities/simple-item.interface";
import { AppAccessTypesEnum } from "../../Models/Enums/app-access-types.enum";

const defaultState: IAppConfigReducerState = {
  accessType: null,
  district: null,
  school: null,
  theme: "light",
};

export const AppConfigReducerSlice = createSlice({
  name: "app-config",
  initialState:
    LocalStorageHelper.getObject(LocalStorageKeysEnum.APP_CONFIG_STATE) ||
    defaultState,
  reducers: {
    setTheme: (
      state: IAppConfigReducerState,
      action: PayloadAction<"light" | "dark">
    ): void => {
      state.theme = action.payload;

      LocalStorageHelper.setItem(
        LocalStorageKeysEnum.APP_CONFIG_STATE,
        JSON.stringify(state)
      );
    },
    setAccess: (
      state: IAppConfigReducerState,
      action: PayloadAction<{
        accessType: AppAccessTypesEnum | null;
        district: ISimpleItem | null;
        school: ISimpleItem | null;
      }>
    ): void => {
      const { accessType, district, school } = action.payload;

      state.accessType = accessType;
      state.district = district;
      state.school = school;

      LocalStorageHelper.setItem(
        LocalStorageKeysEnum.APP_CONFIG_STATE,
        JSON.stringify(state)
      );
    },
  },
});
export const AppConfigReducerActions = AppConfigReducerSlice.actions;
