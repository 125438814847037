import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppNavbar } from "./Navbar/Navbar";
import { AppSidebar } from "./Sidebar/Sidebar";
import { ProjectStyles } from "./Project.styles";
import { RouteHelper } from "./route.helper";
import { AppForbidden } from "./Forbidden/Forbidden";
import { RouteComponentProps, withRouter, matchPath } from "react-router";
import { StoreHelper } from "../Shared/Reducers/store-helper";

const appProject = (props: RouteComponentProps): JSX.Element => {
  const classes = ProjectStyles({});
  const [selectedRoute, setSelectedRoute] = useState(
    RouteHelper.getRootConfigs()[0]
  );
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const routes = RouteHelper.getFlatConfigsList();
  const { district, school } = StoreHelper.selector((state) => state.appConfig);

  useEffect(() => {
    const path = props.location.pathname;
    const currentRoute = routes.find((route) => matchPath(path, route));
    if (currentRoute) {
      setSelectedRoute(currentRoute);
    }
  }, [props.location.pathname]);

  return (
    <div className={classes.project}>
      <div className={classes.sideBar}>
        <AppSidebar
          selectedRouteConfig={selectedRoute}
          sidebarOpen={sidebarOpen}
          shouldClose={() => setSidebarOpen(!sidebarOpen)}
        />
      </div>
      <div className={classes.mainPanelWrapper}>
        <div className={classes.mainPanelContent}>
          <div className={classes.navBar}>
            <AppNavbar
              routeConfig={selectedRoute}
              sidebarOpen={sidebarOpen}
              shouldOpenSidebar={() => setSidebarOpen(true)}
            />
          </div>
          <div className={classes.content}>
            <Switch>
              {routes.map((route) => (
                <Route
                  path={route.path}
                  key={route.key}
                  exact={route.exact}
                  render={() => {
                    if (
                      route.requireSchoolOrGroup &&
                      !school?.id &&
                      !district?.id
                    ) {
                      return (
                        <AppForbidden text="Please select a school or school group on the bottom left to view this page!" />
                      );
                    }

                    if (route.requireSchool && !school?.id) {
                      return (
                        <AppForbidden text="Please select a school on the bottom left to view this page!" />
                      );
                    }

                    if (route.enabled === false) {
                      return (
                        <AppForbidden text="Sorry! You don't have permission to view that page!" />
                      );
                    }

                    return <route.component />;
                  }}
                />
              ))}
              <Route>
                <Redirect to="/school-manager" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AppProject = withRouter(appProject);
