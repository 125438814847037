import React from "react";
import { TableRow as MuiTableRow, TableRowProps } from "@material-ui/core";
import { appMakeStyles } from "../../../Themes/app-make-styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BaseTableRowStyles = appMakeStyles((theme) => ({
  tableRow: {},
}));

// eslint-disable-next-line
export type BaseTableRowProps = TableRowProps & {};

export const AppBaseTableRow = (props: BaseTableRowProps): JSX.Element => {
  const classes = BaseTableRowStyles({});

  return <MuiTableRow {...props} className={classes.tableRow} />;
};
