import React, { useState } from "react";
import { IPaginationOptions } from "../../../Shared/Components/Tables/TablePagination/pagination-options.interface";
import { TablePaginationHelper } from "../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { AppGrid } from "../../../Shared/Components/Grid";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { AppButton } from "../../../Shared/Components/Button";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { IEvent } from "../../../Shared/Models/Entities/event.interface";
import { EventService } from "../../../Shared/Services/event.service";
import { AppEventListFilters } from "./EventListFilters/EventListFilters";
import { AppEventListTable } from "./EventListTable/EventListTable";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";

export interface IEventListFilters {
  categoryId?: number;
  status?: "true" | "false";
  paginationOptions: IPaginationOptions;
}

export const AppEventList = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [filters, setFilters] = useState<IEventListFilters>({
    paginationOptions: TablePaginationHelper.getDefaultOptions(),
  });
  const fixedFilters = {
    ...filters,
    districtId: district?.id,
    schoolId: school?.id,
  };

  const eventsListState = DataHooks.useFunctionCallState<IEvent[]>({
    func: async () => EventService.get(fixedFilters),
    deps: [
      fixedFilters.categoryId,
      fixedFilters.status,
      fixedFilters.schoolId,
      fixedFilters.districtId,
      fixedFilters.paginationOptions?.size,
      fixedFilters.paginationOptions?.page,
    ],
    showLoadingIndicator: true,
  });

  const eventsCountState = DataHooks.useFunctionCallState<number>({
    func: () => EventService.getCount(fixedFilters),
    deps: [
      fixedFilters.categoryId,
      fixedFilters.status,
      fixedFilters.schoolId,
      fixedFilters.districtId,
    ],
  });

  return (
    <>
      <AppGrid container direction="column" spacing={2}>
        <AppGrid
          item
          container
          direction="row"
          wrap="wrap-reverse"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={2}
        >
          <AppEventListFilters
            filters={filters}
            setFilters={(filters) => {
              // Invalidate count so we will re-fetch
              eventsCountState.setData(null);
              setFilters({
                ...filters,
                // Reset pagination since we are viewing a new list
                paginationOptions: TablePaginationHelper.getDefaultOptions(),
              });
            }}
          />
          <AppGrid item container spacing={2} marginLeft="auto">
            <Actions />
          </AppGrid>
        </AppGrid>
        <AppGrid item>
          <AppEventListTable
            events={eventsListState.data}
            eventsLoading={eventsListState.loading}
            count={eventsCountState.data}
            countLoading={eventsCountState.loading}
            filters={filters}
            setFilters={setFilters}
          />
        </AppGrid>
      </AppGrid>
    </>
  );
};

const Actions = () => {
  return (
    <>
      {hasPermission(AppPermissionsEnum.EVENTS_EDIT) && (
        <AppButton
          text="Manage Supervisors"
          icon={Icons.AccountKey}
          size="large"
          palette={PaletteTypes.PRIMARY}
          linkTo="/event-supervisors"
        />
      )}
      {hasPermission(AppPermissionsEnum.EVENTS_EDIT) && (
        <AppButton
          text="Manage Categories"
          icon={Icons.Shape}
          size="large"
          palette={PaletteTypes.PRIMARY}
          linkTo="/event-categories"
        />
      )}
      {hasPermission(AppPermissionsEnum.EVENTS_EDIT) && (
        <AppButton
          text="Create Event"
          icon={Icons.PersonAdd}
          size="large"
          palette={PaletteTypes.SUCCESS}
          linkTo="/events/create"
        />
      )}
    </>
  );
};
