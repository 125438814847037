import React, { useState } from "react";
import { AppAutocompleteControl } from "../../../../../Shared/Components/Controls/AutocompleteControl";
import { AppRadioControl } from "../../../../../Shared/Components/Controls/RadioControl";
import { AppGrid } from "../../../../../Shared/Components/Grid";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";
import { DataHooks } from "../../../../../Shared/Hooks/data-hooks";
import { ISchoolSimple } from "../../../../../Shared/Models/Entities/school.interface";
import { ISimpleItem } from "../../../../../Shared/Models/Entities/simple-item.interface";
import { AppAccessTypesEnum } from "../../../../../Shared/Models/Enums/app-access-types.enum";
import { DistrictService } from "../../../../../Shared/Services/district.service";
import { SchoolService } from "../../../../../Shared/Services/school.service";

export const AdminUserSchoolDistrictSelector = (props: {
  schoolId: number | null;
  setSchoolId: (schoolId: number | null) => void;
  schoolGroupId: number | null;
  setSchoolGroupId: (schoolId: number | null) => void;
  error: boolean;
  schoolGroupLabel?: string;
  schoolLabel?: string;
}): JSX.Element => {
  const districtsData = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: DistrictService.getAllSimple,
  });
  const schoolsData = DataHooks.useFunctionCallState<ISchoolSimple[]>({
    func: SchoolService.getAllSimple,
  });
  const [accessType, setAccessType] = useState<string>(
    props.schoolId ? AppAccessTypesEnum.SCHOOL : AppAccessTypesEnum.DISTRICT
  );

    return (
    <AppGrid container spacing={3}>
      <AppRadioControl
        gridProps={{ xs: 12 }}
        label="Access Type *"
        value={accessType}
        valueUpdated={(value) => {
          setAccessType(value);
        }}
        options={[
          {
            label: props.schoolGroupLabel || "User should have access to entire school group",
            value: AppAccessTypesEnum.DISTRICT,
          },
          {
            label: props.schoolLabel || "User should have access to one school",
            value: AppAccessTypesEnum.SCHOOL,
          },
        ]}
      />

      <AppAutocompleteControl
        gridProps={{
          xs: 12,
          hidden: accessType !== AppAccessTypesEnum.DISTRICT,
        }}
        placeholder="Select a SchoolGroup"
        icon={Icons.District}
        options={districtsData.data || []}
        selectedId={props.schoolGroupId}
        selectedIdUpdated={(selectedId) => {
          props.setSchoolGroupId(selectedId);
          props.setSchoolId(null);
        }}
        loading={districtsData.loading}
        opaqueBackground
        disableClearable
        error={props.error}
      />
      <AppAutocompleteControl
        gridProps={{
          xs: 12,
          hidden: accessType !== AppAccessTypesEnum.SCHOOL,
        }}
        placeholder="Select a School"
        icon={Icons.District}
        options={schoolsData.data || []}
        selectedId={props.schoolId}
        selectedIdUpdated={(selectedId) => {
          props.setSchoolId(selectedId);
          props.setSchoolGroupId(null);
        }}
        loading={schoolsData.loading}
        opaqueBackground
        disableClearable
        error={props.error}
      />
    </AppGrid>
  );
};
