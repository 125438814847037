import React, { Dispatch, SetStateAction } from "react";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppGrid } from "../../../../Shared/Components/Grid";

import { AppButton } from "../../../../Shared/Components/Button";
import { Dialog, DialogContent } from "@material-ui/core";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { useHistory } from "react-router";
import { BellScheduleService } from "../../../../Shared/Services/bell-schedule.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { IBellSchedule } from "../../../../Shared/Models/Entities/bell-schedule.interface";

export const AppBellScheduleDeleteDialog = (props: {
  bellSchedule: IBellSchedule;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const history = useHistory();

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleAgree = async () => {
    LoadingBarService.show();
    try {
      await BellScheduleService.delete(props.bellSchedule);
      history.push("/bell-schedules");
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="confirmation-dialog"
    >
      <DialogContent>
        <AppCard hasHeader>
          <AppCardHeader
            title={"Delete Bell Schedule?"}
            subheader={
              "Are you sure you want to delete this bell schedule?\nThis may cause data issues with past behaviors logged on this schedule."
            }
            icon={Icons.Delete}
            palette={PaletteTypes.ERROR}
          />
          <AppCardContent>
            <AppGrid container justifyContent="flex-end" spacing={1}>
              <AppButton
                text="No"
                icon={Icons.Close}
                palette={PaletteTypes.WARNING}
                onClick={handleClose}
                variant="outlined"
              />
              <AppButton
                text="Yes"
                icon={Icons.Check}
                palette={PaletteTypes.SUCCESS}
                onClick={handleAgree}
              />
            </AppGrid>
          </AppCardContent>
        </AppCard>
      </DialogContent>
    </Dialog>
  );
};
