import React from "react";
import { IEventListFilters } from "../EventList";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { EventCategoryService } from "../../../../Shared/Services/event-category.service";

export const AppEventListFilters = ({
  filters,
  setFilters,
}: {
  filters: IEventListFilters;
  setFilters: (props: IEventListFilters) => void;
}): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const categoriesState = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: () =>
      EventCategoryService.get({
        schoolId: school?.id,
        districtId: district?.id,
      }),
    deps: [school?.id, district?.id],
    showLoadingIndicator: true,
  });

  return (
    <AppGrid item container spacing={3} alignItems="center">
      <AppSelectControl
        gridProps={{ width: 225 }}
        label="Category"
        value={filters.categoryId}
        options={categoriesState.data || []}
        loading={categoriesState.loading}
        selected={(value) => {
          setFilters({
            ...filters,
            categoryId: value ? Number(value) : undefined,
          });
        }}
      />

      <AppSelectControl
        gridProps={{ width: 225 }}
        label="Status"
        value={filters.status}
        options={[
          { id: "true", name: "Active" },
          { id: "false", name: "Inactive" },
        ]}
        selected={(value) => {
          setFilters({
            ...filters,
            status: (value as "true" | "false") || undefined,
          });
        }}
      />
    </AppGrid>
  );
};
