import {
  ISchoolUser,
  ISchoolUserSimple,
} from "../Models/Entities/school-user.interface";
import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { HttpClient } from "../Helpers/http-client";
import { PaginationHelper } from "../Helpers/pagination.helper";

export interface IGetUserProps {
  searchKey?: string;
  schoolId?: number;
  districtId?: number;
  inactive?: boolean;
  paginationOptions: IPaginationOptions;
}

export class SchoolUserService {
  static async getById(schoolUserId: number): Promise<ISchoolUser> {
    return HttpClient.get({
      path: "/school-users/" + schoolUserId,
    });
  }

  static async getSimple(props: IGetUserProps): Promise<ISchoolUserSimple[]> {
    return HttpClient.get({
      path: "/school-users",
      params: {
        ...PaginationHelper.getPaginationParams(props.paginationOptions),
        ...(props?.districtId && {
          districtId: props?.districtId,
        }),
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),
        ...(props?.inactive && {
          inactive: true,
        }),
        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
      },
    });
  }

  static async getCount(props: IGetUserProps): Promise<number> {
    return HttpClient.get({
      path: "/school-users/count",
      params: {
        ...(props?.districtId && {
          districtId: props?.districtId,
        }),
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),
        ...(props?.inactive && {
          inactive: true,
        }),
        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
      },
    });
  }

  static async create(user: ISchoolUser): Promise<ISchoolUser> {
    return HttpClient.post({
      path: "/school-users",
      body: user,
    });
  }

  static async update(user: ISchoolUser): Promise<ISchoolUser> {
    return HttpClient.put({
      path: "/school-users/" + user.id.toString(),
      body: user,
    });
  }

  static async toggleActive(
    userId: number,
    active: boolean
  ): Promise<ISchoolUser> {
    return HttpClient.put({
      path: "/school-users/" + userId + "/toggle-active",
      params: {
        active,
      },
    });
  }

  static async importUsers(body: {
    schoolId: number;
    roleId?: number;
    overrideRoles: boolean;
    overridePasswords: boolean;
    defaultPassword: string;
    mapping: string[];
    data: string[][];
  }): Promise<number> {
    return HttpClient.post({
      path: "/school-users/import",
      body,
    });
  }
}
