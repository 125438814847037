import React from "react";
import { TableHead as MuiTableHead, TableHeadProps } from "@material-ui/core";
import { appMakeStyles } from "../../../Themes/app-make-styles";

const BaseTableHeadStyles = appMakeStyles((theme) => ({
  tableHead: {
    "& .MuiTableCell-root": {
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
  },
}));

// eslint-disable-next-line
export type BaseTableHeadProps = TableHeadProps & {};

export const AppBaseTableHead = (props: BaseTableHeadProps): JSX.Element => {
  const classes = BaseTableHeadStyles({});

  return <MuiTableHead {...props} className={classes.tableHead} />;
};
