import { HttpClient } from "../Helpers/http-client";
import { IEventSupervisor } from "../Models/Entities/event-supervisor.interface";

export class EventSupervisorService {
  static async get({
    schoolId,
    districtId,
  }: {
    schoolId?: number;
    districtId?: number;
  }): Promise<IEventSupervisor[]> {
    return HttpClient.get({
      path: "/event-supervisors",
      params: {
        ...(schoolId && {
          schoolId,
        }),
        ...(districtId && {
          districtId,
        }),
      },
    });
  }

  static async create(
    eventSupervisor: IEventSupervisor,
    schoolId: number,
    districtId: number
  ): Promise<IEventSupervisor> {
    return HttpClient.post({
      path: "/event-supervisors",
      body: eventSupervisor,
      params: {
        ...(schoolId && { schoolId }),
        ...(districtId && { districtId }),
      },
    });
  }

  static async update(
    eventSupervisor: IEventSupervisor,
    schoolId: number,
    districtId: number
  ): Promise<IEventSupervisor> {
    return HttpClient.put({
      path: "/event-supervisors/" + eventSupervisor.id,
      body: eventSupervisor,
      params: {
        ...(schoolId && { schoolId }),
        ...(districtId && { districtId }),
      },
    });
  }

  static async delete(eventSupervisorId: number): Promise<IEventSupervisor> {
    return HttpClient.delete({
      path: "/event-supervisors/" + eventSupervisorId,
    });
  }

  static async canDelete(eventSupervisorId: number): Promise<boolean> {
    return HttpClient.get({
      path: "/event-supervisors/" + eventSupervisorId + "/can-delete",
    });
  }
}
