import React, { useState } from "react";
import { AppSchoolChurnTable } from "../SchoolChurnTable/SchoolChurnTable";
import { AppSchoolEditor } from "../SchoolEditor/SchoolEditor";
import { ISchool } from "../../../../Shared/Models/Entities/school.interface";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import {
  IChurnInfo,
  SchoolService,
} from "../../../../Shared/Services/school.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { AppConfirmationDialog } from "../../../../Shared/Components/Dialogs/ConfirmationDialog";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";

export const AppSchoolPanel = (props: { schoolId: number }): JSX.Element => {
  const canUpdate = hasPermission(AppPermissionsEnum.SCHOOLS_EDIT);
  const [confirmChurnOpen, setConfirmChurnOpen] = useState(false);

  const viewingData = DataHooks.useFunctionCallState<{
    school: ISchool;
    churnInfo: IChurnInfo;
  }>({
    func: async () => {
      const schoolCall = SchoolService.getById(props.schoolId);
      const churnInfoCall = SchoolService.getChurnStatus(props.schoolId);

      return {
        school: await schoolCall,
        churnInfo: await churnInfoCall,
      };
    },
    deps: [props.schoolId],
    showLoadingIndicator: true,
  });

  const { school, churnInfo } = viewingData.data || {};

  const handleChurnClick = async () => {
    if (school) {
      LoadingBarService.show();
      try {
        const churnInfo = await SchoolService.enqueueChurn(props.schoolId);
        viewingData.setData({
          school,
          churnInfo,
        });
      } catch (error) {
        SnackbarService.error(error);
        Logger.error(error);
      }
      LoadingBarService.hide();
    }
  };

  return (
    <>
      <AppGrid container spacing={3}>
        <AppGrid item xs={12}>
          <AppCard hasHeader>
            <AppCardHeader
              title={
                school
                  ? "School: [" + school.id + "] " + school.name
                  : "Loading..."
              }
              subheader={
                canUpdate
                  ? "Fill out this form to update an existing school in Hero."
                  : undefined
              }
              icon={Icons.School}
              palette={PaletteTypes.PRIMARY}
            />
            <AppCardContent>
              <AppGrid container spacing={3} justifyContent="center">
                <AppGrid item>
                  <AppSchoolEditor
                    key={props.schoolId}
                    school={school || null}
                    isCreating={false}
                    churnInfo={churnInfo}
                    churnPressed={() => setConfirmChurnOpen(true)}
                  />
                </AppGrid>
              </AppGrid>
            </AppCardContent>
          </AppCard>
        </AppGrid>
        {churnInfo && (
          <AppGrid item xs={12}>
            <AppCard hasHeader>
              <AppCardHeader
                title={"Churn Info"}
                subheader="Here are details about the the churn info"
                icon={Icons.Delete}
                palette={PaletteTypes.ERROR}
              />
              <AppCardContent padding={0}>
                <AppSchoolChurnTable
                  schoolId={props.schoolId}
                  churnInfo={churnInfo}
                />
              </AppCardContent>
            </AppCard>
          </AppGrid>
        )}
      </AppGrid>
      <AppConfirmationDialog
        open={confirmChurnOpen}
        setOpen={setConfirmChurnOpen}
        title="Confirm New Churn"
        content="Are you sure you want to kick off churn for this school?"
        icon={Icons.Delete}
        agreeAction={async () => handleChurnClick()}
        palette={PaletteTypes.ERROR}
      />
    </>
  );
};
