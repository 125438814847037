import React, { Dispatch, SetStateAction } from "react";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppTablePagination } from "../../../../Shared/Components/Tables/TablePagination/TablePagination";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { ITracCode } from "../../../../Shared/Models/Entities/trac-code.interface";
import { IGetTracCodeProps } from "../../../../Shared/Services/trac-code.service";
import { useHistory } from "react-router";

export const AppTracCodeTable = (props: {
  tracCodes: ITracCode[] | null;
  tracCodesLoading: boolean;
  count: number | null;
  countLoading: boolean;
  filters: IGetTracCodeProps;
  setFilters: (props: IGetTracCodeProps) => void;
  areFiltersDefault: boolean;
  onUserUpdated: () => void;
  canEdit: boolean;
  openReOrder: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const history = useHistory();
  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="Trac Codes"
          subheader="All trac codes that exist in your school/group."
          icon={Icons.FountainPen}
          palette={PaletteTypes.PRIMARY}
          action={
            props.canEdit && (
              <AppGrid container direction="row">
                <AppGrid item padding={8}>
                  <AppButton
                    text="Reorder"
                    icon={Icons.Edit}
                    palette={PaletteTypes.SUCCESS}
                    onClick={() => props.openReOrder(true)}
                  />
                </AppGrid>
                <AppGrid item padding={8}>
                  <AppButton
                    text="Create Trac Code"
                    icon={Icons.Add}
                    palette={PaletteTypes.SUCCESS}
                    onClick={() => history.push("/trac-code/create")}
                  />
                </AppGrid>
              </AppGrid>
            )
          }
        />
        <AppCardContent padding={0}>
          <TableContent
            tracCodes={props.tracCodes}
            tracCodesLoading={props.tracCodesLoading}
            areFiltersDefault={props.areFiltersDefault}
          />
          <AppTablePagination
            count={props.count}
            countLoading={props.countLoading}
            paginationOptions={props.filters.paginationOptions}
            paginationOptionsUpdated={(newOptions) => {
              props.setFilters({
                ...props.filters,
                paginationOptions: newOptions,
              });
            }}
          />
        </AppCardContent>
      </AppCard>
    </>
  );
};

const TableContent = ({
  tracCodes,
  tracCodesLoading,
  areFiltersDefault,
}: {
  tracCodes: ITracCode[] | null;
  tracCodesLoading: boolean;
  areFiltersDefault: boolean;
}) => {
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);
  return (
    <AppCustomTable
      loading={tracCodesLoading && !tracCodes}
      showLoadingText={
        areFiltersDefault
          ? "Choose a term to search by or select a school/group in the sidebar!"
          : null
      }
      columns={[
        {
          id: "code",
          label: "Code",
        },
        {
          id: "description",
          label: "Description",
        },
        {
          id: "owner",
          label: "Owner",
        },
        {
          id: "print-pass",
          label: "Print Pass",
        },
        {
          id: "active",
          label: "Active",
        },
        {
          id: "action",
          label: "Actions",
        },
      ]}
      rows={tracCodes?.map((tracCode) => ({
        cells: [
          {
            value: tracCode.name,
          },
          {
            value: tracCode.description,
          },
          {
            value: tracCode.school
              ? tracCode.school.name
              : tracCode.district
              ? tracCode.district.name
              : "none",
          },
          {
            value: tracCode.printPass ? "true" : "false",
          },
          {
            value: tracCode.active ? "true" : "false",
          },
          {
            value: (
              <AppGrid container spacing={1}>
                <AppButton
                  text={canEdit ? "Edit" : "View"}
                  icon={canEdit ? Icons.Edit : Icons.Visibility}
                  palette={PaletteTypes.SECONDARY}
                  linkTo={"/trac-code/" + tracCode.id.toString()}
                />
              </AppGrid>
            ),
          },
        ],
      }))}
    />
  );
};
