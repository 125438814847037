import { InputLabel } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useHistory } from "react-router";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppRadioControl } from "../../../../Shared/Components/Controls/RadioControl";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { Logger } from "../../../../Shared/Helpers/logger";
import { TracCodeTypeHelper } from "../../../../Shared/Helpers/trac-code-type.helper";
import { Utils } from "../../../../Shared/Helpers/utils";
import { IPopulatedTracCode } from "../../../../Shared/Models/Entities/populated-trac-code.interface";
import { ISimpleOption } from "../../../../Shared/Models/Entities/simple-option.interface";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { TracCodeService } from "../../../../Shared/Services/trac-code.service";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppTracCodePositiveReinforcement } from "../TracCodePositiveReinforcement/TracCodePositiveReinforcement";
import {ITracCodeValidationResults, TracCodeHelper} from "./TracCodeHelper";

export const tracCodeOptions: ISimpleOption[] = [
  { id: "Active", name: "Active" },
  { id: "Print Pass", name: "Print Pass" },
  { id: "Fast Track", name: "Fast Track" },
];

export const disciplinaryReferrals: ISimpleOption[] = [
  {
    id: "Used as a Behavior Code for referrals",
    name: "Used as a Behavior Code for referrals",
  },
  {
    id: "Behavior must be reported to state",
    name: "Behavior must be reported to state",
  },
];

export const AppTracCodeInfo = (props: {
  tracCode: IPopulatedTracCode;
  setTracCode: (bellSchedule: IPopulatedTracCode) => void;
  refreshTracCode: () => void;
  canEdit: boolean;
  hasChanges: boolean;
  isCreating: boolean;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const history = useHistory();
  const [errorResults, setErrorResults] = useState(
        {} as ITracCodeValidationResults
  );
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const handleSave = async () => {
    LoadingBarService.show();
    try {
      const tracCode = Utils.cloneObject(props.tracCode);
      if (!tracCode.rollBackTracCode)
        tracCode.rollBackTracCode = {
          id: -1,
          enabled: false,
          beginAt: 0,
          triggerAt: 0,
          reward: 0,
          rewardCount: 0,
          excludedDays: [],
        };

      if (!tracCode.tracCodeEvent)
        tracCode.tracCodeEvent = {
          id: -1,
          refrenceCode: "",
        };

      const validationResults = TracCodeHelper.validateTracCode(tracCode);
      setErrorResults(validationResults);
      if (validationResults.hasErrors) {
        LoadingBarService.hide();
        SnackbarService.error("Please fix the errors on the form.");
        return;
      }

      if (props.isCreating) {
        const newTracCode = await TracCodeService.create(tracCode, school.id);
        history.push(`/trac-code/${newTracCode.id}`);
      } else {
        await TracCodeService.update(tracCode);
        props.setHasChanges(false);
      }
      LoadingBarService.hide();
      props.setHasChanges(false);
    } catch (error) {
      setTriedToSubmit(true);
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  return (
    <AppCard hasHeader>
      <AppCardHeader
        title="Trac Code Info"
        palette={PaletteTypes.PRIMARY}
        icon={Icons.FountainPen}
        action={
          props.canEdit && (
            <AppButton
              text={props.isCreating ? "Create" : "Save"}
              icon={Icons.Check}
              palette={PaletteTypes.SUCCESS}
              onClick={handleSave}
              disabled={!props.hasChanges}
            />
          )
        }
      />
      <AppCardContent>
        <AppGrid
          container
          justifyContent="center"
          direction="column"
          spacing={2}
        >
          <AppGrid container item direction="row" spacing={2}>
            <AppTextFieldControl
              gridProps={{ xs: 6 }}
              label="Code (short name)*"
              name="name"
              error={!!errorResults.name}
              value={props.tracCode.name}
              onChange={(event) => {
                const name = event.target.value;
                if (props.tracCode) {
                  props.setTracCode({ ...props.tracCode, name });
                  props.setHasChanges(true);
                }
              }}
              disabled={!props.canEdit}
              opaqueBackground
            />
            <AppTextFieldControl
              gridProps={{ xs: 6 }}
              label="Description"
              name="name"
              value={props.tracCode.description}
              onChange={(event) => {
                const description = event.target.value;
                if (props.tracCode) {
                  props.setTracCode({ ...props.tracCode, description });
                  props.setHasChanges(true);
                }
              }}
              disabled={!props.canEdit}
              opaqueBackground
            />
          </AppGrid>
          <AppTextFieldControl
            label="Message on Pass *"
            name="name"
            value={props.tracCode.passMessage}
            onChange={(event) => {
              const passMessage = event.target.value;
              if (props.tracCode) {
                props.setTracCode({ ...props.tracCode, passMessage });
                props.setHasChanges(true);
              }
            }}
            error={!!errorResults.passMessage}
            disabled={!props.canEdit}
            opaqueBackground
          />
          <AppGrid container item direction="row" spacing={2}>
            <AppRadioControl
              gridProps={{ xs: 3, md: 3, lg: 3 }}
              label="Type"
              value={props.tracCode.type.toString()}
              valueUpdated={(value) => {
                const type = parseInt(value);
                props.setTracCode({ ...props.tracCode, type });
                props.setHasChanges(true);
              }}
              options={TracCodeTypeHelper.getOptions()}
              disabled={!props.canEdit}
            />

            <AppGrid
              container
              direction="column"
              xs={3}
              md={3}
              lg={3}
              justifyContent={"space-around"}
              padding={4}
            >
              <InputLabel>{"Options"}</InputLabel>
              <AppSwitchControl
                label="Active"
                checked={props.tracCode?.active}
                onChange={() => {
                  props.setHasChanges(true);
                  props.setTracCode({
                    ...props.tracCode,
                    active: !props.tracCode?.active,
                  });
                }}
                disabled={!props.canEdit}
              />
              <AppSwitchControl
                label="Print Pass"
                checked={props.tracCode.printPass}
                onChange={() => {
                  props.setHasChanges(true);
                  props.setTracCode({
                    ...props.tracCode,
                    printPass: !props.tracCode?.printPass,
                  });
                }}
                disabled={!props.canEdit}
              />
              <AppSwitchControl
                label="Fast Track"
                checked={props.tracCode.fastTrack}
                onChange={() => {
                  props.setHasChanges(true);
                  props.setTracCode({
                    ...props.tracCode,
                    fastTrack: !props.tracCode?.fastTrack,
                  });
                }}
                disabled={!props.canEdit}
              />
            </AppGrid>

            {props.tracCode.tracCodeEvent && (
              <AppTextFieldControl
                gridProps={{ xs: 6 }}
                label="External Reference Code"
                name="refrenceCode"
                value={
                  props.tracCode.tracCodeEvent
                    ? props.tracCode.tracCodeEvent.refrenceCode
                    : ""
                }
                onChange={(event) => {
                  const refrenceCode = event.target.value;
                  if (props.tracCode) {
                    props.setTracCode({
                      ...props.tracCode,
                      tracCodeEvent: {
                        ...props.tracCode.tracCodeEvent,
                        refrenceCode,
                      },
                    });
                    props.setHasChanges(true);
                  }
                }}
                disabled={!props.canEdit}
                opaqueBackground
              />
            )}
          </AppGrid>

          <AppGrid
            container
            item
            direction="column"
            justifyContent="space-between"
            minHeight={"100px"}
          >
            <InputLabel>{"Disciplinary Referrals"}</InputLabel>
            <AppSwitchControl
              label="Used as a Behavior Code for referrals"
              checked={props.tracCode.usedForReferrals}
              onChange={() => {
                props.setHasChanges(true);
                props.setTracCode({
                  ...props.tracCode,
                  usedForReferrals: !props.tracCode?.usedForReferrals,
                });
              }}
              disabled={!props.canEdit}
            />
            <AppSwitchControl
              label="Behavior must be reported to state"
              checked={props.tracCode.referralIsReportableToState}
              onChange={() => {
                props.setHasChanges(true);
                props.setTracCode({
                  ...props.tracCode,
                  usedForReferrals:
                    !props.tracCode?.referralIsReportableToState,
                });
              }}
              disabled={!props.canEdit}
            />
          </AppGrid>
          {school && (
            <>
              <AppFormCategory
                title="Positive Reinforcement"
                gridProps={{ xs: 12 }}
              />
              <AppTracCodePositiveReinforcement {...props} />
            </>
          )}
        </AppGrid>
      </AppCardContent>
    </AppCard>
  );
};
