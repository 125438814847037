import React from "react";
import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from "@material-ui/core";
import { appMakeStyles } from "../../../Themes/app-make-styles";

const EmptyTableRowStyles = appMakeStyles((theme) => ({
  emptyTableCell: {
    background: theme.palette.background.paperAlternate + " !important",
    color: theme.palette.text.secondary,
    height: "50px",
    borderBottom: "1px solid " + theme.palette.divider,
  },
}));

export const AppEmptyTableRow = ({
  cellCount,
  text,
}: {
  cellCount: number;
  text?: string;
}): JSX.Element => {
  const classes = EmptyTableRowStyles({});

  return (
    <MuiTableRow>
      <MuiTableCell
        className={classes.emptyTableCell}
        colSpan={cellCount}
        align="center"
      >
        {text}
      </MuiTableCell>
    </MuiTableRow>
  );
};
