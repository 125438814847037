import { Logger } from "./logger";
import { SessionStorageKeysEnum } from "../Models/Enums/session-storage-keys.enum";

/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
/* eslint-disable no-restricted-syntax  */
export class SessionStorageHelper {
  static getItem(key: SessionStorageKeysEnum): any {
    return sessionStorage.getItem(key);
  }

  static setItem(key: SessionStorageKeysEnum, value: any): void {
    sessionStorage.setItem(key, value);
  }

  static removeItem(key: SessionStorageKeysEnum): void {
    localStorage.removeItem(key);
  }

  static getObject(key: SessionStorageKeysEnum): any {
    const value = SessionStorageHelper.getItem(key);
    return SessionStorageHelper.parseJSON(value);
  }

  static parseJSON(json: string | null): any {
    try {
      return json ? JSON.parse(json) || null : null;
    } catch (error) {
      Logger.error(error);
      return null;
    }
  }
}
