import { IEvent } from "../../../Shared/Models/Entities/event.interface";
import {
  EventRecurrenceRangeTypes,
  EventRecurrenceTypes,
} from "../../../Shared/Models/Entities/event-recurrence.interface";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { TimeHelper } from "../../../Shared/Helpers/time-helper";

export enum EventValidationErrors {
  REQUIRED = "Required",
  ORDER = "Wrong time order",
}

export interface IEventValidationResults {
  // info
  name?: EventValidationErrors.REQUIRED;
  capacity?: EventValidationErrors.REQUIRED;

  // time
  start?: EventValidationErrors.REQUIRED;
  end?: EventValidationErrors.REQUIRED;
  timeOrder?: EventValidationErrors.ORDER;

  // recurrence
  type?: EventValidationErrors.REQUIRED;
  date?: EventValidationErrors.REQUIRED;
  monthOfYear?: EventValidationErrors.REQUIRED;
  dayOfMonth?: EventValidationErrors.REQUIRED;
  everyXMonths?: EventValidationErrors.REQUIRED;
  weekdays?: EventValidationErrors.REQUIRED;
  everyXDays?: EventValidationErrors.REQUIRED;

  // recurrence range
  rangeType?: EventValidationErrors.REQUIRED;
  rangeStart?: EventValidationErrors.REQUIRED;
  rangeEnd?: EventValidationErrors.REQUIRED;
  dateOrder?: EventValidationErrors.ORDER;
  occurrences?: EventValidationErrors.REQUIRED;
}

export class EventViewerHelper {
  public static getDefault(): IEvent {
    return {
      id: 0,
      name: "",
      active: true,
      mustScanOut: true,
      allowMultipleEntries: true,
      start: TimeHelper.formatDateToDBString(TimeHelper.getNoon()),
      end: TimeHelper.formatDateToDBString(TimeHelper.getOnePm()),
      recurrence: {
        date: TimeHelper.formatDateToDBString(new Date()),
        type: EventRecurrenceTypes.ONCE,
        rangeType: EventRecurrenceRangeTypes.NO_END_DATE,
        rangeStart: new Date().toISOString(),
      },
      students: [],
    };
  }

  public static validateEvent(event: IEvent): IEventValidationResults {
    const errorResults = {} as IEventValidationResults;
    const log = (message: string) => {
      SnackbarService.warn(message);
    };
    // info
    if (!event.name) {
      errorResults.name = EventValidationErrors.REQUIRED;
      log("Name is required");
    }
    if (typeof event.capacity !== "number") {
      errorResults.capacity = EventValidationErrors.REQUIRED;
      log("Capacity Amount is required");
    }

    // time
    if (!event.start) {
      errorResults.start = EventValidationErrors.REQUIRED;
      log("Start Hour is required");
    } else if (!event.end) {
      errorResults.end = EventValidationErrors.REQUIRED;
      log("End Hour is required");
    } else if (event.start > event.end) {
      errorResults.timeOrder = EventValidationErrors.ORDER;
      log("End Hour can't be earlier then Start Hour");
    }
    // recurrence
    if ([0, 1, 2, 3].includes(event.recurrence.type)) {
      if (event.recurrence.type === EventRecurrenceTypes.ONCE) {
        if (!event.recurrence.date) {
          errorResults.date = EventValidationErrors.REQUIRED;
          log("Date is required");
        }
      }
      if (event.recurrence.type === EventRecurrenceTypes.DAILY) {
        if (!event.recurrence.everyXDays) {
          errorResults.everyXDays = EventValidationErrors.REQUIRED;
          log("Day is required");
        }
      }
      if (event.recurrence.type === EventRecurrenceTypes.WEEKLY) {
        if (!event.recurrence.weekdays?.length) {
          errorResults.weekdays = EventValidationErrors.REQUIRED;
          log("Day is required");
        }
      }
      if (event.recurrence.type === EventRecurrenceTypes.MONTHLY) {
        if (
          typeof event.recurrence.dayOfMonth !== "number" ||
          !event.recurrence.dayOfMonth
        ) {
          errorResults.dayOfMonth = EventValidationErrors.REQUIRED;
          log("Day of Month is required");
        }
        if (
          typeof event.recurrence.everyXMonths !== "number" ||
          !event.recurrence.everyXMonths
        ) {
          errorResults.everyXMonths = EventValidationErrors.REQUIRED;
          log("Month is required");
        }
      }

      // recurrence range
      if (event.recurrence.type !== EventRecurrenceTypes.ONCE) {
        if ([0, 1, 2].includes(event.recurrence.rangeType)) {
          if (!event.recurrence.rangeStart) {
            errorResults.rangeStart = EventValidationErrors.REQUIRED;
            log("Start Date is required");
          }
          if (
            event.recurrence.rangeType === EventRecurrenceRangeTypes.END_BY_DATE
          ) {
            if (!event.recurrence.rangeEnd) {
              errorResults.rangeEnd = EventValidationErrors.REQUIRED;
              log("End Date is required");
            } else if (
              event.recurrence.rangeStart > event.recurrence.rangeEnd
            ) {
              errorResults.dateOrder = EventValidationErrors.ORDER;
              log("End Date can't be earlier than the Start Date");
            }
          }
          if (
            event.recurrence.rangeType ===
              EventRecurrenceRangeTypes.END_AFTER_OCCURRENCES &&
            !event.recurrence.occurrences
          ) {
            errorResults.occurrences = EventValidationErrors.REQUIRED;
            log("Occurrence is required");
          }
        } else {
          errorResults.rangeType = EventValidationErrors.REQUIRED;
        }
      }
    } else {
      errorResults.type = EventValidationErrors.REQUIRED;
    }

    return errorResults;
  }
}
