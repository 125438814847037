import React from "react";
import { AppSwitchControl } from "../../../../../Shared/Components/Controls/SwitchControl";
import { AppFormCategory } from "../../../../../Shared/Components/FormCategory";
import { AppGrid } from "../../../../../Shared/Components/Grid";
import { TimeHelper } from "../../../../../Shared/Helpers/time-helper";
import { IDisciplinaryActionWeekDay } from "../../../../../Shared/Models/Entities/disciplinary-action.interface";

export const AppDisciplinaryActionWeekdayControl = (props: {
  canEdit: boolean;
  disciplinaryActionWeekdays: IDisciplinaryActionWeekDay[];
  manageDisciplinaryActionWeekDays: (
    disciplinaryActionWeekDayId: number
  ) => (_: unknown, checked: boolean) => void;
  manageAllWeekDays: (checked: boolean) => void;
}): JSX.Element => {
  const selectedDisciplinaryActionWeekDayIds =
    props.disciplinaryActionWeekdays.map((dAWD) => dAWD.weekdayId);
  const allWeekdaysChecked = [1, 2, 3, 4, 5].every((weekDayId) =>
    selectedDisciplinaryActionWeekDayIds.includes(weekDayId)
  );
  return (
    <AppGrid container item direction="column" xs={6} spacing={3}>
      <AppFormCategory
        title="Days When Action Can Happen"
        gridProps={{ xs: 12 }}
      />
      <AppGrid container item direction="column" spacing={2}>
        {TimeHelper.getDayOfWeekString().map((dayString, index) => (
          <AppSwitchControl
            key={`${dayString}-switch-control`}
            label={dayString}
            checked={props.disciplinaryActionWeekdays
              .map((dAW) => dAW.weekdayId)
              .includes(index)}
            onChange={props.manageDisciplinaryActionWeekDays(index)}
          />
        ))}
      </AppGrid>
      <AppSwitchControl
        label={"Weekdays"}
        checked={allWeekdaysChecked}
        onChange={(_, checked) => {
          props.manageAllWeekDays(checked);
        }}
      />
    </AppGrid>
  );
};
