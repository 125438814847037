import React from "react";
import { TableCell as MuiTableCell } from "@material-ui/core";
import { TableCellProps } from "@material-ui/core/TableCell/TableCell";
import { appMakeStyles } from "../../../Themes/app-make-styles";

const BaseTableCellStyles = appMakeStyles<IBaseTableCellProps>((theme) => ({
  tableCell: {
    borderBottom: "1px solid " + theme.palette.divider,
    width: (props) => (props.width ? props.width + "px" : "none"),
    maxWidth: (props) => (props.width ? props.width + "px" : "none"),
    wordWrap: "break-word",
  },
}));

export interface IBaseTableCellProps extends TableCellProps {
  width?: number;
  children?: React.ReactNode;
}

export const AppBaseTableCell = (props: IBaseTableCellProps): JSX.Element => {
  const classes = BaseTableCellStyles(props);

  return <MuiTableCell {...props} className={classes.tableCell} />;
};
