import { appMakeStyles } from "../Shared/Themes/app-make-styles";

export const ProjectStyles = appMakeStyles(() => ({
  project: {
    display: "flex",
    "flex-direction": "row",
    top: "0",
    height: "100vh",
  },
  sideBar: {
    flex: "0 0 auto",
    display: "flex",
  },
  mainPanelWrapper: {
    flex: "1 0 0",
    position: "relative",
  },
  mainPanelContent: {
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  navBar: {},
  content: {
    padding: "0 24px 80px",
    minHeight: "calc(100vh - 123px)",
    maxWidth: "1800px",
  },
}));
