import { IIntervention } from "../../../../Shared/Models/Entities/interventions.interface";

export enum InterventionValidationErrors {
  REQUIRED = "Name is required",
}
export interface IInterventionValidationResults {
  name?: InterventionValidationErrors;
}

export class InterventionEditorPanelHelper {
    static getBlankIntervention(): IIntervention {
      return {
        id: -1,
        name: "",
        description: "",
        type: 0,
        canOverride: false
      };
    }
    static validateIntervention(intervention: IIntervention): IInterventionValidationResults {
      const results = {} as IInterventionValidationResults;
            
      if (!intervention.name) {
        results.name = InterventionValidationErrors.REQUIRED;
      }

      return results;
    }

}