import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../../../Shared/Components/Dialogs/CustomDialog";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../../Shared/Themes/palette-types.enum";
import { AppTracCodeInterventionEditorPanelHelper } from "../TracCodeInterventionEditorPanel/TracCodeInterventionEditorPanel.helper";
import { AppTracCodeInterventionEditorPanel } from "../TracCodeInterventionEditorPanel/TracCodeInterventionEditorPanel";
import { IPopulatedTracCode } from "../../../../../Shared/Models/Entities/populated-trac-code.interface";
import { StoreHelper } from "../../../../../Shared/Reducers/store-helper";

export const AppTracCodeInterventionCreateDialog = (props: {
  tracCode: IPopulatedTracCode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => void;
}): JSX.Element => {
  const schoolId = StoreHelper.selector((state) => state.appConfig?.school?.id);
  const tracCodeUId = props.tracCode.id;

  const emptyTracCodeIntervention =
    AppTracCodeInterventionEditorPanelHelper.getBlankTracCodeIntervention(
      schoolId,
      tracCodeUId
    );
  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title={`Add Intervention to ${props.tracCode.name}`}
      icon={Icons.Add}
      palette={PaletteTypes.SUCCESS}
    >
      <AppTracCodeInterventionEditorPanel
        tracCode={props.tracCode}
        tcIntervention={emptyTracCodeIntervention}
        isCreating={true}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
