import { combineReducers } from "redux";
import { SchoolDistrictManagerReducerSlice } from "./SchoolDistrictManager/school-district-manager.reducer";
import { SnackbarReducerSlice } from "./Snackbar/snackbar.reducer";
import { LoadingBarReducerSlice } from "./LoadingBar/loading-bar.reducer";
import { AppConfigReducerSlice } from "./AppConfig/app-config.reducer";
import { AuthReducerSlice } from "./Auth/auth-reducer";

export const rootReducer = combineReducers({
  appConfig: AppConfigReducerSlice.reducer,
  auth: AuthReducerSlice.reducer,
  districts: SchoolDistrictManagerReducerSlice.reducer,
  loadingBar: LoadingBarReducerSlice.reducer,
  snackbar: SnackbarReducerSlice.reducer,
});
