import { HttpClient } from "../Helpers/http-client";
import { IEventCategory } from "../../Pages/EventCategories/event-category.interface";

export class EventCategoryService {
  static async get({
    schoolId,
    districtId,
  }: {
    schoolId?: number;
    districtId?: number;
  }): Promise<IEventCategory[]> {
    return HttpClient.get({
      path: "/event-categories",
      params: {
        ...(schoolId && {
          schoolId,
        }),
        ...(districtId && {
          districtId,
        }),
      },
    });
  }

  static async create(
    eventCategory: IEventCategory,
    schoolId: number,
    districtId: number
  ): Promise<IEventCategory> {
    return HttpClient.post({
      path: "/event-categories",
      body: eventCategory,
      params: {
        ...(schoolId && { schoolId }),
        ...(districtId && { districtId }),
      },
    });
  }

  static async update(
    eventCategory: IEventCategory,
    schoolId: number,
    districtId: number
  ): Promise<IEventCategory> {
    return HttpClient.put({
      path: "/event-categories/" + eventCategory.id,
      body: eventCategory,
      params: {
        ...(schoolId && { schoolId }),
        ...(districtId && { districtId }),
      },
    });
  }

  static async delete(eventCategoryId: number): Promise<IEventCategory> {
    return HttpClient.delete({
      path: "/event-categories/" + eventCategoryId,
    });
  }

  static async canDelete(eventCategoryId: number): Promise<boolean> {
    return HttpClient.get({
      path: "/event-categories/" + eventCategoryId + "/can-delete",
    });
  }
}
