import React from "react";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppButton } from "../../../../Shared/Components/Button";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { SchoolUserRoleService } from "../../../../Shared/Services/school-user-role.service";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";

export const AppDistrictRolesTable = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const canEdit = hasPermission(AppPermissionsEnum.ROLES_EDIT);

  const { data: schoolUserRoles, loading: isLoading } =
    DataHooks.useFunctionCallState<ISimpleItem[]>({
      func: async () =>
        SchoolUserRoleService.get({
          districtId: district?.id,
          schoolId: school?.id,
          returnGlobalRoles: false,
        }),
      deps: [district?.id, school?.id],
      showLoadingIndicator: true,
    });

  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="Custom Roles"
          subheader="Roles that just belong to your school/group"
          icon={Icons.AccountKey}
          palette={PaletteTypes.PRIMARY}
        />
        <AppCardContent padding={0}>
          <AppCustomTable
            loading={isLoading}
            showLoadingText={
              isLoading ? "Loading..." : "No Custom Roles Found!"
            }
            columns={[
              {
                id: "name",
                label: "Name",
              },
              {
                id: "action",
                label: "Actions",
                size: "small",
                width: 126,
              },
            ]}
            rows={schoolUserRoles?.map((schoolUserRole) => ({
              cells: [
                {
                  value: schoolUserRole.name,
                },
                {
                  value: (
                    <AppButton
                      text={canEdit ? "Edit" : "View"}
                      icon={canEdit ? Icons.Edit : Icons.Visibility}
                      palette={PaletteTypes.SECONDARY}
                      linkTo={
                        "/school-user-roles/" + schoolUserRole.id.toString()
                      }
                    />
                  ),
                },
              ],
            }))}
          />
        </AppCardContent>
      </AppCard>
    </>
  );
};
