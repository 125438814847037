import React, { useState } from "react";
import { PasswordHelper } from "../../../../Auth/PasswordReset/PasswordReset";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { ISchoolUserImportOptions } from "../../../../Shared/Models/Interfaces/csv.interface";
import { SchoolUserRoleService } from "../../../../Shared/Services/school-user-role.service";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

interface ICSVSchoolUserImportOptionsErrors {
  defaultPassword?: boolean;
}

export const AppCSVSchoolUserImportOptions = (props: {
  sendCSV: (options: ISchoolUserImportOptions) => Promise<void>;
}): JSX.Element => {
  const [error, setError] = useState<ICSVSchoolUserImportOptionsErrors>({});
  const [options, setOptions] = useState<ISchoolUserImportOptions>({
    overrideRoles: true,
    overridePasswords: false,
    defaultPassword: "",
  });
  const { data: schoolUserRoles, loading } = DataHooks.useFunctionCallState<
    ISimpleItem[]
  >({
    func: async () => SchoolUserRoleService.get({ returnGlobalRoles: true }),
    showLoadingIndicator: true,
  });

  const handleSubmit = async () => {
    try {
      PasswordHelper.validate(options.defaultPassword, options.defaultPassword);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError({ defaultPassword: error.message });
    }
    await props.sendCSV(options);
  };

  return (
    <AppCard hasHeader>
      <AppCardHeader title="Import Options" palette={PaletteTypes.PRIMARY} />
      <AppCardContent>
        <AppGrid container spacing={3} direction="column">
          <AppSelectControl
            gridProps={{ width: 225 }}
            label="Role"
            value={options.roleId}
            options={schoolUserRoles || []}
            loading={loading}
            selected={(value) => {
              setOptions({
                ...options,
                roleId: value ? Number(value) : undefined,
              });
            }}
          />
          <AppSwitchControl
            label="Override Existing Roles"
            checked={options.overrideRoles}
            onChange={() => {
              setOptions({
                ...options,
                overrideRoles: !options.overrideRoles,
              });
            }}
          />
          <AppSwitchControl
            label="Override Existing Passwords"
            checked={options.overridePasswords}
            onChange={() => {
              setOptions({
                ...options,
                overridePasswords: !options.overridePasswords,
              });
            }}
          />
          <AppTextFieldControl
            label="Enter Password"
            name="password"
            value={options.defaultPassword}
            onChange={(event) => {
              setOptions({
                ...options,
                defaultPassword: event.target.value,
              });
            }}
            error={!!error.defaultPassword}
            icon={Icons.AccountBox}
            opaqueBackground
          />
        </AppGrid>
      </AppCardContent>
      <AppGrid>
        <AppButton
          gridProps={{ width: "100%" }}
          fullWidth
          text="Confirm"
          icon={Icons.Save}
          palette={PaletteTypes.SUCCESS}
          onClick={handleSubmit}
        />
      </AppGrid>
    </AppCard>
  );
};
