import { ISchoolUser } from "../../../Shared/Models/Entities/school-user.interface";

export enum SchoolUserValidationErrors {
  REQUIRED = "Required",
  EMAIL = "Email is not valid",
  PASSWORDS_DONT_MATCH = "Passwords Do Not Match",
  DOES_NOT_MEET_CRITERIA = "Must have at least eight characters, one letter, and one number",
}

export interface ISchoolUserValidationResults {
  email?: SchoolUserValidationErrors;
  access?: SchoolUserValidationErrors;
  role?: SchoolUserValidationErrors;
  password?: SchoolUserValidationErrors;
}

export class SchoolUserViewerHelper {
  static async getDefault(): Promise<ISchoolUser> {
    return {
      id: 0,
      active: true,
      firstName: "",
      lastName: "",
      email: "",
      telephone: "",
      instructorCode: "",
      externalCode: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      country: "",
    };
  }

  static validateSchoolUser(
    user?: ISchoolUser | null
  ): ISchoolUserValidationResults {
    const results = {} as ISchoolUserValidationResults;

    if (!user?.email) {
      results.email = SchoolUserValidationErrors.REQUIRED;
    } else if (!/^\S+@\S+\.\S+$/.test(user.email)) {
      results.email = SchoolUserValidationErrors.EMAIL;
    }

    if (!user?.school?.id && !user?.district?.id) {
      results.access = SchoolUserValidationErrors.REQUIRED;
    }

    if (!user?.role?.id && !user?.permissions?.length) {
      results.role = SchoolUserValidationErrors.REQUIRED;
    }

    if (user?.newPassword) {
      if (user.newPassword !== user.repeatPassword) {
        results.password = SchoolUserValidationErrors.PASSWORDS_DONT_MATCH;
      }
      if (!user.newPassword.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
        results.password = SchoolUserValidationErrors.DOES_NOT_MEET_CRITERIA;
      }
    }

    return results;
  }
}
