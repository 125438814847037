import axios, { Method } from "axios";
import { AuthReducerActions } from "../Reducers/Auth/auth-reducer";
import { LocalStorageKeysEnum } from "../Models/Enums/local-storage-keys.enum";
import { LocalStorageHelper } from "./local-storage.helper";
import { StoreHelper } from "../Reducers/store-helper";
import { SnackbarService } from "../Services/snackbar.service";
import * as qs from "query-string";

export interface IGetOptions {
  path: string;
  externalUrl?: string;
  ignoreAuth?: boolean;
  // eslint-disable-next-line
  params?: any;
}

export interface IPostOptions extends IGetOptions {
  // eslint-disable-next-line
  body?: any;
}

interface IAllOptions extends IGetOptions, IPostOptions {
  method: Method;
}

export class HttpClient {
  public static API_URL = process.env.REACT_APP_AUTHENTICATION_SERVICE_URL;

  public static async get<T>(options: IGetOptions): Promise<T> {
    return this.request({
      ...options,
      method: "get",
    });
  }

  public static async put<T>(options: IPostOptions): Promise<T> {
    return this.request({
      ...options,
      method: "put",
    });
  }

  public static async post<T>(options: IPostOptions): Promise<T> {
    return this.request({
      ...options,
      method: "post",
    });
  }

  public static async delete<T>(options: IPostOptions): Promise<T> {
    return this.request({
      ...options,
      method: "delete",
    });
  }

  private static async request<T>({
    path,
    externalUrl,
    ignoreAuth,
    body,
    params,
    method,
  }: IAllOptions): Promise<T> {
    try {
      const authorizationHeader =
        ignoreAuth || externalUrl ? null : HttpClient.getAuthorizationHeader();
      const url = externalUrl || HttpClient.API_URL + path;

      const response = await axios.request({
        url,
        method,
        headers: {
          ...authorizationHeader,
          "access-control-allow-origin": "*",
        },
        ...(body && {
          data: body,
        }),
        ...(params && {
          params,
        }),
        ...(method === "get" && {
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        }),
      });

      return response.data;
    } catch (error) {
      // eslint-disable-next-line
      const code = (error as any)?.response?.status;
      if (code === 401) {
        StoreHelper.dispatch(AuthReducerActions.clearTokenUser());
        throw new Error("Your token has expired, please login again!");
      } else if (error?.response?.data) {
        SnackbarService.error(error.response.data);
        throw error;
      } else {
        SnackbarService.error("There was an error with an API request!");
        throw error;
      }
    }
  }

  private static getAuthorizationHeader(): { Authorization?: string } {
    // TODO This is pretty sketch. Also refresh isn't implemented yet, so we could maybe just not save tokens on management portal
    const accessToken = LocalStorageHelper.getItem(
      LocalStorageKeysEnum.ACCESS_TOKEN
    );
    if (accessToken) {
      return {
        Authorization: `Bearer ${accessToken}`,
      };
    } else {
      return {};
    }
  }
}
