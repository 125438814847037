import React from "react";
import {AppStudentImportEditorPanel} from "./StudentImportDialogs/StudentImportEditorPanel";

export const AppStudentImportCreate = (): JSX.Element => {

    return (
        <>
            <AppStudentImportEditorPanel />
        </>
    )
}