import React, { useState } from "react";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppDistrictEditor } from "../DistrictEditor/DistrictEditor";
import { IDistrict } from "../../../../Shared/Models/Entities/district.interface";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { DistrictService } from "../../../../Shared/Services/district.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { EffectHooks } from "../../../../Shared/Hooks/effect-hooks";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppDistrictPanel = (props: {
  districtId: number;
  hasSchools: boolean;
}): JSX.Element => {
  const [district, setDistrict] = useState<IDistrict | null>(null);

  EffectHooks.asyncUseEffect(async () => {
    LoadingBarService.show();
    try {
      const district = await DistrictService.getById(props.districtId);
      setDistrict(district);
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  }, [props.districtId]);

  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title={
            district
              ? "School Group : [" + district.id + "] " + district.name
              : "Loading..."
          }
          icon={Icons.District}
          subheader="Expand this school group on the left to check out all of it's schools."
          palette={PaletteTypes.PRIMARY}
        />
        <AppCardContent>
          <AppDistrictEditor
            district={district}
            hasSchools={props.hasSchools}
            isCreating={false}
          />
        </AppCardContent>
      </AppCard>
    </>
  );
};
