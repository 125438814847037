import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { HttpClient } from "../Helpers/http-client";
import { PaginationHelper } from "../Helpers/pagination.helper";
import { IIntervention } from "../Models/Entities/interventions.interface";

export interface IGetInterventionProps {
  searchKey?: string;
  schoolId?: number;
  paginationOptions: IPaginationOptions;
  pagination: boolean;
}

export class InterventionService {
  
  static async getAll(props:IGetInterventionProps): Promise<IIntervention[]> {
    
      return HttpClient.get({
        path: `/interventions/${props.schoolId}`,
        params:{
          ...PaginationHelper.getPaginationParams(props.paginationOptions),
          ...(props?.schoolId && {
            schoolId: props?.schoolId,
          }),
          ...(props?.searchKey && {
            searchKey: props.searchKey,
          }),
          ...(props?.pagination && {
            pagination: props.pagination,
          }),
        }
    });
 

  }
  static async getCount(props:IGetInterventionProps): Promise<number> {
    return HttpClient.get({
        path: `/interventions/count`,
        params:{
          ...(props?.schoolId && {
            schoolId: props?.schoolId,
          }),
          ...(props?.searchKey && {
            searchKey: props.searchKey,
          }),
        }
    });
  }
  static async create(schoolId:number, intervention: IIntervention): Promise<IIntervention> {
    return HttpClient.post({
      path: `/interventions/${schoolId}`,
      body: intervention
    });
  }
  static async delete(interventionId:number | null): Promise<IIntervention> {
    return HttpClient.delete({
      path: `/interventions/${interventionId}`,
    });
  }
  static async update(intervention:IIntervention): Promise<IIntervention> {
    return HttpClient.put({
      path: `/interventions/${intervention.id}`,
      body: intervention
    });
  }

  
}
