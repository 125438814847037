import React, {Dispatch, SetStateAction} from "react";
import {AppCustomDialog} from "../../../Shared/Components/Dialogs/CustomDialog";
import {PaletteTypes} from "../../../Shared/Themes/palette-types.enum";
import {Icons} from "../../../Shared/Components/Icons/Icons";
import {AppStudentImportConfirmPanel} from "./StudentImportConfirmPanel";
import {IStudentImportProcessResult} from "../../../Shared/Models/Entities/student-import-process-result.interface";

export const AppStudentImportPreviewDialog = (props: {
    open: boolean;
    importResult: IStudentImportProcessResult;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onUpdated: () => void;
}): JSX.Element => {

    return (
        <AppCustomDialog
            open={props.open}
            setOpen={props.setOpen}
            fullWidth
            maxWidth="md"
            title="Create a new student import"
            icon={Icons.Upload}
            palette={PaletteTypes.SUCCESS}
        >
            <AppStudentImportConfirmPanel
                studentImportResults={props.importResult}
                setOpen={props.setOpen}
                onUpdated={props.onUpdated}
                isCreating={true}
            />
        </AppCustomDialog>
    )
}