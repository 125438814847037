export class TimeHelper {
  static getNoon(): Date {
    const noon = new Date();
    noon.setHours(12);
    noon.setMinutes(0);
    return noon;
  }

  static getOnePm(): Date {
    const noon = new Date();
    noon.setHours(13);
    noon.setMinutes(0);
    return noon;
  }

  static getTodayTime(date: Date): number {
    const today = new Date();
    today.setHours(date.getHours());
    today.setMinutes(date.getMinutes());

    return today.getTime();
  }

  static formatDateToDBString(date: Date): string {
    if (isNaN(date.valueOf())) return "";
    const offset = date.getTimezoneOffset() * 60000;
    const newDate = new Date(date.getTime() - offset).toISOString();
    return newDate.slice(0, newDate.length - 1);
  }

  static getTime(hour: number, min: number): string {
    const time = new Date();
    time.setHours(hour);
    time.setMinutes(min);
    return this.formatDateToDBString(time);
  }

  static isValidDate(date: Date | string | null): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  static parseDateFromInvalidDateString(
    invalidDateString: string
  ): Date | null {
    const date = new Date();
    const cleanDateString = invalidDateString.replace(/:| /, "");

    // if leading number is not 0 or 1, shift numbers to make leading number the hour
    if (
      cleanDateString[0] !== "0" &&
      cleanDateString[0] !== "1" &&
      cleanDateString[1]
    ) {
      if (["P", "p"].includes(cleanDateString[3])) {
        // if PM, add 12 hours
        date.setHours(Number(cleanDateString[0]) + 12);
      } else {
        date.setHours(parseInt(cleanDateString[0]));
      }

      const minutes = parseInt(cleanDateString[1] + cleanDateString[2]);
      if (minutes <= 60) {
        date.setMinutes(minutes);
      }

      return date;
    }

    // if leading char in period, create date with chars in valid positions
    if (
      cleanDateString[5] &&
      !["A", "a", "P", "p"].includes(cleanDateString[5])
    ) {
      date.setHours(parseInt(cleanDateString[0] + cleanDateString[1]));
      const minutes = parseInt(cleanDateString[2] + cleanDateString[3]);
      if (minutes <= 60) {
        date.setMinutes(minutes);
      }
      return date;
    }

    return null;
  }

  static changeTimeToBaseDate(baseDate: Date, time: Date): Date {
    const newDate = new Date(baseDate.getTime());
    newDate.setHours(time.getHours());
    newDate.setMinutes(time.getMinutes());
    return newDate;
  }

  static formatStrDate(strDate: string): string {
    if (strDate) {
      return TimeHelper.formatDate(new Date(strDate));
    } else {
      return "";
    }
  }
  static formatStrTime(strDate: string): string {
    if (strDate) {
      return TimeHelper.formatTime(new Date(strDate));
    } else {
      return "";
    }
  }

  static formatDate(date: Date | null): string {
    return date?.toLocaleString() || "";
  }
  static formatShortDate(date: Date | null): string {
    return date?.toLocaleDateString() || "";
  }

  static formatTime(date: Date | null): string {
    return date?.toLocaleTimeString([], { timeStyle: "short" }) || "";
  }

  static isSameDay(first: Date, second: Date): boolean {
    return (
      first.getFullYear() === second.getFullYear() &&
      first.getMonth() === second.getMonth() &&
      first.getDate() === second.getDate()
    );
  }

  static getDayOfWeekString(): string[] {
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
  }
}
