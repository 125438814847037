import React from "react";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { IPopulatedTracCode } from "../../../../Shared/Models/Entities/populated-trac-code.interface";
import { AppTracCodeViewer } from "../TracCodeViewer";

class TracCodeViewerHelper {
  static getDefault(): IPopulatedTracCode {
    return {
      id: -1,
      name: "",
      description: "",
      code: "",
      type: 2,
      active: true,
      fastTrack: true,
      printPass: true,
      passMessage: "",
      tracCodeInterventions: [],
      tracCodeDisciplinaryActions: [],
      rollBackTracCode: {
        id: -1,
        enabled: false,
        beginAt: 0,
        triggerAt: 0,
        reward: 0,
        rewardCount: 0,
        excludedDays: [],
      },
      tracCodeEvent: {
        id: -1,
        refrenceCode: "",
      },
    };
  }
}

export const AppTracCodeCreateViewer = (): JSX.Element => {
  const {
    data: tracCode,
    setData: setTracCode,
    refreshData: refreshTracCode,
    loading,
  } = DataHooks.useFunctionCallState({
    func: TracCodeViewerHelper.getDefault,
    showLoadingIndicator: true,
  });
  return (
    <>
      <AppTracCodeViewer
        tracCode={tracCode as IPopulatedTracCode}
        setTracCode={setTracCode}
        refreshTracCode={refreshTracCode}
        loading={loading}
        isCreating={true}
      />
    </>
  );
};
