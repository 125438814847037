import React, { useState } from "react";
import { AppButton } from "../../../Shared/Components/Button";
import { AppGrid } from "../../../Shared/Components/Grid";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { IPaginationOptions } from "../../../Shared/Components/Tables/TablePagination/pagination-options.interface";
import { TablePaginationHelper } from "../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { IDisciplinaryAction } from "../../../Shared/Models/Entities/disciplinary-action.interface";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { DisciplinaryActionService } from "../../../Shared/Services/disciplinary-action.service";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppDisciplinaryActionList } from "./DisciplinaryActionList/DisciplinaryActionList";
import { AppDisciplinaryActionFilters } from "./DisciplinaryActionListFilters/DisciplinaryActionListFilters";
import { AppDisciplinaryActionManagerDialog } from "./DisciplinaryActionManagerDialog/DisciplinaryActionManagerDialog";

export interface DisciplinaryActionFilters {
  searchKey?: string;
  inactive?: boolean;
  paginationOptions?: IPaginationOptions;
}

export const AppDisciplinaryActionManager = (): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT);
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const [disciplinaryActionId, setDisciplinaryActionId] = useState(-1);
  const [showEditorModal, setShowEditorModal] = useState(false);
  const [isClone, setIsClone] = useState(false);
  const [filters, setFilters] = useState<DisciplinaryActionFilters>({
    paginationOptions: TablePaginationHelper.getDefaultOptions(),
  });
  const {
    loading,
    data: disciplinaryActions,
    refreshData: refreshDisciplinaryActions,
  } = DataHooks.useFunctionCallState<IDisciplinaryAction[]>({
    func: () =>
      DisciplinaryActionService.getAll({
        schoolId: school.id,
        paginationOptions: filters.paginationOptions,
        searchKey: filters.searchKey,
      }),
    showLoadingIndicator: true,
    deps: [filters],
  });

  const {
    data: count,
    loading: countLoading,
    refreshData: refreshCount,
  } = DataHooks.useFunctionCallState<number>({
    func: () =>
      DisciplinaryActionService.getCount({
        schoolId: school?.id,
        searchKey: filters.searchKey,
        paginationOptions: filters.paginationOptions,
      }),
    deps: [filters],
  });

  const refreshData = async () => {
    const disciplinaryActionCall = refreshDisciplinaryActions();
    const countCall = refreshCount();

    await disciplinaryActionCall;
    await countCall;
  };

  return (
    <AppGrid container direction="column" spacing={2}>
      <AppGrid
        item
        container
        direction="row"
        wrap="wrap-reverse"
        alignItems="flex-start"
        justifyContent="space-between"
        spacing={5}
      >
        <AppDisciplinaryActionFilters
          filters={filters}
          setFilters={(filters) => {
            setFilters({
              ...filters,
              // Reset pagination since we are viewing a new list
              paginationOptions: TablePaginationHelper.getDefaultOptions(),
            });
          }}
        />
        <AppGrid item container spacing={2} marginLeft="auto">
          <AppButton
            text="Manage Trac Codes"
            icon={Icons.Edit}
            size="large"
            palette={PaletteTypes.PRIMARY}
            linkTo={`/trac-codes`}
          />
          {canEdit && (
            <AppButton
              text="Add"
              icon={Icons.Add}
              size="large"
              palette={PaletteTypes.SUCCESS}
              onClick={() => {
                setDisciplinaryActionId(-1);
                setShowEditorModal(true);
                setIsClone(!!isClone);
              }}
            />
          )}
        </AppGrid>
      </AppGrid>
      <AppGrid item>
        <AppDisciplinaryActionList
          canEdit={canEdit}
          disciplinaryActions={disciplinaryActions ? disciplinaryActions : []}
          loading={loading}
          count={count ? count : 0}
          countLoading={countLoading}
          filters={filters}
          setFilters={setFilters}
          setShowViewerModal={(id: number, isClone?: boolean) => {
            setDisciplinaryActionId(id);
            setShowEditorModal(true);
            setIsClone(!!isClone);
          }}
        />
      </AppGrid>
      {showEditorModal && (
        <AppDisciplinaryActionManagerDialog
          disciplinaryActionId={disciplinaryActionId}
          open={showEditorModal}
          setOpen={setShowEditorModal}
          onDataUpdated={refreshData}
          schoolId={school.id}
          isClone={isClone}
          canEdit={canEdit}
        />
      )}
    </AppGrid>
  );
};
