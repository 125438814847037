import { HttpClient } from "../Helpers/http-client";
import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { PaginationHelper } from "../Helpers/pagination.helper";
import { IStudentSimple } from "../Models/Entities/student-simple.interface";

export class StudentService {
  static async get({
    externalId,
    externalIds,
    firstName,
    lastName,
    grade,
    schoolId,
    districtId,
    paginationOptions,
  }: {
    externalId?: string;
    externalIds?: number[];
    firstName?: string;
    lastName?: string;
    grade?: string;
    schoolId?: number;
    districtId?: number;
    paginationOptions: IPaginationOptions;
  }): Promise<IStudentSimple[]> {
    return HttpClient.get({
      path: "/students",
      params: {
        ...(externalId && { externalId }),
        ...(externalIds && { externalIds }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(grade && { grade }),
        ...(schoolId && {
          schoolId,
        }),
        ...(districtId && {
          districtId,
        }),
        ...PaginationHelper.getPaginationParams(paginationOptions),
      },
    });
  }

  static async getCount({
    externalId,
    externalIds,
    firstName,
    lastName,
    grade,
    schoolId,
    districtId,
  }: {
    externalId?: string;
    externalIds?: number[];
    firstName?: string;
    lastName?: string;
    grade?: string;
    schoolId?: number;
    districtId?: number;
  }): Promise<number> {
    return HttpClient.get({
      path: "/students/count",
      params: {
        ...(externalId && { externalId }),
        ...(externalIds && { externalIds }),
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(grade && { grade }),
        ...(schoolId && {
          schoolId,
        }),
        ...(districtId && {
          districtId,
        }),
      },
    });
  }
}
