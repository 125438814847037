import { IRouteConfig } from "./route.helper";
import { AppSchoolDistrictManager } from "../Pages/Schools/SchoolDistrictManager/SchoolDistrictManager";
import { AppAdminUserManager } from "../Pages/AdminUsers/AdminUserManager/AdminUserManager";
import { AppSchoolUserList } from "../Pages/SchoolUsers/SchoolUserList/SchoolUserList";
import { AppSchoolUserViewer } from "../Pages/SchoolUsers/SchoolUserViewer/SchoolUserViewer";
import { AppSchoolUserRoleList } from "../Pages/SchoolUserRoles/SchoolUserRoleList/SchoolUserRoleList";
import { Icons } from "../Shared/Components/Icons/Icons";
import { AppSchoolUserRoleViewer } from "../Pages/SchoolUserRoles/SchoolUserRoleViewer/SchoolUserRoleViewer";
import { hasPermission } from "../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../Shared/Models/Enums/app-permissions.enum";
import { isSchoolUser } from "../Shared/Hooks/isSchoolUser";
import { AppSchoolViewer } from "../Pages/Schools/SchoolViewer/SchoolViewer";
import { AppEventList } from "../Pages/Events/EventList/EventList";
import { AppEventViewer } from "../Pages/Events/EventViewer/EventViewer";
import { AppEventCategoryList } from "../Pages/EventCategories/EventCategoryList/EventCategoryList";
import { AppEventSupervisorList } from "../Pages/EventSupervisors/EventSupervisorList/EventSupervisorList";
import { AppBellScheduleViewer } from "../Pages/BellSchedules/BellScheduleViewer/BellScheduleViewer";
import { AppBellSchedulesManager } from "../Pages/BellSchedules/BellSchedulesManager/BellSchedulesManager";
import { AppTracCodesManager } from "../Pages/TracCodes/TracCodesManager/TracCodesManager";
import { AppTracCodeManageInterventions } from "../Pages/TracCodes/ManageIntervention/MangeInterventions";
import { AppDisciplinaryActionManager } from "../Pages/TracCodes/ManageDisciplinaryAction/ManageDisciplinaryAction";
import { AppTracCodeCreateViewer } from "../Pages/TracCodes/TracCodeViewer/TracCodeCreateViewer/TracCodeCreateViewer";
import { AppTracCodeEditViewer } from "../Pages/TracCodes/TracCodeViewer/TracCodeEditViewer/TracCodeEditViewer";
import { AppStudentImportList} from "../Pages/StudentImports/StudentImportList/StudentImportList";
import { AppSchoolUserImport } from "../Pages/SchoolUsers/SchoolUserImport/SchoolUserImport";
import {AppStudentImportCreate} from "../Pages/StudentImports/StudentImportCreateEdit";

export const getRouteConfigs = (): IRouteConfig[] => {
  return [
    ...[
      isSchoolUser()
        ? {
            key: "school-info-viewer",
            path: "/school/:id",
            name: "School Info",
            icon: Icons.School,
            component: AppSchoolViewer,
          }
        : {
            key: "school-manager",
            path: "/school-manager",
            name: "Schools",
            icon: Icons.School,
            component: AppSchoolDistrictManager,
          },
    ],
    {
      key: "school-users-list",
      path: "/school-users",
      exact: true,
      name: "School Users",
      icon: Icons.Group,
      enabled: hasPermission(AppPermissionsEnum.SCHOOL_USERS_GET),
      component: AppSchoolUserList,
      children: [
        {
          key: "school-users-create",
          path: "/school-users/create",
          name: "Create",
          icon: Icons.Add,
          enabled: hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT),
          component: AppSchoolUserViewer,
        },
        {
          key: "school-users-edit",
          path: "/school-users/:id",
          name: hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT)
            ? "Edit"
            : "View",
          icon: hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT)
            ? Icons.Edit
            : Icons.Visibility,
          enabled: hasPermission(AppPermissionsEnum.SCHOOL_USERS_GET),
          component: AppSchoolUserViewer,
        },
        {
          key: "school-users-import",
          path: "/school-users-import",
          name: "Import",
          icon: Icons.PersonAdd,
          requireSchool: true,
          enabled: hasPermission(AppPermissionsEnum.IMPORT_SCHOOL_USERS),
          component: AppSchoolUserImport,
        },
        {
          key: "school-users-roles",
          path: "/school-user-roles",
          exact: true,
          name: "Roles",
          icon: Icons.AssignmentInd,
          enabled: hasPermission(AppPermissionsEnum.ROLES_GET),
          requireSchoolOrGroup: true,
          component: AppSchoolUserRoleList,
          children: [
            {
              key: "school-users-roles-creator",
              path: "/school-user-roles/create",
              name: "Create",
              icon: Icons.Add,
              enabled: hasPermission(AppPermissionsEnum.ROLES_EDIT),
              requireSchoolOrGroup: true,
              component: AppSchoolUserRoleViewer,
            },
            {
              key: "school-users-roles-viewer",
              path: "/school-user-roles/:id",
              name: hasPermission(AppPermissionsEnum.ROLES_EDIT)
                ? "Edit"
                : "View",
              icon: hasPermission(AppPermissionsEnum.ROLES_EDIT)
                ? Icons.Edit
                : Icons.Visibility,
              enabled: hasPermission(AppPermissionsEnum.ROLES_EDIT),
              requireSchoolOrGroup: true,
              component: AppSchoolUserRoleViewer,
            },
          ],
        },
      ],
    },
    {
      key: "bell-schedules",
      path: "/bell-schedules",
      name: "Bell Schedules",
      icon: Icons.Bell,
      enabled: hasPermission(AppPermissionsEnum.BELL_SCHEDULES_GET),
      requireSchool: true,
      component: AppBellSchedulesManager,
      children: [
        {
          key: "bell-schedule-edit",
          path: "/bell-schedule/:id",
          name: hasPermission(AppPermissionsEnum.BELL_SCHEDULES_EDIT)
            ? "Edit"
            : "View",
          icon: Icons.Edit,
          requireSchool: true,
          component: AppBellScheduleViewer,
        },
        {
          key: "bell-schedule-create",
          path: "/bell-schedule",
          name: "Create",
          icon: Icons.Add,
          requireSchool: true,
          component: AppBellScheduleViewer,
        },
      ],
    },
    {
      key: "trac-codes",
      path: "/trac-codes",
      name: "Trac Codes",
      icon: Icons.FountainPen,
      enabled: hasPermission(AppPermissionsEnum.TRAC_CODES_VIEW),
      requireSchoolOrGroup: true,
      component: AppTracCodesManager,
      children: [
        {
          key: "interventions",
          path: "/interventions/:id",
          name: hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT)
            ? "Manage Interventions"
            : "View",
          icon: hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT)
            ? Icons.Edit
            : Icons.Visibility,
          requireSchoolOrGroup: true,
          component: AppTracCodeManageInterventions,
        },
        {
          key: "trac-codes-viewer",
          path: "/trac-code/create",
          name: "Create",
          icon: Icons.Add,
          requireSchoolOrGroup: true,
          component: AppTracCodeCreateViewer,
          enabled: hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT),
        },
        {
          key: "trac-codes-viewer",
          path: "/trac-code/:id",
          name: hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT)
            ? "Edit"
            : "View",
          icon: hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT)
            ? Icons.Edit
            : Icons.Visibility,
          requireSchoolOrGroup: true,
          component: AppTracCodeEditViewer,
        },
        {
          key: "trac-codes-disciplinary-actions",
          path: "/disciplinary-actions",
          name: "Disciplinary Actions",
          icon: hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT)
            ? Icons.Edit
            : Icons.Visibility,
          requireSchoolOrGroup: true,
          component: AppDisciplinaryActionManager,
        },
      ],
    },
    {
      key: "events",
      path: "/events",
      exact: true,
      name: "Events",
      icon: Icons.Calendar,
      component: AppEventList,
      enabled: hasPermission(AppPermissionsEnum.EVENTS_GET),
      requireSchool: true,
      children: [
        {
          key: "events-create",
          path: "/events/create",
          name: "Create",
          icon: Icons.Add,
          enabled: hasPermission(AppPermissionsEnum.EVENTS_EDIT),
          requireSchoolOrGroup: true,
          component: AppEventViewer,
        },
        {
          key: "events-viewer",
          path: "/events/:id",
          name: hasPermission(AppPermissionsEnum.EVENTS_EDIT) ? "Edit" : "View",
          icon: hasPermission(AppPermissionsEnum.EVENTS_EDIT)
            ? Icons.Edit
            : Icons.Visibility,
          requireSchoolOrGroup: true,
          component: AppEventViewer,
        },
        {
          key: "events-categories",
          path: "/event-categories",
          name: "Categories",
          icon: Icons.Shape,
          enabled: hasPermission(AppPermissionsEnum.EVENTS_EDIT),
          requireSchoolOrGroup: true,
          component: AppEventCategoryList,
        },
        {
          key: "events-supervisors",
          path: "/event-supervisors",
          name: "Supervisors",
          icon: Icons.AccountKey,
          enabled: hasPermission(AppPermissionsEnum.EVENTS_EDIT),
          requireSchoolOrGroup: true,
          component: AppEventSupervisorList,
        },
      ],
    },
    {
      key: "admin-users",
      path: "/admin-users",
      name: "Admin Users",
      icon: Icons.VerifiedUser,
      enabled: hasPermission(AppPermissionsEnum.ADMIN_USERS_GET),
      component: AppAdminUserManager,
    },
    {
      key: "student-imports",
      path: "/student-imports",
      name: "Student Imports",
      requireSchoolOrGroup: true,
      icon: Icons.Upload,
      enabled: hasPermission(AppPermissionsEnum.STUDENT_IMPORT_GET),
      component: AppStudentImportList,
      children: [
        {
          key: "student-imports-create",
          // for some reason I can't get this route to work if I use "/student-imports/create"
          path: "/student-imports-create",
          requireSchoolOrGroup: true,
          name: "New Import",
          icon: Icons.Add,
          enabled: hasPermission(AppPermissionsEnum.STUDENT_IMPORT_CREATE),
          component: AppStudentImportCreate,
        },
        {
          key: "student-imports-edit",
          path: "/student-imports-edit/:id",
          requireSchoolOrGroup: true,
          name: "Edit Import",
          icon: Icons.Edit,
          enabled: hasPermission(AppPermissionsEnum.STUDENT_IMPORT_CREATE),
          component: AppStudentImportCreate,
        }
      ]
    },
  ];
};
