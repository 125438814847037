import { IDistrict } from "../../../../Shared/Models/Entities/district.interface";
import { ValueHelper } from "../../../../Shared/Helpers/value.helper";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";

export enum DistrictValidationErrors {
  REQUIRED = "Required",
  NAME_EXISTS = "Name already exists.",
}

export interface IDistrictValidationResults {
  name?: DistrictValidationErrors;
}

export class DistrictEditorHelper {
  static validateDistrict(
    district: IDistrict | null,
    allDistricts: ISimpleItem[]
  ): IDistrictValidationResults {
    const results = {} as IDistrictValidationResults;

    if (!district?.name) {
      results.name = DistrictValidationErrors.REQUIRED;
    } else if (
      DistrictEditorHelper.doesNameAlreadyExist(district, allDistricts)
    ) {
      results.name = DistrictValidationErrors.NAME_EXISTS;
    }

    return results;
  }

  static doesNameAlreadyExist(
    district: IDistrict,
    allDistricts: ISimpleItem[]
  ): boolean {
    return allDistricts.some(
      (d) =>
        ValueHelper.matchText(district.name, d.name) && district.id !== d.id
    );
  }
}
