import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { ISchoolUserSimple } from "../../../../Shared/Models/Entities/school-user.interface";
import { AppMultipleSelectControl } from "../../../../Shared/Components/Controls/MultipleSelectControl";
import { ISimpleOption } from "../../../../Shared/Models/Entities/simple-option.interface";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppButton } from "../../../../Shared/Components/Button";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { SchoolUserService } from "../../../../Shared/Services/school-user.service";
import { AppConfirmationDialog } from "../../../../Shared/Components/Dialogs/ConfirmationDialog";

export const AppSchoolUserDeactivateUsersDialog = (props: {
  schoolUsers: ISchoolUserSimple[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDelete: () => void;
}): JSX.Element => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [options, setOptions] = useState<ISimpleOption[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleDeactivateUsers = async () => {
    LoadingBarService.show();
    try {
      await deactivateUsers();
      props.setOpen(false);
      props.onDelete();
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  const deactivateUsers = async () => {
    let passed = 0;
    let failed = 0;
    await Promise.all(
      selectedUserIds.map(async (userId) => {
        try {
          await SchoolUserService.toggleActive(Number(userId), false);
          passed += 1;
        } catch (error) {
          Logger.error(error);
          failed += 1;
        }
      })
    );
    if (failed) {
      SnackbarService.error(
        `Deactivate user(s) complete! ${passed} were deactivated. ${failed} failed to update`
      );
    } else {
      SnackbarService.success(
        `Deactivate user(s) complete! ${passed} were deactivated.`
      );
    }
  };

  useEffect(() => {
    setSelectedUserIds([]);
    setOptions(
      props.schoolUsers.map((schoolUser) => ({
        id: schoolUser.id.toString(),
        name: schoolUser.firstName
          ? schoolUser.firstName + " " + schoolUser.lastName
          : schoolUser.email,
      }))
    );
  }, [props.open]);

  return (
    <>
      <AppCustomDialog
        open={props.open}
        setOpen={props.setOpen}
        fullWidth
        maxWidth="sm"
        title="Deactivate Users"
        icon={Icons.AccountMultipleRemove}
        palette={PaletteTypes.ERROR}
      >
        <AppGrid container direction="column" spacing={3}>
          <AppMultipleSelectControl
            label="Select Users"
            value={selectedUserIds}
            options={options}
            onUpdate={(value) => {
              setSelectedUserIds(value);
            }}
            icon={Icons.Person}
            multiple
          />
          <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
            <AppButton
              text="Cancel"
              icon={Icons.Close}
              palette={PaletteTypes.WARNING}
              variant="outlined"
              onClick={() => props.setOpen(false)}
            />
            <AppButton
              text="Deactivate"
              icon={Icons.Delete}
              palette={PaletteTypes.ERROR}
              onClick={() => setConfirmOpen(true)}
              disabled={!selectedUserIds.length}
            />
          </AppGrid>
        </AppGrid>
      </AppCustomDialog>
      <AppConfirmationDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={`Deactivate ${selectedUserIds.length} Users?`}
        content={`Are you sure you want to deactivate these users?`}
        icon={Icons.Delete}
        agreeAction={handleDeactivateUsers}
        palette={PaletteTypes.ERROR}
      />
    </>
  );
};
