import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { ISchoolUserSimple } from "../../../../Shared/Models/Entities/school-user.interface";
import { ISimpleOption } from "../../../../Shared/Models/Entities/simple-option.interface";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppMultipleSelectControl } from "../../../../Shared/Components/Controls/MultipleSelectControl";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { Logger } from "../../../../Shared/Helpers/logger";
import { SchoolUserService } from "../../../../Shared/Services/school-user.service";
import { AppConfirmationDialog } from "../../../../Shared/Components/Dialogs/ConfirmationDialog";

export const AppSchoolUserUpdatePasswordsDialog = (props: {
  schoolUsers: ISchoolUserSimple[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [options, setOptions] = useState<ISimpleOption[]>([]);
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleUpdatePasswords = async () => {
    if (verifyForm()) {
      LoadingBarService.show();
      try {
        await updatePasswords();
        props.setOpen(false);
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    }
  };

  const verifyForm = (): boolean => {
    const passwordMatch = newPassword === repeatPassword;
    const passwordMeetsCriteria = newPassword.match(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
    );

    if (!passwordMatch) {
      SnackbarService.warn("Passwords do not match!");
      return false;
    } else if (!passwordMeetsCriteria) {
      SnackbarService.warn(
        "Password must have at least eight characters, one letter, and one number"
      );
      return false;
    } else {
      return true;
    }
  };

  const updatePasswords = async () => {
    let passed = 0;
    let failed = 0;
    await Promise.all(
      selectedUserIds.map(async (userId) => {
        try {
          const user = await SchoolUserService.getById(Number(userId));
          user.newPassword = newPassword;
          user.repeatPassword = newPassword;
          await SchoolUserService.update(user);
          passed += 1;
        } catch (error) {
          Logger.error(error);
          failed += 1;
        }
      })
    );
    if (failed) {
      SnackbarService.error(
        `Update password(s) complete! ${passed} user passwords were updated. ${failed} failed to update`
      );
    } else {
      SnackbarService.success(
        `Update password(s) complete! ${passed} user passwords were updated.`
      );
    }
  };

  useEffect(() => {
    setSelectedUserIds([]);
    setNewPassword("");
    setRepeatPassword("");
    setOptions(
      props.schoolUsers.map((schoolUser) => ({
        id: schoolUser.id.toString(),
        name: schoolUser.firstName
          ? schoolUser.firstName + " " + schoolUser.lastName
          : schoolUser.email,
      }))
    );
  }, [props.open]);

  return (
    <>
      <AppCustomDialog
        open={props.open}
        setOpen={props.setOpen}
        fullWidth
        maxWidth="sm"
        title="Update Passwords"
        icon={Icons.AccountKey}
        palette={PaletteTypes.SECONDARY}
      >
        <AppGrid container spacing={3}>
          <AppMultipleSelectControl
            gridProps={{ xs: 12 }}
            label="Select Users"
            value={selectedUserIds}
            options={options}
            onUpdate={(value) => {
              setSelectedUserIds(value);
            }}
            icon={Icons.Person}
            multiple
          />
          <AppTextFieldControl
            gridProps={{ xs: 6 }}
            label="New Password"
            name="newPassword"
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
            }}
            icon={Icons.Lock}
          />
          <AppTextFieldControl
            gridProps={{ xs: 6 }}
            label="Repeat Password"
            name="repeatPassword"
            value={repeatPassword}
            onChange={(event) => {
              setRepeatPassword(event.target.value);
            }}
            icon={Icons.Lock}
          />
          <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
            <AppButton
              text="Cancel"
              icon={Icons.Close}
              palette={PaletteTypes.WARNING}
              variant="outlined"
              onClick={() => props.setOpen(false)}
            />
            <AppButton
              text="Update Passwords"
              icon={Icons.Check}
              palette={PaletteTypes.SUCCESS}
              onClick={() => setConfirmOpen(true)}
              disabled={!selectedUserIds.length}
            />
          </AppGrid>
        </AppGrid>
      </AppCustomDialog>
      <AppConfirmationDialog
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={`Update ${selectedUserIds.length} User Passwords?`}
        content={`Are you sure you want to update the password of these users to be "${newPassword}"?`}
        icon={Icons.AccountKey}
        agreeAction={handleUpdatePasswords}
        palette={PaletteTypes.SUCCESS}
      />
    </>
  );
};
