import { HttpClient } from "../Helpers/http-client";
import { ITracCodeIntervention } from "../Models/Entities/trac-code-intervention.interface";

export class TracCodeInterventionService {
  static async create(
    tcIntervention: ITracCodeIntervention,
  ): Promise<ITracCodeIntervention> {
    return HttpClient.post({
      path: `/trac-code-intervention/${tcIntervention.tracCodeUId}`,
      body: tcIntervention,
    });
  }
  static async delete(
    tcInterventionId: number
  ): Promise<ITracCodeIntervention> {
    return HttpClient.delete({
      path: `/trac-code-intervention/${tcInterventionId}`,
    });
  }
  static async update(
    tcIntervention: ITracCodeIntervention
  ): Promise<ITracCodeIntervention> {
    return HttpClient.put({
      path: `/trac-code-intervention/${tcIntervention.tracCodeInterventionId}`,
      body: tcIntervention,
    });
  }
}
