import { IPaginationOptions } from "./pagination-options.interface";

export class TablePaginationHelper {
  public static getDefaultOptions(size = 10): IPaginationOptions {
    return {
      sizeOptions: [5, 10, 25],
      size: size,
      page: 0,
    };
  }
}
