import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  IChurnInfo,
  SchoolService,
} from "../../../../Shared/Services/school.service";
import { AcademicYearService } from "../../../../Shared/Services/academic-year.service";
import { SubscriptionService } from "../../../../Shared/Services/subscription.service";
import { SupportTierService } from "../../../../Shared/Services/support-tier.service";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { AppDateControl } from "../../../../Shared/Components/Controls/DateControl";
import { ISchool } from "../../../../Shared/Models/Entities/school.interface";
import { Logger } from "../../../../Shared/Helpers/logger";
import {
  ITimezone,
  TimeZonesHelper,
} from "../../../../Shared/Helpers/time-zones.helper";
import { SchoolEditorHelper } from "./SchoolEditor.helper";
import { DistrictTreeService } from "../../../../Shared/Services/district-tree.service";
import { ISchoolValidationResults } from "./SchoolValidation.interface";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { SchoolDistrictManagerReducerActions } from "../../../../Shared/Reducers/SchoolDistrictManager/school-district-manager.reducer";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { AppAutocompleteControl } from "../../../../Shared/Components/Controls/AutocompleteControl";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { Utils } from "../../../../Shared/Helpers/utils";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { AppButton } from "../../../../Shared/Components/Button";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppSchoolEditor = (props: {
  school: ISchool | null;
  isCreating: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  churnInfo?: IChurnInfo | null;
  churnPressed?: () => void;
}): JSX.Element => {
  const canEditSubscription = hasPermission(
    AppPermissionsEnum.SCHOOLS_SUBSCRIPTION_EDIT
  );
  const canViewEnrollment = hasPermission(
    AppPermissionsEnum.SCHOOLS_ENROLLMENT_VIEW
  );
  const canUpdate = props.isCreating
    ? hasPermission(AppPermissionsEnum.SCHOOLS_CREATE)
    : hasPermission(AppPermissionsEnum.SCHOOLS_EDIT);
  const canDelete = hasPermission(AppPermissionsEnum.SCHOOLS_DELETE);

  // Get our school to edit
  const [editingSchool, setEditingSchool] = useState<ISchool | null>(null);
  useEffect(() => {
    setEditingSchool(Utils.cloneObject(props.school));
  }, [props.school?.id]);
  useEffect(() => {
    updateErrorResults();
  }, [editingSchool]);

  // Get our dropdown result
  const districts = StoreHelper.selector((state) => state.districts.districts);
  const [academicYears, setAcademicYears] = useState([] as ISimpleItem[]);
  const [subscriptions, setSubscriptions] = useState([] as ISimpleItem[]);
  const [supportTiers, setSupportTiers] = useState([] as ISimpleItem[]);
  const [timeZones, setTimeZones] = useState([] as ITimezone[]);

  useEffect(() => {
    AcademicYearService.getAcademicYears().then((items) => {
      setAcademicYears(items);
    });
    SubscriptionService.getSubscriptions().then((items) => {
      setSubscriptions(items);
    });
    SupportTierService.getSupportTiers().then((items) => {
      setSupportTiers(items);
    });
    setTimeZones(TimeZonesHelper.getTimeZones());
  }, []);

  // Handle setting academic year automatically
  useEffect(() => {
    if (
      editingSchool &&
      !editingSchool.currentAcademicYear &&
      academicYears?.length
    ) {
      editingSchool.currentAcademicYear =
        academicYears[academicYears.length - 1];
    }
  }, [academicYears, editingSchool]);

  // Store our form variables
  const [haveTriedSubmitting, setHaveTriedSubmitting] = useState(false);
  const [errorResults, setErrorResults] = useState(
    {} as ISchoolValidationResults
  );

  const updateSchool = (updatedSchool: Partial<ISchool>) => {
    if (editingSchool) {
      setEditingSchool({ ...editingSchool, ...updatedSchool });
    }
  };

  const updateErrorResults = () => {
    if (editingSchool && (!props.isCreating || haveTriedSubmitting)) {
      const errorResults = SchoolEditorHelper.validateSchool({
        school: editingSchool,
        logWarnings: false,
        canEditSubscription,
        canViewEnrollment,
        canUpdate,
      });
      setErrorResults(errorResults);
    } else {
      setErrorResults({});
    }
  };

  const handleClose = () => {
    props.setOpen?.(false);
  };

  async function handleSubmit(): Promise<void> {
    if (editingSchool) {
      const errorResults = SchoolEditorHelper.validateSchool({
        school: editingSchool,
        logWarnings: true,
        canEditSubscription,
        canViewEnrollment,
        canUpdate,
      });
      const isValid = Object.values(errorResults).length === 0;
      setHaveTriedSubmitting(true);
      setErrorResults(errorResults);
      if (isValid) {
        LoadingBarService.show();
        try {
          await SchoolService.upsertSchool(editingSchool);
          await DistrictTreeService.reloadData();
          StoreHelper.dispatch(
            SchoolDistrictManagerReducerActions.setSelectedSchool(
              editingSchool.id
            )
          );
          SnackbarService.success(
            (props.isCreating ? "Created " : "Updated ") + editingSchool.name
          );
          handleClose();
        } catch (error) {
          Logger.error(error);
          SnackbarService.error(error);
        }
        LoadingBarService.hide();
      }
    }
  }

  return (
    <AppGrid container spacing={3}>
      <AppFormCategory gridProps={{ xs: 12 }} title="Basic Info" />
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="School Name *"
        name="schoolName"
        value={editingSchool?.name}
        onChange={(event) => {
          updateSchool({
            name: event.target.value,
          });
        }}
        error={!!errorResults.name}
        disabled={!canUpdate}
        icon={Icons.School}
      />
      <AppAutocompleteControl
        gridProps={{ xs: 6 }}
        label="School Group *"
        options={districts}
        selectedId={editingSchool?.districtId}
        selectedIdUpdated={(selectedId) => {
          updateSchool({
            districtId: selectedId,
          });
        }}
        error={!!errorResults.district}
        disabled={!canUpdate}
        icon={Icons.District}
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="Reference Id *"
        name="schoolReferenceId"
        value={editingSchool?.referenceId}
        type="number"
        onChange={(event) => {
          updateSchool({
            referenceId: (event.target.value as string) || null,
          });
        }}
        error={!!errorResults.referenceId}
        disabled={!canUpdate}
        icon={Icons.Database}
      />
      <AppSelectControl
        gridProps={{ xs: 4 }}
        label="Time Zone *"
        name="schoolTimeZone"
        value={editingSchool?.timeZone}
        selected={(value) => {
          updateSchool({
            timeZone: value || undefined,
          });
        }}
        options={timeZones}
        error={!!errorResults.timeZone}
        disabled={!canUpdate}
        icon={Icons.ClockOutline}
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="Phone"
        name="schoolPhone"
        value={editingSchool?.phone}
        onChange={(event) => {
          updateSchool({
            phone: event.target.value as string,
          });
        }}
        disabled={!canUpdate}
        icon={Icons.Phone}
      />
      <AppTextFieldControl
        gridProps={{ xs: 8 }}
        label="Email Address *"
        name="schoolEmail"
        value={editingSchool?.email}
        onChange={(event) => {
          updateSchool({
            email: event.target.value as string,
          });
        }}
        error={!!errorResults.email}
        disabled={!canUpdate}
        icon={Icons.Email}
      />
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="Principal Name"
        name="schoolPrincipalName"
        value={editingSchool?.principalName}
        onChange={(event) => {
          updateSchool({
            principalName: event.target.value as string,
          });
        }}
        disabled={!canUpdate}
        icon={Icons.AccountBox}
      />

      <AppFormCategory gridProps={{ xs: 12 }} title="Subscription" />
      <AppSelectControl
        gridProps={{ xs: 4 }}
        label="Subscription *"
        name="schoolSubscription"
        value={editingSchool?.customerSubscription?.subscription?.id}
        selected={(value) => {
          if (editingSchool) {
            const subscriptionId = value;
            updateSchool({
              customerSubscription: {
                ...(editingSchool.customerSubscription || {}),
                subscription:
                  subscriptions.find(
                    (subscription) =>
                      subscription.id?.toString() === subscriptionId?.toString()
                  ) || null,
              },
            });
          }
        }}
        options={subscriptions}
        error={!!errorResults.subscription}
        disabled={!canUpdate || !canEditSubscription}
        icon={Icons.FountainPen}
      />
      <AppSelectControl
        gridProps={{ xs: 4 }}
        label="Support Tier *"
        name="schoolSupportTier"
        value={editingSchool?.customerSubscription?.supportTier?.id}
        options={supportTiers}
        selected={(value) => {
          if (editingSchool) {
            const supportTierId = value;
            updateSchool({
              customerSubscription: {
                ...editingSchool.customerSubscription,
                supportTier: supportTiers.find(
                  (supportTier) =>
                    supportTier.id?.toString() === supportTierId?.toString()
                ),
              },
            });
          }
        }}
        error={!!errorResults.supportTier}
        disabled={!canUpdate || !canEditSubscription}
        icon={Icons.FaceAgent}
      />
      <AppSelectControl
        gridProps={{ xs: 4 }}
        label="Academic Year *"
        value={editingSchool?.currentAcademicYear?.id}
        options={academicYears}
        disabled
        icon={Icons.Calendar}
        selected={null}
      />
      <AppDateControl
        gridProps={{ xs: 4 }}
        label="Starts *"
        value={editingSchool?.customerSubscription?.date?.start || null}
        onChange={(date) => {
          if (editingSchool) {
            updateSchool({
              customerSubscription: {
                ...editingSchool.customerSubscription,
                date: {
                  ...editingSchool.customerSubscription?.date,
                  start: date,
                },
              },
            });
          }
        }}
        error={!!errorResults.subscriptionStart}
        disabled={!canUpdate || !canEditSubscription}
      />
      <AppDateControl
        gridProps={{ xs: 4 }}
        label="Ends *"
        value={editingSchool?.customerSubscription?.date?.end || null}
        onChange={(date) => {
          if (editingSchool) {
            updateSchool({
              customerSubscription: {
                ...(editingSchool.customerSubscription || {}),
                date: {
                  ...(editingSchool.customerSubscription?.date || {}),
                  end: date,
                },
              },
            });
          }
        }}
        error={!!errorResults.subscriptionEnd}
        disabled={!canUpdate || !canEditSubscription}
      />
      {canViewEnrollment && (
        <AppTextFieldControl
          gridProps={{ xs: 4 }}
          label="Enrollment *"
          name="schoolEnrollment"
          placeholder="How many users?"
          type="number"
          value={editingSchool?.customerSubscription?.enrollment}
          onChange={(event) => {
            if (editingSchool) {
              updateSchool({
                customerSubscription: {
                  ...(editingSchool.customerSubscription || {}),
                  enrollment: event.target.value as string,
                },
              });
            }
          }}
          error={!!errorResults.enrollment}
          disabled={!canUpdate || !canEditSubscription}
          inputProps={{ min: "1", max: "4999", step: "1" }}
          icon={Icons.AccountBoxOutlined}
        />
      )}
      <AppTextFieldControl
        gridProps={{ xs: 4 }}
        label="SalesForce ID"
        name="SalesForceID"
        value={editingSchool?.salesForceId}
        onChange={(event) => {
          updateSchool({
            salesForceId: event.target.value as string,
          });
        }}
        disabled={!canUpdate}
        icon={Icons.AccountBox}
      />
      <AppSwitchControl
        gridProps={{ xs: 4 }}
        label="Active"
        checked={!!editingSchool?.customerSubscription?.active}
        onChange={(event) => {
          if (editingSchool) {
            updateSchool({
              customerSubscription: {
                ...editingSchool.customerSubscription,
                active: event.target.checked,
              },
            });
          }
        }}
      />
      <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
        {canUpdate && (
          <AppButton
            gridProps={{ hidden: !props.isCreating }}
            text="Cancel"
            icon={Icons.Close}
            variant="outlined"
            palette={PaletteTypes.WARNING}
            onClick={() => handleClose()}
          />
        )}
        {canDelete && (
          <AppButton
            gridProps={{
              hidden: props.isCreating || !!props.churnInfo,
            }}
            text="Add To Churn Queue"
            icon={Icons.Delete}
            disabled={!!props.churnInfo}
            palette={PaletteTypes.ERROR}
            onClick={async () => props.churnPressed?.()}
          />
        )}
        {canUpdate && (
          <AppButton
            text={props.isCreating ? "Create" : "Update"}
            icon={props.isCreating ? Icons.Add : Icons.Check}
            palette={PaletteTypes.SUCCESS}
            onClick={() => handleSubmit()}
            disabled={
              JSON.stringify(props.school) === JSON.stringify(editingSchool)
            }
          />
        )}
      </AppGrid>
    </AppGrid>
  );
};
