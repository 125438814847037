import { LocalStorageKeysEnum } from "../Models/Enums/local-storage-keys.enum";

/* eslint-disable @typescript-eslint/no-explicit-any  */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
/* eslint-disable no-restricted-syntax  */
export class LocalStorageHelper {
  static getItem(key: LocalStorageKeysEnum): any {
    return localStorage.getItem(key);
  }

  static setItem(key: LocalStorageKeysEnum, value: any): void {
    localStorage.setItem(key, value);
  }

  static removeItem(key: LocalStorageKeysEnum): void {
    localStorage.removeItem(key);
  }

  static getObject(key: LocalStorageKeysEnum): any {
    const value = LocalStorageHelper.getItem(key);
    return LocalStorageHelper.parseJSON(value);
  }

  static parseJSON(json: string | null): any {
    return json ? JSON.parse(json) || null : null;
  }
}
