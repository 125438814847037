import React from "react";
import { Paper, Table as MuiTable, TableProps } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import { appMakeStyles } from "../../../Themes/app-make-styles";

const BaseTableStyles = appMakeStyles(() => ({
  tableContainer: {
    boxShadow: "none",
  },
  table: {},
}));

// eslint-disable-next-line
export type BaseTableProps = TableProps & {};

export const AppBaseTable = (props: BaseTableProps): JSX.Element => {
  const classes = BaseTableStyles({});

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <MuiTable {...props} className={classes.table} />
    </TableContainer>
  );
};
