import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppSchoolEditor } from "../SchoolEditor/SchoolEditor";
import { SchoolEditorHelper } from "../SchoolEditor/SchoolEditor.helper";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppSchoolCreateDialog = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const school = SchoolEditorHelper.getBlankSchool();

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title="Create School"
      icon={Icons.School}
      palette={PaletteTypes.SUCCESS}
    >
      <AppSchoolEditor
        school={school}
        setOpen={props.setOpen}
        isCreating={true}
      />
    </AppCustomDialog>
  );
};
