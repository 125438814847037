import { HttpClient } from "../Helpers/http-client";
import { IDistrict } from "../Models/Entities/district.interface";
import { ISimpleItem } from "../Models/Entities/simple-item.interface";

export class DistrictService {
  static async getAllSimple(): Promise<ISimpleItem[]> {
    return HttpClient.get({ path: "/districts" });
  }

  static async getById(id: number): Promise<IDistrict> {
    return HttpClient.get({ path: "/districts/" + id });
  }

  static async upsertDistrict(district: IDistrict): Promise<IDistrict> {
    return HttpClient.post({
      path: "/districts/upsert",
      body: {
        Id: district.id,
        Name: district.name,
        ParentId: district.parentId,
        SalesForceId: district.salesForceId
      },
    });
  }

  static async delete(districtId: number): Promise<void> {
    return HttpClient.delete({
      path: `/districts?districtId=${districtId}`,
    });
  }
}
