import React, { useState } from "react";
import { IEvent } from "../../../../Shared/Models/Entities/event.interface";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { EventCategoryService } from "../../../../Shared/Services/event-category.service";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { IEventValidationResults } from "../EventViewer.helper";
import { AppButton } from "../../../../Shared/Components/Button";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { IEventSupervisor } from "../../../../Shared/Models/Entities/event-supervisor.interface";
import { EventSupervisorService } from "../../../../Shared/Services/event-supervisor.service";
import { AppRadioControl } from "../../../../Shared/Components/Controls/RadioControl";
import { AppEventSupervisorCreateDialog } from "../../../EventSupervisors/EventSupervisorCreateDialog/EventSupervisorCreateDialog";
import { AppEventCategoryCreateDialog } from "../../../EventCategories/EventCategoryCreateDialog/EventCategoryCreateDialog";
import { AppNumberControl } from "../../../../Shared/Components/Controls/NumberControl";

export const AppEventInfo = ({
  event,
  updateEvent,
  errorResults,
}: {
  event: IEvent | null;
  updateEvent: (event: IEvent) => void;
  errorResults: IEventValidationResults;
}): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  const [createCategoryPopupOpen, setCreateCategoryPopupOpen] = useState(false);
  const [createSupervisorPopupOpen, setCreateSupervisorPopupOpen] =
    useState(false);

  const categoriesState = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: () =>
      EventCategoryService.get({
        schoolId: school?.id,
        districtId: district?.id,
      }),
    deps: [school?.id, district?.id],
    showLoadingIndicator: true,
  });
  const supervisorsState = DataHooks.useFunctionCallState<IEventSupervisor[]>({
    func: () =>
      EventSupervisorService.get({
        schoolId: school?.id,
        districtId: district?.id,
      }),
    deps: [school?.id, district?.id],
    showLoadingIndicator: true,
  });

  const handleUpdate = (props: Partial<IEvent>) => {
    if (event) {
      updateEvent({
        ...event,
        ...props,
      });
    }
  };

  return (
    <AppGrid container spacing={3}>
      <AppTextFieldControl
        gridProps={{ xs: 12 }}
        label="Name *"
        value={event?.name || ""}
        onChange={(event) => {
          handleUpdate({
            name: event.target.value,
          });
        }}
        icon={Icons.AccountBox}
        disabled={!canEdit}
        error={!!errorResults.name}
      />
      <AppGrid item xs={6} container spacing={1}>
        <AppSelectControl
          gridProps={{ xs: canEdit ? 10 : 12 }}
          label="Category"
          placeholder="None"
          icon={Icons.Shape}
          value={event?.category?.id}
          selected={(value) => {
            handleUpdate({
              category: value
                ? {
                    id: Number(value),
                    name: "",
                  }
                : undefined,
            });
          }}
          options={categoriesState.data || []}
          disabled={!canEdit}
        />
        {canEdit && (
          <AppButton
            gridProps={{ xs: 2 }}
            height={"100%"}
            size="small"
            icon={Icons.Add}
            palette={PaletteTypes.SUCCESS}
            onClick={() => setCreateCategoryPopupOpen(true)}
          />
        )}
      </AppGrid>
      <AppGrid item xs={6} container spacing={1}>
        <AppSelectControl
          gridProps={{ xs: canEdit ? 10 : 12 }}
          label="Supervisor"
          placeholder="None"
          icon={Icons.Shape}
          value={event?.supervisor?.id}
          selected={(value) => {
            handleUpdate({
              supervisor: value
                ? {
                    id: Number(value),
                    key: "",
                    name: "",
                    canManageEvent: true,
                  }
                : undefined,
            });
          }}
          options={supervisorsState.data || []}
          disabled={!canEdit}
        />
        {canEdit && (
          <AppButton
            gridProps={{ xs: 2 }}
            height={"100%"}
            size="small"
            icon={Icons.Add}
            palette={PaletteTypes.SUCCESS}
            onClick={() => setCreateSupervisorPopupOpen(true)}
          />
        )}
      </AppGrid>
      <AppEventCapacity
        event={event}
        canEdit={canEdit}
        errorResults={errorResults}
        updateCapacity={(capacity) => {
          handleUpdate({
            capacity,
          });
        }}
      />
      <AppGrid item xs={6} container direction="column" spacing={3}>
        <AppSwitchControl
          label="Active"
          checked={!!event?.active}
          onChange={() => {
            handleUpdate({
              active: !event?.active,
            });
          }}
          disabled={!canEdit}
        />
        <AppSwitchControl
          label="Must Scan Out"
          checked={!!event?.mustScanOut}
          onChange={() => {
            handleUpdate({
              mustScanOut: !event?.mustScanOut,
            });
          }}
          disabled={!canEdit}
        />
        <AppSwitchControl
          label="Allow Multiple Entries"
          checked={!!event?.allowMultipleEntries}
          onChange={() => {
            handleUpdate({
              allowMultipleEntries: !event?.allowMultipleEntries,
            });
          }}
          disabled={!canEdit}
        />
      </AppGrid>
      <AppEventCategoryCreateDialog
        open={createCategoryPopupOpen}
        setOpen={setCreateCategoryPopupOpen}
        onDataUpdated={async () => categoriesState.refreshData()}
      />
      <AppEventSupervisorCreateDialog
        open={createSupervisorPopupOpen}
        setOpen={setCreateSupervisorPopupOpen}
        onDataUpdated={async () => supervisorsState.refreshData()}
      />
    </AppGrid>
  );
};

export const AppEventCapacity = ({
  event,
  canEdit,
  updateCapacity,
  errorResults,
}: {
  event: IEvent | null;
  canEdit: boolean;
  updateCapacity: (capacity: number) => void;
  errorResults: IEventValidationResults;
}): JSX.Element => {
  let capacityType = "limited";
  if (event?.capacity === 0) {
    capacityType = "preregistered";
  }
  if (event?.capacity === -1) {
    capacityType = "unlimited";
  }

  return (
    <AppGrid item xs={6} container direction="column" spacing={3}>
      <AppRadioControl
        label="Capacity Type *"
        value={capacityType}
        valueUpdated={(value) => {
          switch (value) {
            case "preregistered":
              return updateCapacity(0);
            case "unlimited":
              return updateCapacity(-1);
            case "limited":
              return updateCapacity(1);
          }
        }}
        options={[
          {
            label: "Pre-Registered Only",
            value: "preregistered",
          },
          {
            label: "Unlimited Capacity",
            value: "unlimited",
          },
          {
            label: "Limited Capacity",
            value: "limited",
          },
        ]}
        disabled={!canEdit}
        error={!!errorResults.capacity}
      />
      {capacityType === "limited" && (
        <AppNumberControl
          label="Capacity Amount *"
          num={event?.capacity}
          min={1}
          numUpdated={(num) => {
            updateCapacity(num);
          }}
          icon={Icons.AccountBox}
          disabled={!canEdit}
        />
      )}
    </AppGrid>
  );
};
