import React from "react";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppButton } from "../../../../Shared/Components/Button";
import { IDOMEvent } from "../../../../Shared/Models/Interfaces/dom-event.interface";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { AppAutocompleteControl } from "../../../../Shared/Components/Controls/AutocompleteControl";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { DistrictService } from "../../../../Shared/Services/district.service";
import { ISchoolSimple } from "../../../../Shared/Models/Entities/school.interface";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { SchoolService } from "../../../../Shared/Services/school.service";

export const AppAdminUserListFilters = (props: {
  searchHandler: (event: IDOMEvent) => void;
  searchKey: string;
  district?: ISimpleItem | null;
  setDistrict: (district: ISimpleItem | null) => void;
  school?: ISchoolSimple | null;
  setSchool: (school: ISchoolSimple | null) => void;
  setCreateAdminUserDialogOpen: (open: boolean) => void;
  setCreateInternalAdminUserDialogOpen: (open: boolean) => void;
}): JSX.Element => {
  const isSuperAdmin = hasPermission(AppPermissionsEnum.SUPER_ADMIN);
  const canEdit = hasPermission(AppPermissionsEnum.ADMIN_USERS_EDIT);

  const districtsData = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: DistrictService.getAllSimple,
  });
  const schoolsData = DataHooks.useFunctionCallState<ISchoolSimple[]>({
    func: SchoolService.getAllSimple,
  });

  return (
    <AppGrid container item direction="column">
      <AppGrid
        container
        item
        direction="row"
        xs={12}
        md={12}
        lg={12}
        justifyContent="space-between"
        spacing={3}
      >
        <AppTextFieldControl
          gridProps={{ xs: 6, lg: 4 }}
          fullWidth
          opaqueBackground
          id="filled-basic"
          placeholder="Search by name or email"
          icon={Icons.Search}
          onChange={props.searchHandler}
          value={props.searchKey}
        />
        <AppAutocompleteControl
          gridProps={{ xs: 5, lg: 3 }}
          placeholder="All School Groups"
          icon={Icons.District}
          options={districtsData.data || []}
          selectedId={props.district?.id}
          selectedIdUpdated={(selectedId) => {
            const selectedDistrict = districtsData.data?.find(
              (d) => d.id === selectedId
            );
            props.setDistrict(selectedDistrict || null);
          }}
          loading={districtsData.loading}
          opaqueBackground
          disableClearable
        />

        <AppAutocompleteControl
          gridProps={{ xs: 5, lg: 3 }}
          placeholder="All Schools"
          icon={Icons.School}
          options={schoolsData.data || []}
          selectedId={props.school?.id}
          selectedIdUpdated={(selectedId) => {
            const selectedSchool = schoolsData.data?.find(
              (s) => s.id === selectedId
            );
            props.setSchool(selectedSchool || null);
          }}
          loading={schoolsData.loading}
          opaqueBackground
          disableClearable
        />
      </AppGrid>
      <AppGrid
        direction="row"
        container
        item
        spacing={2}
        justifyContent="flex-end"
        alignItems="center"
      >
        {canEdit && (
          <AppButton
            text="Create Admin User"
            icon={Icons.Add}
            size="large"
            palette={PaletteTypes.SUCCESS}
            onClick={() => {
              props.setCreateAdminUserDialogOpen(true);
            }}
          />
        )}
        {canEdit && isSuperAdmin && (
          <AppButton
            text="Create SchoolMint User"
            icon={Icons.Add}
            size="large"
            palette={PaletteTypes.SUCCESS}
            onClick={() => {
              props.setCreateInternalAdminUserDialogOpen(true);
            }}
          />
        )}
      </AppGrid>
    </AppGrid>
  );
};
