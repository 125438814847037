import React from "react";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { Dispatch, SetStateAction } from "react";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { SchoolUserRoleService } from "../../../../Shared/Services/school-user-role.service";
import { Logger } from "../../../../Shared/Helpers/logger";
import { AppConfirmationDialog } from "../../../../Shared/Components/Dialogs/ConfirmationDialog";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppSchoolUserRoleDeleteDialog = (props: {
  schoolUserRole: ISimpleItem | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onUserUpdated: () => void;
}): JSX.Element => {
  const handleDeleteConfirmed = async () => {
    if (props.schoolUserRole) {
      LoadingBarService.show();
      try {
        await SchoolUserRoleService.delete(props.schoolUserRole.id);
        void props.onUserUpdated();
        SnackbarService.success("Deleted " + props.schoolUserRole.name);
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    } else {
      Logger.error("No role passed to archive dialog!");
    }
  };

  return (
    <AppConfirmationDialog
      open={props.open}
      setOpen={props.setOpen}
      title={"Delete Role?"}
      content={"Are you sure you want to delete this role?"}
      icon={Icons.Delete}
      agreeAction={handleDeleteConfirmed}
      palette={PaletteTypes.ERROR}
    />
  );
};
