import {ITracCode} from "../../../../Shared/Models/Entities/trac-code.interface";

export enum TracCodeValidationErrors {
    REQUIRED = "Required",
}

export interface ITracCodeValidationResults {
    name: TracCodeValidationErrors;
    passMessage: TracCodeValidationErrors;
    hasErrors: boolean;
}

export class TracCodeHelper {
    static async getDefault(): Promise<ITracCode> {
        return {
            active: false,
            code: "",
            description: "",
            district: undefined,
            fastTrack: false,
            passMessage: "",
            printPass: false,
            school: undefined,
            schoolGroupId: 0,
            schoolId: 0,
            type: 0,
            id: 0,
            name: ""
        };
    }

    static validateTracCode(
        tracCode?: ITracCode | null
    ): ITracCodeValidationResults {
        const results = {} as ITracCodeValidationResults;

        if (!tracCode?.name || tracCode.name.trim() === "") {
            results.name = TracCodeValidationErrors.REQUIRED;
            results.hasErrors = true;
        }

        if (!tracCode?.passMessage || tracCode.passMessage.trim() === "") {
            results.passMessage = TracCodeValidationErrors.REQUIRED;
            results.hasErrors = true;
        }

        return results;
    }
}