import { ISchoolUserRole } from "../../../Shared/Models/Entities/school-user-role.interface";

export enum SchoolUserRoleValidationErrors {
  REQUIRED = "Required",
}

export interface ISchoolUserRoleValidationResults {
  name: SchoolUserRoleValidationErrors;
  posNegTracCodePermission: SchoolUserRoleValidationErrors;
}

export class SchoolUserRoleViewerHelper {
  static async getDefault(): Promise<ISchoolUserRole> {
    return {
      id: 0,
      name: "",
      permissionIds: [],
      isGlobal: false,
      posNegTracCodePermission: 0,
    };
  }

  static validateSchoolUserRole(
    role?: ISchoolUserRole | null
  ): ISchoolUserRoleValidationResults {
    const results = {} as ISchoolUserRoleValidationResults;

    if (!role?.name || role.name.trim() === "") {
      results.name = SchoolUserRoleValidationErrors.REQUIRED;
    }

    if (typeof role?.posNegTracCodePermission !== "number") {
      results.posNegTracCodePermission =
        SchoolUserRoleValidationErrors.REQUIRED;
    }

    return results;
  }
}
