import React from "react";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { GridTypeMap } from "@material-ui/core/Grid/Grid";
import { AppGrid } from "../Grid";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RadioControlStyles = appMakeStyles<IRadioControlProps>((theme) => ({
  formControl: {},
  formLabel: {
    fontSize: theme.typography.largeFontSize,
    lineHeight: 1.5,
  },
}));

export interface IRadioControlProps {
  label: string;
  value: string;
  valueUpdated: (value: string) => void;
  options: Array<{
    label: string;
    value: string;
  }>;
  gridProps?: Partial<GridTypeMap["props"]>;
  disabled?: boolean;
  error?: boolean;
}

export const AppRadioControl = (props: IRadioControlProps): JSX.Element => {
  const classes = RadioControlStyles(props);
  const { label, value, valueUpdated, options, gridProps, disabled, error } =
    props;

  return (
    <>
      <AppGrid item {...gridProps}>
        <FormControl className={classes.formControl} error={error}>
          <FormLabel className={classes.formLabel}>{label}</FormLabel>
          <RadioGroup
            value={value}
            onChange={(event, newValue) => {
              valueUpdated(newValue);
            }}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={option.value.toString() + index}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.label}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </AppGrid>
    </>
  );
};
