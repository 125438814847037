import { useState, useRef, useEffect, DependencyList } from "react";
import { SnackbarService } from "../Services/snackbar.service";
import { Logger } from "../Helpers/logger";
import { SessionStorageHelper } from "../Helpers/session-storage.helper";
import { LoadingBarService } from "../Services/loading-bar.service";
import { LocalStorageHelper } from "../Helpers/local-storage.helper";
import { SessionStorageKeysEnum } from "../Models/Enums/session-storage-keys.enum";
import { LocalStorageKeysEnum } from "../Models/Enums/local-storage-keys.enum";

export class DataHooks {
  public static getDataWithCallback<T>({
    func,
    callback,
    deps,
    showLoadingIndicator,
  }: {
    func: () => Promise<T>;
    callback: (data: T) => void;
    deps?: DependencyList;
    showLoadingIndicator?: boolean;
  }): {
    error: Error | null;
    loading: boolean;
  } {
    const isMounted = useRef(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    async function init() {
      if (showLoadingIndicator) {
        LoadingBarService.show();
      }
      try {
        const data = await func();
        if (isMounted.current && callback) {
          setLoading(false);
          callback(data);
        }
      } catch (e) {
        Logger.error(e);
        SnackbarService.error(e);
        if (isMounted.current) {
          setError(e);
        }
      }
      if (showLoadingIndicator) {
        LoadingBarService.hide();
      }
    }

    useEffect(() => {
      isMounted.current = true;

      void init();
      return () => {
        isMounted.current = false;
      };
    }, deps || []);

    return { error, loading };
  }

  /**
   * Easily adds powerful state functionality for any function call
   *
   * @prop func the core function we want to call. Passes our current data as props
   * @prop deps when should react auto refresh this data call?
   * @prop showLoadingIndicator should we show our loading bar while this call is running
   *
   * @returns data the current result of the function
   * @returns setData a function for manually setting data
   * @returns refreshData a function that tells our hook to refetch data (can also be determined automatically through deps)
   * @returns error the current error of the function
   * @returns loading is our function loading? stays true during errors (TODO: Maybe turn false during errors?)
   */
  public static useFunctionCallState<T>({
    func,
    deps,
    showLoadingIndicator,
  }: {
    func: (currData?: T | null) => Promise<T | null> | T | null;
    deps?: DependencyList;
    showLoadingIndicator?: boolean;
  }): {
    data: T | null;
    setData: (data: T | null) => void;
    refreshData: () => void;
    error: Error | null;
    loading: boolean;
  } {
    const [data, setData] = useState(null as T | null);
    const [error, setError] = useState(null as Error | null);
    const [loading, setLoading] = useState(true);

    const runIdRef = useRef<string>("");

    const getDataCall = async (runId: string) => {
      try {
        const response = await func(data);
        if (runId === runIdRef.current) {
          setData(response);
          setLoading(false);
        }
      } catch (e) {
        Logger.error(e);
        SnackbarService.error(e);
        setError(e);
      }
      if (showLoadingIndicator && runId === runIdRef.current) {
        LoadingBarService.hide();
      }
    };

    const startDataCall = () => {
      if (!loading) {
        setLoading(true);
      }
      if (showLoadingIndicator) {
        LoadingBarService.show();
      }
      const runId = Math.round(Math.random() * 1000000).toString();
      runIdRef.current = runId;
      void getDataCall(runId);
    };

    useEffect(() => startDataCall(), deps || []);

    return { data, setData, error, loading, refreshData: startDataCall };
  }

  /**
   * Hook similar to useState() but allow reading and writing to local or session storage
   */
  public static useCachedState<T>(props: {
    defaultObject: T;
    sessionStorageKey?: SessionStorageKeysEnum;
    localStorageKey?: LocalStorageKeysEnum;
    getStorableObject?: (object: T) => T;
  }): [T, (object: T) => void] {
    const storedObject = props.sessionStorageKey
      ? SessionStorageHelper.getObject(props.sessionStorageKey)
      : props.localStorageKey
      ? LocalStorageHelper.getObject(props.localStorageKey)
      : null;

    const [object, setObject] = useState<T>(
      storedObject || props.defaultObject
    );

    const handleSetObject = (updatedObject: T) => {
      setObject(updatedObject);
      const clonedObject = JSON.parse(JSON.stringify(updatedObject));
      const storableObject =
        props.getStorableObject?.(clonedObject) || clonedObject;
      if (props.sessionStorageKey) {
        SessionStorageHelper.setItem(
          props.sessionStorageKey,
          JSON.stringify(storableObject)
        );
      }
      if (props.localStorageKey) {
        LocalStorageHelper.setItem(
          props.localStorageKey,
          JSON.stringify(storableObject)
        );
      }
    };

    return [object, handleSetObject];
  }
}
