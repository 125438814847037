import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { StoreHelper } from "../../../Reducers/store-helper";
import { LightPalette } from "../../../Themes/light-palette";
import { DarkPalette } from "../../../Themes/dark-palette";

export const HeroIcon = (): JSX.Element => {
  const theme = StoreHelper.selector((state) => state.appConfig.theme);
  const color =
    theme === "light"
      ? LightPalette.navigation.background
      : DarkPalette.text.primary;

  return (
    <SvgIcon width="20" height="26" viewBox="0 0 20 26">
      <defs>
        <path id="uj6jw1ltea" d="M0 0L19.652 0 19.652 26.02 0 26.02z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g transform="translate(-19 -24) translate(19 24)">
            <mask id="sxbrtfndbb" fill="#fff">
              <use href="#uj6jw1ltea" />
            </mask>
            <path
              fill={color}
              d="M0 23.085h2.763V2.945H.082V0h7.062v12.08c1.699-3.02 3.626-3.81 6.268-3.81 3.21 0 4.947 2.266 4.947 5.74v9.105l1.293.002v2.902h-7.498v-2.934h1.824v-8.017c0-1.247-.3-2.228-.906-2.832-.453-.492-1.095-.718-1.887-.718-2.002 0-4.041 1.926-4.041 5.023v6.544h2.303v2.935H0v-2.935z"
              mask="url(#sxbrtfndbb)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
