import { createTheme } from "@material-ui/core/styles";
import { DarkPalette } from "./dark-palette";
import { LightPalette } from "./light-palette";
import { ITypography } from "./theme.interface";

export const Themes = {
  light: createTheme({
    palette: LightPalette,
    typography: {
      fontSize: 16,
      largeFontSize: 18,
      headerFontSize: 20,
    } as ITypography,
  }),
  dark: createTheme({
    palette: DarkPalette,
    typography: {
      fontSize: 16,
      largeFontSize: 18,
      headerFontSize: 20,
    } as ITypography,
  }),
};
