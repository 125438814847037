import React, { Dispatch, SetStateAction, useState } from "react";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppCustomDialog } from "../../../Shared/Components/Dialogs/CustomDialog";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { ISchoolSimple } from "../../../Shared/Models/Entities/school.interface";
import { SchoolService } from "../../../Shared/Services/school.service";
import { ISimpleItem } from "../../../Shared/Models/Entities/simple-item.interface";
import { DistrictService } from "../../../Shared/Services/district.service";
import { AppAutocompleteControl } from "../../../Shared/Components/Controls/AutocompleteControl";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { AppConfigReducerActions } from "../../../Shared/Reducers/AppConfig/app-config.reducer";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppButton } from "../../../Shared/Components/Button";
import { AppRadioControl } from "../../../Shared/Components/Controls/RadioControl";
import { AppAccessTypesEnum } from "../../../Shared/Models/Enums/app-access-types.enum";

export const AppSchoolDistrictSelector = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const {
    user: { internal, belongsToDistrict },
  } = StoreHelper.selector((state) => state.auth);
  const districtsData = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: DistrictService.getAllSimple,
  });
  const schoolsData = DataHooks.useFunctionCallState<ISchoolSimple[]>({
    func: SchoolService.getAllSimple,
  });

  const appConfig = StoreHelper.selector((state) => state.appConfig);
  const [accessType, setAccessType] = useState(appConfig.accessType);
  const [district, setDistrict] = useState(appConfig.district);
  const [school, setSchool] = useState(appConfig.school);

  const [hasChanges, setHasChanges] = useState(false);

  const handleSubmit = () => {
    StoreHelper.dispatch(
      AppConfigReducerActions.setAccess({
        accessType,
        district,
        school,
      })
    );
    handleClose();
  };

  const handleClose = () => {
    props.setOpen?.(false);
  };

  const canUpdate =
    (hasChanges && accessType === AppAccessTypesEnum.GLOBAL) ||
    (accessType === AppAccessTypesEnum.DISTRICT && district) ||
    (accessType === AppAccessTypesEnum.SCHOOL && school);

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="sm"
      title="School & Group Selector"
      subHeader="Select which school or school group you would like to view data for!"
      icon={Icons.School}
      palette={PaletteTypes.PRIMARY}
    >
      <AppGrid container spacing={3}>
        <AppRadioControl
          gridProps={{ xs: 12 }}
          label="Access Type *"
          value={accessType}
          valueUpdated={(value) => {
            setAccessType(value);
            setDistrict(null);
            setSchool(null);
            setHasChanges(true);
          }}
          options={[
            ...(internal
              ? [
                  {
                    label: "Show data for all schools and school groups",
                    value: AppAccessTypesEnum.GLOBAL,
                  },
                ]
              : []),
            ...(belongsToDistrict || internal
              ? [
                  {
                    label: "Show all of the data for a school group",
                    value: AppAccessTypesEnum.DISTRICT,
                  },
                ]
              : []),
            {
              label: "Show all of the data for a school",
              value: AppAccessTypesEnum.SCHOOL,
            },
          ]}
        />

        <AppAutocompleteControl
          gridProps={{
            xs: 12,
            hidden: accessType !== AppAccessTypesEnum.DISTRICT,
          }}
          placeholder="All School Groups"
          icon={Icons.District}
          options={districtsData.data || []}
          selectedId={district?.id}
          selectedIdUpdated={(selectedId) => {
            const selectedDistrict = districtsData.data?.find(
              (d) => d.id === selectedId
            );
            setDistrict(selectedDistrict || null);
            setSchool(null);
            setHasChanges(true);
          }}
          loading={districtsData.loading}
          opaqueBackground
          disableClearable
        />

        <AppAutocompleteControl
          gridProps={{
            xs: 12,
            hidden: accessType !== AppAccessTypesEnum.SCHOOL,
          }}
          placeholder="All Schools"
          icon={Icons.School}
          options={schoolsData.data || []}
          selectedId={school?.id}
          selectedIdUpdated={(selectedId) => {
            const selectedSchool = schoolsData.data?.find(
              (s) => s.id === selectedId
            );
            setDistrict(null);
            setSchool(selectedSchool || null);
            setHasChanges(true);
          }}
          loading={schoolsData.loading}
          opaqueBackground
          disableClearable
        />

        <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
          <AppButton
            text="Cancel"
            icon={Icons.Close}
            palette={PaletteTypes.WARNING}
            variant="outlined"
            onClick={() => handleClose()}
          />
          <AppButton
            text="Update"
            icon={Icons.Check}
            palette={PaletteTypes.PRIMARY}
            disabled={!canUpdate}
            onClick={() => handleSubmit()}
          />
        </AppGrid>
      </AppGrid>
    </AppCustomDialog>
  );
};
