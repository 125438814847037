import React, { useEffect, useState } from "react";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { ISchoolUser } from "../../../../Shared/Models/Entities/school-user.interface";
import { ISchoolUserValidationResults } from "../SchoolUserViewer.helper";
import { AppRadioControl } from "../../../../Shared/Components/Controls/RadioControl";
import { AppAutocompleteControl } from "../../../../Shared/Components/Controls/AutocompleteControl";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { SchoolService } from "../../../../Shared/Services/school.service";
import { DistrictService } from "../../../../Shared/Services/district.service";
import { ISimpleItem } from "../../../../Shared/Models/Entities/simple-item.interface";
import { ISchoolSimple } from "../../../../Shared/Models/Entities/school.interface";
import { SchoolUserRoleService } from "../../../../Shared/Services/school-user-role.service";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppSchoolUserPermissionSelector } from "../../../SchoolUserRoles/SchoolUserRoleViewer/SchoolUserPermissionSelector/SchoolUserPermissionSelector";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";

export const AppSchoolUserAccessEditor = ({
  schoolUser,
  updateSchoolUser,
  errorResults,
}: {
  schoolUser: ISchoolUser | null;
  updateSchoolUser: (schoolUser: ISchoolUser) => void;
  errorResults: ISchoolUserValidationResults;
}): JSX.Element => {
  return (
    <>
      <AppGrid container spacing={3}>
        <AccessTypeOptions
          schoolUser={schoolUser}
          updateSchoolUser={updateSchoolUser}
          errorResults={errorResults}
        />
        <RoleTypeOptions
          schoolUser={schoolUser}
          updateSchoolUser={updateSchoolUser}
          errorResults={errorResults}
        />
      </AppGrid>
    </>
  );
};

const AccessTypeOptions = ({
  schoolUser,
  updateSchoolUser,
  errorResults,
}: {
  schoolUser: ISchoolUser | null;
  updateSchoolUser: (schoolUser: ISchoolUser) => void;
  errorResults: ISchoolUserValidationResults;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);
  const schoolsState = DataHooks.useFunctionCallState<ISchoolSimple[]>({
    func: SchoolService.getAllSimple,
  });
  const districtsState = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: DistrictService.getAllSimple,
  });

  const [accessType, setAccessType] = useState("");

  useEffect(() => {
    if (schoolUser?.district?.id) {
      setAccessType("district");
    } else {
      setAccessType("school");
    }
  }, [schoolUser?.id]);

  return (
    <>
      <AppRadioControl
        label="Access Type *"
        value={accessType}
        valueUpdated={(value) => {
          setAccessType(value);
        }}
        options={[
          {
            label: "User should have access to an entire school group",
            value: "district",
          },
          {
            label: "User should have access to one school",
            value: "school",
          },
        ]}
        error={!!errorResults.access}
        disabled={!canEdit}
      />

      <AppAutocompleteControl
        gridProps={{ xs: 12, hidden: accessType !== "district" }}
        label="School Group"
        placeholder="Select a school group"
        icon={Icons.District}
        options={districtsState.data || []}
        selectedId={schoolUser?.district?.id || null}
        selectedIdUpdated={(selectedId) => {
          if (schoolUser) {
            updateSchoolUser({
              ...schoolUser,
              ...(selectedId
                ? {
                    district: {
                      id: selectedId,
                      name: "",
                    },
                    school: undefined,
                  }
                : { school: undefined, district: undefined }),
              role: undefined,
              permissions: undefined,
            });
          }
        }}
        loading={districtsState.loading}
        error={!!errorResults.access}
        helperText={errorResults.access}
        disabled={!canEdit}
      />

      <AppAutocompleteControl
        gridProps={{ xs: 12, hidden: accessType !== "school" }}
        label="School"
        placeholder="Select a school"
        icon={Icons.School}
        options={schoolsState.data || []}
        selectedId={schoolUser?.school?.id || null}
        selectedIdUpdated={(selectedId) => {
          if (schoolUser) {
            updateSchoolUser({
              ...schoolUser,
              ...(selectedId
                ? {
                    school: {
                      id: selectedId,
                      name: "",
                    },
                    district: undefined,
                  }
                : { school: undefined, district: undefined }),
              role: undefined,
              permissions: undefined,
            });
          }
        }}
        loading={schoolsState.loading}
        error={!!errorResults.access}
        helperText={errorResults.access}
        disabled={!canEdit}
      />
    </>
  );
};

const RoleTypeOptions = ({
  schoolUser,
  updateSchoolUser,
  errorResults,
}: {
  schoolUser: ISchoolUser | null;
  updateSchoolUser: (schoolUser: ISchoolUser) => void;
  errorResults: ISchoolUserValidationResults;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.SCHOOL_USERS_EDIT);
  const rolesState = DataHooks.useFunctionCallState<ISimpleItem[]>({
    func: async () =>
      schoolUser?.school?.id || schoolUser?.district?.id
        ? await SchoolUserRoleService.get({
            schoolId: schoolUser?.school?.id,
            districtId: schoolUser?.district?.id,
            returnGlobalRoles: true,
          })
        : null,
    deps: [schoolUser?.school?.id, schoolUser?.district?.id],
  });

  const [roleType, setRoleType] = useState("");

  useEffect(() => {
    if (schoolUser?.role || !schoolUser?.permissions) {
      setRoleType("role");
    } else {
      setRoleType("permissions");
    }
  }, [schoolUser?.id]);

  return (
    <>
      <AppRadioControl
        label="Role Type *"
        value={roleType}
        valueUpdated={(value) => {
          setRoleType(value);
        }}
        options={[
          {
            label: "User should have a single role",
            value: "role",
          },
          {
            label: "User should have a custom list of permissions",
            value: "permissions",
          },
        ]}
        disabled={
          (!schoolUser?.school?.id && !schoolUser?.district?.id) || !canEdit
        }
        error={!!errorResults.role}
      />
      <AppSelectControl
        gridProps={{ xs: 12, hidden: roleType !== "role" }}
        label="Role"
        name="role"
        placeholder="Select a role"
        value={schoolUser?.role?.id}
        type="number"
        selected={(value) => {
          if (schoolUser && value) {
            const selectedId = Number(value);
            updateSchoolUser({
              ...schoolUser,
              permissions: undefined,
              role: selectedId
                ? {
                    id: selectedId,
                    name: "",
                  }
                : undefined,
            });
          }
        }}
        options={rolesState.data || []}
        loading={rolesState.loading}
        icon={Icons.SupervisedUserCircle}
        disabled={
          (!schoolUser?.school?.id && !schoolUser?.district?.id) || !canEdit
        }
        error={!!errorResults.role}
        helperText={errorResults.role}
      />
      <AppGrid item xs={12} hidden={roleType !== "permissions"}>
        <AppSchoolUserPermissionSelector
          selectedPermissionIds={
            schoolUser?.permissions?.map((permission) => permission.id) || []
          }
          selectedPermissionIdsUpdated={(permissionIds) => {
            if (schoolUser) {
              updateSchoolUser({
                ...schoolUser,
                role: undefined,
                permissions: permissionIds.map((permissionId) => ({
                  id: permissionId,
                  name: "",
                })),
              });
            }
          }}
          loading={!schoolUser}
          disabled={!canEdit}
        />
      </AppGrid>
    </>
  );
};
