import { ITracCodeIntervention } from "../../../../../Shared/Models/Entities/trac-code-intervention.interface";
import { SnackbarService } from "../../../../../Shared/Services/snackbar.service";
export enum TracCodeInterventionValidationErrors {
  REQUIRED = "Required",
  RANGE = "Value should be between 1 and 100",
}
export interface ITracCodeInterventionValidationResults {
  interventionId?: TracCodeInterventionValidationErrors;
  tracCountTrigger?: TracCodeInterventionValidationErrors;
}
export class AppTracCodeInterventionEditorPanelHelper {
  static getBlankTracCodeIntervention(
    schoolId: number,
    tracCodeUId: number
  ): ITracCodeIntervention {
    return {
      tracCodeInterventionId: -1,
      tracCodeUId: tracCodeUId,
      interventionId: -1,
      name: "",
      description: "",
      tracCountTrigger: 1,
      interventionData: "",
      interventionDataFR: "",
      interventionDataES: "",
      schoolId: schoolId,
      isActive: true,
      isDefault: false,
      sms: false,
      push: false,
      email: false,
    };
  }
  static validateTracCodeIntervention(
    tcIntervention: ITracCodeIntervention
  ): ITracCodeInterventionValidationResults {
    const results = {} as ITracCodeInterventionValidationResults;
    const log = (message: string) => {
      SnackbarService.warn(message);
    };

    if (tcIntervention.interventionId === -1) {
      results.interventionId = TracCodeInterventionValidationErrors.REQUIRED;
      log("Intervention is required");
    }
    if (!tcIntervention.tracCountTrigger) {
      results.tracCountTrigger = TracCodeInterventionValidationErrors.REQUIRED;
      log("The trigger number is required");
    } else if (
      Number(tcIntervention.tracCountTrigger) <= 0 ||
      Number(tcIntervention.tracCountTrigger) >= 100
    ) {
      results.tracCountTrigger = TracCodeInterventionValidationErrors.RANGE;
      log("The number must be positive and under 100");
    }
    return results;
  }
}
