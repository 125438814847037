import React, {useState} from "react";
import {AppGrid} from "../../../../Shared/Components/Grid";
import {AppSwitchControl} from "../../../../Shared/Components/Controls/SwitchControl";
import {IGetImportProps} from "../../../../Shared/Services/student-import.service";
import {AppTextFieldControl} from "../../../../Shared/Components/Controls/TextFieldControl";
import {Icons} from "../../../../Shared/Components/Icons/Icons";

export const AppStudentImportListFilters = ({
                                                filters,
                                                setFilters,
                                            }: {
    filters: IGetImportProps;
    setFilters: (props: IGetImportProps) => void;
}): JSX.Element => {
    const [currFilterKey, setCurrFilterKey] = useState(filters.searchKey);
    return (
        <AppGrid item container spacing={3} alignItems="center">
            <AppTextFieldControl
                gridProps={{width: 435}}
                placeholder="Search by import name."
                icon={Icons.Search}
                value={currFilterKey || ""}
                type="search"
                onBlur={() => {
                    if (currFilterKey !== filters.searchKey) {
                        setFilters({
                            ...filters,
                            searchKey: currFilterKey,
                        });
                    }
                }}
                onKeyPress={(event) => {
                    if (event.code === "Enter") {
                        setFilters({
                            ...filters,
                            searchKey: currFilterKey,
                        });
                    }
                }}
                onChange={(event) => {
                    setCurrFilterKey(event.target.value);
                }}
                opaqueBackground
            />
            <AppSwitchControl
                gridProps={{width: 200}}
                label="Only district"
                checked={filters.showGroupOnly}
                onChange={(event) => {
                    const value = event.target.checked;
                    setFilters({
                        ...filters,
                        showGroupOnly: value,
                    });
                }}
            />
            <AppSwitchControl
                gridProps={{width: 239}}
                label="Show Processed"
                checked={filters.showProcessed}
                onChange={(event) => {
                    const value = event.target.checked;
                    setFilters({
                        ...filters,
                        showProcessed: value,
                    });
                }}
            />
        </AppGrid>
    );
};

/*<AppSwitchControl
    gridProps={{ width: 215 }}
    label="Show Expired"
    checked={filters.showExpired}
    onChange={(event) => {
        const value = event.target.checked;
        setFilters({
            ...filters,
            showExpired: value,
        });
    }}
/>*/
