import React, { useState } from "react";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppCustomTable } from "../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppButton } from "../../../Shared/Components/Button";
import { EventCategoryService } from "../../../Shared/Services/event-category.service";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppCardHeader } from "../../../Shared/Components/Cards/CardHeader";
import { AppCardContent } from "../../../Shared/Components/Cards/CardContent";
import { AppCard } from "../../../Shared/Components/Cards/Card";
import { AppEventCategoryCreateDialog } from "../EventCategoryCreateDialog/EventCategoryCreateDialog";
import { AppEventCategoryEditDialog } from "../EventCategoryEditDialog/EventCategoryEditDialog";
import {IEventCategory} from "../event-category.interface";

export const AppEventCategoryList = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const categoriesState = DataHooks.useFunctionCallState<IEventCategory[]>({
    func: () =>
      EventCategoryService.get({
        schoolId: school?.id,
        districtId: district?.id,
      }),
    deps: [school?.id, district?.id],
    showLoadingIndicator: true,
  });

  const [createPopupOpen, setCreatePopupOpen] = useState<boolean>(false);
  const [editingCategory, setEditingCategory] = useState<IEventCategory | null>(
    null
  );
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

  return (
    <AppGrid container>
      <AppGrid
        item
        xs={12}
        maxWidth={450}
        container
        direction="column"
        spacing={1}
      >
        <AppGrid item xs={12} container justifyContent="flex-end">
          <AppButton
            text="Create Category"
            icon={Icons.Add}
            size="large"
            palette={PaletteTypes.SUCCESS}
            onClick={() => setCreatePopupOpen(true)}
          />
        </AppGrid>
        <AppGrid item xs={12}>
          <AppCard hasHeader>
            <AppCardHeader
              title="Event Categories"
              subheader="What categories should we group events by?"
              icon={Icons.Shape}
              palette={PaletteTypes.PRIMARY}
            />
            <AppCardContent padding={0}>
              <AppEventCategoryListTable
                loading={categoriesState.loading}
                categories={categoriesState.data || []}
                editCategoryPressed={(category) => {
                  setEditingCategory(category);
                  setEditDialogOpen(true);
                }}
              />
            </AppCardContent>
          </AppCard>
        </AppGrid>
      </AppGrid>
      <AppEventCategoryCreateDialog
        open={createPopupOpen}
        setOpen={setCreatePopupOpen}
        onDataUpdated={async () => categoriesState.refreshData()}
      />
      {editingCategory && (
        <AppEventCategoryEditDialog
          eventCategory={editingCategory}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          onDataUpdated={async () => categoriesState.refreshData()}
        />
      )}
    </AppGrid>
  );
};

const AppEventCategoryListTable = ({
  loading,
  categories,
  editCategoryPressed,
}: {
  loading: boolean;
  categories: IEventCategory[];
  editCategoryPressed: (category: IEventCategory) => void;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  return (
    <AppCustomTable
      loading={loading}
      columns={[
        {
          id: "name",
          label: "Name",
        },
        ...(canEdit
          ? [
              {
                id: "action",
                label: "",
                width: 100,
              },
            ]
          : []),
      ]}
      rows={categories?.map((category) => ({
        cells: [
          {
            value: category.name,
          },
          ...(canEdit
            ? [
                {
                  value: (
                    <AppButton
                      icon={Icons.Edit}
                      palette={PaletteTypes.INFO}
                      onClick={() => editCategoryPressed(category)}
                    />
                  ),
                },
              ]
            : []),
        ],
      }))}
    />
  );
};
