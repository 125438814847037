import React from "react";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { ISchoolUser } from "../../../../Shared/Models/Entities/school-user.interface";
import { ISchoolUserValidationResults } from "../SchoolUserViewer.helper";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppSchoolUserPasswordEditor = ({
  schoolUser,
  updateSchoolUser,
  errorResults,
}: {
  schoolUser: ISchoolUser | null;
  updateSchoolUser: (schoolUser: ISchoolUser) => void;
  errorResults: ISchoolUserValidationResults;
}): JSX.Element => {
  return (
    <AppGrid container spacing={3}>
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="New Password"
        name="newPassword"
        value={schoolUser?.newPassword}
        onChange={(event) => {
          if (schoolUser) {
            updateSchoolUser({
              ...schoolUser,
              newPassword: event.target.value,
            });
          }
        }}
        error={!!errorResults.password}
        helperText={errorResults.password}
        icon={Icons.Lock}
      />
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        label="Repeat Password"
        name="repeatPassword"
        value={schoolUser?.repeatPassword}
        onChange={(event) => {
          if (schoolUser) {
            updateSchoolUser({
              ...schoolUser,
              repeatPassword: event.target.value,
            });
          }
        }}
        error={!!errorResults.password}
        icon={Icons.Lock}
      />
    </AppGrid>
  );
};
