import { IPalette } from "./theme.interface";

export const DarkPalette = {
  overrideHeaderColor: "rgb(235, 235, 235)",

  // Text & Background
  text: {
    primary: "rgba(235, 235, 235, 0.9)",
    secondary: "rgba(235, 235, 235, 0.7)",
    disabled: "rgba(235, 235, 235, 0.5)",
    hint: "rgba(235, 235, 235, 0.5)",
  },
  background: {
    default: "rgb(34, 34, 31)",
    paper: "rgb(15, 15, 15)",
    paperHover: "rgb(20, 20, 20)",
    paperAlternate: "rgb(8, 8, 8)",
    loadingTint: "rgba(0, 0, 0, .2)",
    editableInput: "rgb(0, 0, 0)",
  },
  divider: "rgba(255, 255, 255, 0.2)",
  errorDivider: "rgb(200, 22, 22)",

  // Default Colors
  primary: {
    main: "rgb(36, 60, 96)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(72, 120, 192)",
  },
  secondary: {
    main: "rgb(33, 99, 165)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(49, 148, 247)",
  },

  // State Colors
  success: {
    main: "rgb(25, 85, 25)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(50, 170, 50)",
  },
  info: {
    main: "rgb(30, 90, 150)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(45, 135, 225)",
  },
  warning: {
    main: "rgb(170, 60, 0)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(255, 120, 0)",
  },
  error: {
    main: "rgb(180, 30, 30)",
    contrastText: "rgb(255, 255, 255)",
    outline: "rgb(240, 40, 40)",
  },

  // Custom colors must have all members written, standard will auto-generate
  navigation: {
    background: "rgb(5, 5, 5)",
    item: "rgba(255, 255, 255, .0)",
    selected: "rgba(255, 255, 255, .08)",
    border: "1px solid rgb(255, 255, 255, .15)",
    text: "rgba(255, 255, 255, 1) !important",
    disabledText: "rgba(255, 255, 255, .8) !important",
    accent: "rgba(255, 255, 255, .0)",
  },
} as IPalette;
