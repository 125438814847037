import { HttpClient } from "../Helpers/http-client";
import { ITokenUser } from "../Models/Entities/token-user.interface";

export class AuthenticationService {
  static async getLoginUrl(): Promise<{ loginUrl: string }> {
    return HttpClient.get({ path: "/getLoginUrl", ignoreAuth: true });
  }

  static async googleLogin(value: string): Promise<ITokenUser | null> {
    return HttpClient.post({
      path: "/googleLogin",
      body: {
        value,
      },
      ignoreAuth: true,
    });
  }

  static async basicLogin(
    username: string,
    password: string
  ): Promise<ITokenUser | null> {
    return HttpClient.post({
      path: "/basicLogin",
      body: {
        username,
        password,
      },
      ignoreAuth: true,
    });
  }

  static async requestPasswordReset(email: string): Promise<void> {
    return HttpClient.post({
      path: "/request-password-reset",
      body: {
        email,
      },
      ignoreAuth: true,
    });
  }

  static async resetPassword(password: string): Promise<void> {
    return HttpClient.post({
      path: "/reset-password",
      body: {
        password,
      },
    });
  }
}
