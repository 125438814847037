import { List, ListItem } from "@material-ui/core";
import React, { useState } from "react";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { IDateSet } from "../../../../Shared/Models/Entities/date-set.interface";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { DateSetService } from "../../../../Shared/Services/date-set-service";
import { appMakeStyles } from "../../../../Shared/Themes/app-make-styles";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppDateSetManager } from "../DateSetManager/DateSetManager";

const ExcludedDayManagerStyles = appMakeStyles(() => ({
  excludedDayList: {
    overflowX: "hidden",
    overflowY: "scroll",
    maxHeight: 200,
  },
}));

export const AppExcludedDayManager = (props: {
  canEdit: boolean;
  excludedDays: IDateSet[];
  manageDateSet: (dateSet: IDateSet) => (_: unknown, checked: boolean) => void;
}): JSX.Element => {
  const [excludedDayId, setExcludedDayId] = useState(-1);
  const [showDateSetManager, setShowDateSetManager] = useState(false);
  const classes = ExcludedDayManagerStyles({});
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const { data: allDateSets, refreshData } = DataHooks.useFunctionCallState({
    func: () => DateSetService.getAll(school.id),
    showLoadingIndicator: true,
  });

  return (
    <AppGrid container>
      {allDateSets && (
        <AppGrid container direction="column">
          <List className={classes.excludedDayList}>
            {allDateSets.map((dateSet, i) => (
              <ListItem key={`${dateSet.name}-${i}`}>
                <AppSwitchControl
                  label={dateSet.name}
                  checked={props.excludedDays
                    .map((excludedDay) => excludedDay.id)
                    .includes(dateSet.id)}
                  onChange={props.manageDateSet(dateSet)}
                  disabled={!props.canEdit}
                />
                <Icons.Edit
                  onClick={() => {
                    setExcludedDayId(dateSet.id);
                    setShowDateSetManager(true);
                  }}
                />
              </ListItem>
            ))}
          </List>
          <AppGrid item container padding={8} justifyContent={"flex-end"}>
            <AppButton
              text="Add"
              icon={Icons.Add}
              palette={PaletteTypes.SUCCESS}
              onClick={() => {
                setExcludedDayId(-1);
                setShowDateSetManager(true);
              }}
            />
          </AppGrid>
        </AppGrid>
      )}

      {showDateSetManager && (
        <AppDateSetManager
          open={showDateSetManager}
          setOpen={setShowDateSetManager}
          title={
            excludedDayId === -1 ? "Add Excluded Days" : "Edit Excluded Days"
          }
          dateSetId={excludedDayId}
          canEdit={props.canEdit}
          handleSubmit={
            excludedDayId === -1
              ? async (dateSet: IDateSet) =>
                  await DateSetService.create(dateSet, school.id)
              : async (dateSet: IDateSet) =>
                  await DateSetService.update(dateSet)
          }
          refreshData={refreshData}
        />
      )}
    </AppGrid>
  );
};
