import React from "react";
import { AppDistrictTreeNode } from "./DistrictTreeNode/DistrictTreeNode";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppLoadingContent } from "../../../../Shared/Components/LoadingContent";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { appMakeStyles } from "../../../../Shared/Themes/app-make-styles";

const DistrictTreeStyles = appMakeStyles((theme) => ({
  tooManyObjectsContainer: {
    display: "flex",
    justifyContent: "center",
    background: theme.palette.background.paperAlternate,
    padding: "100px 0",
    borderTop: "1px solid " + theme.palette.divider,
  },
}));

export const AppDistrictTree = (props: { loading: boolean }): JSX.Element => {
  const classes = DistrictTreeStyles({});
  const filteredDistrictNodes = StoreHelper.selector(
    (state) => state.districts.filteredDistrictNodes
  );

  if (props.loading) {
    return (
      <AppCard>
        <AppCardContent padding={0} maxHeight="80vh" overflow="auto">
          <AppLoadingContent size={50} padding={50} />
        </AppCardContent>
      </AppCard>
    );
  } else {
    return (
      <AppCard>
        <AppCardContent padding={0} maxHeight="80vh" overflow="auto">
          {filteredDistrictNodes.map((districtNode, index) => (
            <AppDistrictTreeNode
              districtNode={districtNode}
              key={districtNode.district.id + "|" + index}
            />
          ))}
          {filteredDistrictNodes.length == 100 && (
            <div
              className={classes.tooManyObjectsContainer}
              key="too-many-objects"
            >
              Unable to show more than 100 Objects. Please use the search
              filter!
            </div>
          )}
        </AppCardContent>
      </AppCard>
    );
  }
};
