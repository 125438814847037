import React from "react";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppSchoolPanel } from "../SchoolDistrictManager/SchoolPanel/SchoolPanel";

export const AppSchoolViewer = (): JSX.Element => {
  return (
    <AppGrid container justifyContent="center" spacing={3}>
      <AppGrid
        item
        xs={12}
        lg={7}
        xl={6}
        container
        direction="column"
        justifyContent="flex-start"
        spacing={2}
      >
        <AppSchoolPanel schoolId={1} />;
      </AppGrid>
    </AppGrid>
  );
};
