export interface ITimezone {
  id: string;
  name: string;
}

export class TimeZonesHelper {
  private static timeZones: ITimezone[] = [
    { id: "Alaskan Standard Time", name: "Alaskan Time" },
    { id: "Central America Standard Time", name: "Central America Time" },
    { id: "Central Standard Time", name: "Central Time" },
    { id: "China Standard Time", name: "China Time" },
    { id: "Eastern Standard Time", name: "Eastern Time" },
    { id: "E. Australia Standard Time", name: "E. Australia Time" },
    { id: "GMT Standard Time", name: "GMT Time" },
    { id: "Hawaiian Standard Time", name: "Hawaiian Time" },
    { id: "Mountain Standard Time", name: "Mountain Time" },
    { id: "Pacific Standard Time", name: "Pacific Time" },
    { id: "West Pacific Standard Time", name: "West Pacific Time" },
    { id: "US Mountain Standard Time", name: "US Mountain Time" },
  ];

  public static getTimeZones(): ITimezone[] {
    return TimeZonesHelper.timeZones;
  }
}
