import React, { Dispatch, SetStateAction, useState } from "react";
import { IEvent } from "../../../../Shared/Models/Entities/event.interface";
import { IStudentSimple } from "../../../../Shared/Models/Entities/student-simple.interface";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppFileInput } from "../../../../Shared/Components/FileInput";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { StudentService } from "../../../../Shared/Services/student.service";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";

export const AppEventImportStudentsPopup = (props: {
  event: IEvent;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  studentsSelected: (students: IStudentSimple[]) => void;
}): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const [studentIdsText, setStudentIdsText] = useState("");

  // eslint-disable-next-line
  const importCSV = (csv: any) => {
    const rows = (csv?.slice(1) || []) as string[][];
    const ids = rows
      .map((row) => row?.[0])
      .map((text) => (text ? Number(text) : null))
      .filter((num) => num && num !== Number.NaN);
    setStudentIdsText(ids.join("\n"));
  };

  const handleSubmit = async () => {
    LoadingBarService.show();
    try {
      const studentIds = getStudentIds();
      if (studentIds.length) {
        const newStudents = await StudentService.get({
          externalIds: studentIds,
          schoolId: school?.id,
          districtId: district?.id,
          paginationOptions: {
            size: 1000,
            page: 0,
            sizeOptions: [],
          },
        });
        props.studentsSelected(
          (props.event.students || []).concat(newStudents)
        );
        setStudentIdsText("");
        props.setOpen(false);
      } else {
        SnackbarService.warn(
          "Could not find any students to add! Make sure to input ID numbers for students not currently included"
        );
      }
    } catch (error) {
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  const getStudentIds = (): number[] => {
    const newStudentIds: number[] = [];
    studentIdsText.split("\n").forEach((line) => {
      line.split(",").forEach((text) => {
        const id = text.trim();
        if (id && !isNaN(Number(id))) {
          newStudentIds.push(Number(id));
        }
      });
    });
    const currStudentIds = (props.event.students || []).map(
      (student) => student.id
    );
    return newStudentIds.filter(
      (newStudentId) => !currStudentIds.includes(newStudentId)
    );
  };

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title="Import Students To Event"
      subHeader={
        "Use this tool to add students to your event in bulk using their student IDs."
      }
      icon={Icons.Upload}
      palette={PaletteTypes.PRIMARY}
    >
      <AppGrid container spacing={3} direction="column">
        <AppGrid item container spacing={1} direction="column">
          <AppFormCategory title="Option 1: CSV. Your CSV should have a header row and one student ID on each row below that." />
          <AppFileInput
            text="Import CSV"
            icon={Icons.Upload}
            size="large"
            variant="outlined"
            palette={PaletteTypes.PRIMARY}
            fileSelected={importCSV}
          />
        </AppGrid>
        <AppGrid item container spacing={1} direction="column">
          <AppFormCategory title="Option 2: Textbox. Add Student IDs here, separated by commas or on different lines" />
          <AppTextFieldControl
            label=""
            placeholder=" "
            multiline
            minRows={8}
            maxRows={30}
            value={studentIdsText}
            onChange={(event) => {
              setStudentIdsText(event.target.value);
            }}
          />
        </AppGrid>
        <AppGrid item container spacing={1} justifyContent="flex-end">
          <AppButton
            text={"Cancel"}
            icon={Icons.Close}
            size="large"
            variant="outlined"
            palette={PaletteTypes.WARNING}
            onClick={() => {
              setStudentIdsText("");
              props.setOpen(false);
            }}
          />
          <AppButton
            text={"Import"}
            icon={Icons.Save}
            size="large"
            palette={PaletteTypes.SUCCESS}
            onClick={handleSubmit}
            disabled={!studentIdsText}
          />
        </AppGrid>
      </AppGrid>
    </AppCustomDialog>
  );
};
