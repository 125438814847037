import { Theme, useMediaQuery } from "@material-ui/core";

export class MediaQueryHooks {
  public static isDesktop = (): boolean =>
    useMediaQuery((theme: Theme) => theme.breakpoints.up("xl"));
  public static isLaptop = (): boolean =>
    useMediaQuery((theme: Theme) => theme.breakpoints.down("xl")) &&
    useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  public static isTablet = (): boolean =>
    useMediaQuery((theme: Theme) => theme.breakpoints.down("lg")) &&
    useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  public static isMobile = (): boolean =>
    useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
}
