import React, { useState, Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import { AppButton } from "../../../../../Shared/Components/Button";
import { AppSelectControl } from "../../../../../Shared/Components/Controls/SelectControl";
import { AppTextFieldControl } from "../../../../../Shared/Components/Controls/TextFieldControl";
import { AppGrid } from "../../../../../Shared/Components/Grid";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";
import { TablePaginationHelper } from "../../../../../Shared/Components/Tables/TablePagination/table-pagination.helper";
import { ValueHelper } from "../../../../../Shared/Helpers/value.helper";
import { DataHooks } from "../../../../../Shared/Hooks/data-hooks";
import { ITracCodeIntervention } from "../../../../../Shared/Models/Entities/trac-code-intervention.interface";
import { StoreHelper } from "../../../../../Shared/Reducers/store-helper";
import { InterventionService } from "../../../../../Shared/Services/intervention.service";
import { LoadingBarService } from "../../../../../Shared/Services/loading-bar.service";
import { PaletteTypes } from "../../../../../Shared/Themes/palette-types.enum";
import { AppTracCodeInterventionEditorPanelHelper } from "./TracCodeInterventionEditorPanel.helper";
import { ITracCodeInterventionValidationResults } from "./TracCodeInterventionEditorPanel.helper";
import { TracCodeInterventionService } from "../../../../../Shared/Services/trac-code-intervention.service";
import { IPopulatedTracCode } from "../../../../../Shared/Models/Entities/populated-trac-code.interface";
import { SnackbarService } from "../../../../../Shared/Services/snackbar.service";
import { Logger } from "../../../../../Shared/Helpers/logger";
import { Utils } from "../../../../../Shared/Helpers/utils";

export const AppTracCodeInterventionEditorPanel = (props: {
  tracCode: IPopulatedTracCode;
  tcIntervention: ITracCodeIntervention;
  isCreating: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => void;
}): JSX.Element => {
  const history = useHistory();
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const [tcIntervention, setTCIntervention] = useState(
    Utils.cloneObject(props.tcIntervention)
  );
  const handleInputChange = () => {
    setTCIntervention({ ...tcIntervention });
  };

  const [errorResults, setErrorResults] = useState(
    {} as ITracCodeInterventionValidationResults
  );
  const { data: interventionOptionsState } = DataHooks.useFunctionCallState({
    func: () =>
      InterventionService.getAll({
        schoolId: school?.id,
        pagination: false,
        paginationOptions: TablePaginationHelper.getDefaultOptions(),
      }),
    showLoadingIndicator: true,
  });

  const findDescription = (id: number) =>
    interventionOptionsState?.find((inter) => inter.id === id)?.description;
  const findName = (id: number) =>
    interventionOptionsState?.find((inter) => inter.id === id)?.name;

  const handleClose = () => {
    props.setOpen?.(false);
  };

  async function handleSubmit(): Promise<void> {
    const errorResults =
      AppTracCodeInterventionEditorPanelHelper.validateTracCodeIntervention(
        tcIntervention
      );
    const isValid = Object.values(errorResults).length === 0;
    setErrorResults(errorResults);
    if (isValid) {
      LoadingBarService.show();

      try {
        if (props.isCreating) {
          await TracCodeInterventionService.create(tcIntervention);
          history.push(`/trac-code/${props.tracCode.id}`);
        } else {
          await TracCodeInterventionService.update(tcIntervention);
          history.push(`/trac-code/${props.tracCode.id}`);
        }
        props.onDataUpdated();
        SnackbarService.success(
          (props.isCreating ? "Created " : "Updated ") + tcIntervention.name
        );
        handleClose();
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    }
  }
  async function handleDelete(): Promise<void> {
    LoadingBarService.show();
    try {
      await TracCodeInterventionService.delete(
        props.tcIntervention.tracCodeInterventionId
      );
      history.push(`/trac-code/${props.tracCode.id}`);
      props.onDataUpdated();
      SnackbarService.success(`Deleted ${tcIntervention.name}`);
      handleClose();
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  }
  return (
    <AppGrid container direction="column" spacing={3}>
      <AppSelectControl
        gridProps={{ xs: 6 }}
        label="Select Intervention *"
        name="tracCodeIntervention"
        value={tcIntervention.interventionId}
        placeholder="Select an intervention"
        selected={(value) => {
          if (value) {
            const newId = ValueHelper.getNumberFromString(value);
            tcIntervention.interventionId = newId;
            tcIntervention.description = findDescription(newId) as string;
            tcIntervention.name = findName(newId) as string;
            handleInputChange();
          }
        }}
        options={interventionOptionsState ? interventionOptionsState : []}
        icon={Icons.Info}
        error={!!errorResults.interventionId}
      />
      <AppTextFieldControl
        gridProps={{ xs: 6 }}
        name="tracCodeTrigger"
        label="This should happen after X entries"
        helperText="This intervention will occur after the selected number of tracks for a given student. This can happen in parallel with other actions."
        type="number"
        value={tcIntervention.tracCountTrigger}
        onChange={(event) => {
          tcIntervention.tracCountTrigger = ValueHelper.getNumberFromString(
            event.target.value
          );
          handleInputChange();
        }}
        inputProps={{ min: "1", max: "100", step: "1" }}
        icon={Icons.Edit}
        error={!!errorResults.tracCountTrigger}
      />
      <AppTextFieldControl
        gridProps={{ xs: 8 }}
        name="tracCodeDescription"
        label="Description"
        multiline
        minRows={4}
        maxRows={20}
        value={tcIntervention.description}
        onChange={(event) => {
          tcIntervention.description = event.target.value;
          handleInputChange();
        }}
      />
      <AppGrid xs={12} item container direction="row">
        <AppGrid xs={6} item container justifyContent="flex-start">
          {!props.isCreating && (
            <AppButton
              text="Delete"
              icon={Icons.Delete}
              palette={PaletteTypes.ERROR}
              variant="outlined"
              onClick={handleDelete}
            />
          )}
        </AppGrid>

        <AppGrid xs={6} item container spacing={2} justifyContent="flex-end">
          <AppButton
            text="Cancel"
            icon={Icons.Close}
            palette={PaletteTypes.WARNING}
            variant="outlined"
            onClick={handleClose}
          />
          <AppButton
            text="Save"
            icon={props.isCreating ? Icons.Add : Icons.Check}
            palette={PaletteTypes.SUCCESS}
            onClick={() => {
              handleSubmit();
            }}
          />
        </AppGrid>
      </AppGrid>
    </AppGrid>
  );
};
