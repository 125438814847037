// Init CSS
import "./index.css";

// Init React App
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { App } from "./App/App";
import { appStore } from "./Shared/Reducers/app-store";

ReactDOM.render(
  <Provider store={appStore}>
    <App />
  </Provider>,
  document.getElementById("root")
);
