import React from "react";
import { IChurnInfo } from "../../../../Shared/Services/school.service";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { SchoolChurnTableHelper } from "./SchoolChurnTableHelper";
import { TimeHelper } from "../../../../Shared/Helpers/time-helper";

export const AppSchoolChurnTable = ({
  churnInfo,
}: {
  schoolId: number;
  churnInfo: IChurnInfo;
}): JSX.Element => {
  return (
    <AppCustomTable
      columns={[
        {
          id: "churn-status",
          label: "Churn Status",
        },
        {
          id: "added-to-queue-date",
          label: "Added To Queue Date",
        },
        {
          id: "start-date",
          label: "Start Date",
        },
        {
          id: "end-date",
          label: "End Date",
        },
        {
          id: "retry-count",
          label: "Retry Count",
        },
      ]}
      rows={[
        {
          cells: [
            {
              value: SchoolChurnTableHelper.getStatusName(
                churnInfo.execution.status
              ),
            },
            {
              value: TimeHelper.formatStrDate(
                churnInfo.execution.dateTime.enqueueAt
              ),
            },
            {
              value: TimeHelper.formatStrDate(
                churnInfo.execution.dateTime.startAt
              ),
            },
            {
              value: TimeHelper.formatStrDate(
                churnInfo.execution.dateTime.endAt
              ),
            },
            {
              value: churnInfo.execution.retryCount,
            },
          ],
        },
      ]}
    />
  );
};
