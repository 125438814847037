import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { AppEventCategoryEditorPanel } from "../EventCategoryEditorPanel/EventCategoryEditorPanel";
import { IEventCategory } from "../event-category.interface";

export const AppEventCategoryCreateDialog = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
}): JSX.Element => {
  const eventCategory = {
    id: 0,
    name: "",
    canDelete: true,
  } as IEventCategory;

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="sm"
      title={"Create Category"}
      subHeader="Fill out this form to create a category in Hero."
      icon={Icons.Shape}
      palette={PaletteTypes.SECONDARY}
    >
      <AppEventCategoryEditorPanel
        eventCategory={eventCategory}
        isCreating={true}
        setOpen={props.setOpen}
        onDataUpdated={props.onDataUpdated}
      />
    </AppCustomDialog>
  );
};
