import { Divider } from "@material-ui/core";
import React from "react";
import { AppGrid } from "./Grid";

export const AppDivider = (): JSX.Element => {
  return (
    <AppGrid item>
      <Divider />
    </AppGrid>
  );
};
