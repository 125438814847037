import { InputLabel } from "@material-ui/core";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { AppNumberControl } from "../../../../Shared/Components/Controls/NumberControl";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { IDateSet } from "../../../../Shared/Models/Entities/date-set.interface";
import { IPopulatedTracCode } from "../../../../Shared/Models/Entities/populated-trac-code.interface";
import { IRollBackTracCode } from "../../../../Shared/Models/Entities/roll-back-trac-code.interface";
import { AppExcludedDayManager } from "../ExcludedDayManager.tsx/ExcludedDayManager";

class RollBackTracCodeHelper {
  static getDefault(): IRollBackTracCode {
    return {
      id: -1,
      enabled: true,
      beginAt: 0,
      triggerAt: 0,
      reward: 0,
      rewardCount: 0,
      excludedDays: [],
    };
  }
}

export const AppTracCodePositiveReinforcement = (props: {
  tracCode: IPopulatedTracCode;
  setTracCode: (bellSchedule: IPopulatedTracCode) => void;
  refreshTracCode: () => void;
  canEdit: boolean;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  useEffect(() => {
    if (!props.tracCode.rollBackTracCode)
      props.setTracCode({
        ...props.tracCode,
        rollBackTracCode: RollBackTracCodeHelper.getDefault(),
      });
  });

  const rollBackTracCode = props.tracCode?.rollBackTracCode;

  const addDateSet = (dateSet: IDateSet) => {
    if (rollBackTracCode) {
      props.setTracCode({
        ...props.tracCode,
        rollBackTracCode: {
          ...rollBackTracCode,
          excludedDays: [...rollBackTracCode.excludedDays, dateSet],
        },
      });
    }
  };
  const removeDateSet = (dateSetToRemove: IDateSet) => {
    if (rollBackTracCode) {
      props.setTracCode({
        ...props.tracCode,
        rollBackTracCode: {
          ...rollBackTracCode,
          excludedDays: rollBackTracCode.excludedDays.filter(
            (dateSet) => dateSet.id !== dateSetToRemove.id
          ),
        },
      });
    }
  };

  if (!rollBackTracCode) return <></>;

  return (
    <>
      <AppGrid
        container
        justifyContent="flex-start"
        direction="row"
        spacing={2}
        item
        padding={8}
      >
        <AppSwitchControl
          label="Active"
          checked={!!props.tracCode?.rollBackTracCode?.enabled}
          onChange={() => {
            props.setTracCode({
              ...props.tracCode,
              rollBackTracCode: {
                ...rollBackTracCode,
                enabled: !rollBackTracCode.enabled,
              },
            });
          }}
          disabled={!props.canEdit}
          helperText={
            "Turning this on will enable negative behavior tracks to be rolled back for students who meet certain criteria. Configure that criteria after enabling this."
          }
        />
      </AppGrid>

      {!!props.tracCode?.rollBackTracCode?.enabled && (
        <AppGrid
          container
          justifyContent="center"
          direction="row"
          spacing={2}
          item
          padding={8}
        >
          <AppNumberControl
            gridProps={{ xs: 6 }}
            label="Start reinforcement after"
            num={props.tracCode?.rollBackTracCode?.beginAt}
            min={0}
            numUpdated={(num) => {
              props.setHasChanges(true);
              props.setTracCode({
                ...props.tracCode,
                rollBackTracCode: {
                  ...rollBackTracCode,
                  beginAt: num,
                },
              });
            }}
            disabled={!props.canEdit}
            helperText={
              "Positive reinforcement will be possible after a student is tracked the selected number of times for this behavior."
            }
          />
          <AppNumberControl
            gridProps={{ xs: 6 }}
            label="Reward should trigger after"
            num={props.tracCode?.rollBackTracCode?.triggerAt}
            min={0}
            numUpdated={(num) => {
              props.setHasChanges(true);
              props.setTracCode({
                ...props.tracCode,
                rollBackTracCode: {
                  ...rollBackTracCode,
                  triggerAt: num,
                },
              });
            }}
            disabled={!props.canEdit}
            helperText={
              "Rollback of negative tracks will begin after a student goes the selected number of days without being tracked for this behavior."
            }
          />
        </AppGrid>
      )}
      {!!props.tracCode?.rollBackTracCode?.enabled && (
        <AppGrid
          container
          justifyContent="center"
          direction="row"
          spacing={2}
          item
          padding={8}
        >
          <AppNumberControl
            gridProps={{ xs: 6 }}
            label="Reward by removing"
            num={props.tracCode?.rollBackTracCode?.reward}
            min={0}
            numUpdated={(num) => {
              props.setHasChanges(true);
              props.setTracCode({
                ...props.tracCode,
                rollBackTracCode: {
                  ...rollBackTracCode,
                  reward: num,
                },
              });
            }}
            disabled={!props.canEdit}
            helperText="The selected amount of tracks will be removed when the student meets the criteria above."
          />
          <AppNumberControl
            gridProps={{ xs: 6 }}
            label="Reward Can Happen"
            num={props.tracCode?.rollBackTracCode?.rewardCount}
            min={0}
            numUpdated={(num) => {
              props.setHasChanges(true);
              props.setTracCode({
                ...props.tracCode,
                rollBackTracCode: {
                  ...rollBackTracCode,
                  rewardCount: num,
                },
              });
            }}
            disabled={!props.canEdit}
            helperText={
              "The reinforcement cycle can repeat the selected number of times at maximum."
            }
          />
        </AppGrid>
      )}
      <InputLabel>Excluded Days</InputLabel>
      <AppExcludedDayManager
        canEdit={props.canEdit}
        excludedDays={rollBackTracCode.excludedDays}
        manageDateSet={(dateSet: IDateSet) => (_: unknown, checked) => {
          if (checked) {
            addDateSet(dateSet);
          } else {
            removeDateSet(dateSet);
          }
          props.setHasChanges(true);
        }}
      />
    </>
  );
};
