import React from "react";
import { IEvent } from "../../../../Shared/Models/Entities/event.interface";
import { IEventListFilters } from "../EventList";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppTablePagination } from "../../../../Shared/Components/Tables/TablePagination/TablePagination";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppButton } from "../../../../Shared/Components/Button";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";
import { MediaQueryHooks } from "../../../../Shared/Hooks/media-query-hooks";

export const AppEventListTable = ({
  events,
  eventsLoading,
  count,
  countLoading,
  filters,
  setFilters,
}: {
  events: IEvent[] | null;
  eventsLoading: boolean;
  count: number | null;
  countLoading: boolean;
  filters: IEventListFilters;
  setFilters: (props: IEventListFilters) => void;
}): JSX.Element => {
  return (
    <>
      <AppCard hasHeader>
        <AppCardHeader
          title="Events"
          subheader="Create and setup events to be used by teachers and students in Access"
          icon={Icons.Group}
          palette={PaletteTypes.PRIMARY}
        />
        <AppCardContent padding={0}>
          <TableContent events={events} eventsLoading={eventsLoading} />
          <AppTablePagination
            count={count}
            countLoading={countLoading}
            paginationOptions={filters.paginationOptions}
            paginationOptionsUpdated={(newOptions) => {
              setFilters({
                ...filters,
                paginationOptions: newOptions,
              });
            }}
          />
        </AppCardContent>
      </AppCard>
    </>
  );
};

const TableContent = ({
  events,
  eventsLoading,
}: {
  events: IEvent[] | null;
  eventsLoading: boolean;
}): JSX.Element => {
  const isDesktop = MediaQueryHooks.isDesktop();
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  const getRecurrenceType = (event: IEvent) => {
    switch (event.recurrence.type) {
      case 0:
        return "Once";
      case 1:
        return "Daily";
      case 2:
        return "Weekly";
      case 3:
        return "Monthly";
      default:
        return "Once";
    }
  };

  return (
    <AppCustomTable
      loading={eventsLoading}
      columns={[
        {
          id: "id",
          label: "ID",
          width: 80,
        },
        {
          id: "name",
          label: "Name",
        },
        {
          id: "category",
          label: "Category",
        },
        {
          id: "type",
          label: "Type",
        },
        {
          id: "status",
          label: "Status",
        },
        {
          id: "action",
          label: "",
          width: 120,
        },
      ]}
      rows={events?.map((event) => ({
        cells: [
          {
            value: event.id,
          },
          {
            value: event.name,
          },
          {
            value: event.category?.name,
          },
          {
            value: getRecurrenceType(event),
          },
          {
            value: event.active ? "Active" : "Inactive",
          },
          {
            value: (
              <AppGrid container spacing={1}>
                <AppButton
                  text={isDesktop ? (canEdit ? "Edit" : "View") : undefined}
                  icon={canEdit ? Icons.Edit : Icons.Visibility}
                  palette={PaletteTypes.SECONDARY}
                  linkTo={"/events/" + event.id.toString()}
                />
              </AppGrid>
            ),
          },
        ],
      }))}
    />
  );
};
