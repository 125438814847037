import React from "react";
import { UrlHelper } from "../../../../Shared/Helpers/url.helper";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { IPopulatedTracCode } from "../../../../Shared/Models/Entities/populated-trac-code.interface";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { TracCodeService } from "../../../../Shared/Services/trac-code.service";
import { AppTracCodeViewer } from "../TracCodeViewer";

export const AppTracCodeEditViewer = (): JSX.Element => {
  const tracCodeId = UrlHelper.getParamNumber("id");
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const {
    data: tracCode,
    setData: setTracCode,
    refreshData: refreshTracCode,
    loading,
  } = DataHooks.useFunctionCallState({
    func: () => TracCodeService.get(tracCodeId, school?.id),
    showLoadingIndicator: true,
  });
  return (
    <>
      <AppTracCodeViewer
        tracCode={tracCode as IPopulatedTracCode}
        setTracCode={setTracCode}
        refreshTracCode={refreshTracCode}
        loading={loading}
        isCreating={false}
      />
    </>
  );
};
