import React, { Dispatch, SetStateAction, useState } from "react";
import { AppSelectControl } from "../../../../../Shared/Components/Controls/SelectControl";
import { AppCustomDialog } from "../../../../../Shared/Components/Dialogs/CustomDialog";
import { AppGrid } from "../../../../../Shared/Components/Grid";
import { Icons } from "../../../../../Shared/Components/Icons/Icons";
import { DataHooks } from "../../../../../Shared/Hooks/data-hooks";
import { ITracCodeDisciplinaryAction } from "../../../../../Shared/Models/Entities/trac-code-disciplinary-action.interface";
import { DisciplinaryActionService } from "../../../../../Shared/Services/disciplinary-action.service";
import { PaletteTypes } from "../../../../../Shared/Themes/palette-types.enum";
import { IDisciplinaryAction } from "../../../../../Shared/Models/Entities/disciplinary-action.interface";
import { TracCodeDisciplinaryActionService } from "../../../../../Shared/Services/trac-code-disciplinary-action.service";
import { StoreHelper } from "../../../../../Shared/Reducers/store-helper";
import { AppSwitchControl } from "../../../../../Shared/Components/Controls/SwitchControl";
import { AppNumberControl } from "../../../../../Shared/Components/Controls/NumberControl";
import { AppButton } from "../../../../../Shared/Components/Button";
import { SnackbarService } from "../../../../../Shared/Services/snackbar.service";
import { Logger } from "../../../../../Shared/Helpers/logger";
import { IPopulatedTracCode } from "../../../../../Shared/Models/Entities/populated-trac-code.interface";

class TracCodeDisciplinaryActionHelper {
  static getDefault(schoolId: number): ITracCodeDisciplinaryAction {
    return {
      id: -1,
      disciplinaryActionId: -1,
      schoolId,
      name: "",
      notes: "",
      complianceRequired: false,
      advanceNotice: 0,
      dailyCapacity: 0,
      triggerAt: 0,
      points: 0,
      printCopies: 0,
      repeat: 0,
    };
  }
}

export const TracCodeDisciplinaryActionManagerDialog = (props: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  tracCodeDisciplinaryActionId: number;
  tracCode: IPopulatedTracCode;
  refreshTracCode: () => void;
}): JSX.Element => {
  const { school } = StoreHelper.selector((state) => state.appConfig);
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const getDisciplinaryActionToEdit =
    async (): Promise<ITracCodeDisciplinaryAction> => {
      if (props.tracCodeDisciplinaryActionId !== -1) {
        return await TracCodeDisciplinaryActionService.get(
          props.tracCodeDisciplinaryActionId
        );
      } else {
        return TracCodeDisciplinaryActionHelper.getDefault(school.id);
      }
    };

  const {
    setData: setTracCodeDisciplinaryAction,
    data: tracCodeDisciplinaryAction,
  } = DataHooks.useFunctionCallState<ITracCodeDisciplinaryAction>({
    func: getDisciplinaryActionToEdit,
    showLoadingIndicator: true,
    deps: [props.tracCodeDisciplinaryActionId],
  });

  const { loading: loadingDisciplinaryActions, data: disciplinaryActions } =
    DataHooks.useFunctionCallState<IDisciplinaryAction[]>({
      func: () =>
        DisciplinaryActionService.getAll({
          schoolId: school.id,
          paginate: false,
        }),
      showLoadingIndicator: true,
      deps: [school],
    });

  const updateFields = (
    updatedFields: Partial<ITracCodeDisciplinaryAction>
  ) => {
    if (tracCodeDisciplinaryAction)
      setTracCodeDisciplinaryAction({
        ...tracCodeDisciplinaryAction,
        ...updatedFields,
      });
  };

  const handleSubmit = async () => {
    if (tracCodeDisciplinaryAction) {
      try {
        setTriedToSubmit(true);

        if (tracCodeDisciplinaryAction.disciplinaryActionId === -1) {
          SnackbarService.error("Please select an Action");
          return;
        }

        if (props.tracCodeDisciplinaryActionId === -1) {
          await TracCodeDisciplinaryActionService.create(
            tracCodeDisciplinaryAction,
            props.tracCode.id
          );
          SnackbarService.success(`Disciplinary Action Added`);
          props.refreshTracCode();
          props.setOpen(false);
        } else {
          await TracCodeDisciplinaryActionService.update(
            tracCodeDisciplinaryAction
          );
          SnackbarService.success(`Disciplinary Action Updated`);
          props.refreshTracCode();
          props.setOpen(false);
        }
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
    }
  };

  const handleDelete = async () => {
    if (tracCodeDisciplinaryAction) {
      try {
        if (props.tracCodeDisciplinaryActionId !== -1) {
          const disciplinaryAction =
            await TracCodeDisciplinaryActionService.delete(
              props.tracCodeDisciplinaryActionId
            );
          if (disciplinaryAction) {
            SnackbarService.success(`Disciplinary Action Removed`);
            props.refreshTracCode();
            props.setOpen(false);
          } else {
            throw new Error("Error Deleting Disciplinary Action");
          }
        }
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
    }
  };

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title={`Add Action To ${props.tracCode.name}`}
      icon={Icons.Info}
      palette={PaletteTypes.SECONDARY}
    >
      <AppGrid container spacing={2} direction="column">
        <AppSelectControl
          label="Select Action"
          name="action"
          value={tracCodeDisciplinaryAction?.disciplinaryActionId}
          selected={(value) => {
            const selectedId = Number(value);
            const selectedDisciplinaryAction = disciplinaryActions?.find(
              (dA) => dA.id === selectedId
            );

            if (selectedDisciplinaryAction && tracCodeDisciplinaryAction) {
              updateFields({
                name: selectedDisciplinaryAction.name,
                disciplinaryActionId: selectedDisciplinaryAction.id,
              });
            }
          }}
          options={disciplinaryActions || []}
          loading={loadingDisciplinaryActions}
          icon={Icons.Star}
          error={
            triedToSubmit &&
            tracCodeDisciplinaryAction?.disciplinaryActionId === -1
          }
        />
        <AppSwitchControl
          gridProps={{ xs: 12 }}
          label="Make this the default action"
          helperText="This is the action that occurs when no other actions meet the current criteria for an individual track."
          checked={tracCodeDisciplinaryAction?.triggerAt === 9999}
          onChange={() => {
            updateFields({
              triggerAt:
                tracCodeDisciplinaryAction?.triggerAt === 9999 ? 0 : 9999,
            });
          }}
        />
        {tracCodeDisciplinaryAction?.triggerAt !== 9999 && (
          <AppNumberControl
            gridProps={{ xs: 6 }}
            label="Should happen after X entries"
            helperText="This action will occur after this amount of tracks. Add a very high number if you are configuring a code where you want to use dropdown options."
            num={tracCodeDisciplinaryAction?.triggerAt}
            min={1}
            numUpdated={(num) => {
              updateFields({
                triggerAt: num,
              });
            }}
          />
        )}
        <AppNumberControl
          gridProps={{ xs: 6 }}
          label="Remove or add points"
          helperText="This action will add or remove the selected amount of points from a tracked student. Use negative numbers for removing points."
          num={tracCodeDisciplinaryAction?.points}
          numUpdated={(num) => {
            updateFields({
              points: num,
            });
          }}
        />
        <AppNumberControl
          gridProps={{ xs: 6 }}
          label="Repeat action X times when rule met"
          helperText="This will multiple the action by the number selected. For example, if you select 2 for a detention, the student will be given 2 detentions. This should not be changed in most cases."
          num={tracCodeDisciplinaryAction?.repeat}
          min={1}
          numUpdated={(num) => {
            updateFields({
              repeat: num,
            });
          }}
        />
        <AppNumberControl
          gridProps={{ xs: 6 }}
          label="Print X copies of each pass"
          helperText="This will determine how many passes are printed by default when this action is used."
          num={tracCodeDisciplinaryAction?.printCopies}
          min={1}
          numUpdated={(num) => {
            updateFields({
              printCopies: num,
            });
          }}
        />
        <AppGrid
          container
          item
          direction="row-reverse"
          justifyContent="space-between"
        >
          <AppGrid container item direction="row" spacing={2}>
            <AppButton
              text="Cancel"
              icon={Icons.Close}
              palette={PaletteTypes.ERROR}
              onClick={() => props.setOpen(false)}
            />
            <AppButton
              text="Save"
              icon={Icons.Save}
              palette={PaletteTypes.SUCCESS}
              onClick={handleSubmit}
            />
          </AppGrid>
          {props.tracCodeDisciplinaryActionId !== -1 && (
            <AppButton
              text="Delete"
              icon={Icons.Delete}
              palette={PaletteTypes.ERROR}
              onClick={handleDelete}
            />
          )}
        </AppGrid>
      </AppGrid>
    </AppCustomDialog>
  );
};
