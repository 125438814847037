import React, { useState } from "react";
import { AppFileInput } from "../../../Shared/Components/FileInput";
import { AppGrid } from "../../../Shared/Components/Grid";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { AppCSVValidator } from "../../../Shared/Components/Validators/CSVValidator/CSVValidator";
import {
  ICSVValidationCategory,
  IHeroColumn,
  ISchoolUserImportOptions,
} from "../../../Shared/Models/Interfaces/csv.interface";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { SchoolUserService } from "../../../Shared/Services/school-user.service";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppCSVSchoolUserImportOptions } from "./CSVSchoolUserImportOptions/CSVSchoolUserImportOptions";

const HERO_COLUMNS: IHeroColumn[] = [
  { name: "Teacher First Name", mandatory: true, databaseField: "firstName" },
  { name: "Teacher Last Name", mandatory: true, databaseField: "lastName" },
  { name: "Teacher ID", mandatory: true, databaseField: "instructorCode" },
  { name: "Email", mandatory: true, databaseField: "userCode" },
  { name: "Category", mandatory: false, databaseField: "category" },
];

export const AppSchoolUserImport = (): JSX.Element => {
  const schoolId = StoreHelper.selector((state) => state.appConfig.school.id);
  const [csvValidation, setCSVValidation] = useState<
    ICSVValidationCategory[] | null
  >(null);
  const [mapping, setMapping] = useState<null | string[]>(null);
  const [data, setData] = useState<undefined | string[][]>();
  const handleImport = (csv: string[][]) => {
    const columnHeaders = csv[0];
    const sampleData = csv[1];
    if (
      !columnHeaders ||
      !sampleData ||
      columnHeaders.length === 0 ||
      columnHeaders.length !== sampleData.length
    )
      return SnackbarService.error("Error retrieving file, please try again");

    const mandatoryFields = HERO_COLUMNS.filter((column) => column.mandatory);

    if (columnHeaders.length < mandatoryFields.length)
      SnackbarService.error(
        "Not enough fields to map to mandatory fields, please upload a different file"
      );

    setCSVValidation(
      columnHeaders.map((columnHeader, i) => ({
        columnHeader,
        sampleData: sampleData[i],
        heroField: { id: "", name: "" },
      }))
    );
    csv.shift();
    setData(csv);
  };

  const handleSubmit = async (options: ISchoolUserImportOptions) => {
    LoadingBarService.show();
    try {
      if (!options.roleId) {
        SnackbarService.error("Please select Role");
      } else if (options.defaultPassword === "") {
        SnackbarService.error("Please select a Default Password");
      } else if (mapping && data && options.roleId) {
        const response = await SchoolUserService.importUsers({
          schoolId,
          mapping,
          data,
          ...options,
        });
        SnackbarService.success(`${response} users updated`);
      }
    } catch (error) {
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };
  return (
    <AppGrid container direction="column" spacing={3}>
      {csvValidation === null && (
        <>
          <AppGrid item>
            Use this tool to import faculty and staff users into Hero in bulk.
            Click{" "}
            <a
              href="https://docs.google.com/spreadsheets/d/1mAM2H8k2ot3Xkr07cjvQsqQM4iJZ0ioNaT_Y6qrxuE8/export?gid=0&format=csv"
              download={true}
            >
              here
            </a>{" "}
            to download a sample file you can use to fill in your information
          </AppGrid>
          <AppGrid>
            <AppFileInput
              text="Import CSV"
              icon={Icons.Upload}
              size="large"
              variant="outlined"
              palette={PaletteTypes.PRIMARY}
              fileSelected={handleImport}
            />
          </AppGrid>
        </>
      )}
      {csvValidation && mapping === null && (
        <>
          <AppGrid item>
            Drag each available field to the corresponding column to the right
          </AppGrid>
          <AppCSVValidator
            csvValidationCategories={csvValidation}
            heroColumns={HERO_COLUMNS}
            confirmValidation={setMapping}
          />
        </>
      )}

      {csvValidation && mapping && (
        <AppCSVSchoolUserImportOptions sendCSV={handleSubmit} />
      )}
    </AppGrid>
  );
};
