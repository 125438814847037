import React from "react";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppTablePagination } from "../../../../Shared/Components/Tables/TablePagination/TablePagination";
import { IDisciplinaryAction } from "../../../../Shared/Models/Entities/disciplinary-action.interface";
import { IGetDisciplinaryActionProps } from "../../../../Shared/Services/disciplinary-action.service";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppDisciplinaryActionList = (props: {
  canEdit: boolean;
  disciplinaryActions: IDisciplinaryAction[];
  loading: boolean;
  count: number;
  countLoading: boolean;
  filters: IGetDisciplinaryActionProps;
  setFilters: (props: IGetDisciplinaryActionProps) => void;
  setShowViewerModal: (id: number, isClone?: boolean) => void;
}): JSX.Element => {
  return (
    <AppCard hasHeader>
      <AppCardHeader
        title="Disciplinary Actions"
        palette={PaletteTypes.PRIMARY}
        icon={Icons.FountainPen}
      />
      <AppCardContent padding={0}>
        <AppCustomTable
          loading={props.loading}
          columns={[
            {
              id: "name",
              label: "Name",
            },
            {
              id: "notes",
              label: "Message On Pass",
            },
            {
              id: "clone",
              label: "",
            },
            {
              id: "edit",
              label: "",
            },
          ]}
          rows={props.disciplinaryActions.map((disciplinaryAction) => ({
            cells: [
              {
                value: disciplinaryAction.name,
              },
              {
                value: disciplinaryAction.notes,
              },
              {
                value: (
                  <AppGrid container spacing={1}>
                    <AppButton
                      icon={props.canEdit ? Icons.Edit : Icons.Visibility}
                      palette={PaletteTypes.SECONDARY}
                      text={"Clone"}
                      onClick={() =>
                        props.setShowViewerModal(disciplinaryAction.id, true)
                      }
                    />
                  </AppGrid>
                ),
              },
              {
                value: (
                  <AppGrid container spacing={1}>
                    <AppButton
                      icon={props.canEdit ? Icons.Edit : Icons.Visibility}
                      palette={PaletteTypes.SECONDARY}
                      text={props.canEdit ? "Edit" : "View"}
                      onClick={() =>
                        props.setShowViewerModal(disciplinaryAction.id)
                      }
                    />
                  </AppGrid>
                ),
              },
            ],
          }))}
        />
        <AppTablePagination
          count={props.count}
          countLoading={props.countLoading}
          paginationOptions={props.filters.paginationOptions}
          paginationOptionsUpdated={(newOptions) => {
            props.setFilters({
              ...props.filters,
              paginationOptions: newOptions,
            });
          }}
        />
      </AppCardContent>
    </AppCard>
  );
};
