import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppSelectControl } from "../../../../Shared/Components/Controls/SelectControl";
import { DistrictService } from "../../../../Shared/Services/district.service";
import { IDistrict } from "../../../../Shared/Models/Entities/district.interface";
import {
  DistrictEditorHelper,
  IDistrictValidationResults,
} from "./DistrictEditorHelper";
import { Utils } from "../../../../Shared/Helpers/utils";
import { AppButton } from "../../../../Shared/Components/Button";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { Logger } from "../../../../Shared/Helpers/logger";
import { DistrictTreeService } from "../../../../Shared/Services/district-tree.service";
import { StoreHelper } from "../../../../Shared/Reducers/store-helper";
import { SchoolDistrictManagerReducerActions } from "../../../../Shared/Reducers/SchoolDistrictManager/school-district-manager.reducer";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { AppConfirmationDialog } from "../../../../Shared/Components/Dialogs/ConfirmationDialog";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";

export const AppDistrictEditor = (props: {
  district: IDistrict | null;
  hasSchools: boolean;
  isCreating: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const canUpdate = props.isCreating
    ? hasPermission(AppPermissionsEnum.DISTRICTS_CREATE)
    : hasPermission(AppPermissionsEnum.DISTRICTS_EDIT);
  const canDelete = hasPermission(AppPermissionsEnum.DISTRICTS_DELETE);
  const isCreating = !props.district?.id;

  const [district, setDistrict] = useState<IDistrict | null>(null);
  useEffect(() => {
    setDistrict(Utils.cloneObject(props.district));
  }, [props.district?.id]);

  const [errorResults, setErrorResults] = useState(
    {} as IDistrictValidationResults
  );
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const districts = StoreHelper.selector((state) => state.districts.districts);

  const handleInputChange = () => {
    const errorResults = DistrictEditorHelper.validateDistrict(
      district,
      districts
    );
    setErrorResults(errorResults);
    setDistrict(district);
  };

  const handleClose = () => {
    props?.setOpen?.(false);
  };

  async function handleSubmit() {
    if (district) {
      const errorResults = DistrictEditorHelper.validateDistrict(
        district,
        districts
      );
      const isValid = Object.values(errorResults).length === 0;
      if (isValid) {
        LoadingBarService.show();
        try {
          await DistrictService.upsertDistrict(district);
          await DistrictTreeService.reloadData();
          StoreHelper.dispatch(
            SchoolDistrictManagerReducerActions.setSelectedDistrict(
              district?.id
            )
          );
          SnackbarService.success(
            (isCreating ? "Created " : "Updated ") + district?.name
          );
          handleClose();
        } catch (error) {
          Logger.error(error);
          SnackbarService.error(error);
        }
        LoadingBarService.hide();
      }
    }
  }

  async function handleDelete() {
    if (district) {
      LoadingBarService.show();
      try {
        await DistrictService.delete(district.id);
        await DistrictTreeService.reloadData();
        StoreHelper.dispatch(
          SchoolDistrictManagerReducerActions.setSelectedDistrict(null)
        );
        SnackbarService.success("Deleted " + district.name);
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    }
  }

  return (
    <>
      <AppGrid container justifyContent="center" spacing={3}>
        <AppTextFieldControl
          gridProps={{ xs: 4 }}
          icon={Icons.District}
          label="Name *"
          name="districtName"
          value={district?.name}
          onChange={(event) => {
            if (district) {
              district.name = event.target.value;
              handleInputChange();
            }
          }}
          error={!!errorResults.name}
          disabled={!canUpdate}
        />
        <AppSelectControl
          gridProps={{ xs: 4 }}
          label="Parent Group *"
          name="districtParent"
          value={district?.parentId}
          selected={(value) => {
            if (district) {
              district.parentId = value ? Number(value) : null;
              handleInputChange();
            }
          }}
          options={districts}
          disabled={!canUpdate}
          icon={Icons.District}
        />
        <AppTextFieldControl
          gridProps={{ xs: 4 }}
          label="SalesForce ID"
          name="SalesForceID"
          value={district?.salesForceId}
          onChange={(event) => {
            if (district) {
              district.salesForceId = event.target.value;
              handleInputChange();
            }
          }}
          disabled={!canUpdate}
          icon={Icons.AccountBox}
        />
        <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
          {isCreating && (
            <AppButton
              text="Cancel"
              icon={Icons.Close}
              palette={PaletteTypes.WARNING}
              variant="outlined"
              onClick={handleClose}
            />
          )}
          {!props.hasSchools && !props.isCreating && canDelete && (
            <AppButton
              text="Delete"
              icon={Icons.Delete}
              palette={PaletteTypes.ERROR}
              onClick={() => setConfirmDeleteOpen(true)}
            />
          )}
          {canUpdate && (
            <AppButton
              text={props.isCreating ? "Create" : "Update"}
              icon={props.isCreating ? Icons.Add : Icons.Check}
              palette={PaletteTypes.SUCCESS}
              onClick={handleSubmit}
              disabled={
                JSON.stringify(props.district) === JSON.stringify(district)
              }
            />
          )}
        </AppGrid>
      </AppGrid>
      <AppConfirmationDialog
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        title={"Confirm Deleting " + district?.name}
        content="Are you sure you want to delete this school group??"
        icon={Icons.Delete}
        agreeAction={async () => handleDelete()}
        palette={PaletteTypes.ERROR}
      />
    </>
  );
};
