import React, { Dispatch, SetStateAction } from "react";
import { AppButton } from "../../../../Shared/Components/Button";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { AppCustomTable } from "../../../../Shared/Components/Tables/CustomTable/CustomTable";
import { TimeHelper } from "../../../../Shared/Helpers/time-helper";
import { IBellSchedule } from "../../../../Shared/Models/Entities/bell-schedule.interface";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";

export const AppPeriodList = (props: {
  bellSchedule: IBellSchedule;
  loading: boolean;
  canEdit: boolean;
  refreshBellSchedule: () => void;
  selectPeriod: Dispatch<SetStateAction<number>>;
  addPeriod: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  props.bellSchedule.periods.sort(
    (a, b) =>
      TimeHelper.getTodayTime(new Date(a.start)) -
      TimeHelper.getTodayTime(new Date(b.start))
  );
  return (
    <AppCard hasHeader>
      <AppCardHeader
        title="Period Details"
        palette={PaletteTypes.PRIMARY}
        icon={Icons.Bell}
        action={
          props.canEdit && (
            <AppButton
              text="Add Period"
              icon={Icons.Add}
              palette={PaletteTypes.SUCCESS}
              onClick={() => props.addPeriod(true)}
            />
          )
        }
      />
      <AppCardContent padding={0}>
        <AppCustomTable
          loading={props.loading}
          columns={[
            {
              id: "name",
              label: "Period Name",
            },
            {
              id: "start",
              label: "Start Time",
            },
            {
              id: "end",
              label: "End Time",
            },
            {
              id: "refCode",
              label: "Ref. Code",
            },
            {
              id: "extCode",
              label: "Ext. Code",
            },
            ...(props.canEdit
              ? [
                  {
                    id: "edit",
                    label: "",
                  },
                ]
              : []),
          ]}
          rows={props.bellSchedule.periods.map((period, index) => ({
            cells: [
              {
                value: period.name,
              },
              {
                value: TimeHelper.formatStrTime(period.start),
              },
              {
                value: TimeHelper.formatStrTime(period.end),
              },
              {
                value: period.referenceCode,
              },
              {
                value: period.extPeriodCode,
              },
              ...(props.canEdit
                ? [
                    {
                      value: (
                        <AppGrid container spacing={1}>
                          <AppButton
                            icon={Icons.Edit}
                            palette={PaletteTypes.SECONDARY}
                            onClick={() => {
                              props.selectPeriod(index);
                            }}
                          />
                        </AppGrid>
                      ),
                    },
                  ]
                : []),
            ],
          }))}
        />
      </AppCardContent>
    </AppCard>
  );
};
