import React, { useEffect, useState } from "react";
import { SchoolDistrictManagerReducerActions } from "../../../Shared/Reducers/SchoolDistrictManager/school-district-manager.reducer";
import {
  DistrictTreeService,
  IDistrictTreeNeededData,
} from "../../../Shared/Services/district-tree.service";
import { AppDistrictTree } from "./DistrictTree/DistrictTree";
import { AppWelcomePanel } from "./WelcomePanel/WelcomePanel";
import { AppDistrictPanel } from "./DistrictPanel/DistrictPanel";
import { AppSchoolPanel } from "./SchoolPanel/SchoolPanel";
import { AppButton } from "../../../Shared/Components/Button";
import { IDOMEvent } from "../../../Shared/Models/Interfaces/dom-event.interface";
import { AppTextFieldControl } from "../../../Shared/Components/Controls/TextFieldControl";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { AppGrid } from "../../../Shared/Components/Grid";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppSchoolCreateDialog } from "./SchoolCreateDialog/SchoolCreateDialog";
import { AppDistrictCreateDialog } from "./DistrictCreateDialog/DistrictCreateDialog";

export const AppSchoolDistrictManager = (): JSX.Element => {
  const [districtDialogOpen, setDistrictDialogOpen] = useState(false);
  const [schoolDialogOpen, setSchoolDialogOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const { selectedId, selectedType } = StoreHelper.selector(
    (state) => state.districts
  );

  const { loading } = DataHooks.getDataWithCallback<IDistrictTreeNeededData>({
    func: DistrictTreeService.getData,
    callback: (data) => {
      StoreHelper.dispatch(
        SchoolDistrictManagerReducerActions.setDistrictsTree(data)
      );
    },
    showLoadingIndicator: true,
  });

  // Clear our filters on leaving this page!
  useEffect(() => {
    return () => {
      StoreHelper.dispatch(
        SchoolDistrictManagerReducerActions.setSelectedDistrict(null)
      );
      StoreHelper.dispatch(
        SchoolDistrictManagerReducerActions.filterDistrictsTree(null)
      );
    };
  }, []);

  const renderSelectedItem = () => {
    if (selectedId) {
      if (selectedType === "district") {
        return (
          <AppDistrictPanel
            districtId={selectedId}
            hasSchools={false}
            key={selectedId}
          />
        );
      }
      if (selectedType === "school") {
        return <AppSchoolPanel schoolId={selectedId} key={selectedId} />;
      }
    }
    return <AppWelcomePanel />;
  };

  const searchHandler = (event: IDOMEvent) => {
    setSearchKey(event?.target?.value);
    StoreHelper.dispatch(
      SchoolDistrictManagerReducerActions.filterDistrictsTree(searchKey)
    );
  };

  return (
    <>
      <AppGrid container spacing={3}>
        <AppGrid
          item
          xs={6}
          md={4}
          lg={4}
          container
          direction="column"
          justifyContent="flex-start"
          spacing={2}
        >
          <AppTextFieldControl
            fullWidth
            opaqueBackground
            id="filled-basic"
            placeholder="Search by ID or Name..."
            value={searchKey}
            icon={Icons.Search}
            onChange={searchHandler}
          />
          <AppGrid item>
            <AppDistrictTree loading={loading} />
          </AppGrid>
        </AppGrid>
        <AppGrid
          item
          xs={12}
          md={8}
          lg={8}
          container
          direction="column"
          justifyContent="flex-start"
          spacing={2}
        >
          <AppGrid item container justifyContent="flex-end" spacing={1}>
            {hasPermission(AppPermissionsEnum.SCHOOLS_CREATE) && (
              <AppButton
                text="Create School"
                icon={Icons.Add}
                size="large"
                palette={PaletteTypes.SUCCESS}
                onClick={() => {
                  setSchoolDialogOpen(true);
                }}
              />
            )}
            {hasPermission(AppPermissionsEnum.DISTRICTS_CREATE) && (
              <AppButton
                text="Create School Group"
                icon={Icons.Add}
                size="large"
                palette={PaletteTypes.SUCCESS}
                onClick={() => {
                  setDistrictDialogOpen(true);
                }}
              />
            )}
          </AppGrid>
          <AppGrid item>{renderSelectedItem()}</AppGrid>
        </AppGrid>
      </AppGrid>
      <AppSchoolCreateDialog
        open={schoolDialogOpen}
        setOpen={setSchoolDialogOpen}
      />
      <AppDistrictCreateDialog
        open={districtDialogOpen}
        setOpen={setDistrictDialogOpen}
      />
    </>
  );
};
