import { IAdminUser } from "../Models/Entities/admin-user.interface";
import { HttpClient } from "../Helpers/http-client";
import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { PaginationHelper } from "../Helpers/pagination.helper";

export interface IGetAdminUserProps {
  searchKey?: string;
  schoolId?: number;
  districtId?: number;
  paginationOptions: IPaginationOptions;
}

export class AdminUserService {
  static async get(props: IGetAdminUserProps): Promise<IAdminUser[]> {
    return HttpClient.get({
      path: "/admin-users",
      params: {
        ...PaginationHelper.getPaginationParams(props.paginationOptions),
        ...(props?.districtId && {
          districtId: props?.districtId,
        }),
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),

        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
      },
    });
  }

  static async getCount(props: IGetAdminUserProps): Promise<number> {
    return HttpClient.get({
      path: "/admin-users/count",
      params: {
        ...PaginationHelper.getPaginationParams(props.paginationOptions),
        ...(props?.districtId && {
          districtId: props?.districtId,
        }),
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),

        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
      },
    });
  }

  static async create(user: IAdminUser): Promise<IAdminUser> {
    return HttpClient.post({
      path: "/admin-users",
      body: user,
    });
  }

  static async update(user: IAdminUser): Promise<IAdminUser> {
    return HttpClient.put({
      path: "/admin-users",
      body: user,
    });
  }

  static async delete(user: IAdminUser): Promise<IAdminUser> {
    return HttpClient.delete({
      path: "/admin-users",
      body: user,
    });
  }
}
