import React, { Dispatch, SetStateAction } from "react";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { DataHooks } from "../../../../Shared/Hooks/data-hooks";
import { DisciplinaryActionService } from "../../../../Shared/Services/disciplinary-action.service";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppSwitchControl } from "../../../../Shared/Components/Controls/SwitchControl";
import { AppNumberControl } from "../../../../Shared/Components/Controls/NumberControl";
import { AppExcludedDayManager } from "../../TracCodeViewer/ExcludedDayManager.tsx/ExcludedDayManager";
import {
  IDisciplinaryAction,
  IDisciplinaryActionWeekDay,
} from "../../../../Shared/Models/Entities/disciplinary-action.interface";
import { IDateSet } from "../../../../Shared/Models/Entities/date-set.interface";
import { AppDisciplinaryActionWeekdayControl } from "./DisciplinaryActionWeekdayControl/DisciplinaryActionWeekdayControl";
import { AppFormCategory } from "../../../../Shared/Components/FormCategory";
import { AppButton } from "../../../../Shared/Components/Button";
import { Logger } from "../../../../Shared/Helpers/logger";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";
import { LoadingBarService } from "../../../../Shared/Services/loading-bar.service";

class DisciplinaryActionHelper {
  static getDefault(schoolId: number): IDisciplinaryAction {
    return {
      id: -1,
      schoolId,
      name: "",
      notes: "",
      complianceRequired: false,
      advanceNotice: 0,
      advanceNoticeExclusion: false,
      repeat: 0,
      dailyCapacity: 0,
      disciplinaryActionWeekDays: [],
      excludedDays: [],
    };
  }
}

export const AppDisciplinaryActionManagerDialog = (props: {
  disciplinaryActionId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
  schoolId: number;
  isClone: boolean;
  canEdit: boolean;
}): JSX.Element => {
  const isCreating = props.disciplinaryActionId === -1;

  const getDisciplinaryActionToView = async () => {
    if (isCreating) {
      return DisciplinaryActionHelper.getDefault(props.schoolId);
    } else if (props.isClone) {
      const disciplinaryAction = await DisciplinaryActionService.get(
        props.disciplinaryActionId
      );

      return {
        ...disciplinaryAction,
        name: "",
        id: -1,
      };
    } else {
      return DisciplinaryActionService.get(props.disciplinaryActionId);
    }
  };

  const {
    data: disciplinaryAction,
    setData: setDisciplinaryAction,
    loading,
  } = DataHooks.useFunctionCallState({
    func: getDisciplinaryActionToView,
    showLoadingIndicator: true,
  });

  const setDisciplinaryActionWeekDays = (
    disciplinaryActionWeekDays: IDisciplinaryActionWeekDay[]
  ) =>
    disciplinaryAction &&
    setDisciplinaryAction({
      ...disciplinaryAction,
      disciplinaryActionWeekDays,
    });
  const setDisciplinaryExcludedDays = (excludedDays: IDateSet[]) =>
    disciplinaryAction &&
    setDisciplinaryAction({
      ...disciplinaryAction,
      excludedDays,
    });

  const addDateSet = (dateSet: IDateSet) => {
    disciplinaryAction &&
      setDisciplinaryExcludedDays([
        ...disciplinaryAction.excludedDays,
        dateSet,
      ]);
  };
  const removeDateSet = (dateSetToRemove: IDateSet) => {
    disciplinaryAction &&
      setDisciplinaryExcludedDays(
        disciplinaryAction.excludedDays.filter(
          (dateSet) => dateSet.id !== dateSetToRemove.id
        )
      );
  };

  const addWeekDay = (weekdayId: number) => {
    disciplinaryAction &&
      setDisciplinaryActionWeekDays([
        ...disciplinaryAction.disciplinaryActionWeekDays,
        {
          id: -1,
          weekdayId,
        },
      ]);
  };

  const removeWeekDay = (weekdayId: number) => {
    disciplinaryAction &&
      setDisciplinaryActionWeekDays(
        disciplinaryAction.disciplinaryActionWeekDays.filter(
          (disciplinaryActionWeekDay) =>
            disciplinaryActionWeekDay.weekdayId !== weekdayId
        )
      );
  };

  const addAllWeekDays = () => {
    if (disciplinaryAction) {
      const existingWeekDaysIds =
        disciplinaryAction.disciplinaryActionWeekDays.map(
          (disciplinaryActionWeekDay) => disciplinaryActionWeekDay.weekdayId
        );
      const weekDaysToAdd: IDisciplinaryActionWeekDay[] = [1, 2, 3, 4, 5]
        .filter((weekDayId) => !existingWeekDaysIds.includes(weekDayId))
        .map((weekdayId) => ({ id: -1, weekdayId }));

      setDisciplinaryActionWeekDays([
        ...disciplinaryAction.disciplinaryActionWeekDays,
        ...weekDaysToAdd,
      ]);
    }
  };

  const removeAllWeekDays = () => {
    disciplinaryAction &&
      setDisciplinaryActionWeekDays(
        disciplinaryAction.disciplinaryActionWeekDays.filter(
          (disciplinaryActionWeekDay) =>
            disciplinaryActionWeekDay.id === 0 ||
            disciplinaryActionWeekDay.id === 7
        )
      );
  };

  const handleSubmit = async () => {
    LoadingBarService.show();
    try {
      if (disciplinaryAction) {
        disciplinaryAction.id === -1
          ? await DisciplinaryActionService.create(
              disciplinaryAction,
              props.schoolId
            )
          : await DisciplinaryActionService.update(disciplinaryAction);
        SnackbarService.success(
          `Disciplinary Action ${
            disciplinaryAction.id === -1 ? "Created" : "Updated"
          }`
        );
        props.onDataUpdated();
        props.setOpen(false);
      }
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  const handleDelete = async () => {
    LoadingBarService.show();
    try {
      disciplinaryAction &&
        (await DisciplinaryActionService.delete(disciplinaryAction));
      SnackbarService.success(`Disciplinary Action Removed`);
      props.onDataUpdated();
      props.setOpen(false);
    } catch (error) {
      Logger.error(error);
      SnackbarService.error(error);
    }
    LoadingBarService.hide();
  };

  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title={isCreating ? "Create" : "Edit" + " Action"}
      icon={Icons.Calendar}
      palette={PaletteTypes.SECONDARY}
    >
      {!loading && (
        <AppGrid container spacing={3} direction="column">
          <AppGrid container item spacing={3} direction="row">
            <AppTextFieldControl
              gridProps={{ xs: 4 }}
              label="Name"
              name="name"
              value={disciplinaryAction?.name}
              error={disciplinaryAction?.name === ""}
              onChange={(event) => {
                disciplinaryAction &&
                  setDisciplinaryAction({
                    ...disciplinaryAction,
                    name: event.target.value,
                  });
              }}
              icon={Icons.Info}
              opaqueBackground
              helperText="Choose a short but clear name to identify the action. These will be global and can be applied to any Trac Code."
            />
            <AppTextFieldControl
              gridProps={{ xs: 8, sm: 8, md: 8, lg: 8, xl: 8 }}
              label="Message on Pass"
              name="notes"
              value={disciplinaryAction?.notes}
              error={disciplinaryAction?.notes === ""}
              onChange={(event) => {
                disciplinaryAction &&
                  setDisciplinaryAction({
                    ...disciplinaryAction,
                    notes: event.target.value,
                  });
              }}
              icon={Icons.Info}
              opaqueBackground
            />
          </AppGrid>
          <AppSwitchControl
            label="Enable Compliance Reaction"
            checked={disciplinaryAction?.complianceRequired}
            onChange={(event) => {
              const complianceRequired = event.target.checked;
              disciplinaryAction &&
                setDisciplinaryAction({
                  ...disciplinaryAction,
                  complianceRequired,
                });
            }}
          />

          <AppGrid
            item
            container
            direction="row"
            justifyContent="space-between"
            spacing={3}
          >
            <AppNumberControl
              gridProps={{ xs: 4 }}
              label="Day(s) until reaction begin"
              num={disciplinaryAction?.advanceNotice}
              min={0}
              numUpdated={(advanceNotice) => {
                disciplinaryAction &&
                  setDisciplinaryAction({
                    ...disciplinaryAction,
                    advanceNotice,
                  });
              }}
            />
            <AppNumberControl
              gridProps={{ xs: 4 }}
              label="Limit number of students to "
              num={disciplinaryAction?.dailyCapacity}
              min={0}
              numUpdated={(dailyCapacity) => {
                disciplinaryAction &&
                  setDisciplinaryAction({
                    ...disciplinaryAction,
                    dailyCapacity,
                  });
              }}
            />
            <AppSwitchControl
              gridProps={{ xs: 4 }}
              label="Skip over excluded days"
              checked={disciplinaryAction?.advanceNoticeExclusion}
              onChange={(event) => {
                const advanceNoticeExclusion = event.target.checked;
                disciplinaryAction &&
                  setDisciplinaryAction({
                    ...disciplinaryAction,
                    advanceNoticeExclusion,
                  });
              }}
            />
          </AppGrid>

          <AppGrid container item direction="row">
            <AppDisciplinaryActionWeekdayControl
              canEdit={props.canEdit}
              disciplinaryActionWeekdays={
                disciplinaryAction?.disciplinaryActionWeekDays || []
              }
              manageDisciplinaryActionWeekDays={(weekdayId: number) =>
                (_, checked: boolean) =>
                  checked ? addWeekDay(weekdayId) : removeWeekDay(weekdayId)}
              manageAllWeekDays={(checked) =>
                checked ? addAllWeekDays() : removeAllWeekDays()
              }
            />
            <AppGrid container item direction="column" xs={5} spacing={3}>
              <AppFormCategory title="Excluded Date Ranges" />
              <AppExcludedDayManager
                canEdit={props.canEdit}
                excludedDays={disciplinaryAction?.excludedDays || []}
                manageDateSet={(dateSet: IDateSet) => (_: unknown, checked) => {
                  if (checked) {
                    addDateSet(dateSet);
                  } else {
                    removeDateSet(dateSet);
                  }
                }}
              />
            </AppGrid>
          </AppGrid>
          <AppGrid
            container
            item
            direction="row"
            justifyContent="space-between"
          >
            {!isCreating && (
              <AppButton
                text={"Delete"}
                icon={Icons.Delete}
                size="large"
                palette={PaletteTypes.ERROR}
                onClick={handleDelete}
              />
            )}
            <AppGrid item container direction="row" spacing={3}>
              <AppButton
                text={"Cancel"}
                icon={Icons.Close}
                size="large"
                palette={PaletteTypes.WARNING}
                variant="outlined"
                onClick={() => props.setOpen(false)}
              />
              <AppButton
                text={disciplinaryAction?.id === -1 ? "Create" : "Update"}
                icon={Icons.Close}
                size="large"
                palette={PaletteTypes.SUCCESS}
                onClick={handleSubmit}
              />
            </AppGrid>
          </AppGrid>
        </AppGrid>
      )}
    </AppCustomDialog>
  );
};
