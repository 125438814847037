import { IPaginationOptions } from "../Components/Tables/TablePagination/pagination-options.interface";
import { HttpClient } from "../Helpers/http-client";
import { PaginationHelper } from "../Helpers/pagination.helper";
import { IDisciplinaryAction } from "../Models/Entities/disciplinary-action.interface";

export interface IGetDisciplinaryActionProps {
  searchKey?: string;
  schoolId?: number;
  paginationOptions?: IPaginationOptions;
  paginate?: boolean;
}

export class DisciplinaryActionService {
  static async getAll(
    props: IGetDisciplinaryActionProps
  ): Promise<IDisciplinaryAction[]> {
    return HttpClient.get({
      path: `/disciplinary-actions`,
      params: {
        ...(props?.paginationOptions &&
          PaginationHelper.getPaginationParams(props.paginationOptions)),
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),
        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
        paginate: props.paginate || true,
      },
    });
  }
  static async getCount(props: IGetDisciplinaryActionProps): Promise<number> {
    return HttpClient.get({
      path: `/disciplinary-actions/count`,
      params: {
        ...(props?.schoolId && {
          schoolId: props?.schoolId,
        }),
        ...(props?.searchKey && {
          searchKey: props.searchKey,
        }),
      },
    });
  }

  static async get(id: number): Promise<IDisciplinaryAction> {
    return HttpClient.get({
      path: `/disciplinary-actions/${id}`,
    });
  }

  static async create(
    disciplinaryAction: IDisciplinaryAction,
    schoolId: number
  ): Promise<IDisciplinaryAction> {
    return HttpClient.post({
      path: `/disciplinary-actions?schoolId=${schoolId}`,
      body: disciplinaryAction,
    });
  }

  static async update(
    disciplinaryAction: IDisciplinaryAction
  ): Promise<IDisciplinaryAction> {
    return HttpClient.put({
      path: `/disciplinary-actions`,
      body: disciplinaryAction,
    });
  }

  static async delete(
    disciplinaryAction: IDisciplinaryAction
  ): Promise<IDisciplinaryAction> {
    return HttpClient.delete({
      path: "/disciplinary-actions",
      body: disciplinaryAction,
    });
  }
}
