import React, {Dispatch, SetStateAction} from "react";
import {AppGrid} from "../../../Shared/Components/Grid";
import {IStudentImportProcessResult} from "../../../Shared/Models/Entities/student-import-process-result.interface";
import {Icons} from "../../../Shared/Components/Icons/Icons";
import {PaletteTypes} from "../../../Shared/Themes/palette-types.enum";
import {AppButton} from "../../../Shared/Components/Button";
import {SnackbarService} from "../../../Shared/Services/snackbar.service";
import {LoadingBarService} from "../../../Shared/Services/loading-bar.service";
import {StudentImportService} from "../../../Shared/Services/student-import.service";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";

export const AppStudentImportConfirmPanel = (props: {
    studentImportResults: IStudentImportProcessResult;
    isCreating: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    onUpdated: () => void;
}): JSX.Element => {

    const handleClose = () => {
        props.setOpen?.(false);
    };

    const handleSubmit = async () => {
        LoadingBarService.show();
        const studentImport = props.studentImportResults.studentImport;
        studentImport.status = "confirmed";
        const result = await StudentImportService.update(studentImport);
        SnackbarService.success(`Student import ${result.id}:${result.name} confirmed`);
        LoadingBarService.hide();
        handleClose();
        props.onUpdated();
    };

    const count = props.studentImportResults.students.length;
    const students = [props.studentImportResults.students[0], props.studentImportResults.students[count-1]];

    return (
        <AppGrid container spacing={1}>
            <AppGrid container spacing={3}>
                <TableContainer>
                    <Table aria-label="Student Import Confirm">
                        <TableHead>
                            <TableRow>
                                <TableCell>Import Fields</TableCell>
                                <TableCell align="right">First Student</TableCell>
                                <TableCell align="right">Last Student</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key="externalId">
                                <TableCell>Student Id</TableCell>
                                <TableCell align="right">{students[0].externalId}</TableCell>
                                <TableCell align="right">{students[1].externalId}</TableCell>
                            </TableRow>
                            <TableRow key="firstName">
                                <TableCell>First Name</TableCell>
                                <TableCell align="right">{students[0].firstName}</TableCell>
                                <TableCell align="right">{students[1].firstName}</TableCell>
                            </TableRow>
                            <TableRow key="middleInitial">
                                <TableCell>Middle Initial</TableCell>
                                <TableCell align="right">{students[0].middleInitial}</TableCell>
                                <TableCell align="right">{students[1].middleInitial}</TableCell>
                            </TableRow>
                            <TableRow key="grade">
                                <TableCell>Grade</TableCell>
                                <TableCell align="right">{students[0].grade}</TableCell>
                                <TableCell align="right">{students[1].grade}</TableCell>
                            </TableRow>
                            <TableRow key="active">
                                <TableCell>Active</TableCell>
                                <TableCell align="right">{students[0].active}</TableCell>
                                <TableCell align="right">{students[1].active}</TableCell>
                            </TableRow>
                            <TableRow key="address">
                                <TableCell>Address</TableCell>
                                <TableCell align="right">{students[0].address}</TableCell>
                                <TableCell align="right">{students[1].address}</TableCell>
                            </TableRow>
                            <TableRow key="birthDate">
                                <TableCell>Birth Date</TableCell>
                                <TableCell align="right">{students[0].birthDate}</TableCell>
                                <TableCell align="right">{students[1].birthDate}</TableCell>
                            </TableRow>
                            <TableRow key="city">
                                <TableCell>City</TableCell>
                                <TableCell align="right">{students[0].city}</TableCell>
                                <TableCell align="right">{students[1].city}</TableCell>
                            </TableRow>
                            <TableRow key="contactPhone">
                                <TableCell>Contact Phone</TableCell>
                                <TableCell align="right">{students[0].contactPhone}</TableCell>
                                <TableCell align="right">{students[1].contactPhone}</TableCell>
                            </TableRow>
                            <TableRow key="country">
                                <TableCell>Country</TableCell>
                                <TableCell align="right">{students[0].country}</TableCell>
                                <TableCell align="right">{students[1].country}</TableCell>
                            </TableRow>
                            <TableRow key="emergencyContact">
                                <TableCell>Emergency Contact</TableCell>
                                <TableCell align="right">{students[0].emergencyContact}</TableCell>
                                <TableCell align="right">{students[1].emergencyContact}</TableCell>
                            </TableRow>
                            <TableRow key="ethnicGroup">
                                <TableCell>Ethnic Group</TableCell>
                                <TableCell align="right">{students[0].ethnicGroup}</TableCell>
                                <TableCell align="right">{students[1].ethnicGroup}</TableCell>
                            </TableRow>
                            <TableRow key="gender">
                                <TableCell>Gender</TableCell>
                                <TableCell align="right">{students[0].gender}</TableCell>
                                <TableCell align="right">{students[1].gender}</TableCell>
                            </TableRow>
                            <TableRow key="guardian">
                                <TableCell>Guardian</TableCell>
                                <TableCell align="right">{students[0].guardian}</TableCell>
                                <TableCell align="right">{students[1].guardian}</TableCell>
                            </TableRow>
                            <TableRow key="guardianEmail">
                                <TableCell>Guardian Email</TableCell>
                                <TableCell align="right">{students[0].guardianEmail}</TableCell>
                                <TableCell align="right">{students[1].guardianEmail}</TableCell>
                            </TableRow>
                            <TableRow key="homePhone">
                                <TableCell>Home Phone</TableCell>
                                <TableCell align="right">{students[0].homePhone}</TableCell>
                                <TableCell align="right">{students[1].homePhone}</TableCell>
                            </TableRow>
                            <TableRow key="homeroom">
                                <TableCell>Homeroom</TableCell>
                                <TableCell align="right">{students[0].homeroom}</TableCell>
                                <TableCell align="right">{students[1].homeroom}</TableCell>
                            </TableRow>
                            <TableRow key="notes">
                                <TableCell>Notes</TableCell>
                                <TableCell align="right">{students[0].notes}</TableCell>
                                <TableCell align="right">{students[1].notes}</TableCell>
                            </TableRow>
                            <TableRow key="schoolId">
                                <TableCell>School ID</TableCell>
                                <TableCell align="right">{students[0].schoolId}</TableCell>
                                <TableCell align="right">{students[1].schoolId}</TableCell>
                            </TableRow>
                            <TableRow key="sendLettersViaEmail">
                                <TableCell>Send Letters via Email</TableCell>
                                <TableCell align="right">{students[0].sendLettersViaEmail}</TableCell>
                                <TableCell align="right">{students[1].sendLettersViaEmail}</TableCell>
                            </TableRow>
                            <TableRow key="state">
                                <TableCell>State</TableCell>
                                <TableCell align="right">{students[0].state}</TableCell>
                                <TableCell align="right">{students[1].state}</TableCell>
                            </TableRow>
                            <TableRow key="studentEmail">
                                <TableCell>Student Email</TableCell>
                                <TableCell align="right">{students[0].studentEmail}</TableCell>
                                <TableCell align="right">{students[1].studentEmail}</TableCell>
                            </TableRow>
                            <TableRow key="studentLanguage">
                                <TableCell>Student Language</TableCell>
                                <TableCell align="right">{students[0].studentLanguage}</TableCell>
                                <TableCell align="right">{students[1].studentLanguage}</TableCell>
                            </TableRow>
                            <TableRow key="teacher">
                                <TableCell>Teacher</TableCell>
                                <TableCell align="right">{students[0].teacher}</TableCell>
                                <TableCell align="right">{students[1].teacher}</TableCell>
                            </TableRow>
                            <TableRow key="workPhone">
                                <TableCell>Work Phone</TableCell>
                                <TableCell align="right">{students[0].workPhone}</TableCell>
                                <TableCell align="right">{students[1].workPhone}</TableCell>
                            </TableRow>
                            <TableRow key="zip">
                                <TableCell>Zip</TableCell>
                                <TableCell align="right">{students[0].zip}</TableCell>
                                <TableCell align="right">{students[1].zip}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Typography style={{ paddingTop: "20px"}} paragraph>Confirm student import fields</Typography>
            </AppGrid>
            <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
                <AppButton
                    text="Cancel"
                    icon={Icons.Close}
                    palette={PaletteTypes.WARNING}
                    variant="outlined"
                    onClick={() => handleClose()}
                />
                <AppButton
                    text="Confirm"
                    icon={props.isCreating ? Icons.Add : Icons.Check}
                    palette={PaletteTypes.SUCCESS}
                    onClick={() => handleSubmit()}
                />
            </AppGrid>
        </AppGrid>
    )
}