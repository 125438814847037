import React, { useState } from "react";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";
import { DataHooks } from "../../../Shared/Hooks/data-hooks";
import { IEventSupervisor } from "../../../Shared/Models/Entities/event-supervisor.interface";
import { EventSupervisorService } from "../../../Shared/Services/event-supervisor.service";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { Icons } from "../../../Shared/Components/Icons/Icons";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { AppCustomTable } from "../../../Shared/Components/Tables/CustomTable/CustomTable";
import { AppButton } from "../../../Shared/Components/Button";
import { AppGrid } from "../../../Shared/Components/Grid";
import { AppCard } from "../../../Shared/Components/Cards/Card";
import { AppCardHeader } from "../../../Shared/Components/Cards/CardHeader";
import { AppCardContent } from "../../../Shared/Components/Cards/CardContent";
import { AppEventSupervisorCreateDialog } from "../EventSupervisorCreateDialog/EventSupervisorCreateDialog";
import { AppEventSupervisorEditDialog } from "../EventSupervisorEditDialog/EventSupervisorEditDialog";

export const AppEventSupervisorList = (): JSX.Element => {
  const { district, school } = StoreHelper.selector((state) => state.appConfig);
  const supervisorsState = DataHooks.useFunctionCallState<IEventSupervisor[]>({
    func: () =>
      EventSupervisorService.get({
        schoolId: school?.id,
        districtId: district?.id,
      }),
    deps: [school?.id, district?.id],
    showLoadingIndicator: true,
  });

  const [createPopupOpen, setCreatePopupOpen] = useState<boolean>(false);
  const [editingSupervisor, setEditingSupervisor] =
    useState<IEventSupervisor | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);

  return (
    <AppGrid container>
      <AppGrid
        item
        xs={12}
        maxWidth={800}
        container
        direction="column"
        spacing={1}
      >
        <AppGrid item xs={12} container justifyContent="flex-end">
          <AppButton
            text="Create Supervisor"
            icon={Icons.Add}
            size="large"
            palette={PaletteTypes.SUCCESS}
            onClick={() => setCreatePopupOpen(true)}
          />
        </AppGrid>
        <AppGrid item xs={12}>
          <AppCard hasHeader>
            <AppCardHeader
              title="Event Supervisors"
              subheader="Setup keys that school users can use to manage events"
              icon={Icons.AccountKey}
              palette={PaletteTypes.PRIMARY}
            />
            <AppCardContent padding={0}>
              <AppEventSupervisorListTable
                loading={supervisorsState.loading}
                supervisors={supervisorsState.data || []}
                editSupervisorPressed={(supervisor) => {
                  setEditingSupervisor(supervisor);
                  setEditDialogOpen(true);
                }}
              />
            </AppCardContent>
          </AppCard>
        </AppGrid>
      </AppGrid>
      <AppEventSupervisorCreateDialog
        open={createPopupOpen}
        setOpen={setCreatePopupOpen}
        onDataUpdated={async () => supervisorsState.refreshData()}
      />
      {editingSupervisor && (
        <AppEventSupervisorEditDialog
          eventSupervisor={editingSupervisor}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          onDataUpdated={async () => supervisorsState.refreshData()}
        />
      )}
    </AppGrid>
  );
};

const AppEventSupervisorListTable = ({
  loading,
  supervisors,
  editSupervisorPressed,
}: {
  loading: boolean;
  supervisors: IEventSupervisor[];
  editSupervisorPressed: (supervisor: IEventSupervisor) => void;
}): JSX.Element => {
  const canEdit = hasPermission(AppPermissionsEnum.EVENTS_EDIT);

  return (
    <>
      <AppCustomTable
        loading={loading}
        columns={[
          {
            id: "name",
            label: "Supervisor Name",
          },
          {
            id: "key",
            label: "Supervisor Key",
          },
          {
            id: "can-manage-events",
            label: "Can Manage Events",
          },
          ...(canEdit
            ? [
                {
                  id: "action",
                  label: "",
                  width: 180,
                  align: "right" as const,
                },
              ]
            : []),
        ]}
        rows={supervisors.map((supervisor) => ({
          cells: [
            {
              value: supervisor.name,
            },
            {
              value: supervisor.key,
            },
            {
              value: supervisor.canManageEvent ? "Y" : "N",
            },
            ...(canEdit
              ? [
                  {
                    value: (
                      <AppButton
                        icon={Icons.Edit}
                        palette={PaletteTypes.INFO}
                        onClick={() => {
                          editSupervisorPressed(supervisor);
                        }}
                      />
                    ),
                  },
                ]
              : []),
          ],
        }))}
      />
    </>
  );
};
