import { appMakeStyles } from "../../../../../Shared/Themes/app-make-styles";

export const DistrictTreeNodeStyles = appMakeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.background.paperAlternate,

    "&:first-child *": {
      borderTop: "none !important",
    },
  },
  nodeItem: {
    display: "flex",
    width: "100%",
    cursor: "pointer",
    fontWeight: 500,

    "&:hover,&:focus,&:active": {
      backgroundColor: theme.palette.background.paperHover,
    },
  },
  nodeItemSelected: {
    fontWeight: 700,
    backgroundColor: theme.palette.background.paperHover,
  },
  districtNode: {
    height: "49px",
    borderTop: "1px solid " + theme.palette.divider,
  },
  districtNodeOpen: {
    borderBottom: "1px solid " + theme.palette.divider,
  },
  districtIcon: {
    padding: "10px",

    "& svg": {
      width: "25px",
    },

    "&:hover,&:focus,&:active": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  emptyDistrictIcon: {
    width: "45px",
    "padding-right": "8px",
  },
  districtText: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    padding: "10px",
  },
  schoolList: {},
  schoolNode: {
    display: "flex",
    justifyContent: "flex-end",
    borderLeft: "5px solid " + theme.palette.divider,
    padding: "10px 15px",

    "&:not(:last-child)": {
      borderBottom: "1px solid " + theme.palette.divider,
    },
  },
  disabledItem: {
    svg: {
      display: "none",
    },
  },
  labelIcon: {
    width: "14px",
    height: "14px",
    marginRight: "6px",
  },
  nodeInfo: {
    display: "flex",
    margin: "-4px",
  },
  nodeName: {
    color: theme.palette.text.primary,
    margin: "4px",
  },
  nodeId: {
    color: theme.palette.text.secondary,
    margin: "4px",
    fontWeight: 300,
  },
  emptyIcon: {},
}));
