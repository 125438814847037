import { IAdminUser } from "../../../../Shared/Models/Entities/admin-user.interface";
import { AppPermissionsEnum } from "../../../../Shared/Models/Enums/app-permissions.enum";

export enum UserValidationErrors {
  REQUIRED = "Required",
  EMAIL = "Email is not valid",
}

export interface IUserValidationResults {
  firstName?: UserValidationErrors;
  lastName?: UserValidationErrors;
  email?: UserValidationErrors;
  schoolOrSchoolGroup?: UserValidationErrors;
}

export class AdminUserEditorPanelHelper {
  static getBlankUser(): IAdminUser {
    return {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      permissions: [],
      internal: false,
      schoolId: null,
      schoolGroupId: null,
    };
  }

  static getBlankInternalUser(): IAdminUser {
    return {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      permissions: [
        AppPermissionsEnum.ACCESS_MANAGEMENT_PORTAL,
        AppPermissionsEnum.EVENTS_GET,
        AppPermissionsEnum.EVENTS_EDIT,
        AppPermissionsEnum.SCHOOLS_EDIT,
        AppPermissionsEnum.SCHOOL_USERS_GET,
        AppPermissionsEnum.SCHOOL_USERS_EDIT,
        AppPermissionsEnum.ROLES_GET,
        AppPermissionsEnum.ROLES_EDIT,
        AppPermissionsEnum.BELL_SCHEDULES_GET,
        AppPermissionsEnum.BELL_SCHEDULES_EDIT,
        AppPermissionsEnum.TRAC_CODES_VIEW,
        AppPermissionsEnum.TRAC_CODES_EDIT,
      ],
      internal: true,
    };
  }

  static validateUser(user: IAdminUser): IUserValidationResults {
    const results = {} as IUserValidationResults;

    if (!user.firstName) {
      results.firstName = UserValidationErrors.REQUIRED;
    }
    if (!user.lastName) {
      results.lastName = UserValidationErrors.REQUIRED;
    }
    if (!user.email) {
      results.email = UserValidationErrors.REQUIRED;
    } else if (!/^\S+@\S+\.\S+$/.test(user.email)) {
      results.email = UserValidationErrors.EMAIL;
    }
    if (!user.internal && !user.schoolId && !user.schoolGroupId) {
      results.schoolOrSchoolGroup = UserValidationErrors.REQUIRED;
    }

    return results;
  }
}
