import { AuthenticationService } from "../../Shared/Services/authentication.service";
import { AuthReducerActions } from "../../Shared/Reducers/Auth/auth-reducer";
import { RouteComponentProps } from "react-router";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import { SnackbarService } from "../../Shared/Services/snackbar.service";
import * as qs from "query-string";
import { LoadingBarService } from "../../Shared/Services/loading-bar.service";
import { ITokenUser } from "../../Shared/Models/Entities/token-user.interface";
import { AppPermissionsEnum } from "../../Shared/Models/Enums/app-permissions.enum";

export class GoogleAuthTokenHandler {
  static async checkGoogleAuthTokenInQueryParams({
    location,
    history,
  }: RouteComponentProps): Promise<void> {
    try {
      const code = GoogleAuthTokenHandler.getUserCodeFromLocation(location);
      if (code) {
        GoogleAuthTokenHandler.clearQueryCode(history);
        LoadingBarService.show();
        const tokenUser = await AuthenticationService.googleLogin(code);
        LoadingBarService.hide();
        if (tokenUser) {
          const canAccessMP =
            GoogleAuthTokenHandler.canAccessTheManagementPortal(tokenUser);
          if (canAccessMP) {
            StoreHelper.dispatch(AuthReducerActions.setTokenUser(tokenUser));
          } else {
            SnackbarService.error(
              "Sorry, you're not authorized to access the Management Portal. Please " +
                "contact product support if you think this is an error."
            );
          }
        } else {
          SnackbarService.error(
            "Sorry, we could not decode that access token!"
          );
        }
      }
    } catch (error) {
      LoadingBarService.hide();
      SnackbarService.error(`There was an error using that token to login!`);
    }
  }

  static getUserCodeFromLocation(
    location: RouteComponentProps["location"]
  ): string | null | undefined {
    const { code } = qs.parse(location.search);
    return Array.isArray(code) ? code[0] : (code as string | undefined);
  }

  static clearQueryCode(history: RouteComponentProps["history"]): void {
    history.push("/");
  }

  // TODO: Check has permission
  static canAccessTheManagementPortal(tokenUser: ITokenUser): boolean {
    return tokenUser.permissions.includes(
      AppPermissionsEnum.ACCESS_MANAGEMENT_PORTAL
    );
  }
}
