import React, { useState } from "react";
import { AppGrid } from "../../../Shared/Components/Grid";
import { IPopulatedTracCode } from "../../../Shared/Models/Entities/populated-trac-code.interface";
import { AppTracCodeDisciplinaryActions } from "./TracCodeDisciplinaryActions/TracCodeDisciplinaryActions";
import { AppTracCodeInfo } from "./TracCodeInfo/TracCodeInfo";
import { AppTracCodeInterventions } from "./TracCodeInterventions/TracCodeInterventions";
import { AppPermissionsEnum } from "../../../Shared/Models/Enums/app-permissions.enum";
import { hasPermission } from "../../../Shared/Hooks/has-permission";
import { StoreHelper } from "../../../Shared/Reducers/store-helper";

export const AppTracCodeViewer = ({
  tracCode,
  setTracCode,
  refreshTracCode,
  loading,
  isCreating,
}: {
  tracCode: IPopulatedTracCode;
  setTracCode: (tracCode: IPopulatedTracCode) => void;
  refreshTracCode: () => void;
  loading: boolean;
  isCreating: boolean;
}): JSX.Element => {
  const { school } = StoreHelper.selector((state) => state.appConfig);

  const canEdit = hasPermission(AppPermissionsEnum.TRAC_CODES_EDIT);
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <>
      {!loading && (
        <AppGrid container spacing={isCreating ? 1 : 5} justifyContent="center">
          <AppGrid
            xs={12}
            md={12}
            lg={6}
            xl={5}
            spacing={5}
            container
            item
            direction="column"
          >
            <AppGrid item xs={12}>
              <AppTracCodeInfo
                tracCode={tracCode as IPopulatedTracCode}
                setTracCode={setTracCode}
                refreshTracCode={refreshTracCode}
                canEdit={canEdit}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
                isCreating={isCreating}
              />
            </AppGrid>
          </AppGrid>
          {!isCreating && school && (
            <AppGrid
              xs={12}
              md={12}
              lg={6}
              xl={6}
              container
              item
              spacing={5}
              direction="column"
            >
              <AppGrid item>
                <AppTracCodeDisciplinaryActions
                  canEdit={canEdit}
                  loading={loading}
                  disciplinaryActions={
                    tracCode ? tracCode.tracCodeDisciplinaryActions : []
                  }
                  tracCode={tracCode as IPopulatedTracCode}
                  refreshTracCode={refreshTracCode}
                />
              </AppGrid>

              <AppGrid item>
                <AppTracCodeInterventions
                  tracCode={tracCode as IPopulatedTracCode}
                  refreshTracCode={refreshTracCode}
                  setTracCode={setTracCode}
                  canEdit={canEdit}
                />
              </AppGrid>
            </AppGrid>
          )}
        </AppGrid>
      )}
    </>
  );
};
