import { AuthenticationService } from "../../Shared/Services/authentication.service";
import { StoreHelper } from "../../Shared/Reducers/store-helper";
import { AuthReducerActions } from "../../Shared/Reducers/Auth/auth-reducer";
import { SnackbarService } from "../../Shared/Services/snackbar.service";
import { ITokenUser } from "../../Shared/Models/Entities/token-user.interface";
import { AppPermissionsEnum } from "../../Shared/Models/Enums/app-permissions.enum";

export class BasicLoginHandler {
  static async handleBasicLogin(
    username: string,
    password: string
  ): Promise<void> {
    try {
      const tokenUser = await AuthenticationService.basicLogin(
        username,
        password
      );
      if (tokenUser) {
        const canAccessMP =
          BasicLoginHandler.canAccessTheManagementPortal(tokenUser);
        if (canAccessMP) {
          StoreHelper.dispatch(AuthReducerActions.setTokenUser(tokenUser));
        } else {
          SnackbarService.error(
            "Sorry, you're not authorized to access the Management Portal. Please " +
              "contact engineering if you think this is an error."
          );
        }
      } else {
        SnackbarService.error("Sorry, we could not decode that access token!");
      }
    } catch (error) {
      SnackbarService.error(`There was an error using that token to login!`);
    }
  }

  // TODO: Check has permission
  static canAccessTheManagementPortal(tokenUser: ITokenUser): boolean {
    return tokenUser.permissions.includes(
      AppPermissionsEnum.ACCESS_MANAGEMENT_PORTAL
    );
  }
}
