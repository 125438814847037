import React, { Dispatch, SetStateAction, useState } from "react";
import { AppCustomDialog } from "../../../../Shared/Components/Dialogs/CustomDialog";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { IBellSchedulePeriod } from "../../../../Shared/Models/Entities/bell-schedule-period.interface";
import { AppGrid } from "../../../../Shared/Components/Grid";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { AppButton } from "../../../../Shared/Components/Button";
import { TimeHelper } from "../../../../Shared/Helpers/time-helper";
import { PeriodEditorHelper } from "./PeriodEditor.helper";
import { AppTimeControl } from "../../../../Shared/Components/Controls/TimeControl";
export const AppPeriodEditorDialog = (props: {
  period?: IBellSchedulePeriod | false;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDataUpdated: () => Promise<void>;
  handleSubmit: (period: IBellSchedulePeriod) => void;
  handleDelete?: () => void;
}): JSX.Element => {
  const [name, setName] = useState(props.period ? props.period.name : "");
  const [referenceCode, setReferenceCode] = useState(
    props.period ? props.period.referenceCode : null
  );
  const [extPeriodCode, setExtPeriodCode] = useState(
    props.period ? props.period.extPeriodCode : null
  );

  const [start, setStart] = useState<string>(
    props.period
      ? TimeHelper.formatDateToDBString(new Date(props.period.start))
      : TimeHelper.formatDateToDBString(TimeHelper.getNoon())
  );
  const [end, setEnd] = useState<string>(
    props.period
      ? TimeHelper.formatDateToDBString(new Date(props.period.end))
      : TimeHelper.formatDateToDBString(TimeHelper.getNoon())
  );

  const datesAreCorrect = start && end && start < end;
  return (
    <AppCustomDialog
      open={props.open}
      setOpen={props.setOpen}
      fullWidth
      maxWidth="md"
      title={props.period ? "Edit Period" : "Add Period"}
      icon={Icons.Calendar}
      palette={PaletteTypes.SECONDARY}
    >
      <AppGrid container spacing={3}>
        <AppTextFieldControl
          gridProps={{ xs: 4 }}
          label="Name"
          name="name"
          value={name}
          error={name === ""}
          onChange={(event) => {
            setName(event.target.value);
          }}
          icon={Icons.Info}
          opaqueBackground
        />
        <AppTimeControl
          label={"Start Time"}
          value={start}
          onChange={(date) => {
            date && setStart(TimeHelper.formatDateToDBString(date));
          }}
          error={!datesAreCorrect}
        />
        <AppTimeControl
          label={"End Time"}
          value={end}
          onChange={(date) =>
            date && setEnd(TimeHelper.formatDateToDBString(date))
          }
          error={!datesAreCorrect}
        />

        <AppTextFieldControl
          gridProps={{ xs: 4 }}
          label="Ref. Code"
          name="referenceCode"
          value={referenceCode}
          onChange={(event) => {
            setReferenceCode(event.target.value);
          }}
          icon={Icons.Database}
          opaqueBackground
        />
        <AppTextFieldControl
          gridProps={{ xs: 4 }}
          label="Ext. Code"
          name="extPeriodCode"
          value={extPeriodCode}
          onChange={(event) => {
            setExtPeriodCode(event.target.value);
          }}
          icon={Icons.Dns}
          opaqueBackground
        />

        <AppGrid item xs={12} container justifyContent="flex-end" spacing={1}>
          {props.period && (
            <AppButton
              text={"Delete"}
              icon={Icons.Delete}
              palette={PaletteTypes.ERROR}
              onClick={props.handleDelete}
            />
          )}
          <AppButton
            text={"Cancel"}
            icon={Icons.Close}
            palette={PaletteTypes.ERROR}
            onClick={() => props.setOpen(false)}
          />
          <AppButton
            disabled={!!(!datesAreCorrect && start && end && name)}
            text={props.period ? "Update" : "Add"}
            icon={props.period ? Icons.Edit : Icons.Save}
            palette={PaletteTypes.SECONDARY}
            onClick={() => {
              const period = {
                id: props.period ? props.period.id : -1,
                name,
                start,
                end,
                referenceCode: referenceCode ? referenceCode : "",
                extPeriodCode: extPeriodCode ? extPeriodCode : "",
              };
              const errorResults = PeriodEditorHelper.validatePeriod(period);
              const isValid = Object.values(errorResults).length === 0;
              if (isValid) props.handleSubmit(period);
            }}
          />
        </AppGrid>
      </AppGrid>
    </AppCustomDialog>
  );
};
