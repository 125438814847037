import React from "react";
import { AppCard } from "../../../../Shared/Components/Cards/Card";
import { AppCardContent } from "../../../../Shared/Components/Cards/CardContent";
import { AppCardHeader } from "../../../../Shared/Components/Cards/CardHeader";
import { PaletteTypes } from "../../../../Shared/Themes/palette-types.enum";
import { Icons } from "../../../../Shared/Components/Icons/Icons";

export const AppWelcomePanel = (): JSX.Element => {
  return (
    <AppCard hasHeader>
      <AppCardHeader
        title={"Welcome to the Schools Manager!"}
        subheader={
          "Please pick one of the school groups on your left to view or edit!"
        }
        icon={Icons.EmojiPeople}
        palette={PaletteTypes.PRIMARY}
      />
      <AppCardContent />
    </AppCard>
  );
};
