import React, { useRef } from "react";
import { parse } from "papaparse";
import { PaletteTypes } from "../Themes/palette-types.enum";
import { AppIcon } from "./Icons/Icons";
import { AppButton } from "./Button";
import { SnackbarService } from "../Services/snackbar.service";

export const AppFileInput = ({
  text,
  icon,
  size,
  variant,
  palette,
  fileSelected,
  rawFile
}: {
  text: string;
  icon: AppIcon;
  size: "small" | "medium" | "large";
  variant: "text" | "outlined" | "contained";
  palette: PaletteTypes;
  // eslint-disable-next-line
  fileSelected: (file: any) => void;
  rawFile?: boolean;
}): JSX.Element => {
  const fileUploadRef = useRef(null);

  const onFilesAdded = () => {
    try {
      // eslint-disable-next-line
      const file = (document.getElementById("fileInput") as any).files[0];
      if (!rawFile) {
        parse(file, {
          worker: true,
          complete: (results) => {
            fileSelected(results.data);
          },
          error: (error) => {
            SnackbarService.error(error);
          },
        });
      } else {
        fileSelected(file);
      }
    } catch (error) {
      SnackbarService.error(error);
    }
  };

  return (
    <>
      <input
        id="fileInput"
        type="file"
        accept=".csv"
        hidden
        ref={fileUploadRef}
        onChange={onFilesAdded}
      />
      <AppButton
        text={text}
        icon={icon}
        size={size}
        variant={variant}
        palette={palette}
        // eslint-disable-next-line
        onClick={() => (fileUploadRef.current as any)?.click()}
      />
    </>
  );
};
