import React, { Dispatch, SetStateAction } from "react";
import { AppConfirmationDialog } from "../../../Shared/Components/Dialogs/ConfirmationDialog";
import { PaletteTypes } from "../../../Shared/Themes/palette-types.enum";
import { SnackbarService } from "../../../Shared/Services/snackbar.service";
import { Logger } from "../../../Shared/Helpers/logger";
import { LoadingBarService } from "../../../Shared/Services/loading-bar.service";
import { ISchoolUserSimple } from "../../../Shared/Models/Entities/school-user.interface";
import { SchoolUserService } from "../../../Shared/Services/school-user.service";
import { Icons } from "../../../Shared/Components/Icons/Icons";

export const AppSchoolUserArchiveDialog = (props: {
  schoolUser: ISchoolUserSimple | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onUserUpdated: () => void;
}): JSX.Element => {
  const verb = props.schoolUser?.active ? "Archive" : "Restore";

  const handleArchiveConfirmed = async () => {
    if (props.schoolUser) {
      LoadingBarService.show();
      try {
        await SchoolUserService.toggleActive(
          props.schoolUser.id,
          !props.schoolUser.active
        );
        void props.onUserUpdated();
        SnackbarService.success(
          verb +
            "d " +
            props.schoolUser.firstName +
            " " +
            props.schoolUser.lastName
        );
      } catch (error) {
        Logger.error(error);
        SnackbarService.error(error);
      }
      LoadingBarService.hide();
    } else {
      Logger.error("No user passed to archive dialog!");
    }
  };

  return (
    <AppConfirmationDialog
      open={props.open}
      setOpen={props.setOpen}
      title={verb + " Archive School User?"}
      content={"Are you sure you want to " + verb.toLowerCase() + " this user?"}
      icon={props.schoolUser?.active ? Icons.Delete : Icons.Refresh}
      agreeAction={handleArchiveConfirmed}
      palette={
        props.schoolUser?.active ? PaletteTypes.ERROR : PaletteTypes.SUCCESS
      }
    />
  );
};
