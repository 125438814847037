import React, { useState } from "react";
import { AppTextFieldControl } from "../../../../Shared/Components/Controls/TextFieldControl";
import { Icons } from "../../../../Shared/Components/Icons/Icons";
import { DisciplinaryActionFilters } from "../ManageDisciplinaryAction";

export const AppDisciplinaryActionFilters = ({
  filters,
  setFilters,
}: {
  filters: DisciplinaryActionFilters;
  setFilters: (props: DisciplinaryActionFilters) => void;
}): JSX.Element => {
  const [currFilterKey, setCurrFilterKey] = useState(filters.searchKey);

  return (
    <AppTextFieldControl
      fullWidth
      gridProps={{ xs: 6, lg: 4 }}
      id="filled-basic"
      placeholder="Search by Name or Description..."
      value={currFilterKey}
      icon={Icons.Search}
      type="search"
      onBlur={() => {
        if (currFilterKey !== filters.searchKey) {
          setFilters({
            ...filters,
            searchKey: currFilterKey,
          });
        }
      }}
      onKeyPress={(event) => {
        if (event.code === "Enter") {
          setFilters({
            ...filters,
            searchKey: currFilterKey,
          });
        }
      }}
      onChange={(event) => {
        setCurrFilterKey(event.target.value);
      }}
      opaqueBackground
    />
  );
};
