import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAuthReducerState } from "./auth-reducer-state.interface";
import { LocalStorageKeysEnum } from "../../Models/Enums/local-storage-keys.enum";
import { LocalStorageHelper } from "../../Helpers/local-storage.helper";
import { ITokenUser } from "../../Models/Entities/token-user.interface";

const defaultState = {
  isUserAuthenticated: false,
  user: null,
};

export const AuthReducerSlice = createSlice({
  name: "auth",
  initialState:
    LocalStorageHelper.getObject(LocalStorageKeysEnum.AUTH_STATE) ||
    defaultState,
  reducers: {
    setTokenUser: (
      state: IAuthReducerState,
      action: PayloadAction<ITokenUser>
    ): void => {
      const tokenUser = action.payload;
      state.user = tokenUser;
      state.isUserAuthenticated = true;
      LocalStorageHelper.setItem(
        LocalStorageKeysEnum.AUTH_STATE,
        JSON.stringify(state)
      );
      LocalStorageHelper.setItem(
        LocalStorageKeysEnum.ACCESS_TOKEN,
        tokenUser.accessToken
      );
    },
    clearTokenUser: (state: IAuthReducerState): void => {
      if (state.isUserAuthenticated) {
        state.user = null;
        state.isUserAuthenticated = false;
        LocalStorageHelper.removeItem(LocalStorageKeysEnum.AUTH_STATE);
        LocalStorageHelper.removeItem(LocalStorageKeysEnum.ACCESS_TOKEN);
      }
    },
  },
});
export const AuthReducerActions = AuthReducerSlice.actions;
