import React from "react";
import { TablePaginationProps, TablePagination } from "@material-ui/core";
import { appMakeStyles } from "../../../Themes/app-make-styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BaseTablePaginationStyles = appMakeStyles((theme) => ({
  tablePagination: {
    borderBottom: "none",
  },
}));

// eslint-disable-next-line
export type BaseTablePaginationProps = TablePaginationProps & {};

export const AppBaseTablePagination = (
  props: BaseTablePaginationProps
): JSX.Element => {
  const classes = BaseTablePaginationStyles({});

  return <TablePagination {...props} className={classes.tablePagination} />;
};
