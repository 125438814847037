import { ISchool } from "../../../../Shared/Models/Entities/school.interface";
import {
  ISchoolValidationResults,
  SchoolValidationErrors,
} from "./SchoolValidation.interface";
import { SnackbarService } from "../../../../Shared/Services/snackbar.service";

export class SchoolEditorHelper {
  static getBlankSchool(): ISchool {
    return {
      id: 0,
      name: "",
      phone: "",
      email: "",
      principalName: "",
      referenceId: null,
      timeZone: "",
      districtId: null,
      currentAcademicYear: null,
      customerSubscription: {
        id: 0,
        enrollment: "",
        date: {
          start: new Date(),
          end: new Date(),
        },
        supportTier: null,
        subscription: null,
        active: true,
      },
      salesForceId: null,
    };
  }

  static validateSchool({
    school,
    logWarnings,
    canEditSubscription,
    canViewEnrollment,
    canUpdate,
  }: {
    school: Partial<ISchool> | null;
    logWarnings: boolean;
    canEditSubscription: boolean;
    canViewEnrollment: boolean;
    canUpdate: boolean;
  }): ISchoolValidationResults {
    const results = {} as ISchoolValidationResults;

    const log = (message: string) => {
      if (logWarnings) {
        SnackbarService.warn(message);
      }
    };

    if (canUpdate) {
      if (!school?.name) {
        results.name = SchoolValidationErrors.REQUIRED;
        log("Name is required");
      }
      if (!school?.referenceId) {
        results.referenceId = SchoolValidationErrors.REQUIRED;
        log("Reference ID is required");
      }
      if (!school?.timeZone) {
        results.timeZone = SchoolValidationErrors.REQUIRED;
        log("Timezone is required");
      }
      if (!school?.email) {
        results.email = SchoolValidationErrors.REQUIRED;
        log("Email is required");
      } else if (!/^\S+@\S+\.\S+$/.test(school?.email)) {
        results.email = SchoolValidationErrors.EMAIL;
        log("Email must be in a valid format. Ex: test.email@testing.com");
      }
      if (!school?.districtId) {
        results.district = SchoolValidationErrors.REQUIRED;
        log("District is required");
      }

      if (canEditSubscription) {
        if (!school?.customerSubscription?.supportTier) {
          results.supportTier = SchoolValidationErrors.REQUIRED;
          log("Support tier is required");
        }
        if (!school?.customerSubscription?.subscription) {
          results.subscription = SchoolValidationErrors.REQUIRED;
          log("Subscription is required");
        }
        if (!school?.customerSubscription?.date?.start) {
          results.subscriptionStart = SchoolValidationErrors.REQUIRED;
          log("Start date is required");
        }
        if (!school?.customerSubscription?.date?.end) {
          results.subscriptionEnd = SchoolValidationErrors.REQUIRED;
          log("End date is required");
        } else if (
          new Date(school?.customerSubscription?.date?.start || "").getTime() >=
          new Date(school?.customerSubscription?.date?.end || "").getTime()
        ) {
          results.subscriptionEnd = SchoolValidationErrors.DATE;
          log("Start must be before end date!");
        }

        if (canViewEnrollment) {
          const enrollment = school?.customerSubscription?.enrollment;
          if (!enrollment) {
            results.enrollment = SchoolValidationErrors.REQUIRED;
            log("Enrollment is required");
          } else if (Number(enrollment) <= 0 || Number(enrollment) >= 25000) {
            results.enrollment = SchoolValidationErrors.RANGE;
            log("Enrollment must be positive and under 25000");
          }
        }
      }
    }

    return results;
  }
}
