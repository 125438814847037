export enum SchoolValidationErrors {
  DATE = "Must be after 'Starts'",
  RANGE = "Value should be between 1 and 4999",
  EMAIL = "Email is not valid",
  REQUIRED = "Required",
}

export interface ISchoolValidationResults {
  name?: SchoolValidationErrors;
  referenceId?: SchoolValidationErrors;
  timeZone?: SchoolValidationErrors;
  email?: SchoolValidationErrors;
  district?: SchoolValidationErrors;
  enrollment?: SchoolValidationErrors;
  supportTier?: SchoolValidationErrors;
  subscription?: SchoolValidationErrors;
  subscriptionStart?: SchoolValidationErrors;
  subscriptionEnd?: SchoolValidationErrors;
}
