import React from "react";
import { Grid } from "@material-ui/core";
import { GridTypeMap } from "@material-ui/core/Grid/Grid";
import { CommonProps } from "@material-ui/core/OverridableComponent";
import { appMakeStyles } from "../Themes/app-make-styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GridStyles = appMakeStyles<IGridProps>((theme) => ({
  grid: {
    padding: (props) => props.padding || undefined,
    margin: (props) => props.margin || undefined,
    marginTop: (props) => props.marginTop || undefined,
    marginRight: (props) => props.marginRight || undefined,
    marginBottom: (props) => props.marginBottom || undefined,
    marginLeft: (props) => props.marginLeft || undefined,
    width: (props) => props.width || undefined,
    maxWidth: (props) => props.maxWidth || undefined,
    minHeight: (props) => props.minHeight || undefined,
    maxHeight: (props) => props.maxHeight || undefined,
    overflow: (props) => props.overflow || undefined,
  },
}));

export interface IGridProps
  extends Partial<CommonProps<GridTypeMap>>,
    Partial<GridTypeMap["props"]> {
  hidden?: boolean;
  padding?: number | string;
  margin?: number;
  marginTop?: number | string;
  marginRight?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;
  width?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
  overflow?: string;
}

export const AppGrid = (props: IGridProps): JSX.Element => {
  const {
    padding,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    width,
    maxWidth,
    minHeight,
    maxHeight,
    overflow,
    ...otherProps
  } = props;

  const classes = GridStyles({
    padding,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    width,
    maxWidth,
    minHeight,
    maxHeight,
    overflow,
  });
  const className = classes.grid + " " + (props.className ?? "");

  if (props.hidden) {
    return <></>;
  } else {
    if (props.container && props.item) {
      return (
        <Grid
          item
          xs={props.xs}
          sm={props.sm}
          md={props.md}
          lg={props.lg}
          xl={props.xl}
          zeroMinWidth={props.zeroMinWidth}
          className={className}
        >
          <Grid
            container
            alignContent={props.alignContent}
            alignItems={props.alignItems}
            direction={props.direction}
            justifyContent={props.justifyContent}
            spacing={props.spacing}
            wrap={props.wrap}
            className={className}
          >
            {props.children}
          </Grid>
        </Grid>
      );
    } else {
      return <Grid {...otherProps} className={className} />;
    }
  }
};
