import React from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { AppLogin } from "./Login/Login";
import { AppRequestPasswordReset } from "./RequestPasswordReset/RequestPasswordReset";
import { AppPasswordReset } from "./PasswordReset/PasswordReset";

export const Auth = (): JSX.Element => {
  return (
    <>
      <Switch>
        <Route
          path="/request-password-reset"
          component={AppRequestPasswordReset}
          exact={true}
        />
        <Route
          path="/reset-password/:token"
          component={AppPasswordReset}
          exact={true}
        />
        <Route path="/" component={AppLogin} />
      </Switch>
    </>
  );
};

export const AppAuth = withRouter(Auth);
