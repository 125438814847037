import React from "react";
import {
  CardContent as MuiCardContent,
  CardContentProps,
} from "@material-ui/core";
import { appMakeStyles } from "../../Themes/app-make-styles";
import { PaletteTypes } from "../../Themes/palette-types.enum";

const CardContentStyles = appMakeStyles<ICardContentProps>((theme) => ({
  cardContent: {
    padding: (props) =>
      (typeof props.padding === "number" ? props.padding : 24) +
      "px !important",
    maxHeight: (props) => props.maxHeight || undefined,
    overflow: (props) => props.overflow || "hidden",
    borderRadius: "6px",
    background: (props) => {
      return props.background
        ? theme.palette[props.background]?.main
        : theme.palette.background.paper;
    },
    color: (props) => {
      return props.background
        ? theme.palette[props.background]?.contrastText
        : theme.palette.text.primary;
    },
  },
}));

export interface ICardContentProps extends CardContentProps {
  padding?: number;
  maxHeight?: string;
  overflow?: string;
  background?: PaletteTypes;
}

export const AppCardContent = (props: ICardContentProps): JSX.Element => {
  const { padding, maxHeight, overflow, background, ...otherProps } = props;

  const classes = CardContentStyles({
    padding,
    maxHeight,
    overflow,
    background,
  });
  return <MuiCardContent {...otherProps} className={classes.cardContent} />;
};
